import React, { useEffect, useState } from "react";
import moment from "moment";
import Moment from "react-moment";
import "moment/locale/ko";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BarcodeScanner } from '@ionic-native/barcode-scanner';
import AxiosConfig from '../../services/AxiosConfig';
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import { AxiosWaug } from "../app-mypass/AppMyPassRegister";
import { isPlatform } from "@ionic/react";

const classNames = require("classnames");

interface IQrInfo {
    result?:string;
    uid?:number;   // 217432,
    status?:number;   // 6,
    productUid?:number;   // 4,
    amount?:number;   // 1,
    orderUid?:number;   // 126382,
    qrCode?:string;    //"wkh52zc0e5",
    qrMgmtCode?:string;    //"48m-wkh52zc0e5-0-21-000047-DS",
    memo?:string;    //"모바일 QR",
    groupCode?:number;   // null,
    createDate?:string;    //"2021-05-25T11:14:42",
    updateDate?:string;    //"2021-07-23T17:40:24",
    expireDate?:string;    //"2024-05-25T11:14:42",
    managingTitle?:string;    //"디스커버 서울패스 모바일 48시간권",
    koTitle?:string;    //"디스커버 서울패스 모바일 48시간권",
    enTitle?:string;    //"Discover Seoul Pass Mobile 48hours",
    jaTitle?:string;    //"モバイルディスカバーソウルパス 48時間券",
    zhCnTitle?:string;    //"电子首尔转转卡 48小时券",
    zhTwTitle?:string;    //"電子首爾轉轉卡 48小時券",
    purchaseDate?:string;    //"2021-05-25T11:14:42",
    passtypeCode?:number;   // 2,
    passtypeUid?:number;   // 4,
    passtypeTime?:number;   // 48,
    filename?:string;    //"1611652593792-a23c2518-56e3-4eb7-a7e8-457bed692c4e.png",
    filepath?:string;    //"/newproducts/1611652593792-a23c2518-56e3-4eb7-a7e8-457bed692c4e.png",
    fullpath?:string;    //"https://discoverseoulpass-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/uploads/newproducts/1611652593792-a23c2518-56e3-4eb7-a7e8-457bed692c4e.png",
    extension?:string;    //"png"
}

const ExpireChecker = (props:any) => {
    const WAUG_KEY_LENGTH = 8;
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const [qrInfo, setQrInfo] = useState<IQrInfo>(null);
    const [isNotFound, setNotFound] = useState<boolean>(false);
    const [inputQrCode, setInputQrCode] = useState<string>("");

    const fetchQrInfo = async (qrCode:string) => {
        if (qrCode.length < 10) {
            alert("more than 10");
            return;
        }

        let url = `/api2/myPass/findQr/${qrCode}`;
        const res = await AxiosConfig.get(url);

        if (res.status === 200) {
            setQrInfo(res.data);
            setNotFound(false);
        } else {
            // alert(t("expirechecker.alert_msg_not_found"));
            setNotFound(true);
        }

    }

    // const postWaugQrcode = async (qrcodeText:string) => {
    //     const url = `/external/dsp/`;
    //     // const data = JSON.stringify({
    //     //     "waug_key":qrcode
    //     // });
    //     const data = qs.stringify({
    //         "waug_key":qrcodeText
    //     });
    //     const res = await AxiosWaug.instance.post(url,
    //         data,
    //         {
    //             headers: {'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'}
    //         }
    //      );
    //      console.log(JSON.stringify(res));
    //     if (res.status == 200 && res.data.retcode == 1 ) {
    //         // 성공시
    //         // {
    //         //     "retcode":0,
    //         //     "retmsg":"",
    //         //     "qrcode":"ABCDEFGHIJ",
    //         //     "pass":{
    //         //         "qr_code":"ABCDEFGHIJ"
    //         //     }
    //         // }
    //         // alert(res.data)
    //         // setResult(res.data);

    //         // setQrcode(res.data.qrcode);
    //         postFindDspQrcode(res.data.qrcode, 2); //waug에서 응답받은 qr을 넣어서 등록
    //     } else if (res.data.retcode == -1 || res.data.retcode == -99 ) {
    //         // 실패시
    //         // {"retcode":-1,
    //         // "retmsg":"일련번호가 입력되지 않았습니다."}
    //         // {"retcode":-99,
    //         // "retmsg":""일련번호가 일치하지 않습니다.\n다시 한번 확인해 주세요."}

    //         alert(res.data.retmsg);
    //     } else {
    //         console.error(`HTTP: ${res.status} 오류 발생`) //"존재하지 않는 패스 입니다."24p-fwkzeatcya-0-21-000030-DS
    //     }
        
    // }

    
    useEffect(() => {
        
        // fetchNoticeInfo();
        // fetchPrevAndNextNoticeInfo();
    }, [globalInfoStore.currentLangType]);

    const onInputQrCodeChange = (e: any) => {
        console.log(e.type + ":", e.target.value);
        setInputQrCode(e.target.value);
        setNotFound(false);
        setQrInfo(null);
    }

    const onInputQrCodeKeyDown = (e: any) => {
		if (e.keyCode === 13) {
			fetchQrInfo(inputQrCode)
		}
	}

    const postFindQrcode = async (qrcodeText:string) => {
        if (qrcodeText.length < WAUG_KEY_LENGTH ) {
            alert("QRCode length is More than or equals to 8 characters");
            return;
        }
        // if (qrcodeText.length === WAUG_KEY_LENGTH ) { //8자리는 무조건 와그 (설PM)
        //     postWaugQrcode(qrcodeText);
        // } else {
            fetchQrInfo(qrcodeText);
        // }
    }

    const openScanner = async () => {
        const data = await BarcodeScanner.scan();
        console.log(`Barcode data: ${data.text}`);
        setInputQrCode(data.text);
        postFindQrcode(data.text);
    };
    
    return (
        // <h6 className="hidden">공지사항 내용</h6>
        <div className="layout">
            
            <div className="wrap_expiration">
                {/* <p className="exp_tit">패스유효기간 조회</p>
                <p className="exp_txt">보유하고 계신 디스커버서울패스 QR일련번호를 입력하여 <br className="m_Non"/>패스유효기간을 조회하실 수 있습니다.</p>
                <span className="small">*패스 유효 기간: 발행일로부터 5년</span>
                <img src="img/expriation.png" alt="이용권 이미지" />
                <input type="text" placeholder="QR일련번호 입력" />
                <button>유효기간 조회</button>

                {/* <!-- .result : 코드 입력후 나타나는 부분  --> */}
                {/* <p className="result">패스유효기간 2025-06-01</p> } */}

                <p className="exp_tit">{t("expirechecker.title")}</p>
                <p className="exp_txt" dangerouslySetInnerHTML={{__html:t("expirechecker.desc")}}></p>
                <span className="small">{t("expirechecker.small_desc")}</span>
                <img src={require("../../resources/img/expiration_new.png")} alt={t("expirechecker.img_desc")} />

                {/* <!-- 210817 수정- 오류메세지: input에 'focus' 클래스 추가 / txt_alert 활성화 --> */}
                <input className={classNames({focus:isNotFound})} type="text" title={t("expirechecker.input_qrcode_placeholder")}
                            placeholder={t("expirechecker.input_qrcode_placeholder")}
                            value={inputQrCode}
							onChange={onInputQrCodeChange}
							onKeyDown={(e) => onInputQrCodeKeyDown(e)} />

                { isNotFound?<p className="txt_alert">조회 불가능한 패스입니다.</p>:""}
                
                
                { ((isPlatform("capacitor") && isPlatform("android")) || (isPlatform("capacitor") && isPlatform("ios"))
                )?
                 <>
                     <span className="txt">or</span>
                     <button className="btn_scan" onClick={openScanner}>{t("expirechecker.scan_qrcode")}</button>
                 </>
                :
                 <></>
                }
                <button className="btn_view" onClick={()=>{fetchQrInfo(inputQrCode)}}>{t("expirechecker.btn_expire_checker")}</button>

                {/* <!-- .result : 코드 입력후 나타나는 부분  --> */}

                {
                    (qrInfo !== null && qrInfo.expireDate !== null && isNotFound === false)
                    ?
                    <>
                    <p className="result">{t("expirechecker.expirecheck_date")}
                        &nbsp;
                        <Moment format="YYYY-MM-DD HH:mm:ss">
                        { qrInfo.expireDate }
                        </Moment>
                        &nbsp;
                        Asia/Seoul
                    </p>
                    <span className="small">{t("expirechecker.small_desc_2")}</span>
                    </>
                    :<></>
                }
            </div>

        </div>
    );
};

export default ExpireChecker;