import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Axios, { CancelTokenSource } from 'axios';
import Select from 'react-select/src/Select';
import validator from 'validator' 
import { globalInfoStore } from "../stores/GlobalInfoStore";
import { useHistory } from "react-router";

const SignUpPrivacyDesc2 = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    return (
        <div className="greyBox">
            <dl>
                <dt>{t("sign_up_with_email.Items")}</dt>
                <dd dangerouslySetInnerHTML={{__html:t("sign_up_with_email.Items_d")}}></dd>
            </dl>
            <dl>
                <dt>{t("sign_up_with_email.purpose")}</dt>
                <dd>{t("sign_up_with_email.purpose_d")}</dd>
            </dl>
            <dl>
                <dt>{t("sign_up_with_email.retention")}</dt>
                <dd>{t("sign_up_with_email.retention_d")}</dd>
            </dl>
        </div>
    );
};

export default SignUpPrivacyDesc2;