import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { globalInfoStore } from "../../stores/GlobalInfoStore";
// import useDspLocalStorage from "../../services/UseDspLocalStorage";
import { useLocalStorage } from "react-use";

interface RecentKeywordProps {
    // searchKeyword?: string;
}

const RecentKeyword: React.FC<RecentKeywordProps> = (props: RecentKeywordProps) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [recentKeyword, setRecentKeyword, removeRecentKeyword] = useLocalStorage('recentKeyword', []);

    useEffect(() => {

    }, [globalInfoStore.currentLangType, recentKeyword.length]);

    const handleSearchKeyword = (e:any, searchKeyword:string) => {
        history.push(`/app/search/index?page=1&searchKeyword=${searchKeyword}&searchType=total`);
    }

    const handleDelete = (e:any, keyword: string) => {
        e.preventDefault();
        removeRecentKeywordIndex(keyword);
    }

    const removeRecentKeywordIndex = (keyword:string) => {
        const index = recentKeyword.indexOf(keyword);
        if (index > -1) recentKeyword.splice(index, 1);
        setRecentKeyword(recentKeyword);
    }
    
    const recentKeywordItems = (recentKeyword || []).map((item, index) =>{
        return (
            <Fragment key={index}>
                <p onClick={(e)=>handleSearchKeyword(e, item)}>{item}<span className="btn_delete" onClick={(e)=>handleDelete(e, item)}>Delete</span></p>
            {/* // <p>BTS Edition<span className="btn_delete" onClick={()=>handleDelete(item.uid)}>삭제</span></p>
            // <p>BTS Edition<span className="btn_delete">삭제</span></p>
            // <p>Seoul station<span className="btn_delete">삭제</span></p>
            // <p>Card pass<span className="btn_delete">삭제</span></p> */}
            </Fragment>
        );
    });

    const deleteAll = () => {
        // console.log("전부 삭제")
        setRecentKeyword([]);
    }

    return (
        <dl className="recent">
            {/* <dt>최근 검색어</dt> */}
            <dt>{t("search_v2.recent_search")}</dt>
            <dd>
                {recentKeywordItems}

                <span className="all_delete" onClick={deleteAll}>{t("search_v2.delete_all")}</span>
            </dd>
        </dl>
    );
};

export default RecentKeyword;