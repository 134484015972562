import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider, { Settings } from "react-slick";
import AxiosConfig from '../../services/AxiosConfig';
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import AccordionOfflineItem from "./GuideBuyOfflineAccordionItem";
import GuideBuyOfflineItem from "./GuideBuyOfflineItem";
import SliderOfflineItem from "./GuideBuyOfflineSliderItem";

export interface StoreInfo {
    uid: number;
    type: string;
    status: number;
    workerUid: number;
    latitude:  string; //37.569330820501;
    longitude:  string; //126.97675255593;
    title:  string; //서울시티투어버스;
    tel:  string; //+82-2-777-6090;
    partnerUid: number;
    sort: number;
    contract:  string; //2;
    memo:  string; //;
    createDate:  string; //2018-02-05T16:24:05;
    updateDate:  string; //2020-11-11T21:18:59;
    workDate:  string; //2018-06-18T15:31:04;
    relationHourInfo?:RelationHourInfo[],
    relationStoreContentsInfo?:RelationStoreContentsInfo[]
    fullpath: string;
    mapUrl: string;
}

export interface RelationHourInfo {
    uid: number;
    code: number;
    subCode: number;
    weekCode: number;
    storeUid: number;
    contentsUid: number;
    hour: string;
    closed: string;
    createDate: string;
    updateDate: string;
}


export interface RelationStoreContentsInfo  {
    code      :number;
    storeUid  :number;
    title     :string;
    address   :string;
    holiday   :string;
    createDate:string;
    updateDate:string;
}

export interface IframeMapData {
  lang?:string;
  lat:number;
  lon:number;
  uid?:number;
  type?:number; //tourinfo
  title?:string;
}

export interface IPostMessage {
  source:string;
  type:string;  //select | pins
  select:IframeMapData;
  pins?:IframeMapData[];
}

const GuideBuyOffline = () => {
    const { t, i18n } = useTranslation();

    let customSlider = useRef(Array(1));
    const [currentSlide, setCurrentSlide] = useState<number>(1);

    const [offlineItems, setOfflineItems] = useState([]);
    const [filteredOfflineItems, setFilteredOfflineItems] = useState<Array<StoreInfo>>([]);
    const [baseStoreInfo, setBaseStoreInfo] = useState<IframeMapData>({lat:37.565067081436,lon:126.98498111218});
    {/* 명동관광||lat = 37.565067081436, lon = 126.98498111218 기본 좌표 */}
    // setBaseStoreInfo({lat:37.565067081436,lon:126.98498111218})
    const imapRef = useRef(Array(1))




    const fetchOfflineItems = async () => {
        // const res = await AxiosConfig.get("/api2/tourInfo?langCode=1&page1&size=12");
        // let url = `/api2/tourInfo/${props.match.params.uid}?langCode=${globalInfoStore.currentLangType.langCode}`;
        // const url = `/api2/tourInfo?langCode=${globalInfoStore.currentLangType.langCode}&page1&size=12`

        const url = `/api2/storeInfo?size=1000`;

        const res = await AxiosConfig.get(url);
        console.log(res.data);
        setOfflineItems(res.data);
        //FIXME: DB의 데이터가 올바르지 않으면 오류발생.. partnerUid null 관련 API 처리 필요
        // const list = res.data.content.filter((it:any) =>  it)
        const list = res.data.content.filter((it:any) => {
          if (it.relationStoreContentsInfo.length > 0)
          return it;
        })
        console.log(list);
        setFilteredOfflineItems(list)
        console.log(filteredOfflineItems);
        
    };

    // useEffect(() => {
    //     fetchOfflineItems();
    // }, [globalInfoStore.currentLangType]);


    useEffect(() => {
        fetchOfflineItems();
    }, [globalInfoStore.currentLangType, baseStoreInfo.uid]);

    let sliderSettings: Settings = {
      centerMode: true,
      infinite: true,
      arrows: false,
      lazyLoad: "progressive",
      responsive: [
        {
          breakpoint: 100000,
          settings: "unslick",
        },
        {
          breakpoint: 750,
          settings: {
            centerMode: false,
            // centerPadding: "20px",
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            infinite: true,
          },
        },
      ],
      afterChange: (currentSlide: number) => {
        setCurrentSlide(currentSlide + 1);
        handleChangeMapLocation(filteredOfflineItems[currentSlide])
      },
    };

    const handleChangeMapLocation = (item:StoreInfo) => {
      // console.log("parent" + item.uid);
      // setBaseStoreInfo({
      //   lat:parseFloat(item.latitude),
      //   lon:parseFloat(item.longitude),
      //   title:item.title,
      //   uid:item.uid
      // })

      // const postMessage:IPostMessage = {
      //   source:"tourinfo",
      //   type:"select",
      //   select:baseStoreInfo
      // }

      // handleIframePostMessage(postMessage);
    }

    // const handleChangeMapLocation = (item:StoreInfo) => {
    //   changeMapLocation(item)
    // }

    const renderAccordionOfflineItems = (filteredOfflineItems || []).map((item) =>{
        if(item.uid === 1) { item.mapUrl = 'https://naver.me/GNyGu4aJ'; } //Myeong-dong Tourist Information Center (overseas sales and pass refund processing)
        else if(item.uid === 6) { item.mapUrl = 'https://naver.me/5nPjdp4Y'; } //Dongdaemun Tourist Information Center
        else if(item.uid === 98) { item.mapUrl = 'https://naver.me/FCbP4LAB'; } //Seoul Tourism Plaza Tourist Information Center
        else if(item.uid === 101) { item.mapUrl = 'https://naver.me/x5WrOhkv'; } //Incheon International Airport Terminal 1 1F Bookstore(Cash only)
        else if(item.uid === 102) { item.mapUrl = 'https://naver.me/Gcjs3cTg'; } //Incheon International Airport Terminal 1 3F Bookstore(Cash only)
        else if(item.uid === 103) { item.mapUrl = 'https://naver.me/FgiRfNcg'; } //Incheon International Airport Terminal 2 3F Bookstore(Cash only)

        return (<GuideBuyOfflineItem 
          key={item.uid} 
          content={item} 
          storeContents={item.relationStoreContentsInfo} 
          click={handleChangeMapLocation}
          ></GuideBuyOfflineItem>)
    });
    
    const renderSliderOfflineItems = (filteredOfflineItems || []).map((item) =>{
        return (<SliderOfflineItem key={item.uid} content={item} ></SliderOfflineItem>)
    });

    // const renderSliderItems = () => {
    //     if ( window.innerWidth > 750 ) {
    //         return {renderAccordionOfflineItems};
    //     }
    //     return {renderSliderOfflineItems}
    // });

    const next = () => {
      customSlider.current[0].slickNext();
      // console.log("next" + customSlider);
    };
    const previous = () => {
      customSlider.current[0].slickPrev();
      // console.log("prev:" + customSlider);
    };
    const [contentRef, setContentRef] = useState(null)

    const handleIframePostMessage = (item:IPostMessage) => {
      console.log("process.env.REACT_APP_API_URL::" + process.env.REACT_APP_API_URL);
      contentRef.contentWindow.postMessage( item, process.env.REACT_APP_API_URL );
    }
    
    return (
        <div className="wrap_use">
            <p className="use_tit">{t("buy_offline.title")}<span dangerouslySetInnerHTML={{__html:t("buy_offline.go_map")}}></span></p>
            <p className="use_exp">{t("buy_offline.desc")}</p>
            
            <div className="offline_spot">
                {renderAccordionOfflineItems}
            </div>

            <p className="use_tit small">{t("buy_offline.small_title")}</p>
            <div className="exp_box last" dangerouslySetInnerHTML={{__html:t("buy_offline.small_desc")}}>
            </div>
        </div>
    );
};

export default GuideBuyOffline;