import React from 'react';
import { useBarcode } from 'react-barcodes';

const Barcode = (props:{orderNo:string}) => {
    	const { inputRef } = useBarcode({
		value: props.orderNo,
		options: {
		    background: '#ffffff',
            width: 3,
            textAlign: 'center'
		}
	});

    return (
        <img ref={inputRef} width="70%" />
    );
};

export default Barcode;