/* eslint-disable @typescript-eslint/no-unused-vars */
import { ConsoleLogObserver } from 'ionic-appauth';
import { observer, useLocalObservable } from 'mobx-react';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useLocation } from 'react-router';
import { Link } from "react-router-dom";
import { globalInfoStore } from '../../stores/GlobalInfoStore';
import useStore from '../../stores/UseRootStore';
import ProfileNavLeaveId from '../mypage/profile/ProfileNavLeaveId';
import ProfileNavModify from '../mypage/profile/ProfileNavModify';
import ProfileNavPassword from '../mypage/profile/ProfileNavPassword';
import { useCookie } from 'react-use';


const classNames = require("classnames");

const Navigation = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();

    //서브페이지 네비게이션
    // $('nav .clear .depth1').click(function(){
    //     $(this).toggleClass('active');
    // });

    // const [isShowDepth1Menu, setIsShowDepth1Menu] = useState(true);
    // const [isShowDepth2Menu, setIsShowDepth2Menu] = useState(false);
    // const [isShowDepth3Menu, setIsShowDepth3Menu] = useState(false);
    const [isShowDepth1MenuActive, setIsShowDepth1MenuActive] = useState(false);
    
    
    
//    const [value, updateCookie, deleteCookie] = useCookie("dspJwt");
    
    // useEffect(() => {
    //     effect
    //     return () => {
    //         cleanup
    //     }
    // }, [input])

    // const { globalInfoStore } = useStore();




    const Depth1Menu = () => {
        const [isShowDepth1Menu, setIsShowDepth1Menu] = useState(true);
        switch (location.pathname) {
            case "/app/signin/index/login":
            case "/app/signin/index/signup":
            case "/app/signin/index/findid":
            case "/app/signin/index/findpw":
                return (null);
            case "/app/search":
                return (<ul>
                    <li><Link to="/app/search">{t("navigation.app_depth1title_search")}</Link></li>
                </ul>)
            case "/app/policy/terms":
                return (<ul>
                    <li><Link to="/app/policy/terms">{t("navigation.app_depth1title_policy_terms")}</Link></li>
                </ul>)
            case "/app/policy/location":
                return (<ul>
                    <li><Link to="/app/policy/location">{t("navigation.app_depth1title_policy_location")}</Link></li>
                </ul>)
            default:
                // if(isShowDepth1Menu){
                    return (
                        <ul>
                            <li><Link to="/app/guide/index/passguide">{t("navigation.app_depth1title_passguide")}</Link></li>
                            <li><Link to="/app/guide/index/buy">{t("navigation.app_depth1title_how_to_purchase")}</Link></li>
                            <li><Link to="/app/guide/index/how">{t("navigation.app_depth1title_how_to_use")}</Link></li>
                            <li><Link to="/app/pass">{t("navigation.app_depth1title_pass")}</Link></li>
                            <li><Link to="/app/spot/transportation">{t("navigation.app_depth1title_transportation")}</Link></li>
                            <li><Link to="/app/spot/arex">{t("navigation.app_cs_index_arexqr")}</Link></li>
                            <li><Link to="/app/spot/others">{t("navigation.app_depth1title_others")}</Link></li>
                            <li><Link to="/app/cs">{t("navigation.app_depth1title_cs")}</Link></li>
                            { globalInfoStore.getDspJwt() && <li><Link to="/app/mypage">{t("navigation.app_depth1title_mypage")}</Link></li>}
                            
                        </ul>
                    )
                // }
                // return (null)
        }
    }

    const NavPassguide = () => {
        return (<ul>
            <li><Link to="/app/guide">{t("navigation.app_passguide")}</Link></li>
            <li><Link to="/app/guide/index/buy">{t("navigation.app_passguide_index_buy")}</Link></li>
            <li><Link to="/app/guide/index/how">{t("navigation.app_passguide_index_how")}</Link></li>
        </ul>)
    }



    const NavSpot = () => {
        return (<ul>
            <li><Link to="/app/spot">{t("navigation.app_spot")}</Link></li>
            <li><Link to="/app/spot/transportation/index">{t("navigation.app_attraction_transportation")}</Link></li>
            <li><Link to="/app/spot/arex">{t("navigation.app_depth2title_cs_index_arexqr")}</Link></li>
            <li><Link to="/app/spot/others/index">{t("navigation.app_others")}</Link></li>
        </ul>)
    }

    const NavPass = () => {
        return (<ul>
            <li><Link to="/app/pass">{t("navigation.app_pass")}</Link></li>
        </ul>)
    }

    const NavMypage = () => {
        return (
            <ul>
                <li><Link to="/app/mypage/profile">{t("navigation.app_mypage_profile")}</Link></li>
                <li><Link to="/app/mypage/mypass">{t("navigation.app_mypage_mypass")}</Link></li>
                <li><Link to="/app/mypage/myesim">{t("navigation.app_mypage_myesim")}</Link></li>
                <li><Link to="/app/mypage/orderhistory">{t("navigation.app_mypage_orderhistory")}</Link></li>
                <li><Link to="/app/mypage/inquiry">{t("navigation.app_mypage_inquiry")}</Link></li>
                <li><Link to="/app/mypage/interest">{t("navigation.app_mypage_interest")}</Link></li>
            </ul>
        )
    }
    const NavMypageProfile = () => {
        return (
        <ul>
            <li><Link to="/app/mypage/profile/index">{t("navigation.app_mypage_profile_index")}</Link></li>
            <li><Link to="/app/mypage/profile/password">{t("navigation.app_mypage_profile_password")}</Link></li>
            <li><Link to="/app/mypage/profile/modify">{t("navigation.app_mypage_profile_modify")}</Link></li>
            <li><Link to="/app/mypage/profile/leaveid">{t("navigation.app_mypage_profile_leaveid")}</Link></li>
            {/* <li><Link to="/app/mypage/profile/leaveid/complete">{t("navigation.app_mypage_profile_leaveid_complete")}</Link></li> */}
        </ul>
        )
    }

    // const NavMypageMypass = () => {
    //     return (
    //         <ul>
    //             <li><Link to="/app/mypage/mypass">내 패스</Link></li>
    //         </ul>
    //     )
    // }

    const NavMypageOrderhistory = () => {
        return (<ul>
            <li><Link to="/app/mypage/orderhistory">{t("navigation.app_mypage_orderhistory")}</Link></li>
        </ul>)
    }

    const NavMypageInquiry = () => {
        return (<ul>
            <li><Link to="/app/mypage/inquiry">{t("navigation.app_mypage_inquiry")}</Link></li>
        </ul>)
    }

    const NavMypageInterest = () => {
        return (<ul>
            <li><Link to="/app/guide">{t("navigation.app_passguide")}</Link></li>
        </ul>)
    }

    // const NavPassguideHow = () => {
    //     return (<ul>
    //         <li><Link to="/app/guide/index/how/cardpass">실물패스</Link></li>
    //         <li><Link to="/app/guide/index/how/mobilepass">모바일패스</Link></li>
    //         <li><Link to="/app/guide/index/how/arex">공항철도</Link></li>
    //         <li><Link to="/app/guide/index/how/bikeseoul">따릉이</Link></li>
    //     </ul>)
    // }

    // function depth1Login() {
    //   return <span>로그인</span>;
    // }

    // function depth1SignUp() {
    //   return <span>회원가입</span>;
    // }

    // function depth1FindId() {
    //   return <span>이메일(아이디) 찾기</span>;
    // }

    // function depth1FindPw() {
    //   return <span>비밀번호 찾기</span>;
    // }

    const toggleDepth1MenuActive = () => {
        setIsShowDepth1MenuActive(!isShowDepth1MenuActive);
    }

    const NavAboutPass = () => {
        return (<ul>
            <li><Link to="/app/guide/index/passguide">{t("navigation.app_passguide")}</Link></li>
        </ul>)
    }

    const NavHowToPurchase = () => {
        return (<ul>
            <li><Link to="/app/guide/index/buy/online">{t("navigation.app_depth2title_how_to_purchase_online")}</Link></li>
            <li><Link to="/app/guide/index/buy/offline">{t("navigation.app_depth2title_how_to_purchase_offline")}</Link></li>
            <li><Link to="/app/guide/index/buy/cu">{t("navigation.app_depth2title_how_to_purchase_cu")}</Link></li>
            <li><Link to="/app/guide/index/buy/takepass">{t("navigation.app_depth2title_how_to_purchase_pickup")}</Link></li>
        </ul>)
    }

    const NavHowToUse = () => {
        return (<ul>
            <li><Link to="/app/guide/index/how/freeentry">{t("navigation.app_depth2title_how_to_use_freeentry")}</Link></li>
            <li><Link to="/app/guide/index/how/coupon">{t("navigation.app_depth2title_how_to_use_coupon")}</Link></li>
            <li><Link to="/app/guide/index/how/gift">{t("navigation.app_depth2title_how_to_use_gift")}</Link></li>
            <li><Link to="/app/guide/index/how/registerpass">{t("navigation.app_depth2title_how_to_use_registerpass")}</Link></li>
        </ul>)
    }

    const NavBuyPass = () => {
        return (<ul>
            <li><Link to="/app/pass">{t("navigation.app_route_pass")}</Link></li>
        </ul>)
    }

    const NavSpotTransportation = () => {
        return (<ul>
            <li><Link to="/app/spot/transportation/index/topup">{t("navigation.app_depth2title_attraction_transportation_topup")}</Link></li>
            <li><Link to="/app/spot/transportation/index/arex">{t("navigation.app_depth2title_attraction_transportation_arex")}</Link></li>
            <li><Link to="/app/spot/transportation/index/bikeseoul">{t("navigation.app_depth2title_attraction_transportation_ttareungi")}</Link></li>
            <li><Link to="/app/spot/transportation/index/airportbus">{t("navigation.app_depth2title_attraction_transportation_airportbus")}</Link></li>
        </ul>)
    }

    const NavSpotOthers = () => {
        return (<ul>
            <li><Link to="/app/spot/others">{t("navigation.app_depth1title_others")}</Link></li>
        </ul>)
    }

    const NavCs = () => {
        return (<ul>
            <li><Link to="/app/cs/index/faq">{t("navigation.app_cs_index_faq")}</Link></li>
            <li><Link to="/app/cs/index/onetoone">{t("navigation.app_cs_index_onetoone")}</Link></li>
            <li><Link to="/app/cs/index/notice">{t("navigation.app_cs_index_notice")}</Link></li>
            <li><Link to="/app/cs/index/expirechecker">{t("navigation.app_cs_index_expirechecker")}</Link></li>
        </ul>)
    }

    const Depth1Title = (event:any) => {
        
        return (<Switch>
            <Route path="/app/search" component={()=>(<span>{t("navigation.app_route_search")}</span>)}></Route>
            <Route path="/app/guide/index/passguide" component={()=>(<span>{t("navigation.app_route_passguide")}</span>)}></Route>
            <Route path="/app/guide/index/buy" component={()=>(<span>{t("navigation.app_depth1title_how_to_purchase")}</span>)}></Route>
            <Route path="/app/guide/index/how" component={()=>(<span>{t("navigation.app_depth1title_how_to_use")}</span>)}></Route>
            <Route path="/app/spot/index/list" component={()=>(<span>{t("navigation.app_depth1title_spot")}</span>)}></Route> 
            <Route path="/app/spot/transportation" component={()=>(<span>{t("navigation.app_depth1title_transportation")}</span>)}></Route> 
            <Route path="/app/spot/arex" component={()=>(<span>{t("navigation.app_cs_index_arexqr")}</span>)}></Route> 
            <Route path="/app/spot/others" component={()=>(<span>{t("navigation.app_depth1title_others")}</span>)}></Route>
            <Route path="/app/cs" component={()=>(<span>{t("navigation.app_route_cs")}</span>)}></Route> 
            <Route path="/app/pass" component={()=>(<span>{t("navigation.app_route_pass")}</span>)}></Route> 
            <Route path="/app/signin/index/login" component={()=>(<span>{t("navigation.app_route_signin_index_login")}</span>)}></Route> 
            <Route path="/app/signin/index/signup" component={()=>(<span>{t("navigation.app_route_signin_index_signup")}</span>)}></Route> 
            <Route path="/app/signin/index/findid" component={()=>(<span>{t("navigation.app_route_signin_index_findid")}</span>)}></Route> 
            <Route path="/app/signin/index/findpw" component={()=>(<span>{t("navigation.app_route_signin_index_findpw")}</span>)}></Route> 
            <Route path="/app/mypage" component={()=>(<span>{t("navigation.app_route_mypage")}</span>)}></Route> 
        </Switch>)
    }

    const Depth2Menu = () => {
        const [isShowDepth2Menu, setIsShowDepth2Menu] = useState(false);
        const [depth2title, setDepth2title] = useState('');

        const [isShowDepth2MenuActive, setIsShowDepth2MenuActive] = useState(false);

        useEffect(() => {
            // if (location.pathname.startsWith("/app/guide/index/passguide")) {  setDepth2title(t("navigation.app_depth2title_passguide_index_passguide")); setIsShowDepth2Menu(true);}
            // else 
            if (location.pathname.startsWith("/app/guide/index/buy/online")) {setDepth2title(t("navigation.app_depth2title_how_to_purchase_online")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/guide/index/buy/offline")) {  setDepth2title(t("navigation.app_depth2title_how_to_purchase_offline")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/guide/index/buy/cu")) {  setDepth2title(t("navigation.app_depth2title_how_to_purchase_cu")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/guide/index/buy/takepass")) {  setDepth2title(t("navigation.app_depth2title_how_to_purchase_pickup")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/guide/index/how/freeentry")) {setDepth2title(t("navigation.app_depth2title_how_to_use_freeentry")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/guide/index/how/coupon")) {  setDepth2title(t("navigation.app_depth2title_how_to_use_coupon")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/guide/index/how/gift")) {  setDepth2title(t("navigation.app_depth2title_how_to_use_gift")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/guide/index/how/registerpass")) {  setDepth2title(t("navigation.app_depth2title_how_to_use_registerpass")); setIsShowDepth2Menu(true);}
            //else if (location.pathname.startsWith("/app/guide/index/how")) {  setDepth2title(t("navigation.app_depth2title_passguide_index_how")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/spot/transportation/index/topup")) {  setDepth2title(t("navigation.app_depth2title_attraction_transportation_topup")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/spot/transportation/index/arex")) {  setDepth2title(t("navigation.app_depth2title_attraction_transportation_arex")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/spot/transportation/index/bikeseoul")) {  setDepth2title(t("navigation.app_depth2title_attraction_transportation_ttareungi")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/spot/transportation/index/airportbus")) {  setDepth2title(t("navigation.app_depth2title_attraction_transportation_airportbus")); setIsShowDepth2Menu(true);}
            //else if (location.pathname.startsWith("/app/spot/arex")) {  setDepth2title(t("globalnavbar.arexqr")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/spot/others")) {  setDepth2title(t("globalnavbar.attractions_others")); setIsShowDepth2Menu(true);}
            //else if (location.pathname.startsWith("/app/spot")) {  setDepth2title(t("globalnavbar.attraction_info")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/rcourse")) {  setDepth2title(t("navigation.app_depth2title_spot")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/cs/index/faq")) {  setDepth2title(t("navigation.app_depth2title_cs_index_faq")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/cs/index/onetoone")) {  setDepth2title(t("navigation.app_depth2title_cs_index_onetoone")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/cs/index/notice")) {  setDepth2title(t("navigation.app_depth2title_cs_index_notice")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/cs/index/expirechecker")) {  setDepth2title(t("navigation.app_depth2title_cs_index_expirechecker")); setIsShowDepth2Menu(true);}
            //else if (location.pathname.startsWith("/app/pass")) {  setDepth2title(t("navigation.app_depth2title_pass")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/pass/view")) {  setDepth2title(t("navigation.app_depth2title_pass_view")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/mypage/profile")) {  setDepth2title(t("navigation.app_mypage_profile")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/mypage/profile/index")) {  setDepth2title(t("navigation.app_depth2title_mypage_profile_index")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/mypage/profile/modify")) {  setDepth2title(t("navigation.app_depth2title_mypage_profile_modify")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/mypage/profile/password")) {  setDepth2title(t("navigation.app_depth2title_mypage_profile_password")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/mypage/profile/leaveid")) {  setDepth2title(t("navigation.app_depth2title_mypage_profile_leaveid")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/mypage/profile/leaveid/complete")) {  setDepth2title(t("navigation.app_depth2title_mypage_profile_leaveid_complete")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/mypage/mypass")) {  setDepth2title(t("navigation.app_depth2title_mypage_mypass")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/mypage/myesim")) {  setDepth2title(t("navigation.app_depth2title_mypage_myesim")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/mypage/orderhistory")) {  setDepth2title(t("navigation.app_depth2title_mypage_orderhistory")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/mypage/inquiry")) {  setDepth2title(t("navigation.app_depth2title_mypage_inquiry")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/mypage/interest")) {  setDepth2title(t("navigation.app_depth2title_mypage_interest")); setIsShowDepth2Menu(true);}
            else if (location.pathname.startsWith("/app/mypage/ordervoucher")) {  setDepth2title(t("navigation.app_depth2title_mypage_orderhistory")); setIsShowDepth2Menu(true);}
            else {setIsShowDepth2Menu(false)}
        },[])
        // return () => {}
        
        if(!isShowDepth2Menu || depth2title === "") {
            return (<></>)
        }
        
        return (
            <li className={classNames({ depth2: true, active: isShowDepth2MenuActive })} onClick={(e)=>{setIsShowDepth2MenuActive(!isShowDepth2MenuActive)}}>
                <span>{depth2title}</span>
                <Switch>
                    {/* <Route path="/app/guide/index/passguide" component={NavAboutPass} /> */}
                    <Route path="/app/guide/index/buy" component={NavHowToPurchase} />
                    <Route path="/app/guide/index/how" component={NavHowToUse} />
                    <Route path="/app/spot/transportation" component={NavSpotTransportation} />
                    <Route path="/app/spot/others" component={NavSpotOthers} />
                    <Route path="/app/cs" component={NavCs} />

                    {/* <Route path="/app/pass" component={NavBuyPass} /> */}
                    {/* <Route path="/app/spot" component={NavSpot} />
                    <Route path="/app/spot/rcourse" component={NavSpot} />
                    <Route path="/app/cs" component={NavCs} />
                    <Route path="/app/pass" component={NavPass} />
                    <Route path="/app/mypage" component={NavMypage} /> */}
                </Switch>
            </li>
        )
        
    }

    const Depth3Menu = () => {
        const [depth3title, setDepth3title] = useState("");
        const [isShowDepth3Menu, setIsShowDepth3Menu] = useState(false);
        const [isShowDepth3MenuActive, setIsShowDepth3MenuActive] = useState(false);

        const toggleDepth3MenuActive = () => {
            setIsShowDepth3MenuActive(!isShowDepth3MenuActive);
        }


        useEffect(() => {
            switch (location.pathname) {
                // case "/app/mypage/profile": setDepth3title("회원정보"); setIsShowDepth3Menu(true); break;
                case "/app/mypage/profile/index": setDepth3title(t("navigation.app_depth3title_mypage_profile_index")); setIsShowDepth3Menu(true); break;
                case "/app/mypage/profile/password": setDepth3title(t("navigation.app_depth3title_mypage_profile_password")); setIsShowDepth3Menu(true); break;
                case "/app/mypage/profile/modify": setDepth3title(t("navigation.app_depth3title_mypage_profile_modify")); setIsShowDepth3Menu(true); break;
                case "/app/mypage/profile/leaveid": setDepth3title(t("navigation.app_depth3title_mypage_profile_leaveid")); setIsShowDepth3Menu(true); break;
                // case "/app/mypage/profile/leaveid/complete": setDepth3title(t("navigation.app_depth3title_mypage_profile_leaveid_complete")); setIsShowDepth3Menu(true); break;
                // case "/app/mypage/orderhistory": setDepth3title("구매내역"); setIsShowDepth3Menu(true); break;
                // case "/app/mypage/inquiry": setDepth3title("문의내역"); setIsShowDepth3Menu(true); break;
                // case "/app/guide": setDepth3title("관심정보"); setIsShowDepth3Menu(true); break;
                // case "/app/guide/index/how": setDepth3title("실물패스"); setIsShowDepth3Menu(true); break;
                // case "/app/guide/how": setDepth3title("실물패스"); setIsShowDepth3Menu(true); break;
                // case "/app/guide/index/how/cardpass": setDepth3title("실물패스"); setIsShowDepth3Menu(true); break;
                // case "/app/guide/index/how/mobilepass": setDepth3title("모바일패스"); setIsShowDepth3Menu(true); break;
                // case "/app/guide/index/how/arex": setDepth3title("공항철도"); setIsShowDepth3Menu(true); break;
                // case "/app/guide/index/how/bikeseoul": setDepth3title("따릉이"); setIsShowDepth3Menu(true); break;
                default: setIsShowDepth3Menu(false); break;
                    
            }
            return () => {
            }
        }, [])

        if(!isShowDepth3Menu || depth3title === "") {
            return (<></>)
        }

        return (
            <li className={classNames({ depth1: true, active: isShowDepth3MenuActive })} onClick={toggleDepth3MenuActive}>
                <span>{depth3title}</span>
                <Switch>
                    <Route path="/app/mypage/profile" component={NavMypageProfile} />
                    {/* <Route path="/app/mypage/mypass" component={NavMypageMypass} /> */}
                    <Route path="/app/mypage/orderhistory" component={NavMypageOrderhistory} />
                    <Route path="/app/mypage/inquiry" component={NavMypageInquiry} />
                    <Route path="/app/mypage/interest" component={NavMypageInterest} />
                    {/* <Route path="/app/guide/index/how" component={NavPassguideHow} /> */}
                </Switch>
            </li>
        )
    }

    return (
            <nav>
				<div className="layout">
					<ul className="clear">
						<li className="home"><Link to="/app/main">{t("navigation.app_main")}</Link></li>
						<li className={classNames({ depth1: true, active: isShowDepth1MenuActive })} onClick={toggleDepth1MenuActive}>
                            <Depth1Title></Depth1Title>
							
                            <Depth1Menu></Depth1Menu>
						</li>
                        <Depth2Menu></Depth2Menu>
                        <Depth3Menu></Depth3Menu>
					</ul>
				</div>
			</nav>
    );
};

// export default Navigation;
export default observer(Navigation);