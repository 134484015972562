import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Axios, { CancelTokenSource } from 'axios';
import Select from 'react-select/src/Select';
import validator from 'validator' 
import { globalInfoStore } from "../stores/GlobalInfoStore";
import { useHistory } from "react-router";

const SignUpPrivacyDesc = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    return (
        <div className="greyTermsBox">
            <div className="wrap_use terms" dangerouslySetInnerHTML={{__html:t("policy.body_terms")}}></div>
        </div>
    );
};

export default SignUpPrivacyDesc;