import { FacebookLoginResponse } from '@capacitor-community/facebook-login';
import { Plugins } from '@capacitor/core';
import "@codetrix-studio/capacitor-google-auth";
// import '@capacitor-community/http';
import { IonToast, isPlatform } from '@ionic/react';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
// import { AuthFacebook3 } from "../../services/AuthServiceFacebook3";
import { AuthFacebook4 } from "../../services/AuthServiceFacebook4";
import { AuthGoogle4 } from '../../services/AuthServiceGoogle4';
import { AuthWeibo2 } from "../../services/AuthServiceWeibo";
import AxiosConfig from '../../services/AxiosConfig';
import { globalInfoStore } from '../../stores/GlobalInfoStore';
import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from '@ionic-native/sign-in-with-apple';
import useDspLocalStorage from '../../services/UseDspLocalStorage';
import jwt from 'jsonwebtoken';
import ReactGA from 'react-ga';

const { FacebookLogin } = Plugins;

// const verifyToken = (req:any, res:any) => {
//   const token = req.headers.authorization;
//   jwt.decode
//   jwt.verify(token, SECRET_KEY, (err, decoded) => {
//     if (err) {
//       return res.status(401).send();
//     }
//     // can do something with the decoded data
//   })
// }


interface LoginPageProps extends RouteComponentProps {}



const LoginPage : React.FC<LoginPageProps> = (props) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showToast1, setShowToast1] = useState(false);
    const [showToast1Msg, setShowToast1Msg] = useState("");

    const [dspJwt, setDspJwt] = useState("");

    const [redirectPage, setRedirectPage, removeRedirectPage] = useDspLocalStorage('redirectPage', {});

    useEffect(()=>{
        const thisState = props.location.state;
        if (globalInfoStore.getDspJwt()) {
            removeRedirectPage();
        //     // console.log("dspJwt::124  " + dspJwt);
            history.replace(redirectPage);
        } else {
        //     // console.log("dspJwt::125  " + dspJwt);
            // setRedirectPage(props.location.pathname + decodeURIComponent(props.location.search));
        }

        // const fetchData = async () => {
        //     // const result = await asynCall();
        //     const ret = await Http.getCookies({
        //         url: '/',
        //     });
        //     let jwtValue = await ret.value.find((item) => item.key === "dspJwt").value;
        //     const decodedJwt:any = jwt.decode(jwtValue);
        //     if (decodedJwt !== null) {
        //         console.log("jwtValue:: " + jwtValue)
        //         setDspJwt(jwtValue);
        ////         // history.replace(redirectPage);//history.push('/app/mypage')
        //     }
            
        //     // setLoading(false);
        // };
        // fetchData();
    },[globalInfoStore.getDspJwt()]) //TODO: 기존 값이 있으면 자꾸 호출이 안됨...cookie 가 정상동작중...


    const checkLogin = async () => {
        ReactGA.event({
            category: "로그인페이지",
            action: "로그인클릭",
            label: "Sign in",
          });

        var data = JSON.stringify({"email":email, "password":password});

        try {
            const res = await AxiosConfig.post("/api/auth/login",
                data,
                {
                headers: {
                    "Content-Type": "application/json",
                },
                }
            );
            console.log(res);
            if (res.status == 200 && res.data.jwt != null){
                console.log(res);
                globalInfoStore.setDspJwt(res.data.jwt)
                //// history.replace(redirectPage);//history.push('/app/mypage')
                console.log("LoginPage::checkLogin::redirectPage::" + redirectPage); //LoginPage::PrivateRoute::removeredirectPage::?pickup_date=2021-07-14T03:00:00.000Z&pickup_location=8&coupon_code=&qty=1
                console.log("LoginPage::checkLogin::res.data.jwt::" + res.data.jwt); //LoginPage::PrivateRoute::removeredirectPage::?pickup_date=2021-07-14T03:00:00.000Z&pickup_location=8&coupon_code=&qty=1
                removeRedirectPage()
                history.replace(redirectPage);

            } else {
                setShowToast1Msg("계정정보를 찾을 수 없습니다.");
                setShowToast1(true);
            }
        } catch (error) {
          console.error(error);
          setShowToast1Msg("계정정보를 찾을 수 없습니다.");
          setShowToast1(true);
        }
    }

    const onChangeInputPw = (e:any) => {
        console.log(e.target.value);
        setPassword(e.target.value);
    }

    const goToSignUpPage = () => {
        history.replace('/app/signin/index/signup')
    }

    // function handleSignInWithGoogleOIDC(e: any) {
    //     e.preventDefault();
    //     // alert("coming soon.");
    //     AuthGoogleOIDC.Instance("signin").loadTokenFromStorage();
    //     AuthGoogleOIDC.Instance("signin").signIn()
    // }

    // function handleLoadUser(e: any) {
    //     AuthGoogle3.Instance("signin").loadUserInfo()
    // }

    const handleSignInWithGoogle = async (e: any) => {
        e.preventDefault();
        console.log("handleSignInWithGoogle:::: 111")
        if (isPlatform('capacitor')) { //&& isPlatform('android')) {
            // signInGoogleCapacitor();
            console.log("handleSignInWithGoogle:::: 222");

            const googleUser = await Plugins.GoogleAuth.signIn() as any;
            console.log("handleSignInWithGoogle:::: 333::: googleUser::: ", googleUser);
            console.log("handleSignInWithGoogle:::: 3331::: googleUser.idToken:: ", googleUser.idToken);
            console.log("handleSignInWithGoogle:::: 3332::: googleUser.authentication.idToken:: ", googleUser.authentication.idToken);
            if (googleUser) {
                // setShowToast1(true);
                // setShowToast1Msg("googleUser::" + googleUser.email);
                // console.log("serverAuthCode", googleUser.serverAuthCode);
                // console.log("idToken", googleUser.idToken);
                // console.log("authentication", googleUser.authentication);
                // console.log("displayName", googleUser.displayName);
                // console.log("email", googleUser.email);
                // console.log("familyName", googleUser.familyName);
                // console.log("givenName", googleUser.givenName);
                // console.log("id", googleUser.id);
                // console.log("imageUrl", googleUser.photoUrl);
                if (isPlatform("capacitor") && isPlatform("ios")) {
                    postCheckAccessToken(googleUser.authentication.idToken, "googlejwt");
                } else {
                    postCheckAccessToken(googleUser.idToken, "googlejwt");
                }
                // history.push({
                //     pathname: '/home',
                //     state: { name: googleUser.name || googleUser.displayName, image: googleUser.imageUrl, email: googleUser.email }
                // });
            }
            console.log("handleSignInWithGoogle:::: 444")

        // } else if (isPlatform('capacitor') && isPlatform('ios')) {
        //     // signInGoogleCapacitor();
        //     const googleUser = await Plugins.GoogleAuth.signIn(null) as any;
        //     console.log('my user: ', googleUser);
        } else {//웹
            console.log("handleSignInWithGoogle:::: 555")
            // alert("coming soon.");
            AuthGoogle4.Instance().loadTokenFromStorage();
            AuthGoogle4.Instance().signIn()
        }
        console.log("handleSignInWithGoogle:::: 666")
    }

    const postCheckAccessToken = async (accessToken:string, snsType:string) => {
      let url = `/api/auth/oauth/${snsType}/profile/jwt`;
      const data = JSON.stringify({
        accessToken: accessToken,
      });
      const res = await AxiosConfig.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // setResp(res.data);
      // ERROR_ACCESS_TOKEN_UNAVAILABLE("-200", "엑세스토큰이 유효하지 않음"),
      // SIGN_UP_NEEDED("210", "회원가입필요"),
      // SIGN_UP_ALREADY_MEMBER("220", "이미 가입된 회원");
      const json = JSON.stringify(res);
      console.log(`postCheckAccessToken res ${accessToken},   ${snsType},   res:: ${json}`);
      if (res.status == 200) {
        if (res.data.jwt != null && res.data.jwt != "") {
          await globalInfoStore.setDspJwt(res.data.jwt)
          ////   history.replace(redirectPage);//history.push('/app/mypage')
          const thisState = props.location.state;
          console.log("props.history.location.state.from.pathname:: " + thisState)
          // history.replace(props.location.state.from.pathname)
          setShowToast1Msg("로그인 하였습니다.");
          setShowToast1(true);
          removeRedirectPage();
          history.replace(redirectPage);
        } else if (res.data.code == "210") {
          alert("You are not a registered member. You will be taken to the membership registration screen.");
          //   history.replace({
          //     pathname: `/app/signin/index/signup`
          //   });
          let snsId = "";
          if (snsType === "facebook") {
            snsId = res.data.memberInfo.facebookToken;
          } else if (snsType === "google") {
            snsId = res.data.memberInfo.googleToken;
          } else if (snsType === "weibo") {
            snsId = res.data.memberInfo.weiboToken;
          } else if (snsType === "apple") {
            snsId = res.data.memberInfo.appleToken;
          }

          history.replace({
            pathname: `/app/signin/index/signup/withsns`,
            state: {
              snsType: snsType,
              snsId: snsId,
              name: res.data.memberInfo.name,
              email: res.data.memberInfo.email,
            },
          });
        } else if (res.data.code == "230") { // 사용중인 email
          alert("Email Address is taken. Try another.");
        }
      } else {
          setShowToast1Msg(res.status + " (" +  res.data.code + ":" + res.data.message + ")");
        // alert(res.status + " (" +  res.data.code + ":" + res.data.message + ")")
      }
    }

    const signInFacebookCapacitor = async () => {

        const FACEBOOK_PERMISSIONS = ['email', 'user_birthday', 'user_photos', 'user_gender'];
        // const FACEBOOK_PERMISSIONS = ['public_profile', 'email'];
        const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS }) as FacebookLoginResponse;

        if (result.accessToken) {
            // Login successful.
            console.log(`Facebook access token is ${result.accessToken.token}`);
            postCheckAccessToken(result.accessToken.token, "facebook");
        } else {
            console.log(`Facebook Cancelled by user.`);
        }
    }
    
    function handleSignInWithFacebook(e: any) {
        e.preventDefault();
        if (isPlatform('capacitor') && isPlatform('android')) {
            signInFacebookCapacitor();
        } else if (isPlatform('capacitor') && isPlatform('ios')) {
            signInFacebookCapacitor();
        } else {//웹
            // AuthFacebook3.Instance("signin").loadTokenFromStorage();
            // AuthFacebook3.Instance("signin").signIn();    
            AuthFacebook4.Instance().loadTokenFromStorage();
            AuthFacebook4.Instance().signIn();    
        }
        
    }

    function handleSignInWithApple(e: any) {
        e.preventDefault();
        SignInWithApple.signin({
            requestedScopes: [
            ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
            ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
            ]
        })
        .then((res: AppleSignInResponse) => {
            // https://developer.apple.com/documentation/signinwithapplerestapi/verifying_a_user
            // alert('Send token to apple for verification: ' + res.identityToken);
            postCheckAccessToken(res.identityToken, "apple");
            console.log(res);
        })
        .catch((error: AppleSignInErrorResponse) => {
            // alert(error.code + ' ' + error.localizedDescription + ' ' + error.localizedFailureReason);

            console.error(error);
            // if (error.code == 1000) { // 1000 ASAuthorizationErrorUnknown   -  authorization attempt failed for an unknown reason
            //     alert("authorization attempt failed for an unknown reason")
            // } else 
            if (error.code == 1001) { // 1001 ASAuthorizationErrorCanceled   -  user cancelled
                alert("user cancelled")
            } else if (error.code == 1002) { // 1002 ASAuthorizationErrorInvalidResponse   -  authorization request received an invalid response.
                alert("authorization request received an invalid response.")
            } else if (error.code == 1003) { // 1003 ASAuthorizationErrorNotHandled   -  user cancelled
                alert("user cancelled")
            } else { // 1000 and others
                alert("authorization attempt failed for an unknown reason")
            }

            // Failure Callback Data Example
            // error: string "ASAUTHORIZATION_ERROR"
            // code: string 1001
            // localizedDescription: string "The operation couldn’t be completed. (com.apple.AuthenticationServices.AuthorizationError error 1001.)"
            // localizedFailureReason: string ""
            // Error Type
            // 1000 ASAuthorizationErrorUnknown   -  authorization attempt failed for an unknown reason
            // 1001 ASAuthorizationErrorCanceled   -  user cancelled
            // 1002 ASAuthorizationErrorInvalidResponse   -  authorization request received an invalid response.
            // 1003 ASAuthorizationErrorNotHandled   -  user cancelled

            // alert("Failed to Apple Login.")

        });
    }

    function handleSignInWithWeibo(e: any) {
        e.preventDefault();
        // alert("coming soon.");
        AuthWeibo2.Instance.loadTokenFromStorage();
        AuthWeibo2.Instance.signIn();
    }

    const responseFacebook = (response: any) => {
      console.log(response);

      //TODO: 서버측에 회원가입 여부 확인. &&  유효한 accessToken 토큰 확인. && JWT 발급

      //TODO: 없으면 회원가입 페이지로 이동??
    }

    const responseGoogle = (response: any) => {
      console.log(response);
    }

    const SnsLoginButton = () => {
        // if (process.env.REACT_APP_ENV === 'production' && isPlatform("capacitor") && isPlatform("ios")) {
        //     return null;
        // }
        return (
            <>
                <li>
                    <span dangerouslySetInnerHTML={{__html:t("login.signin_with_sns")}}></span>
                    {isPlatform("capacitor") && isPlatform("ios")?
                    <button className="sns apple" onClick={(e) => handleSignInWithApple(e)} >{t("login.signin_with_apple")}</button>
                    :<></>}
                    <button className="sns google" onClick={(e) => handleSignInWithGoogle(e)} >{t("login.signin_with_google")}</button>
                    <button className="sns facebook" onClick={(e) => handleSignInWithFacebook(e)} >{t("login.signin_with_facebook")}</button>
                </li>
            </>
        )
    }

    return (
        <div className="layout">
            <IonToast
                isOpen={showToast1}
                onDidDismiss={() => setShowToast1(false)}
                message={showToast1Msg}
                duration={3000}
            />
            
            <div className="wrap_info login">
                {/* <h6 className="sec_tit side">로그인</h6> */}
                <h6 className="sec_tit side">{t("login.signin")}</h6>

                <dl>
                    {/* <dt>이메일 주소</dt> */}
                    <dt>{t("login.email_address")}</dt>
                    <dd>
                        {/* <input type="text" title="이메일주소" value={email} onChange={e=>(setEmail(e.target.value))} autoCapitalize="false"/> */}
                        <input style={{textTransform: "lowercase"}} type="text" title={t("login.email_address")} value={email} onChange={e=>(setEmail(e.target.value.trim()))} autoCapitalize="false"/>
                    </dd>
                </dl>
                <dl>
                    <dt>{t("login.password")}</dt>
                    <dd>
                    {/* <input type="password" title="비밀번호" value={password} */}
                        <input type="password" title={t("login.password")} value={password}
                        onChange={e=>(onChangeInputPw(e))} />
                    </dd>
                </dl>

                <button className="btn_login" onClick={checkLogin} >{t("login.signin")}</button>

                <ul className="find">
                    {/* <li><a href="#" target="_self">이메일(아이디) 찾기</a></li>
                    <li><a href="#" target="_self">비밀번호 찾기</a></li> */}
                    <li><Link to="/app/signin/index/findid" >{t("login.forgot_email")}</Link></li>
                    <li><Link to="/app/signin/index/findpw" >{t("login.forgot_password")}</Link></li>
                </ul>
                <ul className="etc">
                    {/* ios SNS 회원가입 문제 해결 전에 스토어 업데이트를 하기 위해 SNS 로그인을 제외-임시 */}
                    {isPlatform("capacitor") && isPlatform("ios")?
                        <></>
                        : <SnsLoginButton></SnsLoginButton>}
                    
                    <li>
                        {/* <span>디스커버서울패스<br />계정이 없으세요?</span>
                        <button className="btn_join" onClick={goToSignUpPage}>회원가입</button> */}
                        <span dangerouslySetInnerHTML={{__html:t("login.dont_have_an_account")}}></span>
                        <button className="btn_join" onClick={goToSignUpPage}>{t("login.signup")}</button>
                    </li>
                </ul>
            </div>

        </div>
    );
};

export default LoginPage;