import React from 'react';
import { useTranslation } from 'react-i18next';
import { globalInfoStore } from '../../../stores/GlobalInfoStore';
import Moment from 'react-moment';
import { IMyPass } from './MyPass';
import { getValueByKey, isProduction } from '../../../services/Util';
import { ICodeInfo } from '../../../services/CodeInfoService';

const classNames = require("classnames");

// const AccordionOfflineItem = (props: {content: StoreInfo, storeContents: RelationStoreContentsInfo[], click:any}) => {

interface MyPassItemProps {
    myPassItem:IMyPass;
    filteredCodeInfoes:ICodeInfo[];
    click: any;
}


const MyPassItem: React.FC<MyPassItemProps> = (props: MyPassItemProps) => {
// const MyPassItem = (props:{myPassItem: IMyPass, click: any}) => {
    const { t, i18n } = useTranslation();

    const handleClick = () => {
        // props.click(); //부모 호출
    }

    const getCodeInfo = (codeId:number) => {
        const codeInfo:ICodeInfo = props.filteredCodeInfoes.find((item:ICodeInfo) => {
            return item.codeId === codeId;
        });
        if (codeInfo == null) {
            console.error("category type is null");
            return "codeinfo null";
        }
        return getValueByKey(codeInfo, globalInfoStore.currentLangType.codename);
    }

    
    /*******   OLD   *********/
    // qr_status_1	판매가능	판매 가능
    // qr_status_2	Available	사용 가능
    // qr_status_3	사용완료	사용완료
    // qr_status_4	Available	사용 가능
    // qr_status_5	Refund	환불
    // qr_status_6	폐기	폐기
    // qr_status_7	Available	사용 가능

    /*******   NEW   *********/
    // ||qr_status1,판매가능,2021-09-06 13:11:32,판매가능,For stock
    // ||qr_status2,사용가능,2021-09-06 13:13:09,사용가능,Available
    // ||qr_status3,사용중,2021-09-06 13:13:11,사용중,In use
    // ||qr_status4,사용완료,2021-09-06 13:13:12,사용완료,End of use
    // ||qr_status5,기간만료,2021-09-06 13:13:14,기간만료,Expired
    // ||qr_status6,환불완료,2021-09-06 13:13:15,환불완료,Refunded
    // ||qr_status7,폐기,2021-09-06 13:13:16,폐기,Discard
    // ||qr_status8,환불요청,2021-09-06 13:18:05,환불요청,Refunding

    const getStatusCss = (status: number) => {
        switch (status) {
            case 1: // 판매가능
            case 2: // 사용가능
                return "";
            case 3: // 사용중
                return "occupied";
            case 4: // 사용완료
                return "done";
            case 5: // 기간만료
            case 6: // 환불완료
            case 7: // 폐기 
            case 8: // 환불요청 
                return "expire";
            
            default:
                break;
        }
    }

    // const getStatusText = (status: number) => {
    //     switch (status) {
    //         case 1: 
    //             return t("mypass.qr_status_1");
    //         case 2: // 사용가능
    //         case 3:
    //         case 4:
    //         case 7: // 사용전 
    //             return t("mypass.qr_status_2");
    //         case 5: 
    //             return t("mypass.qr_status_5");
    //         case 6: 
    //             return t("mypass.qr_status_6");
        
    //         default:
    //             break;
    //     }
    // }

    const getPasstypeName = () => {
        if (props.myPassItem.passtypeCode === 1) {
            return t("mypass.card_pass")
        } else {
            return t("mypass.mobile_pass")
        }
    }

    return (
        // {
        //     "uid":217368,
        //     "status":2,
        //     "productUid":null,
        //     "amount":1,
        //     "orderUid":101357,
        //     "qrCode":"qmgd7vp16r",
        //     "qrMgmtCode":"1-24m-qmgd7vp16r-0-21-000014",
        //     "memo":null,
        //     "groupCode":null,
        //     "createDate":"2021-01-25T01:27:15",
        //     "updateDate":null,
        //     "expireDate":null,
        //     "managingTitle":"24시간 모바일권",
        //     "koTitle":"디스커버서울패스 모바일 24시간권",
        //     "enTitle":"Discover Seoul Pass Mobile  24hours",
        //     "jaTitle":"モバイルディスカバーソウルパス 24時間券",
        //     "zhCnTitle":"电子首尔转转卡24小时券",
        //     "zhTwTitle":"電子首爾轉轉卡 24小時券",
        //     "purchaseDate":"2021-01-25T01:27:14",
        //     "passType":2,
        //     "filename":"1611652665077-12745da4-2b89-4cc3-a997-3f00f2fc5ddd.png",
        //     "filepath":"/newproducts/1611652665077-12745da4-2b89-4cc3-a997-3f00f2fc5ddd.png",
        //     "fullpath":"https://discoverseoulpass-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/uploads/newproducts/1611652665077-12745da4-2b89-4cc3-a997-3f00f2fc5ddd.png",
        //     "extension":"png"
        // }

        //         {/* <!--

        // 클래스 정리
        // (기본 : 사용안함)      pass mobile   
        // 사용중 :               pass mobile occupied
        // 기간만료 :             pass mobile expire
        // 사용완료 :             pass mobile done 

        // *모바일은 pass에 'mobile' 클래스 추가
        // *시간은 time24, time48, time72 이렇게 붙음.

        // --> */}

        // <div className={classNames({pass:true, mobile:true})} onClick={(e)=>handleClick()}>  
        <div className={classNames(["pass", "mobile", getStatusCss(props.myPassItem.status)])} onClick={(e)=>handleClick()}>  
        {/* <div className="pass mobile" onClick={(e)=>handleClick()}>   */}
        {/* <div className="pass mobile done" onClick={(e)=>handleClick()}>
        <div className="pass mobile expire" onClick={(e)=>handleClick()}> */}
            {/* <p className="code">QR No. {props.myPassItem.qrMgmtCode == null ? props.myPassItem.qrCode: props.myPassItem.qrMgmtCode}</p> */}
            <p className="code">Order No. {props.myPassItem.orderNo == null ? "": props.myPassItem.orderNo}</p>
            <p className="date">{t("mypass.purchase_date")} <Moment format="YYYY-MM-DD HH:mm:ss">{props.myPassItem.purchaseDate}</Moment>&nbsp;Asia/Seoul</p>

            <div className="cont">
                {/* <span className={classNames({banner:true, card:(getPasstypeDesc(props.myPassItem.passType).type=="card")})}>{getPasstypeName(props.myPassItem.passType)}</span> */}
                <span className={classNames({banner:true, card:true})}>{getPasstypeName()}</span>

                <span className={classNames({banner:true, 
                    time24:(props.myPassItem.passtypeTime === 24),
                    time48:(props.myPassItem.passtypeTime === 48),
                    time72:(props.myPassItem.passtypeTime === 72),
                    time120:(props.myPassItem.passtypeTime === 120)})} >
                        {props.myPassItem.passtypeTime}H
                </span>

                <p className="tit">{getValueByKey(props.myPassItem, globalInfoStore.currentLangType.title2)}</p>

                {/* 
                        // 클래스 정리
                        // (기본 : 사용안함)      state   
                        // 사용중 :               state occupied
                        // 기간만료 :             state expire
                        // 사용완료 :             state done 
                 */}
                {/* QR 상태(1: 판매 가능, 2: 사용 가능, 5: 환불, 6: 폐기) */}
                {/* <span className={classNames(["state", getStatusCss(props.myPassItem.status)])}>{getStatusText(props.myPassItem.status)}</span> */}
                <span className={classNames(["state", getStatusCss(props.myPassItem.status)])}>{getCodeInfo(props.myPassItem.status)}</span>
            </div>
            <div className="img">
                <img src={props.myPassItem.fullpath} alt="서울패스 이미지" />
                {
                    props.myPassItem.iccid_num ? <span className={classNames(["esim"])}>eSIM</span> : ''
                }
            </div>
            {/* {!isProduction()
            ?(<span>패스유효기간</span>)
            :(null)} */}
            {/* <p className="valid">{t("mypass.expirecheck_date")} 2021-05-31</p> */}

            <p className="valid">{t("mypass.expirecheck_date")}
                &nbsp;
                <Moment format="YYYY-MM-DD HH:mm:ss">
                 { props.myPassItem.expireDate }
                </Moment>
                &nbsp;
                Asia/Seoul
            </p>

        </div>

        /*


						<!--

							클래스 정리
							(기본 : 사용안함)

							사용중 : occupied
							기간만료 : expire
							사용완료 : done 

							*모바일은 pass에 'mobile' 클래스 추가
							*시간은 time24, time48, time72 이렇게 붙음.

						 -->

						<div class="pass">
							<p class="code">QR No. cmwjayvv7k</p>
							<p class="date">구매일 2020-07-31</p>

							<div class="cont">
								<span class="banner card">실물패스</span>
								<span class="banner time24">24h</span>
								<p class="tit">디스커버서울패스 플러스에디션</p>
								<span class="state">사용안함</span>
							</div>
							<div class="img">
								<img src="img/passImg01_L.png" alt="서울패스 이미지" />
							</div>
						</div>
						<div class="pass">
							<p class="code">QR No. cmwjayvv7k</p>
							<p class="date">구매일 2020-07-31</p>

							<div class="cont">
								<span class="banner card">실물패스</span>
								<span class="banner time72">24h</span>
								<p class="tit">디스커버서울패스 플러스에디션</p>
								<span class="state occupied">사용중</span>
							</div>
							<div class="img">
								<img src="img/passImg01_L.png" alt="서울패스 이미지" />
							</div>
						</div>
						<div class="pass mobile expire">
							<p class="code">QR No. cmwjayvv7k</p>
							<p class="date">구매일 2020-07-31</p>

							<div class="cont">
								<span class="banner card">모바일패스</span>
								<span class="banner time24">72h</span>
								<p class="tit">디스커버서울패스 플러스에디션</p>
								<span class="state">기간만료</span>
							</div>
							<div class="img">
								<img src="img/passImg04_L.png" alt="서울패스 이미지" />
							</div>
						</div>
						<div class="pass mobile done">
							<p class="code">QR No. cmwjayvv7k</p>
							<p class="date">구매일 2020-07-31</p>

							<div class="cont">
								<span class="banner card">모바일패스</span>
								<span class="banner time72">72h</span>
								<p class="tit">디스커버서울패스 플러스에디션</p>
								<span class="state">사용완료</span>
							</div>
							<div class="img">
								<img src="img/passImg04_L.png" alt="서울패스 이미지" />
							</div>
						</div>
					
        */
    );
};



export default MyPassItem;