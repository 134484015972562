import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { globalInfoStore, LANG_TYPES } from "../../stores/GlobalInfoStore";

const SearchSpotItem = (props:{content:any}) => {
    const { t, i18n } = useTranslation();
    
    return (
        // <li><a href="#" target="_self">한국 전통문화 체험</a></li>
        // <li><Link to={"/app/spot/index/view/" + props.content.uid}>{props.content.title}</Link></li>
        <li>
            <Link to={"/app/spot/index/view/" + props.content.uid}>{props.content[globalInfoStore.currentLangType.title]}</Link>
        </li>
    );
};

export default SearchSpotItem;