import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { globalInfoStore, LANG_TYPES } from "../../../stores/GlobalInfoStore";
import { useHistory } from "react-router";
import AxiosConfig from '../../../services/AxiosConfig';
import { isPlatform } from '@ionic/react';

const classNames = require("classnames");

var mobileos = 0;

const GSurvey = () => {
	const { t, i18n } = useTranslation();
	const history = useHistory();

	const handleClick = (e: any) => {
		e.preventDefault();
	};

	const goToSurvey = async () => {
		const url = `/api2/setGSurvey/`+mobileos+`/2`;
      	const res = await AxiosConfig.post(url);
      	if (res.status === 200) {
      	}
		history.goBack();
	}

	const laterSurvey = async (e: any) => {
		e.preventDefault();
		const url = `/api2/setGSurvey/`+mobileos+`/5`;
      	const res = await AxiosConfig.post(url);
      	if (res.status === 200) {
      	}
		history.goBack();
	}

	const denySurvey = async (e: any) => {
		e.preventDefault();
		const url = `/api2/setGSurvey/`+mobileos+`/9`;
      	const res = await AxiosConfig.post(url);
      	if (res.status === 200) {
      	}
		history.goBack();
	}

	useEffect(() => {
		if (isPlatform("capacitor") && isPlatform("ios")) {
			mobileos = 1;
		} else if (isPlatform("capacitor") && isPlatform("android")) {
			mobileos = 2;
		}
		document.dispatchEvent(new Event('surveyPageShowed'));
	 }, []);
	
    return (

        <div id="app">
			<div className="header">
				<h3 className="tit righteous"> </h3>
				{/* <button className="btn_close" onClick={history.goBack}>닫기</button> */}
			</div>

			<div className="layout">

				<div className="gsurvey_top"></div>
				
				<h3 className="gsurvey_title"  data-aos="fade-up" data-aos-duration="500" dangerouslySetInnerHTML={{__html:t("gsurvey.title")}}></h3>
				<p className="intro_exp" data-aos="fade-up" data-aos-duration="500" data-aos-delay="200" dangerouslySetInnerHTML={{__html:t("gsurvey.desc")}}></p>

				<div className="gsurvey_cont">
					<dl className="dl01" data-aos="fade-left" data-aos-duration="1500" data-aos-delay="200">
						<dt>{t("gsurvey.gift_title")}</dt>
						<dd dangerouslySetInnerHTML={{__html:t("gsurvey.gift_desc")}}></dd>
					</dl>
				</div>

				<div className="gsurvey_link">
					<a href={t("gsurvey.survey_url")+globalInfoStore.getDspJwt()} target="_blank" className="goToSurvey" onClick={goToSurvey}> {t("gsurvey.survey_accept")}</a>
					{/* <a href={t("gsurvey.survey_url")} target="_blank" className="goToSurvey" onClick={goToSurvey}> {t("gsurvey.survey_accept")}</a> */}
          		</div>
				<div className="gsurvey_link2">
					  <a href="#" className="laterSurvey" onClick={(e) => {laterSurvey(e);}}> {t("gsurvey.survey_later")}</a>
          		</div>
				<div className="gsurvey_link3">
					  <a href="#" className="denySurvey" onClick={(e) => {denySurvey(e);}}> {t("gsurvey.survey_deny")}</a>
          		</div>

			</div>

		</div>
    )
};

export default GSurvey;