import { AuthActions, AuthObserver } from 'ionic-appauth';
import jwt from 'jsonwebtoken';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
//import { useCookie } from 'react-use';
import { AuthFacebook4 } from '../../services/AuthServiceFacebook4';
import AxiosConfig from '../../services/AxiosConfig';
import { globalInfoStore } from '../../stores/GlobalInfoStore';
import useDspLocalStorage from "../../services/UseDspLocalStorage";


interface RedirectFacebookPageProps extends RouteComponentProps {}

//http://localhost:3000/loginredirect/facebook?code=AQBt39xcMHV44UkEFRK-9DBpcgkNiMtkKaL1Embia5nB5UJJSlpcFqtdBApTljx1DazGT01T1AmhLk7BgnEP0wkq_8AZlyOALT9pveoSoDCF2YIVilyvjV0sxSnLNBBySh-Nx-9XnM1DpKqXN1H2z3irVXZ14QwD_GuYp6i1vPURilfyZwFGOEmQJleoIEERBATMTnlORHIjR_U6wfGKQu5DdpuE9yFE_mi_4CExeohAblY9Xni55K1Wfd887fzXlbktgA71SM5oIcVscGqNSex7us_I8wfZ2HGxhaGDqZ4Jple9dl9xKIaqGeci3sAxWxE
// &state=E8SvboeLW4#_=_
//http://localhost:3000/loginredirect/facebook?code=AQAqwl5EtT9NwErRw99-xFwPyQS_Oiw6ptN4S43tDbYRsYaoH4crD-fJGzpbH0IjzXJJiGn-3jJZaIhyf-klMSO2-4RbK4RrjDeDB6dVxq8_KvJarpT1Ik9jAvO4CwyI_dPs6vFQDJuE4drip-3yPy3v2qLrxKP0zZfH8hUBjZ6BQY4lFIOsd2I9n6qh2cCjtcjScMeK28wyjfSCba9ip818SCG39vzlwyDYmxWaElToQ9G5E2aZ51nF6SqlA4xpvULF04IuNqfOI70AdjjNje6ULTEr6IdHNwstDdojVBUHUwwh8ifjZTfqIXW9yU2jilk
// &state=woGmBC05Hg#_=_
//https://www.discoverseoulpass.com/loginredirect/facebook?code=AQAK-TXR1ZN-5PdLkhDdP89Lttl4X1MxqYjXp7fPVZILVkRFYMaWZxCWyn68u9scXIk9a5OCtkQo12imOlxbG0W0buUhMvv8nYyDqifMEg2WcdkaGLkjElQBNVHb0s9Uof86c9gu9ZTTdz7kWhQHwma-nNcHaTtwpgHZDEtEVTKpYzc2334dHvODw_1h83zU8KLRlJBMZuddRK2fxiCD5RJ7KwRWXQTwb7lInev7HmNkGse6K8r2xqstU6kSKpHxRo6mqQ00xmI137EKrjJTfKfu4gpYCCzTU9QP0d1v2ZAnZtT6OO2WiInXmHfjN9jxDvA
// &state=RDvHwPUEI7#_=_

// const RedirectFacebook : React.FC<RedirectFacebookPageProps> = (props: RedirectFacebookPageProps) => {
const RedirectFacebook = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const [redirectPage, setRedirectPage, removeRedirectPage] = useDspLocalStorage('redirectPage', {});
    let observer: AuthObserver;
 //   const [value, updateCookie, deleteCookie] = useCookie('dspJwt');
    const [user, setUser] = useState();

    // const [resp, setResp] = useState("");

    // useEffect(()=>{
    //     if (globalInfoStore.getDspJwt() != null) {
    //         history.replace(redirectPage);//history.push('/app/mypage')
    //     }
    // },[globalInfoStore.getDspJwt()]) //TODO: 기존 값이 있으면 자꾸 호출이 안됨...cookie 가 정상동작중...


    const postCheckAccessToken = async (accessToken:string) => {
      let url = `/api/auth/oauth/facebook/profile/jwt`;
      var data = JSON.stringify({
        accessToken: accessToken,
      });
      const res = await AxiosConfig.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // setResp(res.data);
      // ERROR_ACCESS_TOKEN_UNAVAILABLE("-200", "엑세스토큰이 유효하지 않음"),
      // SIGN_UP_NEEDED("210", "회원가입필요"),
      // SIGN_UP_ALREADY_MEMBER("220", "이미 가입된 회원");
      console.log(res);
      if (res.status == 200) {
        if (res.data.jwt != null && res.data.jwt != "") {
          const decodedJwt:any = jwt.decode(res.data.jwt);
          const expires = decodedJwt.exp * 1000
          console.log("expires:" + expires + " Date: " + new Date(expires));
          /** 정상 동작.. TODO: jwt 유효기간 2주.. */
          // updateCookie(res.data.jwt, {
          //     domain: window.location.hostname,
          //     path: "/",
          //     expires: new Date(expires),
          //     sameSite: 'strict' 
          // })

          globalInfoStore.setDspJwt(res.data.jwt)
          history.replace(redirectPage);//history.push('/app/mypage')
          // console.log("value:::" + value);
        } else if (res.data.code == "210") {
          alert("You are not a registered member. You will be taken to the membership registration screen.");
          // history.replace({
          //   pathname: `/app/signin/index/signup`
          // });
          history.replace({
            pathname: `/app/signin/index/signup/withsns`,
            state: {
              snsType: "facebook",
              snsId: res.data.memberInfo.facebookToken,
              name: res.data.memberInfo.name,
              email: res.data.memberInfo.email,
            },
          });
        } else if (res.data.code == "220") { //SIGN_UP_ALREADY_MEMBER
          console.log("RedirectFacebook:: 220")
          alert("You are already a registered member.")
          // history.goBack();
          history.replace({
            pathname: `/app/signin/index/login`
          })
        } else if (res.data.code == "230") { //SIGN_UP_DUPLICATE_EMAIL
          console.log("RedirectFacebook:: 230")
          alert("Your email account is in use.")
          // history.goBack();
          history.replace({
            pathname: `/app/signin/index/login`
          })
        }
      } else {
        alert(res.status + " (" +  res.data.code + ":" + res.data.message + ")")
      }
    }


    useEffect(() => {
      const callbackUrl = window.location.origin + location.pathname +  location.search;
      console.log("RedirectFacebook callbackUrl1: " + callbackUrl);
      AuthFacebook4.Instance().authorizationCallback(callbackUrl);

      observer = AuthFacebook4.Instance().addActionListener((action) => {
            console.log("RedirectFacebook callbackUrl3: " + action.action);
            if (action.action === AuthActions.SignInSuccess) {
              console.log("RedirectFacebook callbackUrl4: ");
              postCheckAccessToken(
                action.tokenResponse.accessToken
              );
            } else {
              console.log("RedirectFacebook callbackUrl5: ");
              history.replace("/app/signin/index/signup")
            }

            // if(action.action === AuthActions.LoadUserInfoSuccess){
            //     setUser(action.user);
            // }
      });

      console.log("RedirectFacebook callbackUrl2: ");
      return () => {
        AuthFacebook4.Instance().removeActionObserver(observer);
        console.log("useIonViewDidLeave removed!!!!!!")
      }
    }, [])

    return (<>Redirect.</>);
    
};



export default RedirectFacebook;
