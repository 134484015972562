import { isPlatform } from '@ionic/react';
import React from 'react';
import { globalInfoStore } from "../../stores/GlobalInfoStore";

/**
 * 
 * @param type:1 -> bold  2 -> em
 * @returns 
 */
const AppDownload = (props:{type:"em"|"bold"}) => {
// const AppDownload = () => {
    if (isPlatform("capacitor")) {
        return null;
    }

    const ApkDownload = () => {
        if (globalInfoStore.currentLangType.i18Str == 'zhChs'){
            return (<li className="file"><a href="https://play.google.com/store/apps/details?id=com.travolution.discover"><em>APK File download</em></a></li>)
          }
          return (null)
      }

    if (props.type === "em") {
        return (
            <ul className="apk">
                <li className="apple"><a href="https://apps.apple.com/kr/app/id1131125136" target='_blank'><em>App Store download</em></a></li>
                <li className="google"><a href="https://play.google.com/store/apps/details?id=com.travolution.discover" target='_blank'><em>Google Play download</em></a></li>
                <ApkDownload></ApkDownload>
            </ul>
        );    
    } else if (props.type === "bold") {
        return (
            <ul className="apk">
                <li className="apple"><a href="https://apps.apple.com/kr/app/id1131125136" target='_blank'><b>App Store</b> download</a></li>
                <li className="google"><a href="https://play.google.com/store/apps/details?id=com.travolution.discover" target='_blank'><b>Google Play</b> download</a></li>
                <ApkDownload></ApkDownload>
            </ul>
        );  
    }

    return (<></>);
};

export default AppDownload;