import React from "react";
import { useTranslation } from "react-i18next";
import { Route } from 'react-router';
import { Link } from "react-router-dom";
import Navigation from '../navigation/Navigation';
import SearchNV from "./SearchNV";

const SearchIndexNV = () => {
	const { t, i18n } = useTranslation();

    return (
        <section id="container" className="sub">

			<article id="content">
				<Route exact path="/appnv/search/index" component={SearchNV} />				
			</article>

		</section>
    );
};

export default SearchIndexNV;