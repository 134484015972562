
import { Plugins } from '@capacitor/core';
import { isPlatform } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import AppDownload from '../../components/organisms/AppDownload';
import { globalInfoStore, LANG_TYPES } from "../../stores/GlobalInfoStore";
import packageJson from '../../../package.json';
import { isProduction } from '../../services/Util';

const { Device } = Plugins;

const classNames = require("classnames");

// export default class Footer extends React.Component {

const Footer = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const [versionInfo, setVersionInfo] = useState("")

    const windowOpen = () => {
      // window.open("http://www.ftc.go.kr/bizCommPop.do?wrkr_no=2458200220", width=500, height=700); return false
      window.open("http://www.ftc.go.kr/bizCommPop.do?wrkr_no=2458200220", "", "width=500,height=700");
    }

    useEffect(()=>{
      const getVersion = async () => {
        const info = await Device.getInfo();
        console.log(info);
        if (isPlatform("capacitor") || isPlatform("android") || isPlatform("ios")) {
          setVersionInfo(`${info.platform} ${info.appVersion} (${info.appBuild})`);
        } else {
          setVersionInfo(`${process.env.REACT_APP_ENV} ${packageJson.version}`);
        }
        
      };
      getVersion();
    },[])

    const handleClickLogo = () => {
      if (globalInfoStore.currentLangType.i18Str == "ko"){
        window.open("http://www.sto.or.kr/index", "_blank");
      } else {
        window.open("http://www.sto.or.kr/english/index", "_blank");
      }
    }
    
    // const IsShowApk = () => {
    //   // console.log(JSON.stringify(globalInfoStore.currentLangType.i18Str));
    //   if (globalInfoStore.currentLangType.i18Str == 'zhChs'){ //중문 간제 제거
    //     return (<li className="file"><a href="https://play.google.com/store/apps/details?id=com.travolution.discover"><em>APK File download</em></a></li>)
    //   }
    //   return (null)
    // }

    const goToTop = (e: any) => {
          // $('html, body').animate({
          //   scrollTop: 0
          // }, 400);
          const scrollToOptions:ScrollToOptions = {
            top:0,
            left:0,
            behavior:'smooth'
          }
          window.scrollTo(scrollToOptions);
    }

    // var settings = {
    //     "pluginKey": "c0586a4b-0e2f-43f7-a350-d06528b9f1a5",
    //     // "memberId": "YOUR_USER_ID",
    //     // "customLauncherSelector": ".custom-button-1, #custom-button-2",
    //     "customLauncherSelector": ".btn_chat",
    //     "hideChannelButtonOnBoot": true,
    //     // "trackDefaultEvent": false,
    //     // "trackUtmSource": false,
    //     "openChatDirectlyAsPossible": true,
    //     // "mobileMessengerMode": "newTab",
    //     // "zIndex": 1,
    //     "language": globalInfoStore.currentLangType.i18Str,
    //     // "profile": {
    //     //   "name": "YOUR_USER_NAME",
    //     //   "mobileNumber": "YOUR_USER_MOBILE_NUMBER",
    //     //   "email": "your@email.com",
    //     //   "avatarUrl": "http://IMAGE_URL",
    //     //   "CUSTOM_VALUE_1": "VALUE_1",
    //     //   "CUSTOM_VALUE_2": "VALUE_2"
    //     // },
    //     // "unsubscribed": false,
    //     // "hidePopup": false,
    // };

    // ChannelService.boot(settings);

    const goToLiveChat = () => {
      window.open("http://discoverseoul.channel.io", "_blank")
    }

    const renderStoPage = () => {
        if (globalInfoStore.currentLangType.i18Str == "ko"){
            return (<a href="http://www.sto.or.kr/index" target="_blank">{t("main.about_us")}</a>)
        }
        return (<a href="http://www.sto.or.kr/english/index" target="_blank">{t("main.about_us")}</a>)
    }


    //TODO: 모바일만 동작 반응형에서 체크 필요.
    return (
      <footer>
        <div className="upper">
          <Link to="/app/policy/terms">{t("footer.terms_of_use")}</Link>
          { globalInfoStore.currentLangType.i18Str == LANG_TYPES[0].i18Str ?
            <a href="http://www.sto.or.kr/privacy" target="_blank" className="personal">{t("footer.privacy_policy")}</a>
            : <a href="http://www.sto.or.kr/english/privacy" target="_blank" className="personal">{t("footer.privacy_policy")}</a>
          }
          {/* <Link to="/app/policy/location">{t("footer.terms_of_location_info")}</Link> */}
          <Link to="/app/cs/index/expirechecker">{t("footer.expirechecker")}</Link>

          <button className="btn_top" onClick={goToTop}>{t("footer.go_to_top")}</button>
          {/* <!-- ↓ 이 버튼은 서브페이지에서만 나타납니다. --> */}
          {(location.pathname == "/app/main")? (""): (<button className="btn_chat" onClick={goToLiveChat}>채팅</button>)}
				  
        </div>
        <div className="bottom">
          <img src={require("../../resources/img/sto_logo.png")} onClick={handleClickLogo} alt="서울관광재단 로고" />
          <p>
            <span className="name">{t("footer.ceo")}</span>
            <span>{t("footer.business_license_number")}</span>
            <a className="point" onClick={windowOpen}> (For Business Profile Verification)</a>
          </p>
          <p>
            <span>Online Transaction Business Registration</span>
            <span>{t("footer.online_transaction_business_registration")}</span>
          </p>
          <address>{t("footer.address")}</address>
          <p>
            <span>Copyright. SEOUL TOURISM ORGANIZATION.</span>
            <span>All Rights Reserved.</span>
            <span style={{color:"#2b295f"}}>{versionInfo}</span>
          </p>

          {/* <ul className="apk">
						<li className="apple"><a href="https://apps.apple.com/kr/app/id1131125136"><em>App Store download</em></a></li>
						<li className="google"><a href="https://play.google.com/store/apps/details?id=com.travolution.discover"><em>Google Play download</em></a></li>
            <IsShowApk></IsShowApk>
          </ul> */}
          <AppDownload type="em"></AppDownload>
          <ul className="sns">
            <li className="facebook"><a href="https://www.facebook.com/discoverseoulpass/" target="_blank">페이스북</a></li>
            <li className="ins_e"><a href="https://www.instagram.com/discoverseoulpass_en/" target="_blank">인스타그램-영문</a></li>
            <li className="ins_j"><a href="https://www.instagram.com/discoverseoulpass_jp/" target="_blank">인스타그램-일문</a></li>
            <li className="yout"><a href="https://www.youtube.com/channel/UC41VYNJotk4Z_UkDeIheNRw/featured" target="_blank">유튜브</a></li>
            <li className="weibo"><a href="https://www.weibo.com/discoverseoulpass" target="_blank">웨이보</a></li>
            <li className="shao"><a href="https://www.xiaohongshu.com/user/profile/5e9942a600000000010055b6" target="_blank">샤오홍슈</a></li>
          </ul>
          <span className="award">앤어워드 수상 홈페이지</span>
        </div>
      </footer>
  );
}

export default Footer;