import React from "react";
import { useTranslation } from "react-i18next";
import { globalInfoStore } from "../../stores/GlobalInfoStore";

const SpotTourNotice = (props:{content:any}) => {
    const { t, i18n } = useTranslation();

    // if (globalInfoStore.currentLangType.i18Str == "ko"){
    //     return (
    //         <p className="top_notice">코로나19로 인해 제휴시설 운영시간이 사전 공지 없이 변동할 수 있으니 방문 전 반드시 해당 제휴시설에 운영 여부를 확인한 후 방문해 주시기 바랍니다.</p>
    //     );
    // } 
    // return (
    //     <p className="top_notice">Due to COVID 19, there can be changes in operating hours of each attractions without notice. Please make sure to check operating hours of attractions before visiting.</p>
    // )

    return (
        <p className="top_notice" dangerouslySetInnerHTML={{__html:props.content[globalInfoStore.currentLangType.title2]}}></p>
    )
    
};

export default SpotTourNotice;