import React from "react";
import { useTranslation } from "react-i18next";
import AppTtareungiDownload from "../../components/organisms/AppTtareungiDownload";
import { isPlatform } from "@ionic/react";

import cycle01 from '../../resources/img/cycle01.png';
import cycle02 from '../../resources/img/cycle02.png';

const TransportationSeoulbike = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <div className="wrap_use">
            <p className="use_tit">{t("how_bike.title")}</p>
            <p className="use_exp" dangerouslySetInnerHTML={{__html:t("how_bike.desc")}}></p>
            <a href="https://www.bikeseoul.com/" target="_blank" className="btn">{t("how_bike.link")}</a>

            {isPlatform("capacitor")?null
            :
                <div className="exp_box use03">
                    <span>{t("how_bike.download_link")}</span>
                    <AppTtareungiDownload></AppTtareungiDownload> 
                </div>
             }

            <p className="use_tit">&nbsp;</p>
            <div className="use_tit">{t("how_bike.title_c")}</div>

            <div className="step row04 tareungi">
                <dl className="step01">
                    <dt>STEP 1</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_bike.step1_cont")}}></dd>
                </dl>
                <dl className="step02">
                    <dt>STEP 2</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_bike.step2_cont")}}></dd>
                </dl>
                <dl className="step03">
                    <dt>STEP 3</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_bike.step3_cont")}}></dd>
                </dl>
                <dl className="step04">
                    <dt>STEP 4</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_bike.step4_cont")}}></dd>
                </dl>
            </div>

            <div className="step row04 tareungi">
                <dl className="step05">
                    <dt>STEP 5</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_bike.step5_cont")}}></dd>
                </dl>
                <dl className="step06">
                    <dt>STEP 6</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_bike.step6_cont")}}></dd>
                </dl>
                <dl className="step07">
                    <dt>STEP 7</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_bike.step7_cont")}}></dd>
                </dl>
            </div>

            <p className="use_tit">{t("how_bike.title_r")}</p>
            <div className="step rent">
                <dl className="step01">
                    <dt>STEP 1</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_bike.step1_rent")}}></dd>
                </dl>
                <dl className="step02">
                    <dt>STEP 2</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_bike.step2_rent")}}></dd>
                </dl>
                <dl className="step03">
                    <dt>STEP 3</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_bike.step3_rent")}}></dd>
                </dl>
            </div>

            <p className="use_tit">{t("how_bike.title_t")}</p>
            <div className="clear">
                <img src={require("../../resources/img/use26.png")} alt="따릉이 이미지" />
                <div className="exp_box">
                    <p>{t("how_bike.exp1_cont")}</p>
                    <p>{t("how_bike.exp2_cont")}</p>
                    <p>{t("how_bike.exp3_cont")}</p>
                </div>
            </div>

            <p className="use_tit">{t("how_bike.title_l")}</p>
            <p className="use_exp">{t("how_bike.exp0_lock")}</p>
            <div className="exp_box list">
                <p>{t("how_bike.exp1_lock")}</p>
                <p>{t("how_bike.exp2_lock")}</p>
            </div>

            <p className="use_tit">{t("how_bike.title_ret")}</p>
            <p className="use_exp">{t("how_bike.exp0_ret")}</p>
            <div className="exp_box list">
                <p>{t("how_bike.exp1_ret")}</p>
                <p>{t("how_bike.exp2_ret")}</p>
                <p>{t("how_bike.exp3_ret")}</p>
            </div>

            <div className="exp_box warning" dangerouslySetInnerHTML={{__html:t("how_bike.warn")}}>
            </div>

        </div>
    );
};

export default TransportationSeoulbike;