import { isPlatform } from '@ionic/react';
import React from 'react';

/**
 * 
 * @param type:1 -> bold  2 -> em
 * @returns 
 */
const AppTtareungiDownload = () => {
    if (isPlatform("capacitor")) {
        return null;
    }

    return (
        <ul className="apk">
            <li className="apple"><a href="https://apps.apple.com/kr/app/%EC%84%9C%EC%9A%B8%EC%9E%90%EC%A0%84%EA%B1%B0-%EB%94%B0%EB%A6%89%EC%9D%B4/id1037272004" target='_blank'><em>App Store download</em></a></li>
            <li className="google"><a href="https://play.google.com/store/apps/details?id=com.dki.spb_android&hl=ko&gl=US" target='_blank'><em>Google Play download</em></a></li>
        </ul>
    ); 
};

export default AppTtareungiDownload;