import { isPlatform } from '@ionic/react';
import dateFnsFormat from 'date-fns/format';
import { ko } from 'date-fns/locale';
import dateFnsParse from 'date-fns/parse';

import React, { useEffect, useRef, useState } from "react";
import { DateUtils, Modifier } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Modal from 'react-modal';
import { useHistory, useLocation } from 'react-router';
import { v4 as uuid } from "uuid";
import AxiosConfig from '../../services/AxiosConfig';
import { IProductInfo } from '../../services/ProductInfoService';
import { getNextYear, getNext3Months, getValueByKey } from '../../services/Util';
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import EasypayIframeView from "./EasypayIframeView";
import { IEasypayResult, IOrderEasypayRequest } from "./IEasypayWeb";
import useDspLocalStorage from '../../services/UseDspLocalStorage';
import { useLocalStorage } from "react-use";
import { useQueryParam, NumberParam, StringParam, withDefault, useQueryParams } from 'use-query-params';
import qs from 'qs';
import PassSidebar from './PassSidebar';
import ReactGA from 'react-ga';

const queryString = require('query-string');

const classNames = require("classnames");

interface IUserInfo {
    [key: string]: any;
}

interface IPickupDay {
    // [key: string]: any;
    selectedDay: any;
    isEmpty: boolean
    isValidDay: boolean,
    isDisabled: boolean,
}

interface IPickupLoc {
    [key: string]: any;
}
interface ICouponInfo {
    // [key: string]: any;
    uid: number; //25,             // '배너,팝업의 고유번호' primary key,
    code: number; //2,             // '' 
    subCode: number; //1,             // '쿠폰 할인 유형 ( 1: 금액, 2: 퍼센트(%) )',
    status: number; //2,             // '쿠폰 상태',
    workerUid: number; //41303,             // '작업자(관리자) 고유번호',
    useCount: number; //0,             // '사용 횟수',
    discountPrice: number; //1000,             // '할인 금액(또는 할인율)',
    // discountPrice2: number; //1000,             // '할인 금액(또는 할인율)',
    title: string;//"testcoupon2",             // '쿠폰 명칭',
    couponCode: string;//"testcoupon2",             // '쿠폰 코드명',
    createDate: string;//"2021-02-03T20:40:14",             // '쿠폰 생성일자',
    updateDate: string;//"2021-02-23T21:17:18",             // '쿠폰 수정일자',
    workDate: string;//"2021-02-03T20:40:14",             // '작업일자',
    startDate: string;//"2021-02-03T00:00:00",             // '쿠폰 시작일자',
    endDate: string;//"2021-02-23T23:59:59"             // '쿠폰 종료일자'
}
 
const formatDate = (date: Date, format: string, locale: string): string => {
  return dateFnsFormat(date, format, { locale: ko });
};

const parseDate = (str: string, format: string, locale: string): Date => {
  const range = {
    from: new Date(2021, 6, 1),
    to: getNext3Months(),
  };

  const parsed = dateFnsParse(str, format, new Date(), { locale: ko });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
};

interface IOrder {
    [key: string]: any;
}

interface IHolidayApiInfo {
    uid: number; //48,
    dateKind: string;// "01",
    dateName: string;// "설날",
    isHoliday: string;// "Y",
    locdate: string;// "20210212",
    updateDt: string;// "2021-07-06T18:31:47",
    seq: number; //1
}

export const CP_SUBCODE_PRICE = 1;
export const CP_SUBCODE_PERCENT = 2;

const PassView = (props: any) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const FORMAT = 'yyyy-MM-dd';

    const [query, setQuery] = useQueryParams( {
		"pickup_date": StringParam,              //receiveDate
		"pickup_location": StringParam,  //receiverUid
		"coupon_code": StringParam,
		"qty": NumberParam,
	});
	const { pickup_date, pickup_location, coupon_code, qty } = query;

    const [redirectPage, setRedirectPage, removeRedirectPage] = useDspLocalStorage('redirectPage', {});

    const [pickupDate, setPickupDate] = useState<IPickupDay>({
        selectedDay: (pickup_date==null||pickup_date=="")?"":new Date(pickup_date),
        isEmpty: false,
        isValidDay: false,
        isDisabled: false
    });
    // pickup_date=2021-07-30T00%3A00%3A00
    // const [selectedPickupDay, setSelectedPickupDay] = useState((pickup_date==null||pickup_date=="")?"":new Date(pickup_date));
    const [selectedPickupLocation, setSelectedPickupLocation] = useState(pickup_location);
    // const [couponCode, setCouponCode] = useState((coupon_code==null)?"":coupon_code); // 실제 결제 시에도 사용되어야 함.
    const [inputCouponCode, setInputCouponCode] = useState((coupon_code==null)?"":coupon_code); // input ui 창에서만 필요.
    const [isModalOpen, setIsModalOpen] = useState(false);
	const [likeActive, setLikeActive] = useState(false);
    const [passItem, setPassItem] = useState<IProductInfo>();
    const [orderNo, setOrderNo] = useState(uuid())
    const [userInfo, setUserInfo] = useState<IUserInfo>({});
    const [iOrderEasypayRequest2, setIOrderEasypayRequest] = useState<IOrderEasypayRequest>({
            productUid: props.match.params.productUid,
            receiveDate: (pickup_date==null||pickup_date=="")?"1970-01-01T00:00:00":pickup_date,
            // receiveDate: (pickup_date==null||pickup_date=="")?"1970-01-01T00:00:00":pickup_date,

            receiverUid: (pickup_location==null||pickup_location=="")?-2:Number(pickup_location),
            couponName: "",
            couponSubCode: null,
            couponCode: (coupon_code==null)?"":coupon_code,
            unitOfCouponPrice: 0,
            sumOfCouponPrice: 0,
            sumOfFinalPrice: 0,  //	 * order_info테이블의 final_price
            unitOfFinalPrice: 0,  //  * 개별 unit의 최종 금액
            unitOfNormalPrice: 0, //	 * order_info테이블의 passtype_price 할인전 금액 최초금액
            unitOfSalesPrice: 0, //	 * order_info테이블의 sale_price 판매금액
            storePartnerUid: (isMobile?73:72),  //웹이면 72 앱이면 73 store_partner_uid
            paymentMethod: "11",
            quantity: (qty==null||qty<1)?1:qty,
        });
    const iOrderEasypayRequestPrevRef = useRef<IOrderEasypayRequest>();
    const [filteredPickupLocations, setFilteredPickupLocations] = useState([]);

    const initCouponInfo = {
        uid: -1, //25,             // '배너,팝업의 고유번호' primary key,
        code: -1, //2,             // '' 
        subCode: -1, //1,             // '쿠폰 할인 유형 ( 1: 금액, 2: 퍼센트(%) )',
        status: -1, //2,             // '쿠폰 상태',
        workerUid: -1, //41303,             // '작업자(관리자) 고유번호',
        useCount: -1, //0,             // '사용 횟수',
        discountPrice: -1, //1000,             // '할인 금액(또는 할인율)',
        title: "",//"testcoupon2",             // '쿠폰 명칭',
        couponCode: "",//"testcoupon2",             // '쿠폰 코드명',
        createDate: "",//"2021-02-03T20:40:14",             // '쿠폰 생성일자',
        updateDate: "",//"2021-02-23T21:17:18",             // '쿠폰 수정일자',
        workDate: "",//"2021-02-03T20:40:14",             // '작업일자',
        startDate: "",//"2021-02-03T00:00:00",             // '쿠폰 시작일자',
        endDate: ""//"2021-02-23T23:59:59"             // '쿠폰 종료일자'
    }
    const [couponInfo, setCouponInfo] = useState<ICouponInfo>(initCouponInfo);
    
    // const [couponCode, setCouponCode] = useState("");

    const [isCouponApplied, setCouponApplied] = useState(false);
    
    
    const [holidayApiInfoes, setHolidayApiInfoes] = useState<IHolidayApiInfo[]>([])

    const [myungDongHoliday, setMyungDongHoliday] = useState<Modifier[]>();
    const [holidayOfCode28, setHolidayOfCode28] = useState<Modifier[]>(); // 28, '2', 1, 42117, 504, '서울관광 기념품 전시관(서울관광플라자)');

    const [myungDongHoliday20230315, setMyungDongHoliday20230315] = useState<Modifier[]>();
    const [holidayOfCode2820230315, setHolidayOfCode2820230315] = useState<Modifier[]>();

    const noneHoliday = [
        {before: new Date()},
        {after: getNext3Months()}
    ];
    // const [isMyungDongActive, setIsMyungDongActive] = useState<boolean>(); //명동센터가 선택되었는지 
    // const [isMyungDongActive, setIsMyungDongActive] = useState<boolean>(); //명동센터가 선택되었는지 
    // const [isMyungDongActive, setIsMyungDongActive] = useState<boolean>(); //명동센터가 선택되었는지 


    const [recentPass, setRecentPass, removeRecentPass] = useLocalStorage('recentPass', []);
    const [isShowRecentPass, setIsShowRecentPass] = useState(false);
    useEffect(() => {
        // console.log("recentPass.length: " + recentPass.length);
        // setIsShowRecentPass(recentPass.length > 0)
    }, [recentPass.length]);
    
    
    let numSelectedPickupLocation = -1; //타입이 다르기에 형변환.


    const selectPickupLocation = (e:any) => {
        // console.log("e.target.value-->" + e.target.value);
        // console.log("e.target.value-->" + typeof e.target.value);
        // console.log("e.target.name-->" + e.target.name);
        // console.log("e.target.key-->" + e.target.key);
        setSelectedPickupLocation(e.target.value);

        // if (e.target.value === "8") {
        //     setIsMyungDongActive(true)
        // } else {
        //     setIsMyungDongActive(false)
        // }

        // setSelectedPickupDay("")
        setPickupDate({
            selectedDay: null,
            isEmpty: false,
            isValidDay: false,
            isDisabled: false
        });

    }

    const getHolidayByPickLocation = () => {
        //임시로 3월 15일부터 수령 가능으로 설정
        const disabedDate = new Date(2023,2,15);
        if(disabedDate > (new Date())) {
            switch (selectedPickupLocation) {
                case "8": // "8" : "명동센터"
                    return myungDongHoliday20230315;
                case "28": // "28", '2', 1, 42117, 504, '서울관광 기념품 전시관(서울관광플라자)');
                    return holidayOfCode2820230315;  // 임시 사용
            }

            return { before: disabedDate }
        }

        switch (selectedPickupLocation) {
            case "8": // "8" : "명동센터"
                return myungDongHoliday;
            case "28": // "28", '2', 1, 42117, 504, '서울관광 기념품 전시관(서울관광플라자)');
                return holidayOfCode28;  // 임시 사용
        }

        return noneHoliday;
    }

    const getExceptPickupDate = () => {        //임시로 3월 15일부터 수령 가능으로 설정
        const disabedDate = new Date(2023,2,15);
        if(disabedDate > (new Date())) {
            return dateFnsFormat(disabedDate, FORMAT)
        }

        return dateFnsFormat(new Date(), FORMAT);
    }

    const loadSearchQuery = () => {
        // const search = location.search;	// 문자열 형식으로 결과값이 반환된다.
        // const queryObj = qs.parse(location.search);	// 문자열의 쿼리스트링을 Object로 변환
        // const queryObj:any = qs.parse(location.search, { plainObjects: true });	//
        // const parsed = queryString.parse(location.search);  // "?key=value"
        // console.log("loadSearchQuery::" +parsed);
        // let aa = queryString.parse(""); typeof aa; // 'object'
        
        // parse.test == ""

        // const { id, author } = queryObj;

    }

	const fetchPass = async () => {
        let url = `/api2/productInfo/${props.match.params.productUid}`;
        const res = await AxiosConfig.get(url);
        
		setPassItem(res.data);
        setLikeActive(res.data.favoriteUid == 1)
        setIOrderEasypayRequest({
          productUid: props.match.params.productUid,
        //   receiveDate: "2021-01-01T00:00:00",te,
          receiveDate: (pickupDate.selectedDay==null||pickupDate.selectedDay=="")?"1970-01-01T00:00:00":pickupDate.selectedDay,
          receiverUid: (selectedPickupLocation==null||selectedPickupLocation=="")?-2:Number(selectedPickupLocation),
          couponName: "",
          couponSubCode: null,
          couponCode: "",
          unitOfCouponPrice: 0,
          sumOfCouponPrice: 0,
          sumOfFinalPrice: res.data.salePrice * ((qty==null||qty<1)?1:qty), //	 * order_info테이블의 final_price
          unitOfFinalPrice: res.data.salePrice, //  * 개별 계산 최종 금액
          unitOfNormalPrice: res.data.normalPrice, //	 * order_info테이블의 passtype_price 할인전 금액 최초금액
          unitOfSalesPrice: res.data.salePrice, //	 * order_info테이블의 sale_price 판매금액
          storePartnerUid: (isMobile?73:72),  //웹이면 72 앱이면 73 store_partner_uid
          paymentMethod: "11",
          quantity: (qty==null||qty<1)?1:qty,
        });
    }
    
    const fetchPickupLocations = async () => {
        const url = `/api2/receiveInfo/list`;
        const res = await AxiosConfig.get(url);
        setFilteredPickupLocations(res.data.content);
    }
    const fetchCouponCode = async (couponcode:string, isShowAlert:boolean = true) => {
        const url = `/api2/couponInfo?productUid=${props.match.params.productUid}&couponCode=${encodeURIComponent(couponcode)}`
        const res = await AxiosConfig.get(url);
        // console.log("fetchCouponCode::" + JSON.stringify(res.data));

        if (res.status == 200 && res.data.couponCode == couponcode) {
            // console.log("fetchCouponCode::couponInfo1-1: " + JSON.stringify(couponInfo));
            console.log("fetchCouponCode::couponInfo1-1: couponCode::" + res.data.couponCode);
            console.log("222222222222222::::::::::::::::::::::" + JSON.stringify(res.data));
            setCouponInfo(res.data);
            // console.log("fetchCouponCode::couponInfo1-2: " + JSON.stringify(couponInfo));

            // iOrderEasypayRequest2.couponName = res.data.title;
            // iOrderEasypayRequest2.couponSubCode = res.data.subCode;
            // iOrderEasypayRequest2.couponCode = res.data.couponCode;
            // iOrderEasypayRequest2.unitOfCouponPrice = res.data.discountPrice;
            iOrderEasypayRequest2.couponName = res.data.title;
            iOrderEasypayRequest2.couponSubCode = res.data.subCode;
            iOrderEasypayRequest2.couponCode = res.data.couponCode;
            setIOrderEasypayRequest({...iOrderEasypayRequest2}); 
            // setIOrderEasypayRequest({...iOrderEasypayRequest2, unitOfCouponPrice: res.data.discountPrice})
            // if (res.data.discountPrice > 0 && res.data.subCode == CP_SUBCODE_PERCENT && res.data.discountPrice <= 100 && res.data.discountPrice >= 0) {
            //     setIOrderEasypayRequest({...iOrderEasypayRequest2, unitOfFinalPrice: (iOrderEasypayRequest2.unitOfSalesPrice) * (1 - (res.data.discountPrice * 0.01))}) 
            // } else if (res.data.discountPrice > 0 && res.data.subCode == CP_SUBCODE_PRICE) {
            //     setIOrderEasypayRequest({...iOrderEasypayRequest2, unitOfFinalPrice: (iOrderEasypayRequest2.unitOfSalesPrice) - (res.data.discountPrice)})
            // }
            // setIOrderEasypayRequest({...iOrderEasypayRequest2, unitOfCouponPrice: res.data.discountPrice}) 

            setCouponApplied(true);

            if (isShowAlert) alert(t("pass_view.promotion_alert"));
            
        } else {
        // } else {
            // {"code":"-1002","desc":"사용할 수 없는 쿠폰입니다."}
            if (isShowAlert) alert("Discount code is not valid.");
            setInputCouponCode('');
            setCouponInfo(initCouponInfo);
        }
        
    }

    const fetchMe = async () => {
        const res = await AxiosConfig.get("/api2/memberInfo");
        setUserInfo(res.data);
    }

    /**
     * 20210706 현재 명동센터만 강제로 일요일/설날/추석 휴일로 적용
     */
    const fetchHolidayApiInfo = async () => {
        const url = `/api2/holidayApiInfo`;
        const res = await AxiosConfig.get(url);

        if (res.status === 200) {
            setHolidayApiInfoes(res.data);
        }
    }

    useEffect(() => {
        if (holidayApiInfoes === undefined ||holidayApiInfoes.length < 1 ) {
            return;
        }

        const infoes1 = holidayApiInfoes.map((it:IHolidayApiInfo) => {
            if (it.dateName === "설날" || it.dateName === "추석") {
                const y = it.locdate.substring(0, 4)
                const m = it.locdate.substring(4, 6)
                const d = it.locdate.substring(6, 8)
                // console.log(`passviewholiday::ymd:: ${y}y${m}m${d}d::`);
                const seoul = new Date(Number(y), Number(m) -1 , Number(d))
                // console.log("passviewholiday::seoul.toUTCString()::" + seoul.toUTCString());
                return new Date(seoul.toUTCString());
            }
        })
        const infoes2 = infoes1.filter((it) => {if (it==null) return false; else return true})
        // console.log("passviewholiday::infoes2:::" + JSON.stringify(infoes2))
        setMyungDongHoliday(
            [
                ...infoes2, 
                new Date(2024, 4, 18),
                new Date(2024, 4, 19),
                {before: new Date()},
                {after: getNext3Months()},
                { daysOfWeek: [0] }  
            ]
        )

        setMyungDongHoliday20230315(
            [
                ...infoes2, 
                {before: new Date(2023,2,15)},
                {after: getNext3Months()},
                { daysOfWeek: [0] }  
            ]
        )

        setHolidayOfCode28([
            ...infoes2,
            new Date(2024, 4, 11),
            new Date(2024, 4, 12),
            {before: new Date()},
            {after: getNext3Months()},
            { daysOfWeek: [0] }
        ]);

        setHolidayOfCode2820230315([
            ...infoes2,
            new Date(2022, 3, 9),  // 값3이 4월임 2022, 3, 9  --> 2022년 4월 9일
            new Date(2022, 3, 10),
            new Date(2022, 3, 16),
            new Date(2022, 3, 17),
            new Date(2022, 3, 23),
            new Date(2022, 3, 24),
            new Date(2022, 3, 30),
            {before: new Date(2023,2,15)},
            {after: getNext3Months()},
            { daysOfWeek: [0] }
        ]);
            // [
            // new Date(),
            // {before: new Date()},
            // {after: getNextYear()},
            // { daysOfWeek: [0] }
            // ]
        return () => {}
    }, [holidayApiInfoes.length])

    /**
     * 
     * @param easypayResult  FIXME: 개발 완료 후 ? (물음표 제거)
     */
    const postOrderProcess = async (easypayResult?: IEasypayResult) => {
      // 두객체 병합...https://mariusschulz.com/blog/object-rest-and-spread-in-typescript
      const orderEasypayRequest:IOrderEasypayRequest = {...easypayResult, ...iOrderEasypayRequestPrevRef.current}
      var data = JSON.stringify({
        productUid: props.match.params.productUid,
      });

      const res = await AxiosConfig.post("/api2/orderProcess", orderEasypayRequest, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.status == 200 && res.data.referenceCode == easypayResult.order_no) {
          closeModal("200", "orderProcess")
          history.replace({
            pathname: `/app/pass/finish/${props.match.params.productUid}/${easypayResult.order_no}`,
            state: { orderEasypayRequest: orderEasypayRequest, orderProcessResult: res.data }
        })
      } else {
        console.error(res.status + " " + JSON.stringify(res.data));
      }
    };

    const easypayResult = (event:any) => {
        const resCd = event.data.res_cd == null ? "" : event.data.res_cd
        if (resCd == "0000") { //0000: 인증응답이 0000이면 정상 - 승인요청처리 필요
            postOrderProcess(event.data)
        } else if (resCd.length == 4) {
            //TODO: 실패정보를 저장할지도 모름.
            closeModal(event.data.res_cd, event.data.res_msg);
        } else if (resCd == "") {
            closeModal("");
        } else {
            closeModal("강제닫기");
        }
    }

    const receiveMessage = (event:any) => {
        if (event.data.source == "easypay-web-order-res") {
            console.info("easypay-web-order-res:: " + JSON.stringify(event.data));
            if (event.data.EP_res_cd == "W002"){
                closeModal(event.data.EP_res_cd, null);
            } else {
                console.info(`(${event.data.EP_res_cd}) ${event.data.EP_res_msg}`)
            }
        } else if (event.data.source == "easypay-mobile-order-res") {
            console.info("easypay-mobile-order-res:: " + JSON.stringify(event.data));
            if (event.data.sp_res_cd == "W002"){
                closeModal(event.data.sp_res_cd, null);
            } else {
                const sp_res_msg = decodeURIComponent(event.data.sp_res_msg)
                console.info(`(${event.data.sp_res_cd}) ${sp_res_msg}`)
            }
        } else if (event.data.source == "easypay-web-result") {
            console.info("easypay-web-result:: " + JSON.stringify(event.data));
            easypayResult(event)
        } else if (event.data.source == "easypay-mobile-result") {
            console.info("easypay-mobile-result:: " + JSON.stringify(event.data));
            easypayResult(event)
        }
    }

    // useEffect(()=>{
    //     setIOrderEasypayRequest({
    //         productUid: props.match.params.productUid,
    //         receiveDate: (pickup_date==null||pickup_date=="")?"1970-01-01T00:00:00":pickup_date,
    //         receiverUid: (selectedPickupLocation==null||selectedPickupLocation=="")?-2:Number(selectedPickupLocation),
    //         couponName: "",
    //         couponCode: (couponCode==null||couponCode=="")?"":couponCode,
    //         unitOfCouponPrice: 0,
    //         sumOfCouponPrice: 0,
    //         sumOfFinalPrice: 0,  //	 * order_info테이블의 final_price
    //         unitOfFinalPrice: 0,  //  * 총합산 금액
    //         unitOfNormalPrice: 0, //	 * order_info테이블의 passtype_price 할인전 금액 최초금액
    //         unitOfSalesPrice: 0, //	 * order_info테이블의 sale_price 판매금액
    //         storePartnerUid: (isMobile?73:72),  //웹이면 72 앱이면 73 store_partner_uid
    //         paymentMethod: "11", //	 * 11:신용카드21:계좌이체22:가상계좌31:휴대폰50:선불결제60:간편결제81:배치인증
    //         quantity: (qty==null||qty<1)?1:qty,
    //     });
          
    //     return () => {}
    // },[])

    useEffect(() => {
        loadSearchQuery();
        fetchMe();
        fetchPass();
        fetchPickupLocations();
        fetchHolidayApiInfo();
        // fetchCouponCode(couponCode, false)
		document.getElementById('root').scrollTo(0, 0);
        window.addEventListener("message", receiveMessage)
        return () => window.removeEventListener("message", receiveMessage)
    }, [globalInfoStore.currentLangType]);

    const updateIOrderEasypayRequest = () => {

    }

    useEffect(()=>{
        console.log("111111111111111::::::::::::::::::::::" + JSON.stringify(couponInfo));
        if (couponInfo.couponCode != "" && couponInfo.discountPrice > 0 && couponInfo.subCode == CP_SUBCODE_PERCENT && couponInfo.discountPrice <= 100 && couponInfo.discountPrice >= 0) {
            const unitOfFinalPrice:number = (iOrderEasypayRequest2.unitOfSalesPrice) * (1 - (couponInfo.discountPrice * 0.01));
            const sumOfFinalPrice:number = (iOrderEasypayRequest2.unitOfSalesPrice * iOrderEasypayRequest2.quantity) * (1 - (couponInfo.discountPrice * 0.01));
            var iOrderEasypayRequest3 = Object.assign({}, iOrderEasypayRequest2);
            iOrderEasypayRequest3.couponName = couponInfo.title;
            iOrderEasypayRequest3.couponSubCode = couponInfo.subCode;
            iOrderEasypayRequest3.couponCode = couponInfo.couponCode;
            iOrderEasypayRequest3.unitOfCouponPrice = couponInfo.discountPrice;
            iOrderEasypayRequest3.unitOfFinalPrice = Math.ceil(unitOfFinalPrice);
            iOrderEasypayRequest3.sumOfCouponPrice = Math.ceil(couponInfo.discountPrice);
            iOrderEasypayRequest3.sumOfFinalPrice = Math.ceil(sumOfFinalPrice);
            setIOrderEasypayRequest(iOrderEasypayRequest3);

        } else if (couponInfo.couponCode != ""  && couponInfo.discountPrice > 0 && couponInfo.subCode == CP_SUBCODE_PRICE) {
            const unitOfFinalPrice:number = ((iOrderEasypayRequest2.unitOfSalesPrice) - (couponInfo.discountPrice));
            const sumOfFinalPrice:number = (iOrderEasypayRequest2.unitOfSalesPrice * iOrderEasypayRequest2.quantity) - (couponInfo.discountPrice * iOrderEasypayRequest2.quantity);
            // console.log("unitOfFinalPrice:::2:::" + unitOfFinalPrice);
            var iOrderEasypayRequest3 = Object.assign({}, iOrderEasypayRequest2);
            iOrderEasypayRequest3.couponName = couponInfo.title;
            iOrderEasypayRequest3.couponSubCode = couponInfo.subCode;
            iOrderEasypayRequest3.couponCode = couponInfo.couponCode;
            iOrderEasypayRequest3.unitOfCouponPrice = couponInfo.discountPrice;
            iOrderEasypayRequest3.unitOfFinalPrice = Math.ceil(unitOfFinalPrice);
            iOrderEasypayRequest3.sumOfCouponPrice = Math.ceil(couponInfo.discountPrice);
            iOrderEasypayRequest3.sumOfFinalPrice = Math.ceil(sumOfFinalPrice);
            setIOrderEasypayRequest(iOrderEasypayRequest3);

        } else {
            const sumOfCouponPrice:number = iOrderEasypayRequest2.unitOfCouponPrice * iOrderEasypayRequest2.quantity;
            const sumOfFinalPrice:number = iOrderEasypayRequest2.unitOfSalesPrice * iOrderEasypayRequest2.quantity;
            var iOrderEasypayRequest3 = Object.assign({}, iOrderEasypayRequest2);
            iOrderEasypayRequest3.sumOfCouponPrice = sumOfCouponPrice;
            iOrderEasypayRequest3.sumOfFinalPrice = Math.ceil(sumOfFinalPrice);
            setIOrderEasypayRequest(iOrderEasypayRequest3);
        }

            // setIOrderEasypayRequest({...iOrderEasypayRequest2, couponName: res.data.title}) 
            // setIOrderEasypayRequest({...iOrderEasypayRequest2, couponSubCode: res.data.subCode}) 
            // setIOrderEasypayRequest({...iOrderEasypayRequest2, couponCode: res.data.couponCode}) 
            // setIOrderEasypayRequest({...iOrderEasypayRequest2, unitOfCouponPrice: res.data.discountPrice})
            // if (res.data.discountPrice > 0 && res.data.subCode == CP_SUBCODE_PERCENT && res.data.discountPrice <= 100 && res.data.discountPrice >= 0) {
            //     setIOrderEasypayRequest({...iOrderEasypayRequest2, unitOfFinalPrice: (iOrderEasypayRequest2.unitOfSalesPrice) * (1 - (res.data.discountPrice * 0.01))}) 
            // } else if (res.data.discountPrice > 0 && res.data.subCode == CP_SUBCODE_PRICE) {
            //     setIOrderEasypayRequest({...iOrderEasypayRequest2, unitOfFinalPrice: (iOrderEasypayRequest2.unitOfSalesPrice) - (res.data.discountPrice)})
            // }


        //console.log("iOrderEasypayRequest2:::" + JSON.stringify(iOrderEasypayRequest2))
        
    }, [iOrderEasypayRequest2.quantity, iOrderEasypayRequest2.couponCode, couponInfo.couponCode])

    // useEffect(() => {
    //     console.log("useEffect::couponInfo.couponCode:::::" + couponInfo.couponCode)
    //     return () => {
            
    //     }
    // }, [couponInfo.couponCode])

    useEffect(()=>{
        try {
            numSelectedPickupLocation = Number(selectedPickupLocation)
        } catch(error) {
            console.error(error);
            return
        }
        if (numSelectedPickupLocation > 0) {
            setIOrderEasypayRequest({...iOrderEasypayRequest2, receiverUid: numSelectedPickupLocation}) 
        }
        
    }, [selectedPickupLocation])


    /** 
     * @param productUid
     * @return null
     * @response {   "code": "200",   "contentsUid": 40,   "favoriteUid": 1,   "desc": "정상" }
     */
    const postLikePass = async () => {
      var data = JSON.stringify({
        productUid: passItem.uid,
      });

      const res = await AxiosConfig.post("/api2/myFavoriteProductInfo", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.status == 200 && res.data.code == "200") {
        passItem.favoriteUid = 1; //
        setLikeActive(true);
      } else {
        console.error(res.status + " " + JSON.stringify(res.data));
      }
    };

    /** 
     * @param productUid
     * @return null
     * @response {  "code": "200",  "desc": "정상"}
     */
    const deleteLikePass = async () => {
      const res = await AxiosConfig.delete(
        `/api2/myFavoriteProductInfo/${passItem.uid}`
      );

      if (res.status == 200 && res.data.code == "200") {

        passItem.favoriteUid = 0;
        setLikeActive(false);

      } else {
        console.error(res.status + " " + JSON.stringify(res.data));
      }
    };
    
    const handleLikePass = async (e:any) => {
        if (passItem.favoriteUid == 1) {
          deleteLikePass()
        } else {
          postLikePass()
        }
    };

    const getModalContent = () => {
        // console.log('isPlatform("capacitor") || isPlatform("android") || isPlatform("ios") || isMobile ' + isPlatform("capacitor") + isPlatform("android") + isPlatform("ios") + isMobile )
        if (isMobile) {
            return {
                top: "50%",
                left: "50%",
                width: "100%" ,
                height: "80%",
                right: "auto",
                bottom: "auto",
                padding: "30 30 30 30",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)"
            }
        }
        return {
                top: "50%",
                left: "50%",
                width: 206 + 452 ,
                height: 548,
                right: "auto",
                bottom: "auto",
                padding: 0,
                marginRight: "-50%",
                transform: "translate(-50%, -50%)"
            }
     } ;

    //  const makeOrderNo = () => {
    //     const today = new Date();
    //     const year  = today.getFullYear();
    //     const month = today.getMonth() + 1;
    //     let monthStr:string;
    //     const date  = today.getDate();
    //     let dateStr:string;
    //     const time  = today.getTime();

    //     if(month < 10)
    //     {
    //         monthStr = "0" + month;
    //     }

    //     if(date < 10)
    //     {
    //         dateStr = "0" + date;
    //     }

    //     // return "DSP" + year + month + date + time + "TEST";
    //     return "TEST" + year + month + date + time;

    // }
    
    const openModal = () => {
        // let payResult : IEasypayResult;
        // payResult = {
        //     "source": "easypay-web-result",        //
        //     "res_cd": "0000",        //응답코드          (CA, CAO, CC, CCO, CPC)
        //     "res_msg": "MPI결제 정상",        //응답메시지        (CA, CAO, CC, CCO, CPC)
        //     "cno": makeOrderNo(),        //PG거래번호        (CA, CAO, CC, CCO, CPC)
        //     "amount": "50000",        //총 결제금액       (CA,                  )
        //     "order_no": "DSP"+makeOrderNo(),        //주문번호          (CA,                  )
        //     "auth_no": "0000000",        //승인번호          (CA,                  )
        //     "tran_date": makeOrderNo(),        //승인일시          (CA,      CC,      CPC)
        //     "escrow_yn": "N",        //에스크로 사용유무 (CA,                  )
        //     "complex_yn": "N",        //복합결제 유무     (CA,                  )
        //     "stat_cd": "TS03",        //상태코드          (CA,      CC,      CPC)
        //     "stat_msg": "매입요청",        //상태메시지        (CA,      CC,      CPC)
        //     "pay_type": "11",        //결제수단          (CA,                  )
        //     "mall_id": "",        //가맹점 Mall ID    (CA                   )
        //     "card_no": "5363200002430235",        //카드번호          (CA,          CCO     )
        //     "issuer_cd": "049",        //발급사코드        (CA,          CCO     )
        //     "issuer_nm": "OK:00252658",        //발급사명          (CA,          CCO     )
        //     "acquirer_cd": "026",        //매입사코드        (CA,          CCO     )
        //     "acquirer_nm": "Payzone TestCard",        //매입사명          (CA,          CCO     )
        //     "install_period": "00",        //할부개월          (CA,          CCO     )
        //     "noint": "00",        //무이자여부        (CA                   )
        //     "part_cancel_yn": "Y",        //부분취소 가능여부 (CA                   )
        //     "card_gubun": "N",        //신용카드 종류     (CA                   )
        //     "card_biz_gubun": "P",        //신용카드 구분     (CA                   )
        //     "cpon_flag": "N",        //쿠폰 사용유무     (    CAO,     CCO     )
        //     "used_cpon": "",        //쿠폰 사용금액     (    CAO              )
        //     "canc_acq_date": "",        //매입취소일시      (                  CPC)
        //     "canc_date": "",        //취소일시          (CC,               CPC)
        //     "account_no": ""        //계좌번호          (CC,                  )
        // };

        // iOrderEasypayRequest2.receiverUid = Number(selectedPickupLocation);
        // iOrderEasypayRequest2.receiveDate = pickupDate.selectedDay;
        // iOrderEasypayRequestPrevRef.current = iOrderEasypayRequest2

        // payResult.amount = "" + iOrderEasypayRequest2.unitOfFinalPrice * iOrderEasypayRequest2.quantity;

        // postOrderProcess(payResult);
        // return;

        console.log("openModal1:::" + new Date() + JSON.stringify(iOrderEasypayRequest2));

        var title = "PAY NOW";
        if(iOrderEasypayRequest2.productUid == 1) {
            title = "디스커버서울패스 24시간권 모바일"; 
        } else if(iOrderEasypayRequest2.productUid == 2) {
            title = "디스커버서울패스 24시간권 실물"; 
        } else if(iOrderEasypayRequest2.productUid == 3) {
            title = "디스커버서울패스 48시간권 실물"; 
        } else if(iOrderEasypayRequest2.productUid == 4) {
            title = "디스커버서울패스 48시간권 모바일"; 
        } else if(iOrderEasypayRequest2.productUid == 5) {
            title = "디스커버서울패스 72시간권 실물"; 
        } else if(iOrderEasypayRequest2.productUid == 7) {
            title = "디스커버서울패스 72시간권 모바일"; 
        } 

        ReactGA.event({
            category: "구매페이지",
            action: "구매클릭",
            label: title,
          });

        //사용자 정보를 가져온 상태여야 함. 사용자 정보 체크
        if (globalInfoStore.getDspJwt() == null || userInfo == undefined ) {

            const urlQuery = qs.stringify({
                pickup_date:pickupDate.selectedDay,
                pickup_location:selectedPickupLocation,
                // coupon_code:iOrderEasypayRequest2.couponCode,
                coupon_code:iOrderEasypayRequest2.couponCode,
                qty:iOrderEasypayRequest2.quantity
            })

            setRedirectPage(location.pathname + "?" + urlQuery);

            history.push("/app/signin/index/login");

            return;
        }

        if (passItem.code==1 && (Number(selectedPickupLocation) < 1 || !Number.isInteger(Number(selectedPickupLocation)))) {
            alert(t("pass_view.choose_location"));
            return;
        }

        if (passItem.code==1 
            && (pickupDate.isEmpty == null 
                || pickupDate.isEmpty == true 
                || pickupDate.isValidDay == false 
                || pickupDate.isDisabled == true
                // || selectedPickupDay === null
                // || selectedPickupDay === ""
                )
                
            ) {
            alert(t("pass_view.select_pickup_date"));
            return;
        }


        if (userInfo == undefined || userInfo.name == null || userInfo.tel == null){
            return;
        }

        iOrderEasypayRequest2.receiverUid = Number(selectedPickupLocation);
        iOrderEasypayRequest2.receiveDate = pickupDate.selectedDay;
        iOrderEasypayRequestPrevRef.current = iOrderEasypayRequest2
        
        setIsModalOpen(true);

        // AxiosConfig.post("/api2/systemApiLog", {
        //     api_info: 'Open Pay Window',
        //     query_params: JSON.stringify(iOrderEasypayRequest2),
        //     user_id: ''+userInfo.uid
        // }, { headers: { "Content-Type": "application/json", },});

    }
  
    const afterOpenModal = () => {
      // references are now sync'd and can be accessed.
    //   subtitle.style.color = '#056';
    }

    const closeModal = (resCd?:string, resMsg?:string) => {
        console.info("resCd: " + resCd + " resMsg:" + resMsg )

        if (resCd == "") {
            alert("Payment information is incorrect.")
            return;
        }

        if (resCd != null && resCd == "W002" ) {
            setIsModalOpen(false);
            return;
        }

        // 결제를 진행중입니다. 창을 닫으시겠습니까?
        if (resCd == "강제닫기") {

            let yn = window.confirm("Do you want to close the payment window?");
            if (yn) {
                setIsModalOpen(false);
            }
            return;
            
        }

        if (resCd.length == 4 &&
            (resCd.startsWith("W") ||
                resCd.startsWith("V") ||
                resCd.startsWith("M") ||
                resCd.startsWith("K") ||
                resCd.startsWith("1") ||
                resCd.startsWith("2") ||
                resCd.startsWith("3") ||
                resCd.startsWith("4") ||
                resCd.startsWith("5") ||
                resCd.startsWith("6") ||
                resCd.startsWith("7") ||
                resCd.startsWith("8") ||
                resCd.startsWith("9") ||
                resCd == "0200")
        ) {
            if (resCd == "W002" ) {
                alert("Payment has been cancelled(" + resCd + ")");    
            } else {
                alert("An error has occurred. (CODE: " + resCd + " " + resMsg + ")");
            }
            setIsModalOpen(false);
            resCd = "";
        } else if (resCd.length == 4 && resCd == "0000") {
            alert("Payment has been completed.");
            setIsModalOpen(false);
            history.replace(`/app/pass/finish/${passItem.uid}/${orderNo}`)
            resCd = "";
        }

        if( resCd == "200" && resMsg == "orderProcess") {
            setIsModalOpen(false);
        }

    };

    const onCouponCodeChange = (e: any) => {
        //console.log(e.type + ":", e.target.value);
        setInputCouponCode(e.target.value);
    }

    const handleCoupon = (e: any) => {
        /** TODO: 검색어 필터가 걸리고 다시 풀려고 할 경우에 대한 테스트 코드 필요...*/
        //INPUT 은 비엇고 searchKeyword는 있을 경우 filter를 제거하고 검색시도한다
        if (inputCouponCode == null)  return;
        if (inputCouponCode == "") { alert("쿠폰번호를 입력하세요."); return; }

        // setCouponCode(inputCouponCode)
        fetchCouponCode(inputCouponCode);
    }

    const TotalCouponPrice = () => {

        if (couponInfo==null) {
            return <p className="td"></p>
        } else if (couponInfo.subCode == CP_SUBCODE_PERCENT){
            return <p className="td">{couponInfo.discountPrice + " %"}</p>
        } else if (couponInfo.subCode == CP_SUBCODE_PRICE){
            return <p className="td">{"KRW " + couponInfo.discountPrice * iOrderEasypayRequest2.quantity}</p>
        }

        return (<></>);
    }

    const onCouponKeyDown = (e: any) => {
		// setSearchKeyword(inputCouponCode)
        if (e.keyCode === 13) {
			/** TODO: 검색어 필터가 걸리고 다시 풀려고 할 경우에 대한 테스트 코드 필요...*/
			//INPUT 은 비엇고 couponCode는 있을 경우 filter를 제거하고 검색시도한다
			if (inputCouponCode == null)  return;
			if (inputCouponCode == "") { alert("검색어를 입력하세요."); return; }

            // setCouponCode(inputCouponCode)
            fetchCouponCode(inputCouponCode);
            // fetchSpots(page, size, []);// hooks로 변경.
        }
    }

    const addQuantity = () => {
        const newQuantity = (iOrderEasypayRequest2.quantity<10?iOrderEasypayRequest2.quantity+1:10);
        setIOrderEasypayRequest({...iOrderEasypayRequest2, quantity: newQuantity});
    }

    const subtractQuantity = () => {
        const newQuantity = (iOrderEasypayRequest2.quantity>2 ? iOrderEasypayRequest2.quantity - 1:1)
        setIOrderEasypayRequest({...iOrderEasypayRequest2, quantity: newQuantity});
    }
    
    const handleDayChange = (selectedDay:any, modifiers:any, dayPickerInput:any) => {
        const input = dayPickerInput.getInput();

        console.log(selectedDay)
        setPickupDate({
          selectedDay,
          isEmpty: !input.value.trim(),
          isValidDay: typeof selectedDay !== 'undefined',
          isDisabled: modifiers.disabled,
        });

        // setSelectedPickupDay(selectedDay);
    }
      
    
    const renderListOfPickupLocations = (filteredPickupLocations || []).map((item) => {
        return (
            <option key={item.uid} value={item.uid}>
              {item[globalInfoStore.currentLangType.title2]}
            </option>
          )
    });

    if (passItem == undefined) {
		return <></>
	}
    return (
        <div className="layout">
            <div>
                <Modal
                    isOpen={isModalOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={(ev)=>{closeModal("강제닫기")}}
                    appElement={document.getElementById("#modal-root")}
                    style={{
                        overlay: {
                          backgroundColor: '#3339'
                        },
                        content:getModalContent()
                    }}
                    contentLabel="Discover Seoul Pass"
                >
                    <EasypayIframeView
                        content={passItem}
                        user={userInfo}
                        productAmt={iOrderEasypayRequest2.sumOfFinalPrice}
                        orderNo={orderNo}
                        onClose={(code:any)=>{closeModal(code)}}
                        />
                </Modal>
            </div>
            

            <div className="wrap_passView"> 
                <p className="tit">{getValueByKey(passItem, globalInfoStore.currentLangType.title2)}
                    <span className={classNames({like:true, on:(( passItem.favoriteUid == null || passItem.favoriteUid < 1 )? false: true)})} onClick={(e)=>handleLikePass(e)} >북마크</span>

                </p>

                <div className="purchase">
                    <img src={passItem.fullpath} alt={getValueByKey(passItem, globalInfoStore.currentLangType.title2)} />
                    <ul className="info">
                        <li>
                            <p className="th">{t("pass_view.product")}</p>
                            <p className="td">{getValueByKey(passItem, globalInfoStore.currentLangType.title2)}</p>
                        </li>
                        <li>
                            <p className="th">{t("pass_view.pass_type")}</p>
                            <p className="td">
                                <span className="sort">
                                    {passItem.code==1?t("pass_view.card_pass"):t("pass_view.mobile_pass")}
                                </span>
                            </p>
                        </li>
                        <li>
                            <p className="th">{t("pass_view.hour")}</p>
                            <p className="td">
                                <span className="time">{passItem.passtypeTime}H</span>
                            </p>
                        </li>
                        <li>
                            <p className="th">{t("pass_view.price")}</p>
                            <p className="td">KRW {iOrderEasypayRequest2.unitOfSalesPrice.toLocaleString()}</p>
                            {/* <p className="rate">CNY 465.84</p> */}
                        </li>
                    </ul>
                    <ul className="input_info">
                        <li>
                            <div className="cnt">
                                <button className="min" onClick={subtractQuantity}>빼기</button>
                                <p>{iOrderEasypayRequest2.quantity}</p>
                                <button className="plus" onClick={addQuantity}>더하기</button>
                            </div>
                            <p className="price">KRW {(iOrderEasypayRequest2.unitOfSalesPrice * iOrderEasypayRequest2.quantity).toLocaleString()}</p>
                        </li>
                        
                        <li hidden={passItem.code!=1}>
                            <p className="th">{t("pass_view.select_pickup_location")}</p>
                                {/* 명동관광정보센터 (09:00~17:30)
                                인천국제공항 제1여객터미널 (24시간)
                                인천국제공항 제2여객터미널 (07:00-20:00)
                                김포공항 (10:00~22:00) */}
                            <select onChange={e => selectPickupLocation(e)}  value={selectedPickupLocation}>
                                <option>{t("pass_view.choose_location")}</option>
                                {renderListOfPickupLocations}
							</select>
                        </li>

                        <li hidden={passItem.code!=1}>
                            <p className="th">{t("pass_view.select_pickup_date")}</p>
                            <DayPickerInput
                                onDayChange={handleDayChange}
                                formatDate={formatDate}
                                format={FORMAT}
                                parseDate={parseDate}
                                placeholder={
                                    `${getExceptPickupDate()}`
                                    // `${dateFnsFormat(new Date(pickup_date), FORMAT)}`
                                }
                                dayPickerProps={{ 
                                    // disabledDays: isMyungDongActive==true?myungDongHoliday:noneHoliday
                                    disabledDays: getHolidayByPickLocation()
                                }}
                                inputProps={{
                                    readonly: 'readonly'
                                }}
                                value={pickupDate.selectedDay}
                                />
                        </li>
                    </ul>
                    <div className="discount">
                        <input type="text" placeholder={t("pass_view.enter_promotion_code")}
                            onChange={onCouponCodeChange}
                            onKeyDown={(e) => onCouponKeyDown(e)}
                            disabled={isCouponApplied}
                            value={inputCouponCode}
                        />
                        <button onClick={handleCoupon} disabled={isCouponApplied}>{t("pass_view.apply")}</button>
                        <p className="th">{(couponInfo==null)?"":t("pass_view.total_discount_amount")}</p>
                        <TotalCouponPrice></TotalCouponPrice>
                        
                    </div>
                    <div className="total">
                        <p className="th">{t("pass_view.total")}</p>
                        <p className="td">KRW {iOrderEasypayRequest2.sumOfFinalPrice.toLocaleString()}</p>
                    </div>


                    <button className="btn_purchase" onClick={openModal}>{t("pass_view.pay_now")}</button>
                    
                </div>

                <div className="cont">
                    <p className="cont_tit">{t("pass_view.detailed_information")}</p>
                    <p dangerouslySetInnerHTML={{__html:getValueByKey(passItem, globalInfoStore.currentLangType.desc)}}></p>
                </div>
            </div>

            {(isShowRecentPass)?<PassSidebar show={setIsShowRecentPass}></PassSidebar>:""}

            

        </div>
    );
};

export default PassView;



  