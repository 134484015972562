import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch } from 'react-router';
import Navigation from '../navigation/Navigation';
import Guide from './Guide';
import GuideBuyIndex from './GuideBuyIndex';
import GuideBuyOffline from './GuideBuyOffline';
import GuideBuyOnline from './GuideBuyOnline';
import GuideBuyTakepass from './GuideBuyTakepass';
//import GuideHowArex from './GuideHowArex';
import GuideHowIndex from './GuideHowIndex';
import GuideHowMPass from './GuideHowMobilePass';
import GuideHowCardPass from './GuideHowCardPass';
import GuideHowSeoulbike from './GuideHowSeoulbike';
import { globalInfoStore } from "../../stores/GlobalInfoStore";

const GuideIndex = () => {
	const { t, i18n } = useTranslation();

	

    const [pageTitle, setPageTitle] = useState("");

	useEffect(() => {

	}, [globalInfoStore.currentLangType])

	const PageTitle = (event:any) => {
        
        return (<Switch>
            <Route path="/app/guide/index/passguide" component={()=>(<span>{t("navigation.app_depth1title_passguide")}</span>)}></Route>
			<Route path="/app/guide/index/buy" component={()=>(<span>{t("navigation.app_depth1title_how_to_purchase")}</span>)}></Route>
			<Route path="/app/guide/index/how" component={()=>(<span>{t("navigation.app_depth1title_how_to_use")}</span>)}></Route>
        </Switch>)
    }

    return (
        <section id="container" className="sub">
			{/* <h6 className="hidden">패스소개</h6> */}

			<article className="bg sub05">
				<div className="layout">
					<h2 className="page_tit"><PageTitle></PageTitle></h2>
				</div>
			</article>

			<Navigation></Navigation>

			<article id="content">
				
                <Switch>
					<Route path="/app/guide/index/passguide" component={Guide} />
					<Route path="/app/guide/index/buy" component={GuideBuyIndex} />
					<Route path="/app/guide/index/how" component={GuideHowIndex} />
					{/* <Route exact path="/app/guide/index/buy" render={()=><Redirect to="/app/guide/index/buy/online"/>} /> */}
                    {/* <Route path="/app/guide/index/buy/online" component={GuideBuyOnline} />
					<Route path="/app/guide/index/buy/offline" component={GuideBuyOffline} />
					<Route path="/app/guide/index/buy/takepass" component={GuideBuyTakepass} /> */}
					
					{/* <Route exact path="/app/guide/index/how" render={()=><Redirect to="/app/guide/index/how/cardpass" />} /> */}
					{/* <Route path="/app/guide/index/how/cardpass" component={GuideHowCardPass} />
					<Route path="/app/guide/index/how/arex" component={GuideHowArex} />
					<Route path="/app/guide/index/how/mobilepass" component={GuideHowMPass} />
                    <Route path="/app/guide/index/how/bikeseoul" component={GuideHowSeoulbike} /> */}
                </Switch>
			</article>

		</section>
    );
};

export default GuideIndex;
