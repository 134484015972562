import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import { RelationStoreContentsInfo, StoreInfo } from "./GuideBuyOffline";

const classNames = require("classnames");

const AccordionOfflineItem = (props: {content: StoreInfo, storeContents: RelationStoreContentsInfo[], click:any}) => {
    const { t, i18n } = useTranslation();
    const [isExpand, setExpand] = useState({
        active:true,
        display:"block"
    });

    function changeMapLocation(item: StoreInfo) {

        if (isExpand.active) {
            setExpand({active:false, display:"none"});    
        } else {
            props.click(item);
            setExpand({active:true, display:"block"});    
        }
        console.log("child" + item.uid);
        // props.click(item);
    }

    // useEffect(() => {
    //     return () => {
    //     }
    // }, [isExpand])

    // const toggleAccordion = (e:any) => {
    //     setExpand(!isExpand);
    // }
    	// 	//아코디언 메뉴
		// $(function(){
		// 	$("dl.accordion>dt").click(function(){
		// 		$(this).next("dd").slideToggle();
		// 		$(this).toggleClass("active");
		// 	});
		// });
    
    return (
        <dl className="accordion" onClick={(e)=>{changeMapLocation(props.content)}}>
            {/* <dt onClick={(e)=>{toggleAccordion(e)}}> */}
            <dt className={classNames({active:isExpand.active})}>
                <p>{props.storeContents.find( it => it.code == globalInfoStore.currentLangType.langCode).title}</p>
            </dt>
            <dd className="clear" style={{display:isExpand.display}}>
            {/* <dd className="clear" style={{display:"none"}}> */}
                { (props.content.fullpath)? <img className="clear" src={props.content.fullpath} alt={props.content.title + " 이미지"}/> : ''}
                <p>
                    <span>{t("buy_offline.contact")}</span>
                    <span>{props.content.tel}</span>
                </p>
                <p>
                    <span>{t("buy_offline.operating_hours")}</span>
                    <span>{props.content.relationHourInfo[0].hour}</span>
                </p>
                <p>
                    <span>{t("buy_offline.holiday")}</span>
                    <span>{props.storeContents.find( it => it.code == globalInfoStore.currentLangType.langCode).holiday}</span>
                </p>
                <p>
                    <span>{t("buy_offline.address")}</span>
                    <span>{props.storeContents.find( it => it.code == globalInfoStore.currentLangType.langCode).address}
                    <a className="gToMap2" href={props.content.mapUrl} target="_blank">{t("buy_offline.go_to_map")}</a>
                    </span>
                </p>
            </dd>
        </dl>
    )
}

export default AccordionOfflineItem;