import { getPlatforms } from '@ionic/react';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import validator from 'validator';
import SignUpPrivacyDesc from '../../components/SignUpPrivacyDesc';
import SignUpPrivacyDesc2 from '../../components/SignUpPrivacyDesc2'
import AxiosConfig from '../../services/AxiosConfig';
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import { ISignUpData } from './SignUpWithEmail';

const classNames = require("classnames");

// type InputProps = {
//     email: string,        //Email] = useState(props.location.state.email); //props.location.state.email
//     fullname: string,        //Fullname] = useState("");
//     gender: 2,        //Gender] = useState(2);//회원 성별 ( 1: 남자, 2: 여자 )
//     password: string,        //Password] = useState("");
//     passwordConfirm: string,        //PasswordConfirm] = useState("");
//     selectedBirthyear: string,        //SelectedBirthyear] = useState("");
//     selectedCountry: string,        //SelectedCountry] = useState("");
//     selectedPhoneCountry: string,        //SelectedPhoneCountry] = useState("");
//     phoneNumber: string,        //PhoneNumber] = useState("");
//     isReceived: 2,        //IsReceived] = useState(2);
// }

const SignUpWithSns = (props:any) => {
    const LANG = "en";
    const { t, i18n } = useTranslation();
    const history = useHistory();
    /* 실제용 */
    // let snsEmail = "";
    // let snsCode = 2;
    
    const [isReadonlyEmail, setReadOnlyEmail] = useState(true);
   
    const [email, setEmail] = useState(props.location.state.email);
    //const [email, setEmail] = useState(""); 
    const [fullname, setFullname] = useState("");
    const [gender, setGender] = useState<number>(null);//회원 성별 ( 1: 남자, 2: 여자 )
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [selectedBirthyear, setSelectedBirthyear] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedPhoneCountry, setSelectedPhoneCountry] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isReceived, setIsReceived] = useState(2);
    const [listOfBirthYear, setListOfBirthYear] = useState([]);
    const [listOfCountry, setListOfCountry] = useState([]);
    const [isDisabledSubmit, setDisabledSubmit] = useState<boolean>(false);

    const [isAgreeAll, setIsAgreeAll] = useState(2);
    const [isAgreeTUA, setIsAgreeTUA] = useState(2);
    const [isAgreePICU, setIsAgreePICU] = useState(2);
    const [isAgreeAge, setIsAgreeAge] = useState(2);

    const fetchListOfBirthYear = () => {
        const year = (new Date()).getFullYear();
        setListOfBirthYear(Array.from(new Array(120),( val, index) => year - index));
    }

    const fetchListOfCountry = async () => {
        const res = await AxiosConfig.get("/api2/countryInfo");
        setListOfCountry(res.data);
    };

    useEffect(() => {
        fetchListOfBirthYear();
        fetchListOfCountry();
    },[globalInfoStore.currentLangType])

    const renderListOfCountry = (listOfCountry || []).map((item) => {
        return (
            <option key={item.uid} value={item.uid}>
              {item[LANG]}({item.telCode}) 
            </option>
          )
    });

    // const renderListOfPhoneCountry = (listOfCountry || []).map((item) => {
    //     return (
    //         <option key={item.uid} value={item.telCode}>
    //           {item[LANG]}({item.telCode}) 
    //         </option>
    //       )
    // });

    const handleOnBlurEmail = () => {
        let emailError = "";

        if (!validator.isEmail(email)) {
          emailError = "email Error!";
        }
        if (emailError) {
            // alert("이메일 주소 확인.")
        //   this.setState({ emailError });
          return false;
        }
        return true;
    }

    /**
     * 
     * @param gender 회원 성별 ( 1: 남자, 2: 여자 )
     */
    // const setGender = (gender:number) => {
    //     setGender(gender);
    // }

    const handleIsReceived = () => {
        setIsReceived(isReceived==1?2:isReceived==2?1:2);
    }

    const handleIsAgreeAll = () => {
        setIsAgreeAll(isAgreeAll==1?2:isAgreeAll==2?1:2);

        if(isAgreeAll == 2) {
            setIsAgreeTUA(1);
            setIsAgreePICU(1);
        } else {
            setIsAgreeTUA(2);
            setIsAgreePICU(2);
        }
    }
    const handleIsAgreeTUA = () => {
        setIsAgreeTUA(isAgreeTUA==1?2:isAgreeTUA==2?1:2);

        if(isAgreeTUA == 2 && isAgreePICU == 1) {
            setIsAgreeAll(1);
        } else {
            setIsAgreeAll(2);
        }
    }
    const handleIsAgreePICU = () => {
        setIsAgreePICU(isAgreePICU==1?2:isAgreePICU==2?1:2);

        if(isAgreeTUA == 1 && isAgreePICU == 2) {
            setIsAgreeAll(1);
        } else {
            setIsAgreeAll(2);
        }
    }
    const handleIsAgreeAge = () => {
        setIsAgreeAge(isAgreeAge==1?2:isAgreeAge==2?1:2);
    }

    const handleBirthyear = () => {

    }

    const handleCountry = () => {

    }

    const postSignUpWithEmail = async (signUpData:ISignUpData) => {
        return await AxiosConfig.post("/api/auth/signup/email",  
            signUpData, 
            { 
                headers: {  
                    // 'Authorization': 'Basic xxxxxxxxxxxxxxxxxxx', 
                    'Content-Type' : 'application/json'  
                } 
            } 
        ) 

    }

    // const timeout = (ms:number) => {
    //     return new Promise(resolve => setTimeout(resolve, ms));
    // }

    /**
     * FIXME: 이름 길이 체크 추가.
     */
    const submitSignUp = async () => {
        if (isDisabledSubmit) return;

        setDisabledSubmit(true)
       // 이메일(아이디) *
        // 이메일을 입력하세요.
        if (!validator.isEmail(email)) { 
            alert("Please Check Email Address.")
            setDisabledSubmit(false);
            return;
        }
        
        //이름 확인
        if (!( fullname.length > 2 && fullname.length < 40 )) {
            alert("Please Check Name.(Min 3, Max 50");
            setDisabledSubmit(false);
            return;
        }

        // // 출생년도 *
        // if (selectedBirthyear == t("sign_up_with_email.select_birth") || selectedBirthyear == "" ) {
        //     alert("selectedBirthyear");
        //     return;
        // }

        // // 국적 *
        // if (selectedCountry == t("sign_up_with_email.select_country") || selectedCountry == "" ) {
        //     alert("selectedCountry");
        //     return;
        // }
        
        if (selectedPhoneCountry == t("sign_up_with_email.select_phone_no") || selectedPhoneCountry == "" ) {
            alert("Please Select Phone Country.");
            setDisabledSubmit(false);
            return;
        }
        // 국가 전화번호 확인
        if (!validator.isMobilePhone(phoneNumber)) {
            alert("Please Check Phone Number.");
            setDisabledSubmit(false);
            return;
        }

        //이름 확인
        if (!( password.length > 6 && password.length <= 15 )) {
            alert("Please Check Password.");
            setDisabledSubmit(false);
            return;
        } else {
            const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,15}$/
            if (!passwordRegex.test(password)) {
                alert("Please Check Password validation.");
                setDisabledSubmit(false);
                return;
            }
        }

        if (!( passwordConfirm.length > 6 && passwordConfirm.length <= 15 )) {
            alert("Please Check Confirm Password.");
            setDisabledSubmit(false);
            return;
        }

        //비밀번호 두개가 같은지 체크
        if ( password != passwordConfirm ) {
            alert("Please Check Password.");
            setDisabledSubmit(false);
            return;
        }

        // let signUpData:SignUpData = {};
        

        // let signUpData:SignUpData = {
        //     snsType: "1",
        //     countryUid: 112,
        //     telCountryUid: 82,
        //     gender: 1,
        //     email: "uniqer@daum.net",
        //     name: "Ahn Sung-dae",
        //     password: "12341234",
        //     tel: "01089397747",
        //     mobileUuid: "testuuid",
        //     snsId: null,
        //     birthYear: "1982",
        //     isReceived: 0
        // };

        // 회원가입 유형 ( 1: 이메일 가입, 2: 페북 연동, 3: 구글 연동, 4: 웨이보 연동 )',
        let snsCode = 2;
        if (props.location.state.snsType === "facebook") {
            snsCode = 2;
            // setEmail(props.location.state.email);
            setReadOnlyEmail(true);
        } else if (props.location.state.snsType === "google") {
            snsCode = 3;
            // setEmail(props.location.state.email);
            setReadOnlyEmail(true);
        // } else if (props.location.state.snsType === "weibo") {
        //     snsCode = 4;
        //     setEmail("");
        //     setReadOnlyEmail(false);
        } else if (props.location.state.snsType === "apple") {
            snsCode = 5;
            setEmail("");
            setReadOnlyEmail(false);
        } else {
            setDisabledSubmit(false);
            return;
        }

        
        if (props.location.state.snsType === "facebook") {
            snsCode = 2;
        } else if (props.location.state.snsType === "google") {
            snsCode = 3;
        // } else if (props.location.state.snsType === "weibo") {
        //     snsCode = 4;
        } else if (props.location.state.snsType === "apple") {
            snsCode = 4;
        } else {
            setDisabledSubmit(false);
            return;
        }

        if (isAgreeTUA == 2) {
            alert("Please Check Terms of Use Agreement.");
            setDisabledSubmit(false);
            return ;
        }

        if (isAgreePICU == 2) {
            alert("Please Check Personal Information Collection and Usage Agreement.");
            setDisabledSubmit(false);
            return ;
        }

        if (isAgreeAge == 2) {
            alert(t("sign_up_with_email.under14"));
            setDisabledSubmit(false);
            return ;
        }


        let signUpData:ISignUpData = {
            code: snsCode, //1: email
            countryUid: parseInt(selectedCountry),
            telCountryUid: parseInt(selectedPhoneCountry),
            gender: gender,
            email: email,
            name: fullname,
            password: password,
            tel: phoneNumber,
            mobileUuid: "",  //https://www.npmjs.com/package/@sparkfabrik/capacitor-plugin-idfa
            snsType: props.location.state.snsType,
            snsId: props.location.state.snsId, //email 회원은 snsId 가 null
            birthYear: selectedBirthyear,
            isReceived: isReceived,
            platformInfo: getPlatforms().join(",")
        };

        const res = await postSignUpWithEmail(signUpData)
        
        if (res.status == 201 && res.data.code == "201") {
            //alert(res.data.desc);
            alert(t("sign_up_with_email.completed"));
            history.replace("/app/signin/index/login")
        } else {
            alert("Membership registration failed:" + res.data.desc)
        }
        setDisabledSubmit(false);
        
    }

    

    return (
        <div className="layout">
            
	
            <div className="wrap_info">
                
                {/* <h6 className="sec_tit side">추가정보<br />입력</h6> */}
                <h6 className="sec_tit side" dangerouslySetInnerHTML={{__html:t("sign_up_with_email.title_with_sns")}}></h6>

                <em className="top_exp">* {t("sign_up_with_email.required")}</em>

                <dl>
                    <dt>{t("sign_up_with_email.email")} <em>*</em></dt>
                    <dd>
                        {/* <input type="text" value={email} onChange={handleChangeSearchText} title="이름" placeholder="이메일을 입력하세요." /> */}
                        <input className={classNames({readonly:isReadonlyEmail})} type="text" autoCapitalize="false" 
                                value={email} 
                                onChange={e => setEmail(e.target.value)}
                                onBlur={handleOnBlurEmail} title="이름" readOnly={isReadonlyEmail}/>
                        {/* <input className="readonly" type="text" autoCapitalize="false" 
                                value={email} 
                                onChange={e => setEmail(e.target.value)}
                                onBlur={handleOnBlurEmail} title="이름" readOnly={true}/> */}
                    </dd>
                </dl>
                <dl>
                    <dt>{t("sign_up_with_email.name")} <em>*</em></dt>
                    <dd>
                        <input type="text" value={fullname} onChange={e => setFullname(e.target.value)} title="이름" />
                    </dd>
                </dl>
                {/* <dl>
                    <dt>{t("sign_up_with_email.year_of_birth")} </dt>
                    <dd>
                        <select onChange={e => setSelectedBirthyear(e.target.value)} value={selectedBirthyear}>
                            <option>{t("sign_up_with_email.select_birth")}</option>
                            {listOfBirthYear.map((year, index) => {
                                return <option key={`year${index}`} value={year}>{year}</option>
                            })}
                        </select>
                    </dd>
                </dl> */}
                <dl>
                    <dt>{t("sign_up_with_email.country")} </dt>
                    <dd>
                        <select onChange={e => setSelectedCountry(e.target.value)}  value={selectedCountry}>
                            <option>{t("sign_up_with_email.select_country")}</option>
                            {renderListOfCountry}
                        </select>
                        {/* <Select options={options} /> */}
                    </dd>
                </dl>
                <dl>
                    <dt>{t("sign_up_with_email.gender")} </dt>
                    <dd>
                        <button className={classNames({active: gender==null})} onClick={()=>setGender(null)}>{t("sign_up_with_email.gender_unknown")}</button>
                        <button className={classNames({active: gender==2})} onClick={()=>setGender(2)}>{t("sign_up_with_email.female")}</button>
                        <button className={classNames({active: gender==1})} onClick={()=>setGender(1)}>{t("sign_up_with_email.male")}</button>
                    </dd>
                </dl>
                <dl>
                    <dt>{t("sign_up_with_email.phone_no")} <em>*</em></dt>
                    <dd>
                        <select className="phone"  onChange={e => setSelectedPhoneCountry(e.target.value)} value={selectedPhoneCountry}>
                            <option>{t("sign_up_with_email.select_phone_no")}</option>
                            {renderListOfCountry}
                        </select>
                        <input type="text" title="전화번호" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} autoComplete="false" />
                    </dd>
                </dl>
                <dl>
                    <dt>{t("sign_up_with_email.password")} <em>*</em></dt>
                    <dd>
                        <input type="password" value={password} onChange={e => setPassword(e.target.value)}  title="비밀번호" autoComplete="false" />
                    </dd>
                    <dd className="exp">{t("sign_up_with_email.pw_validation")}</dd>
                </dl>
                <dl>
                    <dt>{t("sign_up_with_email.confirm_password")} <em>*</em></dt>
                    <dd>
                        <input type="password" value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)}  title="비밀번호 확인"  autoComplete="false" />
                    </dd>
                </dl>

                <input type="checkbox" id="ck" title="체크박스" checked={isReceived==1?true:false} onChange={handleIsReceived}/>
                <label htmlFor="ck">{t("sign_up_with_email.subscribe_desc")}</label>

                <div className="divagree">
                    <input type="checkbox" id="ckagreeall" title="체크박스" checked={isAgreeAll==1?true:false} onChange={handleIsAgreeAll}/>
                    <label htmlFor="ckagreeall">{t("sign_up_with_email.check_all")}<span className="redpoint">*</span></label>

                    <div className="divarea">
                        <input type="checkbox" id="cktua" title="체크박스" checked={isAgreeTUA==1?true:false} onChange={handleIsAgreeTUA}/>
                        <label htmlFor="cktua">{t("sign_up_with_email.check_tua")}<span className="redpoint">*</span></label>

                        <SignUpPrivacyDesc></SignUpPrivacyDesc>
                    </div>

                    <div className="divarea2">
                        <input type="checkbox" id="ckpicu" title="체크박스" checked={isAgreePICU==1?true:false} onChange={handleIsAgreePICU}/>
                        <label htmlFor="ckpicu">{t("sign_up_with_email.check_picu")}<span className="redpoint">*</span></label>
                        
                        <SignUpPrivacyDesc2></SignUpPrivacyDesc2>
                    </div>

                    <div className="divarea">
                        <input type="checkbox" id="ckage" title="체크박스" checked={isAgreeAge==1?true:false} onChange={handleIsAgreeAge}/>
                        <label htmlFor="ckage">{t("sign_up_with_email.check_age")}<span className="redpoint">*</span></label>
                    </div>
                    
                </div>

                <div className="divattention">
                    {t("sign_up_with_email.attention")}
                </div>

                {/* <button className="btn_join" onClick={submitSignUp}>회원가입</button> */}
                <button className="btn_save" onClick={submitSignUp} disabled={isDisabledSubmit}>{t("sign_up_with_email.sign_up")}</button>
            </div>

        

    </div>
    );
};

export default SignUpWithSns;