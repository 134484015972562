import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AxiosConfig from '../../services/AxiosConfig';
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import { ISearchV2Resp, ISearchV2PopularRespResultDocument } from "./ISearchV2";

interface PopularKeywordProps {
    click?: any
}

const PopularKeyword: React.FC<PopularKeywordProps> = (props: PopularKeywordProps) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    
    //관광지
    // const [popularKeyword, setPopularKeyword] = useState<ISearchV2Resp>();
    const [popularKeywords, setPopularKeywords] = useState<ISearchV2PopularRespResultDocument[]>([]);
        
    const fetchPopularKeyword = async () => {
        let url = process.env.REACT_APP_SEARCH_API_URL + `/extensions/PopularSearch?language=${globalInfoStore.currentLangType.searchLang}`;
        // let url = process.env.REACT_APP_MOCK_SEARCH_API_URL + `/PopularSearch`;

        const res = await AxiosConfig.get(url);
        setPopularKeywords(res.data.resultSet.result[0].resultDocuments);
    };

    useEffect(() => {
        fetchPopularKeyword()
    }, [])

    const handleSearchKeyword = (e:any, searchKeyword:string) => {
        history.push(`/app/search/index?page=1&searchKeyword=${searchKeyword}&searchType=total`);
    }


    /**
     * <li className="active"><em>1</em> BTS 에디션</li>
        <li><em>2</em> 시티투어버스</li>
        <li><em>3</em> 한강</li>
        <li><em>4</em> 남산타워</li>
        <li><em>5</em> 경복궁</li>
     */
    // const recentKeywordItems = (popularKeywords || []).map((item) =>{
    //     if (item.RANKING == "1") {
    //         return (<li className="active" onClick={(e)=>handleSearchKeyword(e, item.KEYWORD)}><em>{item.RANKING}</em>{item.KEYWORD}</li>)
    //     }
    //     return (<li onClick={(e)=>handleSearchKeyword(e, item.KEYWORD)}><em>{item.RANKING}</em>{item.KEYWORD}</li>)
    // });

    // const RecentKeywordItems = () => {
    //     // if (popularKeywords.length == 0 ) {
    //     //     return (<li className="active"><em>-</em></li>)
    //     // }
    //     return popularKeywords.map((item) => {
    //         if (item.RANKING == "1") {
    //             return (<li className="active" onClick={(e)=>handleSearchKeyword(e, item.KEYWORD)}><em>{item.RANKING}</em>{item.KEYWORD}</li>)
    //         }
    //        return (<li onClick={(e)=>handleSearchKeyword(e, item.KEYWORD)}><em>sss{item.RANKING}</em>{item.KEYWORD}</li>)
    //     });
    // };

    const PopularKeywordItems = () => {
        if (popularKeywords == null || popularKeywords.length == 0){
            return (<li className="active"><em>-</em></li>)
        }
        return (
            <ul>
            {
                popularKeywords.map((item) => {
                    if (item.RANKING == "1") {
                        return (<li className="active" key={item.RANKING} onClick={(e)=>handleSearchKeyword(e, item.KEYWORD)}><em>{item.RANKING}</em>{item.KEYWORD}</li>)
                    }
                    return (<li key={item.RANKING} onClick={(e)=>handleSearchKeyword(e, item.KEYWORD)}><em>{item.RANKING}</em>{item.KEYWORD}</li>)
                })
            }
            </ul> 
        )
    }




    return (
        <div className="hot">
            <span>{t("search_v2.popular_search")}</span>

            <ul>
                <PopularKeywordItems></PopularKeywordItems>
            </ul>
        </div>
    );
};

export default PopularKeyword;