import React, { useEffect, useState } from "react";
import qrcode from "qrcode.react";
import { useTranslation } from "react-i18next";
import QRCode from 'qrcode.react';
import moment from "moment";
import Moment from "react-moment";
import "moment/locale/ko";
import { useInterval } from "react-use";
import AxiosConfig from "../../services/AxiosConfig";
import RemainTimer from "./RemainTimer";
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import styled, { keyframes } from 'styled-components';
import { IMyPass } from "../mypage/mypass/MyPass";
import { getValueByKey } from "../../services/Util";
import { IProductInfo } from "../../services/ProductInfoService";

const classNames = require("classnames");

const StyledWrapper = styled.div`
	animation: .8s linear 1s infinite alternate slidein;
`;

interface Visit {
  date: string,
  title: string
}

const AppMyPassCardSlideItem = (props:{productItem:IMyPass, productInfoList:IProductInfo[]}) => {
  const { t, i18n } = useTranslation();
  // const [visitList, setVisitList] = useState<Visit[]>();
  const [visitList, setVisitList] = useState([]);
  // const [infoes, setInfoes] = useState([]);
  const [isVisitHistoryActive, setIsVisitHistoryActive] = useState(false);
  const [isArexActive, setIsArexActive]= useState(false);
  const [isBikeActive, setIsBikeActive]= useState(false);
  const [expireDate, setExpireDate] = useState(props.productItem.expireDate)
  
  const fetchMyPassByQrUid = async () => {
    // const url = `/api2/myPass/expireDate/${props.productItem.uid}`;
    const url = `/api2/myPass/${props.productItem.uid}`;
    const res = await AxiosConfig.get(url);

	try {
		if (res.status == 200) {
			//FIXME: api 수정 필요.
			setExpireDate(res.data.content[0].expireDate);
		} else {
			setExpireDate(null)
		}
	} catch(error) {
		console.error(error);
	}
  }
  
    const fetchVisitHistory = async () => {
		const url = `/api2/myPass/${props.productItem.uid}/logs?size=1000`;
		const res = await AxiosConfig.get(url);
		
		try {
			if (res.status == 200) {
				setVisitList(res.data.content)
			} else {
				setVisitList([])
			}
		} catch(error) {
			console.error(error);
		}
    }

  useEffect(() => {
	fetchVisitHistory()
  }, [globalInfoStore.currentLangType]);

  const SpotVisitHistoryItem = (item:any) => {
	  return (<li>
		<p className="date"><Moment format="YYYY-MM-DD H:mm:ss">{item.createDate}</Moment></p>
		<p className="tit">{getValueByKey(item.item, globalInfoStore.currentLangType.title2)}</p>
	  </li>)
  }

  const SpotVisitHistory = (visitList || []).map((item) =>{
      return (
		  <SpotVisitHistoryItem key={item.tourUid} item={item}></SpotVisitHistoryItem>
      );
  });


  //관광지 상세보기
  const toggleVisit = () => {
	// console.log("isVisitHistoryActive1::" + isVisitHistoryActive)
	// if (!isVisitHistoryActive) {
	// 	// console.log("isVisitHistoryActive2::" + isVisitHistoryActive)
	// 	fetchVisitHistory()
	// }
	// console.log("isVisitHistoryActive3::" + isVisitHistoryActive)
    setIsVisitHistoryActive(!isVisitHistoryActive);
	// console.log("isVisitHistoryActive4::" + isVisitHistoryActive)
  }

  //공항철도 이용권 조회
  const toggleArex = () => {
    setIsArexActive(!isArexActive);
  }

  //따릉이 이용 조회
  const toggleBike = () => {
    setIsBikeActive(!isBikeActive);
  }

  // //공항철도 이용권 조회
  // $('.view_trafiic>button').click(function(){
  //   $(this).next().addClass('active');
  // });
  // $('.view_trafiic .code').click(function(){
  //   $(this).removeClass('active');
  // });

	// const getPasstypeName = () => {
	// 	if (props.productItem.passtypeCode == 1){
	// 		return t("mypass.card_pass")
	// 	} else {
	// 		return t("mypass.mobile_pass")
	// 	}
	// }

	const refreshRemainTime = () => {
		// alert("시간갱신");
		fetchMyPassByQrUid();
	}

	if (props.productItem.qrCode == null){
		return <></>
	}
	return (
		<div className="slide">
					
					{/* <p className="number" >QR No. {props.productItem.qrMgmtCode == null ? props.productItem.qrCode: props.productItem.qrMgmtCode}</p> */}
					
					<div className="top_img">
						<img src={props.productItem.fullpath} alt="실물카드 이미지" className="card" />
					</div>
					
					<div className="move">
						<StyledWrapper >
						DISCOVER SEOUL PASS DISCOVER SEOUL PASS
						</StyledWrapper>
					</div>

					<div className="bottom">
						<div className="card">
							<span className="img">
								{/* <img src="img/app/s_card.png" alt="실물카드 이미지" /> */}
								{/* <img src={require("../../resources/img/passImg01.png")} alt="실물카드 이미지" /> */}
								<img src={props.productItem.fullpath} alt="실물카드 이미지" />
							</span>
							<span className="time"><em>{props.productItem.passtypeTime}</em> H</span>
							<span className="sort">{t("mypass.card_pass")}</span>

							{/* <p className="tit">디스커버서울패스</p> */}
							<p className="tit">{getValueByKey(props.productItem, globalInfoStore.currentLangType.title)}</p>
						</div>

						<div className="info">
							<dl>
								{/* <dt>유효시간</dt> */}
								<dt>{t("app_my_pass.pass_duration")}</dt>
								{/* <dd>{props.productItem.expireDate == null? "00:00:00": getRemainTime(props.productItem.expireDate)}</dd> */}
								{/* <dd>{props.productItem.expireDate == null? "00:00:00": props.productItem.expireDate}</dd> */}
								<dd>
									{/* {props.productItem.expireDate == null? "00:00:00": <RemainTimer expireDate={expireDate}></RemainTimer>} */}
									<RemainTimer expireDate={expireDate} productItem={props.productItem} productInfoList={props.productInfoList}></RemainTimer>
									<button className="btn_refresh" onClick={refreshRemainTime}>새로고침</button>
								</dd>
							</dl>
							<dl onClick={toggleVisit}>
								<dt>{t("app_my_pass.visited_attraction")}</dt>
								<dd>{visitList.length}
									<button className="btn_detail">{t("app_my_pass.details")}</button>
								</dd>
							</dl>
						</div>

					</div>

					<div className="view_trafiic">
						{/* <button className="train" onClick={toggleArex} >AREX 탑승권</button>
						<p className={classNames({code:true, train:true, active:isArexActive})} onClick={toggleArex} >AREX 티켓번호. 00000-0000-0000</p>
						<button className="bycle" onClick={toggleBike} >따릉이 탑승권</button>
						<p className={classNames({code:true, bycle:true, active:isBikeActive})} onClick={toggleBike}>따릉이 대여번호. aaaaabbbbb</p> */}
						{/* <button className="train" onClick={toggleArex} >AREX 탑승권</button>
						<p className="code train" onClick={toggleArex} >AREX 티켓번호. 27994-9023-3688</p>
						<button className="bycle" onClick={toggleBike}>따릉이 탑승권</button>
						<p className="code bycle" onClick={toggleBike}>따릉이 대여번호. t7gqc832nt</p> */}
					</div>

					{/* <!-- s: 관광지 상세보기 화면 --> */}
					<div className={classNames({wrap_detail:true, active:isVisitHistoryActive})}>
						{SpotVisitHistory.length > 0?
						<>
							<h6>{t("app_my_pass.visited_attraction")}</h6>
							<ul>
								{SpotVisitHistory}
							</ul>
						</>
						:
						<>
							<h6>{t("app_my_pass.no_visited_attraction")}</h6>
							<ul>
							</ul>
						</>}
						<button className="btn_viewQr" onClick={toggleVisit}>{t("app_my_pass.view_qr_code")}</button>
					</div>
					{/* <!-- e: 관광지 상세보기 화면 --> */}
					
				</div>
	);
};

export default AppMyPassCardSlideItem;