import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import { useLocation, Link } from "react-router-dom";
import AxiosConfig from '../../../services/AxiosConfig';
import { globalInfoStore } from "../../../stores/GlobalInfoStore";
import SpotListItem from "../../spot/SpotListItem";

const classNames = require("classnames");

interface SpotInfo  {
    [key: string]: any;
}

const InterestSpot = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const [passInfo, setSpotInfo] = useState<SpotInfo>();
    const [filteredSpotList, setFilteredSpotList] = useState([]);
    const [page, setPage] = useState(1); //page
	const [size, setSize] = useState(12);  //
	const [sorts, setSorts] = useState([]); //sort['updateDate,desc','uid,asc']

    const fetchBookmarkSpotInfo = async (page:number, size:number, sorts:[]) => {
        let url = `/api2/tourInfo?page=${page}&size=${size}&isMyTourInfo=1`;

        // {{local}}/api2/tourInfo?langCode=1&page1&size=18&isMyTourInfo=1

        if (sorts.length > 0){
            url += (sorts || []).map((item)=>`&sort=${item}`);
        }

        const res = await AxiosConfig.get(url);

        setSpotInfo(res.data);
        setFilteredSpotList(res.data.content)
    };

    useEffect(() => {
        fetchBookmarkSpotInfo(page, size, [])
        window.scrollTo(0, 0);
    }, [globalInfoStore.currentLangType, page]);

    const handlePageClick = (pageNumber: any) => {
		setPage(pageNumber)
	};

    const DspPager = () => {

        if (passInfo == undefined || passInfo.totalPages < 2)
            return (<></>)

        return (
            <Pagination
                innerClass={'board_pager'}
                // activeClass={'active'}
                // activeLinkClass={'active'}
                activePage={page}
                itemsCountPerPage={size}
                totalItemsCount={passInfo.totalElements}
                pageRangeDisplayed={5}
                onChange={(pageNumber)=>handlePageClick(pageNumber)}
                itemClassFirst={'btn_pprv'}
                itemClassPrev={'btn_prev'}
                itemClassNext={'btn_next'}
                itemClassLast={'btn_nnxt'}
            />
        )
    }

    const renderSpotItem = (filteredSpotList || []).map((item) => {
      return (
        // <InterestSpotItem key={item.uid} content={item}></InterestSpotItem>
        // <InterestSpotItem key={item.uid} content={item}></InterestSpotItem>
        <SpotListItem key={item.uid} content={item} ></SpotListItem>
      );
    });

    if (filteredSpotList.length > 0){
        return (
            <>
            <div id="tab_content1" className="active">
                <ul className="spot_list">
                {/* <InterestSpotItem></InterestSpotItem> */}
                    {renderSpotItem}
                </ul>
            </div>
            <DspPager></DspPager>
            </>
        )
    }
    
    return (
        <div className="wrap_ask">
            {/* <p className="exp">관심등록한 관광지가 없습니다.</p> */}
            <p className="exp">{t("interest.spot_none")}</p>

            {/* <a href="#" target="_self" className="btn_goToInquiry">관광지 둘러보기</a>  */}
            <Link to="/app/spot" className="btn_goToInquiry">{t("interest.goto_spot")}</Link>
        </div>
    )
};

export default InterestSpot;