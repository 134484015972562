import React from 'react';

const SearchNoResult = (props:{searchTerm:string}) => {

    return (
        <p className="no_content">검색어 <em>{props.searchTerm}</em>와/과 일치하는 <br className="pc_Non" />검색 결과가 없습니다.</p>
    );
};

export default SearchNoResult;


