import React from "react";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-dom";
import { PrivateRoute } from '../../routes/PrivateRoute';
import Navigation from '../navigation/Navigation';
import CsTitleExpireChecker from "./CsTitleExpireChecker";
import CsTitleFaq from './CsTitleFaq';
import CsTitleNotice from './CsTitleNotice';
import CsTitleOneToOne from './CsTitleOneToOne';
import ExpireChecker from "./ExpireChecker";
import Faq from './Faq';
import NoticeNV from './NoticeNV';
import ArexQr from './ArexQr';
import NoticeViewNV from './NoticeViewNV';
import OneToOne from './OneToOne';
import OneToOneCompleted from './OneToOneCompleted';





const CsIndex = () => {
	const { t, i18n } = useTranslation();
	
    return (
		<section id="container" className="sub">
			<h6 className="hidden">본문</h6>

			<Navigation></Navigation>

			<article id="content">
				<Route exact path="/appnv/cs/index/faq" component={Faq} />
				<PrivateRoute exact path="/appnv/cs/index/onetoone" component={OneToOne} />
				<Route exact path="/appnv/cs/index/notice" component={NoticeNV} />
				<Route exact path="/appnv/cs/index/notice/view/:uid" component={NoticeViewNV} />
				<Route exact path="/appnv/cs/index/arexqr" component={ArexQr} />
				<PrivateRoute exact path="/appnv/cs/index/onetoone/complete" component={OneToOneCompleted} />
				<Route exact path="/appnv/cs/index/expirechecker" component={ExpireChecker} />
			</article>

		</section>
    );
};

export default CsIndex;