import React from "react";
import { useTranslation } from "react-i18next";
import { Route } from 'react-router';
import TransportationIndexNV from './TransportationIndexNV';
import OthersIndexNV from './OthersIndexNV';

const SpotIndexNV = () => {
	const { t, i18n } = useTranslation();
	
    return (
        <section id="container" className="sub">
			<h6 className="hidden">본문</h6>

			<article id="content">	
				<Route path="/appnv/spot/transportation/index" component={TransportationIndexNV} />		
				<Route path="/appnv/spot/others/index" component={OthersIndexNV} />		
			</article>

		</section>
    );
};

export default SpotIndexNV;

