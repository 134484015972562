import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import AxiosConfig from '../../../services/AxiosConfig';
// import axios, { CancelTokenSource } from 'axios';

const ProfileContentPassword = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const [uid, setUid] = useState("")
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");

    const postChangePassword = async () => {

        const data = {
            "oldPassword": oldPassword,
            "newPassword": newPassword
        }

        try {
            const res = await AxiosConfig.put(
                `/api2/memberInfo/updatePassword`
                ,data
            );
            console.log(res.data);
            if (res.status == 200 && res.data.code == 200) {
                alert("Your password has been changed.")
                history.replace("/app/mypage/profile/index")
            }
        } catch (error) {
            alert("Please confirm your password.")
        }
        
        //jwt 토큰 존재 여부 확인.
        

        // if (res.status == 200 && res.data.code == 200) {
        //     alert("비밀번호가 변경되었습니다.")
        // } else {
        //     alert("비밀번호를 확인해주세요.")
        // }
        // {code: "200", desc: "정상"}
        
    };

    const formInput = (text:string) => {
        // 한글 테스트 정규식
        const notPhoneticSymbolExp = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
        if (!notPhoneticSymbolExp.test(text)) {
          return text;
        } else {
          // 한글이 빠른 시간에 여러개 들어오는 경우도 있으니,한글이 없을 때까지 삭제하고, 검사
          text = text.slice(0, -1);
          let condition = notPhoneticSymbolExp.test(text);
          while (condition) {
            text = text.slice(0, -1);
            condition = notPhoneticSymbolExp.test(text);
          }
          return text;
        }
    }


    const onChangeOldPw = (e:any) => { 
        let val = e.target.value.replace(/[^\x00-\x7F]/ig, '');
        setOldPassword(val);
        console.log(val);
    }

    const onChangeNewPw = (e: any) => {
        let val = e.target.value.replace(/[^\x00-\x7F]/ig, '');
        setNewPassword(val);
        console.log(val);
    }

    const onChangeNewPw2 = (e: any) => {
        let val = e.target.value.replace(/[^\x00-\x7F]/ig, '');
        setNewPassword2(val);
        console.log(val);
    }

    const handleChangePassword = (e:any) => {
        if (oldPassword == null 
            || oldPassword.length < 6
            || newPassword == null
            || newPassword.length < 6
            || newPassword2 == null
            || newPassword2.length < 6
            ){
            alert("Please enter your password correctly.");
            return
        }

        if (newPassword != newPassword2) {
            alert("The password is different.");
            return;
        } 

        postChangePassword();
    }

    // const styles = {
    //       ime-mode: "disabled"
    //      as React.CSSProperties,
    //   }

      const styles = {
        ime: {
            imeMode: "active"
            // -webkit-ime-mode:active;
            // -moz-ime-mode:active;
            // -ms-ime-mode:active;
            // ime-mode:active;
        } as React.CSSProperties,
    };

    return (
        <div className="layout">
	
            <div className="wrap_info pw">
                <h6 className="sec_tit side" dangerouslySetInnerHTML={{__html:t("profile_content_password.title")}}>

                </h6>

                <em className="top_exp">* {t("profile_content_password.required")}</em>

                <dl>
                    {/* <dt>현재 비밀번호 <em>*</em></dt> */}
                    <dt>{t("profile_content_password.current_password")} <em>*</em></dt>
                    <dd>
                        {/* <input type="password" title="현재 비밀번호" /> */}
                        {/* <input type="password" title="현재 비밀번호" /> */}
                        <input type="password" title="비밀번호" value={oldPassword}
                            onChange={e=>(onChangeOldPw(e))} />
                    </dd>
                </dl>
                <dl>
                    {/* <dt>새 비밀번호 <em>*</em></dt> */}
                    <dt>{t("profile_content_password.new_password")} <em>*</em></dt>
                    <dd>
                        {/* <input type="password" title="새 비밀번호"/> */}
                        <input type="password" title="비밀번호" value={newPassword}
                            onChange={e=>(onChangeNewPw(e))} />
                    </dd>
                    <dd className="exp">{t("profile_content_password.pw_validation")}</dd>
                </dl>
                <dl>
                    {/* <dt>새 비밀번호 확인 <em>*</em></dt> */}
                    <dt>{t("profile_content_password.confirm_new_password")} <em>*</em></dt>
                    <dd>
                        {/* <input type="password" title="새 비밀번호 확인"/> */}
                        <input type="password" title="새 비밀번호 확인"
                            value={newPassword2}
                            onChange={e=>(onChangeNewPw2(e))} 
                            />
                    </dd>
                </dl>

                <button className="btn_save" onClick={(e)=> handleChangePassword(e)}>{t("profile_content_password.change_password")}</button>
                {/* <button className="btn_save" onClick={(e)=> handleChangePassword(e)}>비밀번호 변경</button> */}
            </div>

        

    </div>
    );
};

export default ProfileContentPassword;