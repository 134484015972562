import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import AxiosConfig from "../../../services/AxiosConfig";
import { codeInfoAtom, codeInfoByGroupCd, ICodeInfo } from "../../../services/CodeInfoService";
import MyPassItem from "./MyPassItem";

// export interface IMyPass {
//     uid: number;               //218217,
//     status: number;               //1,
//     productUid: number;               //8,
//     amount: number;               //1,
//     orderUid: number;               //99707,
//     qrCode: string;               //"da5ugdh120",
//     memo: string;               //"테스트용 120",
//     groupCode: string;               //"G000000001899",
//     createDate: string;               //"2020-12-17T09:00:11",
//     updateDate: string;               //"2020-12-17T00:16:21",
//     expireDate: string;               //"2025-12-09T23:59:59",
//     qrMgmtCode: string;                 //"1-24p-da5ugdh120-1-20-001013",
//     managingTitle: string;               //"24시간 일반권",
//     koTitle: string;               //"디스커버서울패스 24시간권",
//     enTitle: string;               //"Discover Seoul Pass 24hours",
//     jaTitle: string;               //"ディスカバーソウルパス 24時間券",
//     zhCnTitle: string;               //"首尔转转卡 24小时券",
//     zhTwTitle: string;               //"首爾轉轉卡 24小時券",
//     purchaseDate: string;               //"2020-12-12T18:30:54"
// }
/**
 ||1	24시간 일반권	DSP 24시간권
 ||2	24시간 모바일권	DSP 모바일 24시간권
 ||3	48시간 일반권	DSP 48시간권
 ||4	48시간 모바일권	DSP 모바일 48시간권
 ||5	72시간 일반권	DSP 72시간권
 ||6	72시간 모바일권	DSP 모바일 72시간권
 */
export enum EPasstypeUid {
    CARD_24 = 1,
    MOBILE_24 = 2,
    CARD_48 = 3,
    MOBILE_48 = 4,
    CARD_72= 5,
    MOBILE_72 = 6
}

export interface IMyPass {
    uid: number;                                                     //     * QR의 고유번호
    status: number;                                                     //     * QR 상태(1: 판매 가능, 2: 사용 가능, 5: 환불, 6: 폐기)
    productUid: number;                                                     //     * 상품의 고유번호
    amount: number;                                                     //     * QR에서 사용 할 수 있는 패스의 수
    orderUid: number;                                                     //     * 주문 고유번호
    qrCode: string;                                                     //     * QR 번호
    qrMgmtCode: string;                                                     //     * QR 관리번호
    memo: string;                                                     //     * 해당 QR의 메모
    groupCode: string;                                                     //     * QR 그룹코드
    createDate: string;                                                     //     * QR 생성일자
    updateDate: string;                                                     //     * QR 수정일자
    expireDate: string;                                                     //     * QR 유효기간
    managingTitle: string;                                                     //     * 관리용 상품명
    koTitle: string;                                                     //     * 한국어 상품명
    enTitle: string;                                                     //     * 영어 상품명
    jaTitle: string;                                                     //     * 일본어 상품명
    zhCnTitle: string;                                                     //	 * 중국어(간체) 상품명
    zhTwTitle: string;                                                     //	 * 중국어(번체) 상품명
    purchaseDate: string;                                                     //	 * 구매일자
    passtypeCode: number;                                                     //     * 실물:1 / 모바일:2
    passtypeUid:  EPasstypeUid;                                                //
    passtypeTime: number;
    filename: string;
    filepath: string;
    fullpath: string;
    extension: string;
    iccid_num: string;
}

const MyPass = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [myPassItems, setMyPassItems] = useState([]);
    const [filteredMyPassItems, setFilteredMyPassItems] = useState<Array<IMyPass>>([]);
    const [filteredCodeInfo, setFilteredCodeInfo] = useState<ICodeInfo[]>(null);
	const [codeInfoes, setCodeInfoes] = useRecoilState<ICodeInfo[]>(codeInfoAtom);

    const fetchCodeInfoes = async () => {
		if (codeInfoes.length < 1) {
			let url = "/api2/codeInfo";
			const res = await AxiosConfig.get(url);
			if (res.status === 200) {
				setCodeInfoes(res.data)
			}
            console.log("filteredCodeInfo::res.data.length::" + res.data.length)
			filteringCodeInfo(res.data)
		} else {
            console.log("filteredCodeInfo::codeInfoes::" + codeInfoes.length)
			filteringCodeInfo(codeInfoes)
		}
	}

	const filteringCodeInfo = (codeInfoList:ICodeInfo[]) => {
		const filteredCodeInfoByQrStatus = codeInfoList.filter((item:ICodeInfo)=> {
			return item.groupCd === "qr_status"
		})
        
		setFilteredCodeInfo(filteredCodeInfoByQrStatus);
	}

    const fetchMyPassItems = async () => {
        const url = `/api2/myPass`;
        const res = await AxiosConfig.get(url);
        setMyPassItems(res.data);
        setFilteredMyPassItems(res.data.content);
    }



    useEffect(()=>{
        fetchCodeInfoes()
        fetchMyPassItems();
    },[]);

    const handleClickMyPass = () => {
        alert("handleClickMyPass")
    }

    const renderMyPasses = (filteredMyPassItems || []).map((item) => {
        return (<MyPassItem 
                key={item.uid} 
                myPassItem={item}
                filteredCodeInfoes={filteredCodeInfo}
                click={handleClickMyPass}
          ></MyPassItem>)
    })

    if (filteredMyPassItems.length < 1) {
        return (
            <div className="layout">
					
                <div className="wrap_ask">
                    {/* <p className="exp">등록된 패스가 없습니다. <br />패스 구매 또는 등록 후 이용해주세요.</p> */}
                    <p className="exp" dangerouslySetInnerHTML={{__html:t("mypass.no_registered_pass")}}></p>

                    {/* <a href="pass.html" target="_self" className="btn_goToInquiry">패스 구매하기</a>  */}
                    <Link to="/app/pass" className="btn_goToInquiry">{t("main.buy_now")}</Link>
                </div>
            </div>
        )
    }

    
    return (
        <div className="layout">
					
            <div className="wrap_pass">

                {/* <!--

                    클래스 정리
                    (기본 : 사용안함)

                    사용중 : occupied
                    기간만료 : expire
                    사용완료 : done 

                    *모바일은 pass에 'mobile' 클래스 추가
                    *시간은 time24, time48, time72 이렇게 붙음.

                    --> */}
                {renderMyPasses}
            </div>

        </div>
    );
};

export default MyPass;