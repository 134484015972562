import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { BarcodeScanner } from '@ionic-native/barcode-scanner';
import AxiosConfig from '../../services/AxiosConfig';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import qs from 'qs';

export class AxiosWaug {
    static readonly instance: AxiosInstance = axios.create({
        baseURL: 'https://api.waug.com',
        // baseURL: 'https://6a00e9cd-2919-4737-a7fa-987f12fad207.mock.pstmn.io',
        timeout: 5000,
    });
}

const AppMyPassRegister = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [qrcode, setQrcode] = useState("");
    const [result, setResult] = useState({});

    const GROUP_REF_CODE_KEY_LENGTH = 12; //자리
    const GROUP_REF_CODE_PREFIX = "MV";
    const CU_REF_CODE_PREFIX = "CU";
    const WAUG_KEY_LENGTH = 8;

    const postWaugQrcode = async (qrcodeText:string) => {
        const url = `/external/dsp/`;
        // const data = JSON.stringify({
        //     "waug_key":qrcode
        // });
        const data = qs.stringify({
            "waug_key":qrcodeText
        });
        const res = await AxiosWaug.instance.post(url,
            data,
            {
                headers: {'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'}
            }
         );
         
        if (res.status == 200 && res.data.retcode == 1 ) {
            // 성공시
            // {
            //     "retcode":0,
            //     "retmsg":"",
            //     "qrcode":"ABCDEFGHIJ",
            //     "pass":{
            //         "qr_code":"ABCDEFGHIJ"
            //     }
            // }
            // alert(res.data)
            // setResult(res.data);

            // setQrcode(res.data.qrcode);
            postAddDspQrcode(res.data.qrcode, 2); //waug에서 응답받은 qr을 넣어서 등록
        } else if (res.data.retcode == -1 || res.data.retcode == -99 ) {
            // 실패시
            // {"retcode":-1,
            // "retmsg":"일련번호가 입력되지 않았습니다."}
            // {"retcode":-99,
            // "retmsg":""일련번호가 일치하지 않습니다.\n다시 한번 확인해 주세요."}

            alert(res.data.retmsg);
        } else {
            console.error(`HTTP: ${res.status} 오류 발생`) //"존재하지 않는 패스 입니다."24p-fwkzeatcya-0-21-000030-DS
        }
        
    }

   /**
     * 12 자리 
     * 단체 등록 ( "MV1234567890" , "MVXXXXXXXXCC" ) MV로 시작하는 Ref 코드 12자리.
     * @param referenceCode : orderInfo.referenceCode
     * @param passType 1 실물 패스, 2= 모바일 패스
     */
    const postGroupRefCode = async (referenceCode:string) => {
        let url = `/api2/groupref/add/${referenceCode}`;
        
        const res = await AxiosConfig.post(url);
        if (res.status == 200 && res.data.code == 201) {
            setResult(res.data);
            history.replace("/appmypass");
            alert(res.data.desc);
        } else {
            alert(res.data.desc);// "존재하지 않는 패스 입니다."24p-fwkzeatcya-0-21-000030-DS
        }
    }

    /**
     * CU 등록 CU로 시작하는 Ref 코드
     * @param referenceCode : orderInfo.referenceCode
     * @param 
     */
     const postCURefCode = async (referenceCode:string) => {
        let url = `/api2/curef/add/${referenceCode}`;
        
        const res = await AxiosConfig.post(url);
        if (res.status == 200 && res.data.code == 201) {
            setResult(res.data);
            history.replace("/appmypass");
            alert(res.data.desc);
        } else {
            alert(res.data.desc);// "존재하지 않는 패스 입니다."24p-fwkzeatcya-0-21-000030-DS
        }
    }


    /**
     * 
     * @param qrcodeParam 
     * @param passType 1 실물 패스, 2= 모바일 패스
     */
    const postAddDspQrcode = async (qrcodeParam:string, passType:1|2) => {
        let url = `/api2/addQrPass/${qrcodeParam}/${passType}`;

        const res = await AxiosConfig.get(url);
        // setResult(res.data);
        if (res.status === 200 && res.data.code === 201) {
            setResult(res.data);
            history.replace("/appmypass");
        } else {
            alert(res.data.desc);//"존재하지 않는 패스 입니다."24p-fwkzeatcya-0-21-000030-DS
        }
        //     setResult(res.data);
        //     history.goBack(); //FIXME: 확인 필요.
        // } else {
        //     alert("")
        // }
    }

    const postRegistQrcode = async (qrcodeText:string) => {
        
        // if (qrcodeText.length < WAUG_KEY_LENGTH ) {
        //     alert("QRCode length is More than or equals to 8 characters");
        //     return;
        // }

        if (qrcodeText.length == GROUP_REF_CODE_KEY_LENGTH && qrcodeText.startsWith(GROUP_REF_CODE_PREFIX) ) {
            let value = window.confirm(t("app_my_pass.group_qr_confirm_msg"));
            if (value) postRegistDspQrcode(qrcodeText, 1);
            return;
        }

        // if (qrcodeText.startsWith(CU_REF_CODE_PREFIX) ) {
        //     postCURefCode(qrcodeText);
        //     return;
        // }

        if (qrcodeText.length === WAUG_KEY_LENGTH ) { //8자리는 무조건 와그 (설PM)
            let value = window.confirm(t("app_my_pass.waug_confirm_msg"));
            if (value) postRegistDspQrcode(qrcodeText, 1);
        } //else {
        //     postAddDspQrcode(qrcodeText, 1);
        // }

        postRegistDspQrcode(qrcodeText, 1);
    }

    const postRegistDspQrcode = async (qrcodeParam:string, passType:1|2) => {
        let url = `/apinv/pass/addQrPass?qrCode=${qrcodeParam}&passType=${passType}`;

        const res = await AxiosConfig.post(url, null, {
            headers: {
                "langType": globalInfoStore.currentLangType.langCode,
            },
        
        });
        if (res.status === 200 && res.data.code === 200) {
            setResult(res.data.message);
            alert(res.data.message);
            history.replace("/appmypass");
        } else {
            alert(res.data.message);
        }
    }

    useEffect(() => {
    }, [globalInfoStore.currentLangType]);

    const openScanner = async () => {
        const data = await BarcodeScanner.scan();
        
        setQrcode(data.text);
        postRegistQrcode(data.text);
    };
    
    return (
        <div id="app" className="register">
		
            {/* <div className="top">
                <button className="btn_prev">이전 페이지</button>
                <p>패스 등록</p>
                <button className='btn_close'>닫기</button>
            </div>

            <p>구매하신 카드 패스 뒷면의 QR일련번호를 직접 입력하시거나 QR코드를 스캔하여 등록 할 수 있습니다.</p>
            <img src="img/app/sample.png" alt="qr코드 스캔 예시 이미지" />
            
            <input type="text" placeholder="일련번호 입력" />
            <button className="btn_regist">패스 등록</button>

            <span>or</span>

            <button className="btn_scan">QR코드 스캔하여 등록</button> */}
            <div className="top">
                <button className="btn_prev" onClick={history.goBack}>{t("app_my_pass.btn_prev")}</button>
                <p>{t("app_my_pass.pass_register")}</p>
                <button className='btn_close' onClick={history.goBack}>닫기</button>
            </div>

            {/* <p dangerouslySetInnerHTML={{__html:t("app_my_pass.pass_desc")}}></p>
            <img src={require("../../resources/img/app/sample.png")} alt="qr코드 스캔 예시 이미지" /> */}

            <p dangerouslySetInnerHTML={{__html:t("app_my_pass.pass_desc")}}></p>

            <dl>
                <dt>
                    <img src={require("../../resources/img/app/sample2.png")} alt="모바일패스 qr코드 스캔 예시 이미지" />
                    <p>Mobile Pass Voucher</p>
                </dt>
                <dd>
                    <img src={require("../../resources/img/app/sample.png")} alt="실물패스 qr코드 스캔 예시 이미지" />
                    <p>Card Pass</p>
                </dd>
            </dl>
		
            
            <input type="text" placeholder={t("app_my_pass.regist_no_placeholder")} value={qrcode} onChange={e=>(setQrcode(e.target.value.trim()))} />
            <button className="btn_regist" onClick={()=>{postRegistQrcode(qrcode)}}>{t("app_my_pass.register_pass")}</button>

            <span>or</span>

            <button className="btn_scan" onClick={openScanner}>{t("app_my_pass.scan_qrcode")}</button>


            
        </div>
    );
};

export default AppMyPassRegister;