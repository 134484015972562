import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector, { CustomDetector, DetectorOptions } from 'i18next-browser-languagedetector';
import translationEN  from "./i18n/en.json";
import translationKO from "./i18n/ko.json";
import translationZH_CHS from "./i18n/zhChs.json";
import translationZH_CHT from "./i18n/zhCht.json";
import translationJA from "./i18n/ja.json";
// import useBodyClass from "./services/UseBodyClass";

const detectorOptions: DetectorOptions = {
  // order and from where user language should be detected
  order: ['querystring', 'localStorage', 'cookie', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  // lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  // caches: ['localStorage', 'cookie'],
  caches: ['localStorage'],
  // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  // cookieMinutes: 10,
  // cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  // cookieOptions: { path: '/', sameSite: 'strict' }
  // cookieDomain: "/" 
}


const customDetector: CustomDetector = {
  name: 'dspCustomDetector',

  lookup(options: DetectorOptions) {
    // options -> are passed in options
    return 'en';
  },

  cacheUserLanguage(lng: string, options: DetectorOptions) {
    // options -> are passed in options
    // lng -> current language, will be called after init and on changeLanguage
    // store it
    localStorage.getItem(options.lookupLocalStorage)
    localStorage.setItem(options.lookupLocalStorage, lng)
  },
};

const lngDetector = new LanguageDetector(null, detectorOptions);

lngDetector.init(detectorOptions);
lngDetector.addDetector(customDetector);


const resources = {
  "ko": {
    translation: translationKO
  },
  "en": {
    translation: translationEN
  },
  "ja": {
    translation: translationJA
  },
  "zhChs": {
    translation: translationZH_CHS
  },
  "zhCht": {
    translation: translationZH_CHT
  }
};
// customDetector.cacheUserLanguage("en", detectorOptions)
// const currentLang = lngDetector.detect()

i18n
  .use(lngDetector) //
  .use(initReactI18next)  // passes i18n down to react-i18next
  .init({
    resources,
    // lng: "en",
    fallbackLng: "en",
    detection: detectorOptions,

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
  ;


  export default i18n;









