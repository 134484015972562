import React, { useEffect, useState } from "react";
import qrcode from "qrcode.react";
import { useTranslation } from "react-i18next";
import QRCode from 'qrcode.react';
import moment, { now } from "moment";
import Moment from "react-moment";
import "moment/locale/ko";
import { useInterval } from "react-use";
import AxiosConfig from "../../services/AxiosConfig";
import RemainTimer from "./RemainTimer";
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import styled, { keyframes } from 'styled-components';
import { IMyPass } from "../mypage/mypass/MyPass";
import { getValueByKey } from "../../services/Util";
import { IProductInfo } from "../../services/ProductInfoService";

const classNames = require("classnames");

const StyledWrapper = styled.div`
	animation: .8s linear 1s infinite alternate slidein;
`;

// const QRCodeLoader = styled.div`
// 	animation: .8s linear 1s infinite alternate slidein;
// `;

const SSkeletonPulse = styled.div`
  display: inline-block;
  height: 320px;
  width: 320px;
  background: linear-gradient(-90deg, #EEEEEE 0%, #FFFFFF 50%, #EEEEEE 100%);
  background-size: 300% 300%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

const SSkeletonLine = styled(SSkeletonPulse)`
  width: 5.5em;
  border-radius: 5px;

  &::before {
    content: "\00a0";
  }
`;

interface Visit {
  date: string,
  title: string
}

const AppMyPassMobileSlideItem = (props:{productItem:IMyPass, productInfoList:IProductInfo[], doShowPopup:any, doHidePopup:any, doShowEsimPopup:any, doHideEsimPopup:any}) => {
  const { t, i18n } = useTranslation();
  // const [visitList, setVisitList] = useState<Visit[]>();
  const [visitList, setVisitList] = useState([]);
  // const [infoes, setInfoes] = useState([]);
  const [isVisitHistoryActive, setIsVisitHistoryActive] = useState(false);
  const [isArexActive, setIsArexActive]= useState(false);
  const [isBikeActive, setIsBikeActive]= useState(false);
  const [isEsimActive, setIsEsimActive]= useState(false);
  const [isEsimPurchasedActive, setIsEsimPurchasedActive]= useState(false);
  const [arexNo, setArexNo]= useState(null);
  const [bikeNo, setBikeNo]= useState(null);
  const [isUsePass, setUsePass]= useState(null);
  
  let referenceCode:string = null;
  
//   const [props.productItem, setprops.productItem] = useState(props.productItem)
//   const [productUid, setProductUid] = useState(props.productItem.productUid);
  const [expireDate, setExpireDate] = useState(props.productItem.expireDate);

//  console.log("props.productItem.expireDate:::" + props.productItem.expireDate);
//  console.log("AppMyPassMobileSlideItem::: " + props.productItem.qrCode);
  
  
  const fetchMyPassByQrUid = async () => {
    try {
      // const url = `/api2/myPass/expireDate/${props.productItem.uid}`;
      const url = `/api2/myPass/${props.productItem.uid}`;
      const res = await AxiosConfig.get(url);

      if (res.status == 200) {
        //!FIXME: api 수정 필요.
        setExpireDate(res.data.content[0].expireDate);
      } else {
        setExpireDate(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchVisitHistory = async () => {
		try {
			const url = `/api2/myPass/${props.productItem.uid}/logs?size=1000`;
			const res = await AxiosConfig.get(url);
		
			if (res.status == 200) {
				setVisitList(res.data.content)
			} else {
				setVisitList([])
			}
		} catch(error) {
			console.error(error);
		}
  }

// {
//     "uid": 65344,
//     "arex_code": "27994-9023-4469",
//     "active_date": "2021-03-21T16:31:54"
// }
  const fetchArex = async (isUseCheck:Boolean) => {
    try {

		if (isExpired(props.productItem.expireDate) && isUseCheck) {
			alert("QRCode Expired.");
			return;
		} 

      const url = `/api2/myArex/${props.productItem.qrCode}`;
      const res = await AxiosConfig.get(url);
	  //console.log("fetchArex:::" + JSON.stringify(res.data));

      if (res.status == 200) {
        if(res.data.aiuUid == null) {
			if(isUseCheck)
				props.doShowPopup(postUseArexCode);

			setArexNo(res.data.arex_code);
			return ;
		} else {
			setArexNo(res.data.arex_code);
			
			if(isUseCheck)
				setIsArexActive(true);
		}
        // setIsArexActive(!isArexActive);
      } else {
        if (res.data.code == "-500") {
          setArexNo("Unavailable");
        } else {
          setArexNo("-");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkESimSite = async () => {
	try {
		//모바일 패스만 대상
		if(props.productItem.passtypeUid === 2 ||
			props.productItem.passtypeUid === 4 ||
			props.productItem.passtypeUid === 6) {
				const url = `/api2/esim/${props.productItem.uid}`;
				const res = await AxiosConfig.get(url);
		  
				if (res.status === 200) {
		
					if(res.data.iccid_num) {
						setIsEsimActive(false);
						setIsEsimPurchasedActive(true);
					} else {
						setIsEsimActive(true);
						setIsEsimPurchasedActive(false);
					}
		
				} else {
				  setIsEsimActive(true);
				  setIsEsimPurchasedActive(false);
				}
		} else {
			setIsEsimActive(false);
			setIsEsimPurchasedActive(false);
		}
		
	  } catch (error) {
		console.error(error);
	  }
  };

  const popupEsimSite = async () => {
	props.doShowEsimPopup(goToESimSite);

	try {
		const url = `/api2/esim/${props.productItem.uid}`;
		const res = await AxiosConfig.get(url);
		
		if (res.status === 200) {
			if(res.data.iccid_num) {
				referenceCode = null;
				props.doHideEsimPopup();

				setIsEsimActive(false);
				setIsEsimPurchasedActive(true);
				setTimeout(function() {
					alert(t("how_eSIM.complete"));
				}, 500);
			} else {
				referenceCode = res.data.reference_code;
			}

		} else {
		}
	  } catch (error) {
		console.error(error);
	  }
  }

  const goToESimSite = async () => {
	props.doHideEsimPopup();

	var lang = "kr"; //en / ja / zhChs / zhCht
	switch(globalInfoStore.currentLangType.i18Str) {
		case "en": lang = "en"; break;
		case "ja": lang = "jp"; break;
		case "zhChs": lang = "cn"; break;
		case "zhCht": lang = "tw"; break;
		default: break;
	}

	if(referenceCode != null) {
		if(process.env.REACT_APP_ENV === "development") {
			console.log("https://seoulpass.verigood.kr/?reference_code="+referenceCode+"&qr_code="+props.productItem.qrCode+"&lang="+lang, "_blank");
			window.open("https://seoulpass.verigood.kr/?reference_code="+referenceCode+"&qr_code="+props.productItem.qrCode+"&lang="+lang, "_blank");
		} else {
			console.log("https://seoulpass.simtalk.kr//?reference_code="+referenceCode+"&qr_code="+props.productItem.qrCode+"&lang="+lang, "_blank");
			window.open("https://seoulpass.simtalk.kr//?reference_code="+referenceCode+"&qr_code="+props.productItem.qrCode+"&lang="+lang, "_blank");
		}
	}

	// try {
	// 	const url = `/api2/esim/${props.productItem.uid}`;
	// 	const res = await AxiosConfig.get(url);
		  
	// 	if (res.status === 200) {
	// 		if(res.data.iccid_num) {
	// 			setIsEsimActive(false);
	// 			setIsEsimPurchasedActive(true);
	// 			alert(t("how_eSIM.complete"));
	// 		} else {
	// 			//console.log("https://seoulpass.verigood.kr/?reference_code="+res.data.reference_code+"&qr_code="+props.productItem.qrCode+"&lang="+lang);
	// 			//[상용]https://seoulpass.simtalk.kr/?reference_code={value}&qr_code={value}&lang={value}
	// 			//[개발]https://seoulpass.verigood.kr/?reference_code={value}&qr_code={value}&lang={value}

				

	// 			// if(process.env.REACT_APP_ENV === "development") {
	// 			// 	console.log("https://seoulpass.verigood.kr/?reference_code="+res.data.reference_code+"&qr_code="+props.productItem.qrCode+"&lang="+lang, "_blank");
	// 			// 	window.open("http://discoverseoul.channel.io", "_blank");
	// 			// 	//window.open("https://seoulpass.verigood.kr/?reference_code="+res.data.reference_code+"&qr_code="+props.productItem.qrCode+"&lang="+lang, "_blank");
	// 			// } else {
	// 			// 	console.log("https://seoulpass.simtalk.kr//?reference_code="+res.data.reference_code+"&qr_code="+props.productItem.qrCode+"&lang="+lang, "_blank");
	// 			// 	window.open("http://discoverseoul.channel.io", "_blank");
	// 			// 	//window.open("https://seoulpass.simtalk.kr//?reference_code="+res.data.reference_code+"&qr_code="+props.productItem.qrCode+"&lang="+lang, "_blank");
	// 			// }
				
	// 		}

	// 	} else {
	// 	}
	//   } catch (error) {
	// 	console.error(error);
	//   }

  };


// {
//     "uid": 11111,
//     "seoulbike_code": "xxxxxxxxxx",
//     "active_date": "2021-03-21T16:35:00"
// }
  const fetchBike = async () => {
    try {
      const url = `/api2/myBike/${props.productItem.qrCode}`;
      const res = await AxiosConfig.get(url);

      if (res.status == 200) {
        setBikeNo(res.data.seoulbike_code);
        setIsBikeActive(!isBikeActive);
      } else {
        if (res.data.code == "-500") {
          setBikeNo("Unavailable");
        } else {
          setBikeNo("-");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkIsUsePass = async (isForceUse:Boolean) => {
	const exp = moment(props.productItem.expireDate, 'YYYY-MM-DDThh:mm:ss');
    const momentNow = moment();

	let addDays;
    switch (props.productItem.passtypeUid) {  // 1 = 24시간 ,3 = 48시간,5 = 72시간
        case 1: case 2: addDays = 1; break;
        case 3: case 4: addDays = 2; break;
        case 5: case 6: addDays = 3; break;
    }

	if (exp.isBefore(momentNow)) {
        setUsePass(false);
    } else if (exp.isAfter(momentNow.add(addDays, 'days'))) {
		setUsePass(false);
    } else {
		setUsePass(true);
	}

	if(isForceUse) {
		props.productItem.expireDate = moment(new Date(), 'YYYY-MM-DDThh:mm:ss').toString();
		setUsePass(true);
	}

  };

  
  useEffect(() => {
	fetchArex(false);
	fetchVisitHistory();
	checkIsUsePass(false);
	checkESimSite();
  }, [globalInfoStore.currentLangType]);

  

  const SpotVisitHistory = (visitList || []).map((item) =>{
      return (
        <li>
          <p className="date"><Moment format="YYYY-MM-DD H:mm:ss">{item.createDate}</Moment></p>
          <p className="tit">{item[globalInfoStore.currentLangType.title2]}</p>
        </li>
      )
  });


  //관광지 상세보기
  const toggleVisit = () => {
	// console.log("isVisitHistoryActive1::" + isVisitHistoryActive)
	// if (!isVisitHistoryActive) {
	// 	// console.log("isVisitHistoryActive2::" + isVisitHistoryActive)
	// 	fetchVisitHistory()
	// }
	// console.log("isVisitHistoryActive3::" + isVisitHistoryActive)
    setIsVisitHistoryActive(!isVisitHistoryActive);
	// console.log("isVisitHistoryActive4::" + isVisitHistoryActive)
  }

  //공항철도 이용권 조회
  const toggleArex = () => {

	if (isExpired(props.productItem.expireDate)) {
		alert("QRCode Expired.");
		return;
	  } else {
		setIsArexActive(!isArexActive);  
	  }
		
  }

  //따릉이 이용 조회
  const toggleBike = () => {
	  if (isExpired(props.productItem.expireDate)) {
		alert("QRCode Expired.");
		return;
	  } 
	  if (bikeNo == null) {
		fetchBike()
	  } else {
		setIsBikeActive(!isBikeActive);
	  }
  }

	const refreshRemainTime = () => {
		// alert("시간갱신");
		fetchMyPassByQrUid();
	}

//   const toggleQrCode = () => {
// 	  setIsQrMgmtCode(!isQrMgmtCode);
//   }

  if (props.productItem.qrCode == null){
	  return <></>
  }

	/** 테스트용 랜덤 함수 */
  const rand = (min:number, max:number) => {
	return Math.floor(Math.random() * (max - min)) + min;
  }

  /** 만료된경우 true  */
  const isExpired = (expiredDate:string) => {
	  if (props.productItem.expireDate == null ) {
		  return true;
	  }

	  const exp = moment(props.productItem.expireDate, 'YYYY-MM-DDThh:mm:ss');
	  const momentNow = moment();

	  if (exp.isBefore(momentNow)) {
		  return true;
	  }
	  return false;
  }

  const postUseArexCode = async () => {
	let url = `/api2/usearex/${props.productItem.qrCode}`;
	const res = await AxiosConfig.get(url);
	if (res.status === 200) {
		setIsArexActive(true);
		fetchMyPassByQrUid();
		checkIsUsePass(true);

		if (res.data.arex_code) {
			setArexNo(res.data.arex_code);
			fetchArex(false);
		}
	}
	props.doHidePopup();
}

  const DspQRCode = () => {
	const QRCODE_MIN_LENGTH = 10;
	const AREX_MIN_LENGTH = 13;

	const qrCode2 = (props.productItem.qrCode==null?props.productItem.qrMgmtCode:props.productItem.qrCode);
	const qrMgmtCode2 = (props.productItem.qrMgmtCode == null ? props.productItem.qrCode: props.productItem.qrMgmtCode);
	// const randNumber = rand(0,9) //테스트용 
	if (isExpired(props.productItem.expireDate)) {
		return (
			<>
				<p className="number" > - </p>
				<div className="top_img">
					<img className="qr" src={require("../../resources/img/app/qr.png")} width="320px" height="320px" alt="qrcode expired" />
				</div>

				<div className="move">
				</div>
			</>
		)
	}

	//if (props.productItem.qrCode == null || props.productItem.qrCode == "" || props.productItem.qrCode.length < QRCODE_MIN_LENGTH || arexNo == null || arexNo == "" || arexNo.length < AREX_MIN_LENGTH) { //FIXME
	if (props.productItem.qrCode == null || props.productItem.qrCode == "" || props.productItem.qrCode.length < QRCODE_MIN_LENGTH) { //FIXME
		return (
			<>
				<p className="number" > - </p>
				<div className="top_img">
					<SSkeletonLine className="qr" />
				</div>

				<div className="move">
				</div>
			</>
		)
	}

	var resultQRCodeValue = qrMgmtCode2;

	// if(qrMgmtCode2.length > 40) {
	// 	resultQRCodeValue = qrMgmtCode2;
	// } else {
	// 	resultQRCodeValue = arexNo + "-" + qrMgmtCode2;
	// }

	return (
		<>
			<p className="number" >QR No. {qrCode2}</p>
			<div className="top_img">
				<QRCode className="qr" renderAs="svg" width={320} height={320} value={resultQRCodeValue}/>
			</div>
			
			{ isUsePass === false ? <> </> 
				:
				<div className="validdate">
					{/* <Moment subtract={{hours:props.productItem.passtypeTime}} format="YYYY-MM-DD HH:mm:ss">{props.productItem.expireDate}</Moment> ~   */}
					<span>{t("mypass.expirecheck_date")} :</span>  
					<Moment format="YYYY-MM-DD HH:mm:ss">{props.productItem.expireDate}</Moment>
				</div>
			}
			

			<div className="move">
				<StyledWrapper >
				DISCOVER SEOUL PASS DISCOVER SEOUL PASS
				</StyledWrapper>
			</div>
		</>
	)
  }
  
  return (
      <div className="slide m_pass">
				{/* <p className="number" onClick={toggleQrCode}>QR No. {isQrMgmtCode?props.productItem.qrMgmtCode:props.productItem.qrCode}</p> */}
				<DspQRCode></DspQRCode>
			
				<div className="bottom">
					<div className="card">
						<span className="img">
							{/* <img src="img/app/s_card.png" alt="실물카드 이미지" /> */}
							{/* <img src={require("../../resources/img/passImg01.png")} alt="실물카드 이미지" /> */}
							<img src={props.productItem.fullpath} alt="실물카드 이미지" />
						</span>

						<span className="time"><em>{props.productItem.passtypeTime}</em> H</span>
						<span className="sort">{t("mypass.mobile_pass")}</span>

						{/* <p className="tit">디스커버서울패스</p> */}
						<p className="tit">{getValueByKey(props.productItem, globalInfoStore.currentLangType.title)}</p>
					</div>

					<div className="info">
						<dl>
							{/* <dt>유효시간</dt> */}
							<dt>{t("app_my_pass.pass_duration")}</dt>
							{/* <dd>{props.productItem.expireDate == null? "00:00:00": getRemainTime(props.productItem.expireDate)}</dd> */}
							{/* <dd>{props.productItem.expireDate == null? "00:00:00": props.productItem.expireDate}</dd> */}
							<dd>
								{/* {props.productItem.expireDate == null? "00:00:00": <RemainTimer expireDate={expireDate}></RemainTimer>} */}
								{/* <RemainTimer></RemainTimer> */}
								<RemainTimer expireDate={expireDate} productItem={props.productItem} productInfoList={props.productInfoList}></RemainTimer>

								<button className="btn_refresh" onClick={refreshRemainTime}>새로고침</button>
							</dd>
						</dl>
						<dl onClick={toggleVisit}>
							<dt>{t("app_my_pass.visited_attraction")}</dt>
							<dd>{visitList.length}
								<button className="btn_detail">{t("app_my_pass.details")}</button>
							</dd>
						</dl>
					</div>

				</div>

				{ isExpired(props.productItem.expireDate) || !isEsimActive ? '' :
					<div className="view_esim">
						<button className="esim" onClick={() => popupEsimSite()} >{t("app_my_pass.esim")}</button>
					</div>
				}
				{ isExpired(props.productItem.expireDate) || !isEsimPurchasedActive ? '' :
					<div className="view_esim">
						<button className="esimpurchased">{t("how_eSIM.complete")}</button>
					</div>
				}

				<div className="view_trafiic">
					<button className="train" onClick={() => fetchArex(true)} >{t("app_my_pass.arex_code")}</button>
					<p className={classNames({code:true, train:true, active:isArexActive})} onClick={toggleArex} >{t("app_my_pass.arex_code2")} {arexNo}</p>
					{/* <button className="bycle" onClick={toggleBike} >{t("app_my_pass.seoulbike_code")}</button>
					<p className={classNames({code:true, bycle:true, active:isBikeActive})} onClick={toggleBike}>{t("app_my_pass.seoulbike_code2")} {bikeNo}</p> */}
          			{/* <button className="train" onClick={toggleArex} >AREX 탑승권</button>
					<p className="code train" onClick={toggleArex} >AREX 티켓번호. 27994-9023-3688</p>
					<button className="bycle" onClick={toggleBike}>따릉이 탑승권</button>
					<p className="code bycle" onClick={toggleBike}>따릉이 대여번호. t7gqc832nt</p> */}
				</div>

				{/* <!-- s: 관광지 상세보기 화면 --> */}
				<div className={classNames({wrap_detail:true, active:isVisitHistoryActive})}>
					{SpotVisitHistory.length > 0?
					<>
						<h6>{t("app_my_pass.visited_attraction")}</h6>
						<ul>
							{SpotVisitHistory}
						</ul>
					</>
					:
					<>
						<h6>{t("app_my_pass.no_visited_attraction")}</h6>
						<ul>
						</ul>
					</>}
					<button className="btn_viewQr" onClick={toggleVisit}>{t("app_my_pass.view_qr_code")}</button>
				</div>
				{/* <!-- e: 관광지 상세보기 화면 --> */}

				
				
	  </div>
  );
};

export default AppMyPassMobileSlideItem;