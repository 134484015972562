import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import { isPlatform } from '@ionic/react';
import AxiosConfig from '../../services/AxiosConfig';

const MyPage = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [gsurvey, setGsurvey]= useState(false);

    const fetchGSurveyInfo = async () => {
        if (globalInfoStore.getDspJwt() != null) {
          var mobileos = 0;
          if (isPlatform("capacitor") && isPlatform("ios")) {
            mobileos = 1;
          } else if (isPlatform("capacitor") && isPlatform("android")) {
            mobileos = 2;
          }
  
          const data = JSON.stringify({
            fcm_token: localStorage.getItem("deviceToken"),
            mobileos: mobileos
          });
          const res = await AxiosConfig.post(`/api2/isGSurvey`, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
  
          if (res.status === 200) {
            if(res.data.isGSurvey == 2 || res.data.isGSurvey == 5) {
              setGsurvey(true)
            }
          } 
        }
      };

    useEffect(() => {
        fetchGSurveyInfo();
        if (globalInfoStore.getDspJwt() == null) {
            history.push('/')
        }
        return () => {}
    }, [globalInfoStore.getDspJwt()])
    
    return (
        <div className="layout">
					
            {/* <h6 className="sec_tit">회원정보 관리</h6>
            <p className="sec_exp">가입하신 회원정보를 변경하거나 탈퇴할 수 있습니다.</p>

            <ul className="wrap_personal">
                <li className="step01"><Link to="/app/mypage/profile/modify">개인정보 수정</Link></li>
                <li className="step02"><Link to="/app/mypage/profile/password">비밀번호 변경</Link></li>
                <li className="step03"><Link to="/app/mypage/profile/leaveid">회원 탈퇴</Link></li>
            </ul> */}
            <h6 className="sec_tit">{t("mypage.title")}</h6>
            <p className="sec_exp">{t("mypage.desc")}</p>

            <ul className="wrap_personal li05">
                {/* <li class="step01"><a href="personal0101.html" target="_self">Profile</a></li>
						<li class="step02"><a href="personal02.html" target="_self">My PASS</a></li>
						<li class="step03"><a href="personal0301.html" target="_self">My Orders</a></li>
						<li class="step04"><a href="personal0401.html" target="_self">My Inquiries</a></li>
						<li class="step05"><a href="personal0501.html" target="_self">Favorites</a></li> */}
                <li className="step01"><Link to="/app/mypage/profile">{t("mypage.profile")}</Link></li>
                <li className="step06"><Link to="/appmypass/register">{t("mypage.pass_register")}</Link></li>
                <li className="step02"><Link to="/app/mypage/mypass">{t("mypage.my_pass")}</Link></li>
                <li className="step08"><Link to="/app/mypage/myesim">{t("mypage.my_esim")}</Link></li>
                <li className="step03"><Link to="/app/mypage/orderhistory">{t("mypage.my_orders")}</Link></li>
                <li className="step04"><Link to="/app/mypage/support">{t("mypage.my_inquiries")}</Link></li>
                <li className="step05"><Link to="/app/mypage/interest">{t("mypage.favorites")}</Link></li>

                {/* <PrivateRoute exact path="/app/mypage" component={MyPage} />
                <PrivateRoute exact path="/app/mypage/profile/index" component={ProfileContentIndex} />
                <PrivateRoute exact path="/app/mypage/profile/password" component={ProfileContentPassword} />
                <PrivateRoute exact path="/app/mypage/profile/modify" component={ProfileContentModify} />
                <PrivateRoute exact path="/app/mypage/profile/leaveid" component={ProfileContentLeaveId} />
                <PrivateRoute exact path="/app/mypage/profile/leaveid/complete" component={ProfileContentLeaveIdCompleted} />
                <PrivateRoute exact path="/app/mypage/mypass" component={MyPass} />
                <PrivateRoute exact path="/app/mypage/inquiry" component={InquiryList} />
                <PrivateRoute path="/app/mypage/interest" component={Interest} />
                <PrivateRoute exact path="/app/mypage/orderhistory" component={OrderHistory} />
                <PrivateRoute exact path="/app/mypage/ordervoucher/:uid" component={OrderVoucher} /> */}
            </ul>
            { gsurvey === true ? 
            <>
                <ul className="wrap_personal li07">
                    <li className="step07"><Link to="/gsurvey">{t("mypage.gsurvey")}</Link></li>
                </ul>
            </>
             : 
             <>
             </> }
        </div>
    );
};

export default MyPage;