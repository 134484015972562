import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import {useHistory} from "react-router-dom";
import { globalInfoStore, LANG_TYPES } from "../../stores/GlobalInfoStore";

const classNames = require("classnames");

// const ArticleSpotListItem = (props:any) => {

interface ArticleSpotListItemProps extends RouteComponentProps {}



const ArticleSpotListItem = (props:any) => {
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const SpotSubCodes = [
        // {subCode:0,id:'li01',title:'전체'},
        {subCode:1,id:'li02',title:t("spot.cate_history")},
        {subCode:2,id:'li03',title:t("spot.cate_museum")},
        {subCode:3,id:'li04',title:t("spot.cate_entertainment")},
        {subCode:4,id:'li05',title:t("spot.cate_transportation")},
        {subCode:5,id:'li06',title:t("spot.cate_travel")},
        {subCode:6,id:'li07',title:t("spot.cate_experience")},
        {subCode:7,id:'li08',title:t("spot.cate_performance")},
        {subCode:8,id:'li09',title:t("spot.cate_shopping")},
        {subCode:9,id:'li10',title:t("spot.cate_others")},
        {subCode:10,id:'li11',title:t("spot.cate_one_day_class")}
      ]
    
    const handleItemClick = (spotId:number) => {
        history.push("/app/spot/index/view/" + spotId)
    }

    const getImage = (fullpath:string) => {

       if (fullpath == null || fullpath.length < 10 || 
              (!fullpath.startsWith("https://") && !fullpath.startsWith("http://"))
          ) {
            return require("../../resources/img/noimage.png")
       } 

        return fullpath;
    }

    const getSubCodeTitle = (subCode:number) => {
        const result = SpotSubCodes.find(item => item.subCode == subCode );
        return result.title;
    }

    return (
        <li className={classNames({closed:(props.content.opened==="2")})} onClick={() => handleItemClick(props.content.uid)}>
        {/* <li onClick={() => handleItemClick(props.content.uid)}> */}
            {/* <span className="tag discount">할인</span> */}
            {props.content.code == 1 ? <span className="tag free">{t("spot.spot_free")}</span>: ""}
            {props.content.code == 2 ? <span className="tag discount">{t("spot.spot_discount")}</span>: ""}

            <div className="img">
                {/* <img src=} alt="관광지 이미지" /> */}
                <img src={getImage(props.content.fullpath)} alt="관광지 이미지" />
                <p className="tit">{props.content[globalInfoStore.currentLangType.title]}</p>
            </div>

            <div className="hover">
                <p className="tit">{props.content[globalInfoStore.currentLangType.title]}</p>
                <div className="tags">
                    {props.content.hot?<span className="hot">HOT</span>:""}
                    {props.content.subCode!=null?<span className="sort">{getSubCodeTitle(props.content.subCode)}</span>:""}
                    {props.content.plusEdition?<span>{t("spot.seoul_pass_edition")}</span>:""}
                    {props.content.fastEnter?<span>{t("spot.fast_track")}</span>:""}
                </div>
            </div>
        </li>
    );
};

export default ArticleSpotListItem;