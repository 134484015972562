import React, { useEffect } from "react";
import { Route, Switch } from 'react-router';
import Guide from './Guide';
import GuideBuyIndexNV from './GuideBuyIndexNV';
import GuideHowIndex from './GuideHowIndexNV';

const GuideIndex = () => {

	useEffect(() => {

	}, [])

    return (
        <section id="container" className="sub">

			<article id="content">
				
                <Switch>
					<Route path="/appnv/guide/index/passguide" component={Guide} />
					<Route path="/appnv/guide/index/buy" component={GuideBuyIndexNV} />
					<Route path="/appnv/guide/index/how" component={GuideHowIndex} />
                </Switch>
			</article>

		</section>
    );
};

export default GuideIndex;
