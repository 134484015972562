import { isPlatform } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import { ConsoleLogObserver, AuthService, AuthActionBuilder, AUTHORIZATION_RESPONSE_KEY } from 'ionic-appauth';
import { CapacitorBrowser, CapacitorSecureStorage } from 'ionic-appauth/lib/capacitor';

import { AxiosRequestor } from './AxiosService';
import { AuthWeibo3 } from './AuthServiceWeibo3';
import { StorageBackend, Requestor, BaseTokenRequestHandler, AuthorizationServiceConfiguration, AuthorizationNotifier, TokenResponse, AuthorizationRequestJson, AuthorizationRequest, DefaultCrypto, GRANT_TYPE_AUTHORIZATION_CODE, TokenRequestJson, TokenRequest, GRANT_TYPE_REFRESH_TOKEN, AuthorizationResponse, AuthorizationError, LocalStorageBackend, JQueryRequestor, TokenRequestHandler, StringMap } from '@openid/appauth';
import { DspAuthorizationRequestHandler } from './DspAuthorizationRequestHandler';

const { App } = Plugins;

const TOKEN_RESPONSE_KEY = "token_response";
const AUTH_EXPIRY_BUFFER = 10 * 60 * -1;  // 10 mins in seconds

export class AuthWeibo2  {
  
  private static auth: AuthWeibo3 | undefined;
  // private static authSignIn: AuthWeibo3 | undefined;
  // private static authSignUp: AuthWeibo3 | undefined;

  authServiceConfig: AuthorizationServiceConfiguration | undefined;
  authRequest: AuthorizationRequest | undefined;

  public static get Instance(): AuthWeibo3 {
    console.log("AuthWeibo3:: Instance 1")
    if (!this.auth) {
      console.log("AuthWeibo3:: Instance 2")
      this.auth = new AuthWeibo3();
      console.log("AuthWeibo3:: Instance 3")
    }
    console.log("AuthWeibo3:: Instance 4")
    return this.auth;
  }

}
