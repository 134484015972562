import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
//import { useCookie } from "react-use";
import AxiosConfig from '../../../services/AxiosConfig'
import { globalInfoStore } from "../../../stores/GlobalInfoStore";

const ProfileContentLeaveId = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [password, setPassword] = useState("");
    // const [result, setResult] = useState({});
//    const [value, updateCookie, deleteCookie] = useCookie("dspJwt");

    const postDeleteMemberInfo = async () => {
        const url = `/api2/memberInfo/deleteMemberInfo`;
        const data = JSON.stringify({
            "oldPassword":password
        });
        const res = await AxiosConfig.put(url,
            data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        console.log("res.data" + res.data);
        if (res.status == 200 && res.data.code === "200") {
            logout()
            history.replace("/app/mypage/profile/leaveid/complete")
        } else if (res.data.code === "-401") { // 비밀번호 오류
            alert("Incorrect Password.")
        } else if (res.data.code === "400") { // 토큰오류
            alert("Login Error.") //토큰오류 발생시 Login 오류를 띄움.
        } else {
            //알수없는 오류
            alert(res.status)
        }
    }

    const logout = () => {
        // console.log("cookie:::1:::" + value);
        // updateCookie("", {
        //     domain: window.location.hostname,
        //     path: "/",
        //     expires: new Date(),
        //     sameSite: 'strict' 
        // })
        globalInfoStore.removeDspJwt()
        // console.log("cookie:::2:::" + value);

        // deleteCookie();
        // console.log("cookie:::3:::" + value);
    }

    const handleLeave = () => {
        console.log("비밀번호 : password ---> " + password)
        if (password === "") {
            alert("Please enter your password")
            return;
        }
        postDeleteMemberInfo()
    }
    
    return (
        <div className="layout">
            
            {/* <div className="wrap_info leave">
                <h6 className="sec_tit side">회원탈퇴</h6>
                <p className="sec_exp side">디스커버서울패스 회원을 탈퇴하시겠습니까?</p>

                <em className="top_exp">* 필수입력</em>

                <dl>
                    <dt>현재 비밀번호 <em>*</em></dt>
                    <dd>
                        <input type="password" title="현재 비밀번호" />
                    </dd>
                </dl>

                <button className="btn_leave">회원 탈퇴</button>
            </div> */}
            <div className="wrap_info leave">
                <h6 className="sec_tit side" dangerouslySetInnerHTML={{__html:t("profile_content_leave.title")}}>

                </h6>
                <p className="sec_exp side" dangerouslySetInnerHTML={{__html:t("profile_content_leave.desc")}}></p>

                <em className="top_exp">* {t("profile_content_leave.required")}</em>

                <dl>
                    <dt>{t("profile_content_leave.current_password")} <em>*</em></dt>
                    <dd>
                        {/* <input type="password" title="현재 비밀번호" value="" /> */}
                        <input type="password" title="현재 비밀번호" value={password} onChange={e=>(setPassword(e.target.value))} />
                    </dd>
                </dl>

                <button className="btn_leave" onClick={handleLeave}>{t("profile_content_leave.btn_leave")}</button>
            </div>

            

        </div>
    );
};

export default ProfileContentLeaveId;