import React from "react";
import { useTranslation } from "react-i18next";
import AppDownload from "../../components/organisms/AppDownload";
import { isPlatform } from "@ionic/react";
import { useLocation } from "react-router-dom";


const GuideHowCoupon = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const getGraph = () => {
        return t("common.curlang");
    };

    const getStepclass = (str:string) => {
        //return str + t("common.curlang");
        return str + "en";
    };

    return (
        <div className="wrap_use">
            <p className="use_tit">{t("how_coupon.title")}</p>
            <div className="exp_box" dangerouslySetInnerHTML={{__html:t("how_coupon.desc")}}></div>

            <div className="step step04 coupon">
                <dl className={getStepclass("step01")}>
                    <dt>STEP 1</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_coupon.step1_cont")}}></dd>
                </dl>
                <dl className={getStepclass("step02")}>
                    <dt>STEP 2</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_coupon.step2_cont")}}></dd>
                </dl>
                <dl className={getStepclass("step03")}>
                    <dt>STEP 3</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_coupon.step3_cont")}}></dd>
                </dl>
                <dl className={getStepclass("step04")}>
                    <dt>STEP 4</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_coupon.step4_cont")}}></dd>
                </dl>
            </div>

            {isPlatform("capacitor") || location.pathname.startsWith('/appnv') ? null
            :
                <div className="exp_box use03">
                    <span>{t("how_arex.app_download")}</span>
                    <AppDownload type="bold"></AppDownload>
                </div>
             }

        </div>
    );
};

export default GuideHowCoupon;