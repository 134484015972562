import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Route, Switch } from "react-router-dom";
import { AxiosRequestor } from '../../services/AxiosService';
import AxiosConfig from '../../services/AxiosConfig';
import { notifyListeners } from 'mobx/dist/internal';
import Axios, { CancelTokenSource } from 'axios';
// import REACT_APP_API_URL ;
import Navigation from '../navigation/Navigation';
import PassFinish from './PassFinish';
import PassView from './PassView';
import Pass from "./Pass";
import { PrivateRoute } from "../../routes/PrivateRoute";

const PassIndex = () => {
	const { t, i18n } = useTranslation();
	
    return (
        <section id="container" className="sub">
			<h6 className="hidden">본문</h6>

			<article className="bg sub04">
				<div className="layout">
					{/* <Route exact path="/app/pass" component={()=>(<h2 className="page_tit">{t("navigation.app_depth2title_pass")}패스 조회/구매</h2>)} />
					<Route exact path="/app/pass/view/:productUid" component={()=>(<h2 className="page_tit">{t("navigation.app_depth2title_pass_view")}패스 구매</h2>)} />
					<Route exact path="/app/pass/finish/:productUid/:orderNo" component={()=>(<h2 className="page_tit">{t("navigation.app_depth2title_pass_view")}패스 구매</h2>)} /> */}
					<Route exact path="/app/pass" component={()=>(<h2 className="page_tit">{t("navigation.app_depth2title_pass")}</h2>)} />
					<Route exact path="/app/pass/view/:productUid" component={()=>(<h2 className="page_tit">{t("navigation.app_depth2title_pass_view")}</h2>)} />
					<Route exact path="/app/pass/finish/:productUid/:orderNo" component={()=>(<h2 className="page_tit">{t("navigation.app_depth2title_pass_view")}</h2>)} />
				</div>
			</article>

			<Navigation></Navigation>

			<article id="content">
				<h6 className="hidden">콘텐츠 영역</h6>
				<Route exact path="/app/pass" component={Pass} />
				<Switch>
					<Route exact path="/app/pass/view/:productUid" component={PassView} />
					<PrivateRoute exact path="/app/pass/finish/:productUid/:orderNo" component={PassFinish} />
				</Switch>
			</article>

		</section>
    );
};

export default PassIndex;