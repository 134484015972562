import React from "react";
import { useTranslation } from "react-i18next";
import { Route } from 'react-router';
import { Link } from "react-router-dom";
import Navigation from '../navigation/Navigation';
import Search from "./Search";
import SearchV1 from './SearchV1';

const SearchIndex = () => {
	const { t, i18n } = useTranslation();

	console.log("SearchIndex:::" + new Date().toISOString())
	
    return (
        <section id="container" className="sub">
			<h6 className="hidden">본문</h6>


			<article className="bg sub03">
				<div className="layout">
					<h2 className="page_tit">{t("search.unified_search")}</h2>
					{/* <p>디스커버서울패스를 이용하여 할인 또는 무료로 이용할 수 있는 관광지 정보 입니다.</p> */}
				</div>
			</article>

			<Navigation></Navigation>

			<article id="content">
				<Route exact path="/app/searchV1/index" component={SearchV1} />
				<Route exact path="/app/search/index" component={Search} />
				
			</article>

		</section>
    );
};

export default SearchIndex;