import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, useLocation } from 'react-router';
import { Link } from "react-router-dom";
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import PolicyLocation from './PolicyLocation';
import PolicyTerms from './PolicyTerms';

const classNames = require("classnames");

const PolicyIndex = () => {
	const { t, i18n } = useTranslation();
	const location = useLocation();

	const POLICY_01 = "/app/policy/terms";
    const POLICY_02 = "/app/policy/location";

	const [isShowMobileTabMenu, setIsShowMobileTabMenu] = useState(false);

  	useEffect(() => {

	}, [globalInfoStore.currentLangType])

	const isSubMenuActive = (activeName:string) => {
		if ( activeName == location.pathname ) {
			return true;
		} 
		return false;
    }

	const toggleMobileTabMenu = () => {
		setIsShowMobileTabMenu(!isShowMobileTabMenu);
	}

    return (
        <section id="container" className="sub">
			{/* <h6 className="hidden">패스소개</h6> */}

			<article className="bg sub05">
				<div className="layout">
					{/* <h2 className="page_tit">이용 정책</h2> */}

					{/* <Route path="/app/policy/terms" component={()=>(<h2 className="page_tit">{t("footer.terms_of_use")}</h2>)} /> */}
					{/* <Route path="/app/policy/privacy" component={PolicyPrivacy} /> */}
					{/* <Route path="/app/policy/location" component={()=>(<h2 className="page_tit">{t("footer.terms_of_location_info")}</h2>)} /> */}
				</div>
			</article>

			{/* <Navigation></Navigation> */}

			<article id="content">
				<div className="layout">
				
					{/* <div className={classNames({use_tab:true, terms:true, m_active:isShowMobileTabMenu})} onClick={toggleMobileTabMenu}> */}

						{/* {isSubMenuActive(POLICY_01)?<span className="m_only">{t("policy.terms_title")}</span>:""}
						{isSubMenuActive(POLICY_02)?<span className="m_only">{t("policy.location_title")}</span>:""}

						<div className="m_bg">
							<Link to="/app/policy/terms" className={classNames({active:isSubMenuActive(POLICY_01)})}>{t("policy.terms_title")}</Link>
							<Link to="/app/policy/location" className={classNames({active:isSubMenuActive(POLICY_02)})}>{t("policy.location_title")}</Link>
						</div> */}
						{/* {isSubMenuActive(POLICY_01)?<span className="m_only">이용약관</span>:""}
						{isSubMenuActive(POLICY_02)?<span className="m_only">위치정보서비스약관</span>:""} */}
						{/* {isSubMenuActive(POLICY_01)?<span className="m_only">{t("footer.terms_of_use")}</span>:""} */}
						{/* {isSubMenuActive(POLICY_02)?<span className="m_only">{t("footer.terms_of_location_info")}</span>:""} */}

						{/* <div className="m_bg"> */}
							{/* <Link to="/app/policy/terms" className={classNames({active:isSubMenuActive(POLICY_01)})}>{t("footer.terms_of_use")}</Link> */}
							{/* <Link to="/app/policy/location" className={classNames({active:isSubMenuActive(POLICY_02)})}>{t("footer.terms_of_location_info")}</Link> */}
						{/* </div> */}

					{/* </div> */}

					<Route path="/app/policy/terms" component={PolicyTerms} />
					{/* <Route path="/app/policy/privacy" component={PolicyPrivacy} /> */}
					{/* <Route path="/app/policy/location" component={PolicyLocation} /> */}
				</div>
			</article>

		</section>
    );
};

export default PolicyIndex;
