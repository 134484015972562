import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AxiosConfig from '../../services/AxiosConfig';
import { globalInfoStore, LANG_TYPES } from "../../stores/GlobalInfoStore";
import { BarcodeScanner } from '@ionic-native/barcode-scanner';
import { QrReader } from 'react-qr-reader';
import { isPlatform } from "@ionic/react";


const classNames = require("classnames");


const ArexQr = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
	const [loading, setLoading] = useState(false);

    const [qrcode, setQrcode] = useState("");
    const [isNotFound, setNotFound] = useState<boolean>(false);
    const [isKTOPlusError, setKTOPlusError] = useState<boolean>(false);
    const [result, setResult] = useState("");
    const [error, setError] = useState("");
    const [qrdata, setQrData] = useState("");
    const [isWebQR, setWebQR] = useState<boolean>(false);
    const [isShowArexCode, setShowArexCode] = useState<boolean>(false);

    const [isShowPopup, setShowPopup] = useState<boolean>(false);
    const [isAttention, setIsAttention] = useState<boolean>(false);


    const GROUP_REF_CODE_KEY_LENGTH = 12; //자리
    const GROUP_REF_CODE_PREFIX = "MV";
    const WAUG_KEY_LENGTH = 8;


    const openScanner = async () => {
        const data = await BarcodeScanner.scan();
        console.log(`Barcode data: ${data.text}`);
        setQrcode(data.text);
        postSearchArexCode(data.text);
    };

    const displayWebQR = async () => {
        setWebQR(true)
    };

    const postSearchArexCode = async (qrcodeText:string) => {
        if (qrcodeText.length < WAUG_KEY_LENGTH ) {
            alert("QRCode length is More than or equals to 8 characters");
            return;
        }

        setWebQR(false);
        setShowArexCode(false);
        setShowPopup(false);

        let url = `/api2/arex/${qrcodeText}`;
        const res = await AxiosConfig.get(url);
        if (res.status === 200 && res.data.code == undefined && res.data.product_uid == 8) {
            // setResult(null);
            // setError(null);
            // setKTOPlusError(true);
            //alert(t("arexqr.error_kto")+'\r\n'+t("arexqr.error_kto2"));
            alert(t("arexqr.popup_kto_ed"));
        } else if (res.status === 200 && res.data.code == undefined) {
            
            if(res.data.aiuUid == null) {
                setShowPopup(true);
                if(res.data.arex_code != null && res.data.arex_code.length > 0)
                    setResult(res.data.arex_code);

                return ;
            } else {
                if(res.data.arex_code != null && res.data.arex_code.length > 0) {
                    setResult(res.data.arex_code);
                    setShowArexCode(true);
                }
            }

        } else {
            setResult(null);
            setError(res.data.desc);
            setNotFound(true);
        }
    };

    const onInputQrCodeChange = (e: any) => {
        setNotFound(false);
        setKTOPlusError(false);
        setQrcode(e.target.value.trim())
    }

    useEffect(() => {
    }, [globalInfoStore.currentLangType]);

    const handleScan = (data:any) => {
        if (data) {
            setQrcode(data.text);
            postSearchArexCode(data.text);
            setWebQR(false);
        }
    };
    
    const handleIsAttention = async () => {
        setIsAttention(!isAttention);
    }

    const postUseArexCode = async (qrcodeText:string) => {
        if(!isAttention)
            return ;
        
        let url = `/api2/usearex/${qrcode}`;
        const res = await AxiosConfig.get(url);
        if (res.status === 200) {
            if (res.data.code == -1010) {
                alert(t("arexqr.popup_kto_ed"));
            } else {
                setShowArexCode(true);

                if (res.data.arex_code) {
                    setResult(res.data.arex_code);
                }
            }
            
        }
        setShowPopup(false);
    }

    return (
        <div className="layout">

            <div className="wrap_expiration">
            
                <p className="exp_tit">{t("arexqr.title")}</p>

                <p className="exp_txt" dangerouslySetInnerHTML={{__html:t("arexqr.desc")}}></p>
                
                <img src={require("../../resources/img/expiration_new.png")} alt={t("expirechecker.img_desc")} />

                {
                    (isWebQR) ? 
                    <>
                        <div className="wrap_expirationCamera">
                            <QrReader 
                                constraints={{ facingMode:"environment" }}
                                onResult={(result) => handleScan(result)}
                                className={""}  />
                            <span className="exp_guide">{t("arexqr.scan_qrcode_guide")}</span>
                            <br></br>
                        </div>
                        <br></br>
                    </>
                    :
                    <></>
                }

                <input  type="text" 
                        className={classNames({focus:isNotFound})}
                        placeholder={t("arexqr.qrcode_no_placeholder")} 
                        value={qrcode} 
                        onChange={onInputQrCodeChange} />
                { isNotFound?<p className="txt_alert">{t("arexqr.error_qr")}</p>:"" }
                { isKTOPlusError?<p className="txt_alert">{t("arexqr.error_kto")}</p>:"" }
                { isKTOPlusError?<p className="txt_alert">{t("arexqr.error_kto2")}</p>:"" }
                
                {
                    ((isPlatform("capacitor") && isPlatform("android")) || (isPlatform("capacitor") && isPlatform("ios"))) ?
                        <>
                            <span className="txt">or</span>
                            <button className="btn_scan" onClick={openScanner}>{t("arexqr.scan_qrcode")}</button>
                        </>
                        :
                        <>
                            {
                                (isPlatform("android") || isPlatform("ios")) ?
                                <>
                                    <span className="txt">or</span>
                                    <button className="btn_scan" onClick={displayWebQR}>{t("arexqr.scan_qrcode")}</button>
                                </>
                                :
                                <></>
                            }
                        </>
                        
                }
                
                <button className="btn_view" onClick={()=>{postSearchArexCode(qrcode)}}>{t("arexqr.search_qr")}</button>

                { isShowArexCode ? <p className="result">{t("arexqr.scan_result")} : {result}</p> : ""}

            </div>

            <div className={classNames({popup: isShowPopup})} hidden={!isShowPopup}>
                    {/* <button className="btn_close" onClick={(e)=>{setShowPopup(false)}}>닫기</button> */}
                    <div className="cont pd50">
                        <p className="txtR">{t("arexqr.popup_title")}</p>
                        <p className="txt">{t("arexqr.popup_ment1")}</p>
                        <p className="txtR">{t("arexqr.popup_ment11")}</p>
                        <p className="txt">{t("arexqr.popup_ment2")}</p>

                        <div className="input_box">
                            <input type="checkbox" id="ck" checked={isAttention} onChange={handleIsAttention} />
                            <label htmlFor="ck">{t("arexqr.popup_check")}</label>
                        </div>

                        <button className="btn500" onClick={(e)=>{setShowPopup(false)}}>{t("arexqr.popup_cancel")}</button>
                        <button className="btn50" disabled={!isAttention} onClick={()=>{postUseArexCode(qrcode)}}>{t("arexqr.popup_issue")}</button>
                    </div>
                </div>

        </div>
    );
};

export default ArexQr;