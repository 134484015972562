import React from "react";
import { Plugins } from '@capacitor/core';
import { isPlatform } from '@ionic/react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ReactDOM from 'react-dom';
// import { Router } from 'react-router';
import App from './App';
import './i18n';
import * as serviceWorker from './serviceWorker';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { registerWebPlugin } from '@capacitor/core';
import { FacebookLogin } from '@capacitor-community/facebook-login';
// import { Provider } from 'mobx'; // MobX 에서 사용하는 Provider
import { Provider } from 'mobx-react'; // MobX 에서 사용하는 Provider
// import { Provider } from 'mobx-react-lite'; // MobX 에서 사용하는 Provider
// import { CookiesProvider } from 'react-cookie';
// import { UserProvider } from './stores2/UserContext';
import { globalInfoStore } from './stores/GlobalInfoStore';

const { PushNotifications } = Plugins;
const { AppPlugin } = Plugins;

function noop() {}

// replace console.* for disable log on production
if (process.env.REACT_APP_ENV === 'production') {
    console.log = noop;
    // console.error = noop;
    // console.warn = noop;
    console.debug = noop;
}

ReactDOM.render(
    <Provider>
        <Router>
            <QueryParamProvider ReactRouterRoute={Route}>
                <App />
            </QueryParamProvider>
        </Router>
    </Provider>
    , 
    document.getElementById('root')
);

// service worker란 네트웍이 느리거나 오프라인인 상태에서도 온라인 인것 처럼 리소스들을 캐싱해서 보여주는 모듈을 뜻한다. create-react-app에서는 기본으로 구현하여 소스코드에 포함되어있다.
// registerServiceWorker();
registerWebPlugin(FacebookLogin);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// service worker란 네트웍이 느리거나 오프라인인 상태에서도 온라인 인것 처럼 리소스들을 캐싱해서 보여주는 모듈을 뜻한다. create-react-app에서는 기본으로 구현하여 소스코드에 포함되어있다.
// ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();

if (isPlatform("capacitor") && (isPlatform("android") || isPlatform("ios"))) {
    //PushNotifications.register();
    PushNotifications.addListener("registration",data => {
        //alert(data.value);
        //globalInfoStore.setDeviceToken(data.value);
        localStorage.setItem("deviceToken", data.value);
    }) 
    
    AppPlugin.addListener("appUrlOpen", (data: any) => {
        //alert('App opened : ' + data.url);
    })

    PushNotifications.addListener("pushNotificationReceived",notification => {
        //alert("pushNotificationReceived : " + notification.data);
    })

    PushNotifications.addListener("pushNotificationReceived",notification => {
        //alert("pushNotificationReceived : " + notification.data);
    })

    PushNotifications.addListener("pushNotificationActionPerformed",notification => {
        //alert("pushNotificationActionPerformed : " + notification.notification.data);
    })

  }
