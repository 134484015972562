import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// const ScrollToTop = () => {
//     const { pathname } = useLocation();

//     useEffect(() => {
//       window.scrollTo(0, 0);
//     }, [pathname]);

//     return (null);
// };

// export default ScrollToTop;

const ScrollToTop = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, search]);

  return <></>;
};

export default ScrollToTop;
