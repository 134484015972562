import AxiosConfig from '../services/AxiosConfig'

/**
 * 상품 정보
 */
export interface IProductInfo {
    uid: number; //     * 상품 고유번호
    code: number; //     * 상품 유형 (1: 실물, 2: 모바일)
    nativeType: number; // * 구분(1: 내국인, 2: 외국인)
    status: number; //     * 상품 상태 ( 1: 판매, 2: 판매중지 )
    passtypeUid: number; //     * 권종 고유번호
    edition: number; //     * 상품 에디션
    workerUid: number; //     * 상품 작업자(관리자) 고유번호
    normalPrice: number; //     * 상품가격
    salePrice: number; //     * 판매가(원화)
    usdPrice: number; //     * 달러 가격
    jpyPrice: number; //     * 엔화 가격
    cnyPrice: number; //     * 위안 가격
    managingTitle: string; //     * 관리용 상품명
    koTitle: string; //     * 한국어 상품명
    enTitle: string; //     * 영어 상품명
    jaTitle: string; //     * 일본어 상품명
    zhCnTitle: string; // * 중국어(간체) 상품명
    zhTwTitle: string; // * 중국어(번체) 상품명
    color: number; //     * Html 웹 컬러
    fixed: string; //     * 고정 여부 (고정:Y, 고정안함:N 또는 null)
    sort: number; //     * 정렬
    contentsUid: number; //
    createDate: string; //     * 상품 생성일자
    updateDate: string; //     * 상품 수정일자
    workDate: string; //     * 작업일자
    visible: string; //     * 공개여부(1: 공개, 2: 비공개)
    memo: string; //     * 메모
    koDescription: string; //     * 한국어 상세설명
    enDescription: string; //     * 영어 상세설명
    jaDescription: string; //     * 일본어 상세설명
    cnDescription: string; //     * 중국어(간체) 상세설명
    twDescription: string; //     * 중국어(번체) 상세설명
    passtypeTime: string; //
    freeCount: number; //
    disCount: number; //
    favoriteUid: number; //
    favoriteCreateDate: string; //
    filename: string; //
    filepath: string; //
    fullpath: string; //
    extension: string; //
}

export class ProductInfoService {
    productInfoList: IProductInfo[];
    private static service: ProductInfoService | undefined;

    public static Instance(): ProductInfoService {
        if (!ProductInfoService.service) {
            ProductInfoService.service = new ProductInfoService();
        }
        return ProductInfoService.service;
    }

    constructor() {
        const fetchPassItems = async () => {
            const res = await AxiosConfig.get("/api2/productInfoWithDiscount");
            this.productInfoList = res.data.content
        };
        fetchPassItems();
    }
}
