import React from "react";
import { useTranslation } from "react-i18next";

const CsTitleOneToOne = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <div className="layout">
            <h2 className="page_tit">{t("navigation.app_depth2title_cs_index_onetoone")}</h2>
            <p>{t("inquiry.desc2")}</p>
        </div>
    );
};

export default CsTitleOneToOne;