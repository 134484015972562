import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import { RelationStoreContentsInfo, StoreInfo } from "./GuideBuyOffline";

const GuideBuyOfflineItem = (props: {content: StoreInfo, storeContents: RelationStoreContentsInfo[], click:any}) => {
    const { t, i18n } = useTranslation();

    
    return (
        
                <dl>
                    <dt>
                        <img src={props.content.fullpath} alt={props.content.title + " 이미지"} />
                    </dt>
                    <dd>
                        <p className="tit">{props.storeContents.find( it => it.code == globalInfoStore.currentLangType.langCode).title}</p>
                        <ul>
                            <li>
                                <span>{t("buy_offline.contact")}</span>
                                <span>{props.content.tel}</span>
                            </li>
                            <li>
                                <span>{t("buy_offline.operating_hours")}</span>
                                <span>{props.content.relationHourInfo[0].hour}</span>
                            </li>
                            <li>
                                <span>{t("buy_offline.holiday")}</span>
                                <span>{props.storeContents.find( it => it.code == globalInfoStore.currentLangType.langCode).holiday}</span>
                            </li>
                            <li className="address">
                                <span>{t("buy_offline.address")}</span>
                                <span>{props.storeContents.find( it => it.code == globalInfoStore.currentLangType.langCode).address}</span>
                            </li>
                            <li>
                                <span></span>
                                <span><a className="gToMap2" href={props.content.mapUrl} target="_blank">{t("buy_offline.go_to_map")}</a>
                                </span>
                            </li>
                        </ul>
                    </dd>
                </dl>
                
    );
};

export default GuideBuyOfflineItem;