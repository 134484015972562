import React from 'react';

const ReactEnv = () => {
    return (
        <div>
            API_URL: {process.env.REACT_APP_API_URL}
        </div>
    );
};

export default ReactEnv;