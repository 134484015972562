import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NotFound = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <div>
            
        </div>
    );
};

export default NotFound;