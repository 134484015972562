import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const OneToOneCompleted = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <div className="layout">

            <div className="top_box">
                <p className="tit">{t("inquiry.inquiry_summitted_1")}</p>
                <p className="txt" dangerouslySetInnerHTML={{__html:t("inquiry.inquiry_summitted_2")}}></p>
                <p className="small">{t("inquiry.inquiry_summitted_3")}</p>
            </div>

            <div className="bottom_box" dangerouslySetInnerHTML={{__html:t("inquiry.bottom_box")}}>
                
            </div>

        </div>
    );
};

export default OneToOneCompleted;