import { observable } from 'mobx';
import i18n from '../i18n';
import { Plugins } from '@capacitor/core';
import jwt from 'jsonwebtoken';


const { Storage } = Plugins;

export interface ISiteLanguageInfo {
  uid: number;
  code: number;
  name: string;
  status: number;
  createDate: string;
  updateDate: string;
  workDate: string;
  workerUid: number;
}

export interface ILanguage {
  langCode:number;
  langGis:string;
	i18Str:string;
  full:string;
  htmlLang:string;
  langCss:string;
  tag:string;
  title:string;
  title2:string;
  desc:string;
  ask:string;
  answer:string;
  easypay:string;
  prefix1:string;
  prefix2:string;
  download:string;
  address:string;
  position:string;
  worktime:string;
  holiday:string;
  codename:string;
  searchLang:string;
  searchPrefix1:string;
  searchPrefix2:string;
};

export interface ICountryInfo {
  uid: number;// 119,
  code: number;// 2,
  status: number;// 1,
  ko: string;// "대한민국",
  en: string;// "Korea (Republic of)",
  ja: string;// " 大韓民国",
  zhCn: string;// " 韩国",
  zhTw: string;// " 韓國",
  countryCode: string;// "KR",
  languageCode: string;// null,
  telCode: string;// "+82"
}

export interface IProductInfoType {
  code: number;
  time: string;
  passtypeUid: number;
  koTag: string;
  enTag: string;
  jaTag: string;
  zhChsTag: string;
  zhChtTag: string;
  
}

export interface IPayType {
	type:string,
	// desc:string,
	name:string
}

export const LANG_TYPES:ILanguage[]     = [
	{langCode:1, langGis:"ko", i18Str:'ko',    full:'korean', htmlLang:'ko',  langCss:'kor',   tag:'koTag', title:'koTitle', title2:'koTitle', desc:'koDescription', ask:'koAsk', answer:'koAnswer', easypay:'KOR', prefix1:"ko", prefix2:"ko", download:"kor", address:"koAddress", position:"koPosition", worktime:"koWorktime", holiday:"koHoliday", codename:"codeName1", searchLang:"KO", searchPrefix1:"KO_", searchPrefix2:"KO_"},
	{langCode:2, langGis:"en", i18Str:'en',    full:'english', htmlLang:'en',  langCss:'eng',   tag:'enTag', title:'enTitle', title2:'enTitle', desc:'enDescription', ask:'enAsk', answer:'enAnswer', easypay:'ENG', prefix1:"en", prefix2:"en", download:"eng", address:"enAddress", position:"enPosition", worktime:"enWorktime", holiday:"enHoliday", codename:"codeName2", searchLang:"ENG", searchPrefix1:"EN_", searchPrefix2:"EN"},
	{langCode:3, langGis:"jp", i18Str:'ja',    full:'japanese', htmlLang:'ja',  langCss:'jpn',   tag:'jaTag', title:'jaTitle', title2:'jaTitle', desc:'jaDescription', ask:'jaAsk', answer:'jaAnswer', easypay:'JPN', prefix1:"ja", prefix2:"ja", download:"jpn", address:"jaAddress", position:"jaPosition", worktime:"jaWorktime", holiday:"jaHoliday", codename:"codeName3", searchLang:"JA", searchPrefix1:"JA_", searchPrefix2:"JA_"},
	{langCode:4, langGis:"cn", i18Str:'zhChs', full:'chinese', htmlLang:'zh-Hans', langCss:'ch01',  tag:'zhChsTag', title:'cnTitle', title2:'zhCnTitle', desc:'cnDescription', ask:'cnAsk', answer:'cnAnswer', easypay:'CHN', prefix1:"zhCn", prefix2:"cn", download:"chn", address:"zhCnAddress", position:"zhCnPosition", worktime:"zhCnWorktime", holiday:"zhCnHoliday", codename:"codeName4", searchLang:"GAN", searchPrefix1:"CN_", searchPrefix2:"ZH_CN_" },
	{langCode:5, langGis:"cw", i18Str:'zhCht', full:'tchinese', htmlLang:'zh-CN', langCss:'ch02',  tag:'zhChtTag', title:'twTitle', title2:'zhTwTitle', desc:'twDescription', ask:'twAsk', answer:'twAnswer', easypay:'CHN', prefix1:"zhTw", prefix2:"tw", download:"chn", address:"zhTwAddress", position:"zhTwPosition", worktime:"zhTwWorktime", holiday:"zhTwHoliday", codename:"codeName5", searchLang:"BUN", searchPrefix1:"TW_", searchPrefix2:"ZH_TW_"}
];


export const PRODUCT_INFO_TYPES:IProductInfoType[] = [
	{code:1,   time:"24h", passtypeUid:1, koTag:'실물패스',   enTag:"Card Pass",   jaTag:"実物パス",    zhChsTag:"实体卡",    zhChtTag:"实体卡"},
	{code:2,   time:"24h", passtypeUid:2, koTag:'모바일패스', enTag:"Mobile Pass", jaTag:"モバイル専用", zhChsTag:"手机移动版", zhChtTag:"手机移动版"},
	{code:1,   time:"48h", passtypeUid:3, koTag:'실물패스',   enTag:"Card Pass",   jaTag:"実物パス",    zhChsTag:"实体卡",    zhChtTag:"实体卡"},
	{code:2,   time:"48h", passtypeUid:4, koTag:'모바일패스', enTag:"Mobile Pass", jaTag:"モバイル専用", zhChsTag:"手机移动版", zhChtTag:"手机移动版"},
	{code:1,   time:"72h", passtypeUid:5, koTag:'실물패스',   enTag:"Card Pass",   jaTag:"実物パス",    zhChsTag:"实体卡",    zhChtTag:"实体卡"},
	{code:2,   time:"72h", passtypeUid:6, koTag:'모바일패스', enTag:"Mobile Pass", jaTag:"モバイル専用", zhChsTag:"手机移动版", zhChtTag:"手机移动版"},
  {code:1,   time:"120h", passtypeUid:7, koTag:'실물패스',   enTag:"Card Pass",   jaTag:"実物パス",    zhChsTag:"实体卡",    zhChtTag:"实体卡"},
	{code:2,   time:"120h", passtypeUid:8, koTag:'모바일패스', enTag:"Mobile Pass", jaTag:"モバイル専用", zhChsTag:"手机移动版", zhChtTag:"手机移动版"}
];


export interface GlobalInfo {
  activeGnb: boolean;
  activeAppMyPass: boolean;
  appHeader: boolean;
  sidebar: boolean;
  btn_top: boolean;
  activeEbookDownload: boolean;
  searchKeyword: string;
  setSearchKeyword: any;
  currentLangType: ILanguage;
  addBodyClass: any;
  removeBodyClass: any;
  handleSetLang: any;
  dspJwt: any;
  dspJwtExp: any;
  setDspJwt: any;
  getDspJwt: any; //cookie 상의 jwt 
  removeDspJwt: any;
  expireDspJwt: any;
}

interface PrivateProp {
  
}

export const globalInfoStore = observable<GlobalInfo>({
  activeGnb: false,
  activeAppMyPass: false,
  appHeader: false,
  sidebar: false,
  btn_top: false,
  activeEbookDownload: false,
  searchKeyword: "",
  setSearchKeyword: (searchKeyword:string ) => {
    globalInfoStore.searchKeyword = searchKeyword;
  },
  currentLangType: LANG_TYPES[1],
  addBodyClass: (className: any) => document.body.classList.add(className),
  removeBodyClass: (className: any) => document.body.classList.remove(className),
  handleSetLang: (langType:ILanguage ) => {

    if (langType == null || langType.langCode == null){
      throw "GlobalInfoStore handleSetLang Error!";
    }

    // setCurrentLang(langType); 
    globalInfoStore.currentLangType = langType;

    globalInfoStore.removeBodyClass(LANG_TYPES[0].langCss);
    globalInfoStore.removeBodyClass(LANG_TYPES[1].langCss);
    globalInfoStore.removeBodyClass(LANG_TYPES[2].langCss);
    globalInfoStore.removeBodyClass(LANG_TYPES[3].langCss);
    globalInfoStore.removeBodyClass(LANG_TYPES[4].langCss);
    if (langType == null || langType.langCode != 1) {
      globalInfoStore.addBodyClass(langType.langCss);
    }

    i18n.changeLanguage(langType.i18Str);
    // UseBodyClass(langType.langCss); //body에 css 적용
  },
  dspJwt: null,
  dspJwtExp: null,
  setDspJwt: async (jwtString:string ) => {
    if (jwtString.length < 30) {
      return;
    }
    const decodedJwt:any = jwt.decode(jwtString);
    const expires = new Date(0);
    expires.setUTCSeconds(decodedJwt.exp);
    expires.setUTCHours(expires.getUTCHours() - 9)
    // console.log("decodedJwt.exp" + decodedJwt.exp + "expires:" + expires + " Date: " + new Date(expires) + " new Date(expires).toISOString()" + new Date(expires).toISOString());
    // console.log (`jwtString: ${jwtString}, expireDate: ${expires.toUTCString()}`)

    const jwtValue = {jwtString:jwtString, expires: expires.toUTCString()}

    if(localStorage.isCookieUse === 'false') { 
      sessionStorage.setItem("dspJwt-localstorage", JSON.stringify(jwtValue));
    } else {
      localStorage.setItem("dspJwt-localstorage", JSON.stringify(jwtValue));
    }
    
    document.dispatchEvent(new Event('dspJwtUpdated'));
    globalInfoStore.dspJwt = jwtString;
  },
  getDspJwt: () => {
    if(localStorage.isCookieUse === 'false') { 
      const dspJwtStr = sessionStorage.getItem("dspJwt-localstorage");
      if (dspJwtStr == null || dspJwtStr == "") {
        return null
      }
      const dspJwt = JSON.parse(dspJwtStr);
      return dspJwt.jwtString;
    }

    globalInfoStore.expireDspJwt();
    if (globalInfoStore.dspJwt !== null && globalInfoStore.dspJwt !== "") {return globalInfoStore.dspJwt;}
    const dspJwtStr = localStorage.getItem("dspJwt-localstorage");

    if (dspJwtStr == null || dspJwtStr == "") {
      return null
    }
    const dspJwt = JSON.parse(dspJwtStr);
    return dspJwt.jwtString;
  },

  removeDspJwt: async () => {
      globalInfoStore.dspJwt = null;
      globalInfoStore.dspJwtExp = null;
      localStorage.removeItem("dspJwt-localstorage");
      Storage.remove({key:"dspJwt-storage"})

      if(localStorage.isCookieUse === 'false') { 
        sessionStorage.removeItem("dspJwt-localstorage");
      }
  },
  expireDspJwt: () => {
      const dspJwtStr = localStorage.getItem("dspJwt-localstorage");
      if (dspJwtStr == null) return;
      const dspJwt = JSON.parse(dspJwtStr);

      const expires = dspJwt.expires;  // expires obj를 체크 : 테스트용에 적합. Prod에서도 사용은 가능.
      // console.log(`new Date(expires) < new Date(): ${new Date(expires)} < ${new Date()}`)
      if (new Date(expires) < new Date()) {
          globalInfoStore.removeDspJwt();
      }
  },

});