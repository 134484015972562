import React, { Component, useEffect, useRef, forwardRef, LegacyRef, useCallback, useState, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router";
import Footer from "./main/Footer";
import Header from "./main/Header";

import {BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { PrivateRoute } from '../routes/PrivateRoute';
import CsIndex from './cs/CsIndex';
import EndRedirect from "./auth/EndRedirect";
import Main from "./main/Main";
import SpotIndex from "./spot/SpotIndex";
import SpotTransportationIndex from "./spot/TransportationIndex";
import SignInIndex from "./signin/SignInIndex";
import MypageIndex from "./mypage/MypageIndex";
import SupportPage from "./mypage/support/SupportPage";
import Pass from "./pass/Pass";
import GuideIndex from "./guide/GuideIndex";
import useStore from "../stores/UseRootStore";
import { observe } from "mobx";
// import Search from "./search/Search";
import SearchIndex from "./search/SearchIndex";
import Terms from "./policy/PolicyTerms";
import Privacy from "./policy/PolicyPrivacy";
import PolicyTerms from "./policy/PolicyTerms";
import PolicyPrivacy from "./policy/PolicyPrivacy";
import PolicyLocation from "./policy/PolicyLocation";
import PolicyIndex from "./policy/PolicyIndex";
import PassIndex from "./pass/PassIndex";
import ReactEnv from "../ReactEnv";

import { LANG_TYPES, ILanguage, ISiteLanguageInfo } from "../stores/GlobalInfoStore";

const classNames = require("classnames");



interface LayoutProps extends RouteComponentProps {
  // RefObject
}
const Layout : React.FC<LayoutProps> = (props: LayoutProps) => {
    const { t, i18n } = useTranslation();

    const { globalInfoStore } = useStore();
    const [lastScrollTop, setLastScrollTop] = useState(0);

    const [appHeader, setAppHeader] = useState(false);
    const [btnTop, setBtnTop] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const appWrap = useRef<HTMLDivElement>(null);
    const contentRef = useRef(null);

    const AppHeader = observe(globalInfoStore, "appHeader", (change) => {
      setAppHeader(Boolean(change.newValue));
    });

    const ButtonTop = observe(globalInfoStore, "btn_top", (change) => {
      setBtnTop(Boolean(change.newValue));
    });

    const Sidebar = observe(globalInfoStore, "sidebar", (change) => {
      setBtnTop(Boolean(change.newValue));
    });
  

    // const { t, i18n } = useTranslation();
    //   useEffect(() => { 
    //   if (contentRef.current && contentRef.current.getScrollElement) { 
    //     contentRef.current.getScrollElement().then(res => { 
    //       scrollRef.current = res; 
    //     }); 
    //   } 
    // }, [articles]); 

    useEffect(() => {
      // if (contentRef.current && contentRef.current.getScrollElement) {
      //   contentRef.current.getScrollElement().then((res) => {
      //     scrollRef.current = res;
      //   });
      // }
      // console.log("process.env.REACT_APP_ENV" + process.env.REACT_APP_ENV)

  }, [globalInfoStore.currentLangType]); 
  
    useEffect(() => {
        // if (contentRef.current && contentRef.current.getScrollElement) {
        //   contentRef.current.getScrollElement().then((res) => {
        //     scrollRef.current = res;
        //   });
        // }
        // console.log("process.env.REACT_APP_ENV" + process.env.REACT_APP_ENV)
        const query = new URLSearchParams(props.location.search);
        const lang = query.get('lang');
        if(lang) {
          var langNumber = 1; //ENG(Default)
          if(lang) {
            if(Number(lang) > 0)
              langNumber = Number(lang)-1;
          }
          globalInfoStore.handleSetLang(LANG_TYPES[langNumber])
        }
  
    }, [1]); 
  
    const filter = "win16|win32|win64|mac|macintel";
  
    const updateHeader = () => {
      // console.log("const updateHeader");
      // var nowScroll = scrollTop
      const nowScroll = window.scrollY;
        // console.log("scrollTop>>>>>>>>" + scrollTop);
  
        if ( navigator.platform ) { 
              if ( filter.indexOf( navigator.platform.toLowerCase() ) < 0 ) { 
                    //mobile
                    // alert('mobile 접속');  
                    
                  if(nowScroll >= 0 && nowScroll < 200){
  
                        // appHeader = false;
                        // sidebar = false;
                        // btn_top = false;
  
                      globalInfoStore.appHeader = false;
                      globalInfoStore.sidebar = false;
                      globalInfoStore.btn_top = false;
  
                  }else if(nowScroll >= 200){
  
                    globalInfoStore.appHeader = true;
                    globalInfoStore.sidebar = true;
  
                  };
                
                  if(nowScroll >= 3600){
                    globalInfoStore.btn_top = true;
                  }else{
                    globalInfoStore.btn_top = false;
                  }
  
  
              } else {
  
                    //pc alert('pc 접속'); 
                  if(nowScroll >= 0 && nowScroll < 1000){
  
                      // if(container_sub && nowScroll >= 300) { //원본 
                      if(nowScroll >= 300) {
                        globalInfoStore.appHeader = true;
                        globalInfoStore.sidebar = true;
                      }else{
                        globalInfoStore.appHeader = false;
                        globalInfoStore.sidebar = false;
                        globalInfoStore.btn_top = false;
                      }
  
                  }else if(nowScroll >= 1000){
  
                    globalInfoStore.appHeader = true;
                    globalInfoStore.sidebar = true;
  
                  };
                
                  if(nowScroll >= 1200){
                    globalInfoStore.btn_top = true;
                  }else{
                    globalInfoStore.btn_top = false;
                  }
  
                  
              } 
          }
    }
  
    //FIXME: 위로가기 버튼 구현
    const goToTop = (e: any) => {
        // console.log("e->" + e)
        // console.log(contentRef.current);
        // contentRef.current.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }

    // console.log("Layout.tsx:::" + new Date().toISOString())
  
    window.addEventListener("scroll", updateHeader)
    // <ion-content class="scroll-content md hydrated" style="--offset-top:0px; --offset-bottom:0px;"></ion-content>
    return (
      <>
      <Header></Header>
      {/* <button className={classNames({ btn_top: true, active: btnTop })} onClick={goToTop}>위로 가기</button> */}
        
        <div id="wrap" ref={appWrap}>
            
            <Route exact path="/app" render={() => <Redirect to="/app/main" />} />
            <Route exact path="/app/reactenv" component={ReactEnv} />
  
            <Route exact path="/app/policy"  render={()=><Redirect to="/app/policy/terms" />} />
            <Route path="/app/policy" component={PolicyIndex} />
            {/* <Route exact path="/app/policy/index/terms" component={PolicyTerms}  />
            <Route exact path="/app/policy/index/privacy" component={PolicyPrivacy}  />
            <Route exact path="/app/policy/index/location" component={PolicyLocation}  /> */}
  
            <Route path="/app/main"  component={Main}  />
            {/* <Route exact path="/app/landing"/> 인증샘플 */}
            <Route exact path="/app/search" render={()=><Redirect to="/app/search/index" />} />
            <Route path="/app/search"  component={SearchIndex} />
            <Route exact path="/app/searchV1" render={()=><Redirect to="/app/searchV1/index" />} />
            <Route path="/app/searchV1"  component={SearchIndex} />
            {/* <Route path="/app/login" render={() => <Redirect to="/app/landing" />} /> */}
            
            <Route exact path="/app/cs" render={() => <Redirect to="/app/cs/index" />} />
            <Route exact path="/app/cs/index" render={() => <Redirect to="/app/cs/index/faq" />} />
            {/* <PrivateRoute exact  path="/app/home" component={Home} /> */}
            {/* <PrivateRoute exact  path="/app/home/facebook" component={HomeFacebook} /> */}

            <Route exact path="/app/endredirect" component={EndRedirect} />
  
            <Route path="/app/cs/index" component={CsIndex} />
            <Route exact path="/app/signin" render={()=><Redirect to="/app/signin/index/login" />} />
            <Route path="/app/signin/index" component={SignInIndex} />
            {/* <Route path="/app/signin/index/login" component={SignInIndex} /> */}
            
            <Route exact path="/app/spot" render={()=><Redirect to="/app/spot/index/list" />} />
            <Route path="/app/spot" component={SpotIndex} />

            <Route exact path="/app/spot/transportation" render={()=><Redirect to="/app/spot/transportation/index" />} />

            <Route exact path="/app/spot/others" render={()=><Redirect to="/app/spot/others/index" />} />
            
            {/* <Route exact path="/app/mypage" render={()=><Redirect to="/app/mypage/index" />} /> */}
            {/* <Route exact path="/app/mypage/index" component={MypageIndex} /> */}
            {/* <Route exact path="/app/mypage" component={MypageIndex} /> */}
            {/* <Route exact path="/app/mypage/index" component={MypageIndex} /> */}
            {/* <Route exact path="/app/mypage/profile" render={() => <Redirect to="/app/mypage/profile/index" />} /> */}
            {/* <Route exact path="/app/mypage/interest" render={() => <Redirect to="/app/mypage/interest/spot" />} /> */}

            <Route exact path="/app/spot/rcourse" render={()=><Redirect to="/app/spot/rcourse/index/list" />} />
            <Route exact path="/app/spot/rcourse" component={SpotIndex} />
            
            <Route exact path="/app/spot/guide" render={()=><Redirect to="/app/spot/guide/index" />} />
            <Route exact path="/app/spot/guide" component={SpotTransportationIndex} />

            <Route exact path="/app/mypage" render={() => <Redirect to="/app/mypage/menu" />} />
            <Route exact path="/app/mypage/profile" render={() => <Redirect to="/app/mypage/profile/index" />} />
            <Route exact path="/app/mypage/interest" render={() => <Redirect to="/app/mypage/interest/spot" />} />
            <Route exact path="/app/mypage/support" render={() => <Redirect to="/app/mypage/support/index" />} />
            <Route path="/app/mypage" component={MypageIndex} />
            
            
            
            {/* <Route path="/app/mypage/interest" component={Interest} /> */}
            
            {/* <Route exact path="/app/mypage/profile/password" component={Profile} /> */}
            <Route exact path="/app/guide" render={()=><Redirect to="/app/guide/index/passguide" />} />
            <Route path="/app/guide/index" component={GuideIndex} />
            <Route path="/app/pass" component={PassIndex} />
          {/* <!-- //container --> */}
          
          <Footer />
        </div>
      {/* </IonContent> */}
      
      </>
    );
  
}

export default Layout;
