import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
// import useDspLocalStorage from "../../services/UseDspLocalStorage";
import AxiosConfig from '../../services/AxiosConfig';
import { IProductInfo } from "../../services/ProductInfoService";
import { getValueByKey } from "../../services/Util";
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import { useLocalStorage } from "react-use";

const classNames = require("classnames");

const PassListItem = (props:{productItem:IProductInfo}) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [likeActive, setLikeActive] = useState(false);
    const [recentPass, setRecentPass, removeRecentPass] = useLocalStorage('recentPass', []);
    if (props.productItem.code == 1 || props.productItem.code == 2) {  // 1: 일반권, 2: 모바일권
        // continue;
    }

    /** 
     * @param productUid
     * @return null
     * @response {   "code": "200",   "contentsUid": 40,   "favoriteUid": 1,   "desc": "정상" }
     */
    const postLikePass = async () => {
      var data = JSON.stringify({
        productUid: props.productItem.uid,
      });

      const res = await AxiosConfig.post("/api2/myFavoriteProductInfo", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.status == 200 && res.data.code == "200") {
        props.productItem.favoriteUid = 1; //
        setLikeActive(true);
      } else {
        console.error(res.status + " " + JSON.stringify(res.data));
      }
    };

    /** 
     * @param productUid
     * @return null
     * @response {  "code": "200",  "desc": "정상"}
     */
    const deleteLikePass = async () => {
      const res = await AxiosConfig.delete(
        `/api2/myFavoriteProductInfo/${props.productItem.uid}`
      );

      if (res.status == 200 && res.data.code == "200") {

        props.productItem.favoriteUid = 0;
        setLikeActive(false);

      } else {
        console.error(res.status + " " + JSON.stringify(res.data));
      }
    };
    
    const handleLikePass = async (e:any) => {
        if (props.productItem.favoriteUid == 1) {
          deleteLikePass()
        } else {
          postLikePass()
        }
    };

    /** 삭제는 사이드바 Item에서 처리. */
    // const removeRecentPass = (item:any) => {}

    const RECENT_SIZE = 12;

    const addRecentPass = (item:any) => {
        if (recentPass.length < 1) {
            recentPass.push(item)
        } else {
            recentPass.unshift(item)
        }

        if (recentPass.length > RECENT_SIZE){
            recentPass.pop()
        }
        setRecentPass(recentPass);
    }

    const handlePassDetail = (item:any) => {
        // setValue(item)
        addRecentPass(item);

        history.push("/app/pass/view/"+item.uid)
    }





    useEffect(() => {
        if (props.productItem.favoriteUid > 0) {
            setLikeActive(true)
        } else {
            setLikeActive(false)
        }
    }, [globalInfoStore.currentLangType, likeActive]);

    const getExplainPass = () => {
         return t("pass.freecount_prefix") + " " + props.productItem.freeCount + t("pass.freecount_postfix") + " / " + t("pass.discount_prefix") + " " + props.productItem.disCount + " " + t("pass.discount_postfix");
        //  {t("pass.freecount_prefix")} {props.productItem.freeCount}{t("pass.freecount_postfix")}  / {t("pass.discount_prefix")} {props.productItem.disCount}{t("pass.discount_postfix")}
    }


    return (
        <dl>
            
            <dt>
                <span className={'time_tag tag' + props.productItem.passtypeTime} ><em>{props.productItem.passtypeTime}</em> H</span>
                {/* <p className="sort_tag">{item.code == 1 ? '실물패스': '모바일패스'}</p> */}
                <p className="sort_tag">{props.productItem.code == 1 ? t("main.card_pass"): t("main.mobile_pass")}</p>

                <div className="imgArea">
                    {/* <img src={props.productItem.fullpath} alt="카드 실물 이미지입니다." /> */}
                    <img src={props.productItem.fullpath} alt="카드 실물 이미지입니다." />

                    <div className={classNames({like:true, on:(props.productItem.favoriteUid > 0? true: false)})} onClick={(e)=>handleLikePass(e)}>좋아요</div>
                </div>

                {/* <p className="name">{props.productItem[globalInfoStore.currentLangType.title2]}</p> */}
                <p className="name">{getValueByKey(props.productItem, globalInfoStore.currentLangType.title2)}</p>
                <p className="exp" dangerouslySetInnerHTML={{__html:getExplainPass()}}></p>
                <p className="price">KRW <em>{props.productItem.salePrice.toLocaleString()}</em></p>
            </dt>
            
            {props.productItem.status==1?
            // <dd className={classNames({soldOut:props.productItem.status==2})} >{props.productItem.status==1?t("pass.buy_now") :"SOLD OUT"}</dd>
            <dd onClick={()=>handlePassDetail(props.productItem)} key={props.productItem.uid} >{t("pass.buy_now")}</dd>
            :<dd className="soldOut" >{"SOLD OUT"}</dd>
            }

            {/* <dd className={classNames({soldOut:props.productItem.status==2})}>{props.productItem.status==1?t("pass.buy_now") :"SOLD OUT"}</dd> */}
        </dl>
        //FIXME: 재고없음 처리 필요.
        // <dl className="soldOut">
        // <dt>
        //     <span className="time_tag tag72"><em>72</em> H</span>
        //     <p className="sort_tag">실물패스</p>

        //     <div className="imgArea">
        //         <img src="img/passImg07.png" alt="카드 실물 이미지입니다." />
        //         <div className="like">좋아요</div>
        //     </div>

        //     <p className="name">디스커버서울패스</p>
        //     <p className="exp">무료입장 21개소 / 할인 80개소</p>
        //     <p className="price">KRW <em>70,900</em></p>
        // </dt>
        // <dd className="soldOut">SOLD OUT</dd> 
        // </dl>
    )
   

};

export default PassListItem;
