import { isPlatform } from "@ionic/react";
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import GlobalNavBar from "./GlobalNavBar";

const classNames = require("classnames");

type Props = { children: React.ReactNode; type: "submit" | "button" };


const Header:React.FC = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [width, setWidth] = useState(window.innerWidth);

  const toggleGnb = () => {
	  globalInfoStore.activeGnb = !globalInfoStore.activeGnb;
  }

	const goToHome = () => {
    globalInfoStore.activeGnb = false;
    history.replace("/app/main")
  }

  const MyPass = () => {

    if ((process.env.REACT_APP_ENV === "staging") 
       || (process.env.REACT_APP_ENV === "local") 
       || (process.env.REACT_APP_ENV === "development") 
       || (isPlatform("capacitor") && isPlatform("android"))
       || (isPlatform("capacitor") && isPlatform("ios"))) {
      return (
        <>
        {/* <button className="btn_myPass m_only" onClick={goToAppMyPass}>MY PASS</button> */}
        </>
      )
    }
    return (null);
  }

  const goToAppMyPass = (e:any) => {
    // console.log("location.pathname:::" + location.pathname);
    if ("/app/signin/index/login" === location.pathname) {
      return;
    }
    history.push("/appmypass")
  }

  const goToAppMyPassRegister = (e:any) => {
    // console.log("location.pathname:::" + location.pathname);
    if ("/app/signin/index/login" === location.pathname) {
      return;
    }
    history.push("/appmypass/register")
  }

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
      // console.log("updating Width");
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions) 
  }, []);

  const HeaderLogo = () => {
    // console.log("location.pathname:" + ("/app/main" === location.pathname) + " width:" + width);

    if ("/app/main" === location.pathname || width >= 750) {
      return (
        <h1 className={classNames({logo:true, active: globalInfoStore.appHeader })}>
          <a onClick={()=>{goToHome()}} >discover seoul pass</a>
        </h1>
      )
    }
    
    return (
        <a className={classNames({btn_back:true, active: globalInfoStore.appHeader })} onClick={()=>{history.goBack()}}>뒤로가기</a>
    );
  }

  const SeoulLogo = () => {
    return (
      <p className={classNames({seoul_logo:true, active: globalInfoStore.appHeader })}></p>
    )
  }

  // render() {
    //TODO: 모바일만 동작 반응형에서 체크 필요.
    return (
      // <header className={classNames({})}>
      // <header className={classNames({ sub:true, active: globalInfoStore.appHeader })}>
      <header className={classNames({ active: globalInfoStore.appHeader })}>

        <HeaderLogo></HeaderLogo>
        
        <SeoulLogo></SeoulLogo>

        <MyPass></MyPass>
        <button
          className={classNames({
            btn_menu: true,
            active: globalInfoStore.activeGnb,
          })}
          onClick={toggleGnb} >
          메뉴 버튼
        </button>
        
        {globalInfoStore.activeGnb && <GlobalNavBar />}
      </header>
    )
  // }
};

export default observer(Header);
// export default Header;
