import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { globalInfoStore } from '../../../stores/GlobalInfoStore';
import Moment from 'react-moment';
import { IMyEsim } from './MyEsim';
import { getValueByKey, isProduction } from '../../../services/Util';
import { ICodeInfo } from '../../../services/CodeInfoService';

const classNames = require("classnames");

interface MyEsimItemProps {
    myEsimItem:IMyEsim;
    filteredCodeInfoes:ICodeInfo[];
    click: any;
}


const MyEsimItem: React.FC<MyEsimItemProps> = (props: MyEsimItemProps) => {
    const { t, i18n } = useTranslation();

    let isRefund = false;

    const handleClick = () => {
        // props.click(); //부모 호출
    }

    const getCodeInfo = (codeId:number) => {
        const codeInfo:ICodeInfo = props.filteredCodeInfoes.find((item:ICodeInfo) => {
            return item.codeId === codeId;
        });
        if (codeInfo == null) {
            console.error("category type is null");
            return "codeinfo null";
        }
        return getValueByKey(codeInfo, globalInfoStore.currentLangType.codename);
    }

    
    const getEStatus = (estatus: number) => {
        switch (estatus) {
            case 1: // 구매완료
                isRefund = true;
                return t("how_eSIM.complete");
            case 3: // 환불완료
                isRefund = true;
                return t("how_eSIM.refund");;
            default:
                return "";
        }
    }

    const getEStatusCss = (estatus: number) => {
        switch (estatus) {
            case 1: // 구매완료
                return false;
            case 3: // 환불완료
                return true;
            default:
                return false;
        }
    }


    return (

        <div className="purchase">
            <p className="esim_num">
                <span>{t("how_eSIM.iccid_num")}</span>
                <span>{props.myEsimItem.iccid_num}</span>
            </p>

            <p className="esimdate">
                {t("order_history.buy_date")}&nbsp;&nbsp;{" "}
                <strong>{props.myEsimItem.prchs_dtm}</strong>
                &nbsp;
                Asia/Seoul
            </p>

            <p className="esimdate">
                <span className={classNames({banner:true, time24:true, time72:getEStatusCss(props.myEsimItem.estatus)})}>{getEStatus(props.myEsimItem.estatus)}</span>
            </p>

            <p className="esim_tit1">
                <span>{t("sign_up_with_email.name")}&nbsp;: </span>
                <span>{props.myEsimItem.cust_nm}</span>
            </p>
            <p className="esim_tit2">
                <span>{t("login.email_address")}&nbsp;: </span>
                <span>{props.myEsimItem.email_addr}</span>
            </p>
            <p className="esim_tit2">
                <span>{t("how_eSIM.roming_phon_num")}&nbsp;: </span>
                <span>{props.myEsimItem.roming_phon_num}</span>
            </p>
            <p className="esim_tit2">
                <span>{t("how_eSIM.rental_fee_prod_nm")}&nbsp;: </span>
                <span>{props.myEsimItem.rental_fee_prod_nm}</span>
            </p>
            <p className="esim_tit2">
                <span>DSP QR No.&nbsp; </span>
                <span>{props.myEsimItem.qr_code == null ? props.myEsimItem.qr_mgmt_code: props.myEsimItem.qr_code}</span>
            </p>

        </div>

    );
};



export default MyEsimItem;