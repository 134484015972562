import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { Link } from "react-router-dom";
import GuideHowFreeEntry from './GuideHowFreeEntry';
import GuideHowCoupon from './GuideHowCoupon';
import GuideHowGift from './GuideHowGift';
import GuideHowRegisterPass from "./GuideHowRegisterPass";
//import ExpireChecker from "../cs/ExpireChecker";

const classNames = require("classnames");

const GuideHowIndexNV = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    
    const GUIDE_HOW_01 = "/appnv/guide/index/how/freeentry";
	const GUIDE_HOW_02 = "/appnv/guide/index/how/coupon";
    const GUIDE_HOW_03 = "/appnv/guide/index/how/gift";
    const GUIDE_HOW_04 = "/appnv/guide/index/how/registerpass";

    const [isShowMobileTabMenu, setIsShowMobileTabMenu] = useState(false);

    

    const [filterName, setFilterName] = useState('');

    const isSubMenuActive = (activeName:string) => {
		if ( activeName == location.pathname ) {
			return true;
		} 
		return false;
    }
    
    const handleSetFilterName = (filterName:string) => {
		setFilterName(filterName);
    }
    
    const toggleMobileTabMenu = () => {
		setIsShowMobileTabMenu(!isShowMobileTabMenu);
	}


    return (
        <div className="layout">

            {/* <div className={classNames({use_tab:true, m_active:isShowMobileTabMenu})} onClick={toggleMobileTabMenu}>

                {isSubMenuActive(GUIDE_HOW_01)?<span className="m_only">{t("guide.free_entry")}</span>:""}
                {isSubMenuActive(GUIDE_HOW_02)?<span className="m_only">{t("guide.coupon")}</span>:""}
                {isSubMenuActive(GUIDE_HOW_03)?<span className="m_only">{t("guide.gift")}</span>:""}
                {isSubMenuActive(GUIDE_HOW_04)?<span className="m_only">{t("guide.register_pass")}</span>:""}
                
                <div className="m_bg">
                    <Link to="/appnv/guide/index/how/freeentry" className={classNames({active:isSubMenuActive(GUIDE_HOW_01)})}>{t("guide.free_entry")}</Link>
                    <Link to="/appnv/guide/index/how/coupon" className={classNames({active:isSubMenuActive(GUIDE_HOW_02)})}>{t("guide.coupon")}</Link>
                    <Link to="/appnv/guide/index/how/gift" className={classNames({active:isSubMenuActive(GUIDE_HOW_03)})}>{t("guide.gift")}</Link>
                    <Link to="/appnv/guide/index/how/registerpass" className={classNames({active:isSubMenuActive(GUIDE_HOW_04)})}>{t("guide.register_pass")}</Link>
                </div>
            </div> */}

            <Switch>
                <Route exact path="/appnv/guide/index/how" render={()=><Redirect to="/app/guide/index/how/freeentry"/>} />
                <Route path="/appnv/guide/index/how/freeentry" component={GuideHowFreeEntry} />
                <Route path="/appnv/guide/index/how/coupon" component={GuideHowCoupon} />
                <Route path="/appnv/guide/index/how/gift" component={GuideHowGift} />
                <Route path="/appnv/guide/index/how/registerpass" component={GuideHowRegisterPass} />
                {/* <Route path="/appnv/guide/index/how/expirechecker" component={ExpireChecker} /> */}
            </Switch>
         </div>
    );
};

export default GuideHowIndexNV;