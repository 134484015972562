import { FacebookLoginResponse } from '@capacitor-community/facebook-login';
import { Plugins } from '@capacitor/core';
import "@codetrix-studio/capacitor-google-auth";
import { AppleSignInErrorResponse, AppleSignInResponse, ASAuthorizationAppleIDRequest, SignInWithApple } from '@ionic-native/sign-in-with-apple';
import { IonToast, isPlatform } from '@ionic/react';
import { AuthActionBuilder, AuthObserver } from "ionic-appauth";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from 'react-router';
import { AuthFacebook4 } from "../../services/AuthServiceFacebook4";
import { AuthGoogle4 } from "../../services/AuthServiceGoogle4";
// import { AuthGoogleOIDC } from "../../services/AuthServiceGoogleOIDC";
import { AuthWeibo2 } from "../../services/AuthServiceWeibo";
import AxiosConfig from '../../services/AxiosConfig';
import { globalInfoStore } from '../../stores/GlobalInfoStore';
import useDspLocalStorage from "../../services/UseDspLocalStorage";
import ReactGA from 'react-ga';


const { FacebookLogin } = Plugins;

interface SignUpProps extends RouteComponentProps {}

const SignUp : React.FC<SignUpProps> = (props: SignUpProps) => {

    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [redirectPage, setRedirectPage, removeRedirectPage] = useDspLocalStorage('redirectPage', {});
    const [showToast1, setShowToast1] = useState(false);
    const [showToast1Msg, setShowToast1Msg] = useState("");
 //   const [value, updateCookie, deleteCookie] = useCookie('dspJwt');

    const goToLoginPage = () => {
        history.push("/app/signin/index/login");
    }

    const goToSignUpWithEmail = () => {

        ReactGA.event({
            category: "이메일회원가입페이지",
            action: "이메일회원가입클릭",
            label: "Sign up",
          });

        history.push("/app/signin/index/signup/withemail");
    }

    // const goToSignUpWithFacebook = () => {
    //     history.push("/app/signin/index/signup/withsns"); // facebook
    // }

    // const goToSignUpWithGoogle = () => {
    //     history.push("/app/signin/index/signup/withsns"); // google
    // }

    // const goToSignUpWithWeibo = () => {
    //     history.push("/app/signin/index/signup/withsns"); // weibo
    // }


    const [action, setAction] = useState(AuthActionBuilder.Default);
    
    let observerGoogleOIDC: AuthObserver;
    // let observerGoogle: AuthObserver;
    let observerFacebook: AuthObserver;
    let observerWeibo: AuthObserver;


    useEffect(() => {


        // observerGoogleOIDC = AuthGoogleOID4.Instance.addActionListener((action) => {
        //     setAction(action)
        //     if(action.action === AuthActions.SignInSuccess || 
        //         action.action === AuthActions.LoadTokenFromStorageSuccess){
        //     //   props.history.replace('/home');
        //     }
        // });

        // observerGoogle = AuthGoogle.Instance.addActionListener((action) => {
        //     setAction(action)
        //     if(action.action === AuthActions.SignInSuccess || 
        //         action.action === AuthActions.LoadTokenFromStorageSuccess){
        //       props.history.replace('/home');
        //     }
        // });

        // observerFacebook = AuthFacebook4.Instance.addActionListener((action) => {
        //     setAction(action)
        //     if(action.action === AuthActions.SignInSuccess || 
        //         action.action === AuthActions.LoadTokenFromStorageSuccess){
        //         // props.history.replace('/home/facebook');
        //     }
        // });

        // observerWeibo = AuthWeibo2.Instance.addActionListener((action) => {
        //     setAction(action)
        //     if(action.action === AuthActions.SignInSuccess || 
        //         action.action === AuthActions.LoadTokenFromStorageSuccess){
        //     //   props.history.replace('/home/weibo');
        //     }
        // });
        return () => {
            // Auth.Instance.removeActionObserver(observer);
            // AuthGoogleOID4.Instance.removeActionObserver(observerGoogleOIDC);
            // AuthGoogle.Instance.removeActionObserver(observerGoogle);
            // AuthFacebook4.Instance.removeActionObserver(observerFacebook);
            console.log("SignUp:: callbackUrl6 useIonViewDidLeave removed!!!!!!")
            // AuthWeibo2.Instance.removeActionObserver(observerWeibo);
        }
    }, []);


    const postCheckAccessToken = async (accessToken:string, snsType:string) => {
      let url = `/api/auth/oauth/${snsType}/profile/jwt`;
      var data = JSON.stringify({
          "accessToken":accessToken
      })
      const res = await AxiosConfig.post(url,
          data,
          {
            headers: {
                "Content-Type": "application/json",
            },
          }
      );
    //   setResp(res.data);
      console.log(res.data);
    // ERROR_ACCESS_TOKEN_UNAVAILABLE("-200", "엑세스토큰이 유효하지 않음"),
    // SIGN_UP_NEEDED("210", "회원가입필요"),
    // SIGN_UP_ALREADY_MEMBER("220", "이미 가입된 회원");

      if (res.status === 200 ) {
        // if (res.data.code === "220") {
        //   alert("Already Member. Please Login.")
        //   history.replace("/app/signin/index/login")

        if (res.data.jwt != null && res.data.jwt != "") {
            await globalInfoStore.setDspJwt(res.data.jwt)
            history.replace(redirectPage);//history.push('/app/mypage')
            setShowToast1Msg("로그인 하였습니다.");
            setShowToast1(true);
        } else if (res.data.code === "210") {

            let snsId = "";
            if (snsType === "facebook") {
                snsId = res.data.memberInfo.facebookToken;
            } else if (snsType === "google") {
                snsId = res.data.memberInfo.googleToken;
            } else if (snsType === "weibo") {
                snsId = res.data.memberInfo.weiboToken;
            } else if (snsType === "apple") {
                snsId = res.data.memberInfo.appleToken;
            }

            // alert("SNS 회원가입화면으로 이동합니다.")
            history.replace({
                pathname: `/app/signin/index/signup/withsns`,
                state: {
                    snsType: snsType,
                    snsId: snsId,
                    name: res.data.memberInfo.name,
                    email: res.data.memberInfo.email,
                },
            });
         
        } else if (res.data.code == "230") { // 사용중인 email
          alert("Email Address is taken. Try another.");
        }
      } else {
        console.error("postCheckAccessToken:: error"  + JSON.stringify(res));
      }
      
      
    }
 
    // function handleSignUp(e: any) {
    //     e.preventDefault();
    //     Auth.Instance.signIn();
    // }
    
    // function handleSignUpWithGoogleOIDC(e: any) {
    //     e.preventDefault();
    //     alert("coming soon.");
    //     AuthGoogleOID4.Instance.loadTokenFromStorage();
    //     AuthGoogleOID4.Instance.signIn()
    // }

    const handleSignUpWithGoogle = async (e: any) => {
        e.preventDefault();
        console.log("handleSignUpWithGoogle:::: 111")
        if (isPlatform('capacitor')) { //&& isPlatform('android')) {
            // signInGoogleCapacitor();
            console.log("handleSignUpWithGoogle:::: 222");
            // {
            //     authentication: {
            //         accessToken: "ya29.a0AfH...........1S4iyO3o", 
            //         idToken: "eyJhbGci..........YuSk9v4Q8"
            //     }
            //     email: "askurios8@gmail.com",
            //     familyName: "8",
            //     givenName: "askurios",
            //     id: "109374807689818605896",
            //     ....
            // }

            const googleUser = await Plugins.GoogleAuth.signIn() as any;
            console.log("handleSignUpWithGoogle:::: 333::: googleUser::: ", googleUser);
            if (googleUser) {
                // setShowToast1(true);
                // setShowToast1Msg("googleUser::" + googleUser.email);
                // console.log("serverAuthCode", googleUser.serverAuthCode);
                // console.log("idToken", googleUser.idToken);
                // console.log("authentication", googleUser.authentication);
                // console.log("displayName", googleUser.displayName);
                // console.log("email", googleUser.email);
                // console.log("familyName", googleUser.familyName);
                // console.log("givenName", googleUser.givenName);
                // console.log("id", googleUser.id);
                // console.log("imageUrl", googleUser.photoUrl);
                if (isPlatform("capacitor") && isPlatform("ios")) {
                    postCheckAccessToken(googleUser.authentication.idToken, "googlejwt");
                } else {
                    postCheckAccessToken(googleUser.idToken, "googlejwt");
                }
                // history.push({
                //     pathname: '/home',
                //     state: { name: googleUser.name || googleUser.displayName, image: googleUser.imageUrl, email: googleUser.email }
                // });
            }
            console.log("handleSignUpWithGoogle:::: 444")

        // } else if (isPlatform('capacitor') && isPlatform('ios')) {
        //     // signInGoogleCapacitor();
        //     const googleUser = await Plugins.GoogleAuth.signIn(null) as any;
        //     console.log('my user: ', googleUser);
        } else {//웹
            console.log("handleSignUpWithGoogle:::: 555")
            AuthGoogle4.Instance().loadTokenFromStorage();
            AuthGoogle4.Instance().signIn()
        }
        console.log("handleSignUpWithGoogle:::: 666")
    }

    const signUpFacebookCapacitor = async () => {

        const FACEBOOK_PERMISSIONS = ['email', 'user_birthday', 'user_photos', 'user_gender'];
        const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS }) as FacebookLoginResponse;

        if (result.accessToken) {
        // Login successful.
            console.log(`Facebook access token is ${result.accessToken.token}`);
            postCheckAccessToken(result.accessToken.token, "facebook");
        } else {
             // Cancelled by user.
        }
    }
    
    function handleSignUpWithFacebook(e: any) {
        e.preventDefault();
        if (isPlatform('capacitor') && isPlatform('android')) {
            signUpFacebookCapacitor();
        } else if (isPlatform('capacitor') && isPlatform('ios')) {
            signUpFacebookCapacitor();
        } else {//웹
            AuthFacebook4.Instance().loadTokenFromStorage();
            AuthFacebook4.Instance().signIn();
        }
    }

    function handleSignUpWithApple(e: any) {
        e.preventDefault();
        SignInWithApple.signin({
            requestedScopes: [
            ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
            ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
            ]
        })
        .then((res: AppleSignInResponse) => {
            // https://developer.apple.com/documentation/signinwithapplerestapi/verifying_a_user
            // alert('Send token to apple for verification: ' + res.identityToken);
            postCheckAccessToken(res.identityToken, "apple");
            console.log(res);
        })
        .catch((error: AppleSignInErrorResponse) => {
            // alert(error.code + ' ' + error.localizedDescription + ' ' + error.localizedFailureReason);

            console.error(error);
            // if (error.code == 1000) { // 1000 ASAuthorizationErrorUnknown   -  authorization attempt failed for an unknown reason
            //     alert("authorization attempt failed for an unknown reason")
            // } else 
            if (error.code == 1001) { // 1001 ASAuthorizationErrorCanceled   -  user cancelled
                alert("user cancelled")
            } else if (error.code == 1002) { // 1002 ASAuthorizationErrorInvalidResponse   -  authorization request received an invalid response.
                alert("authorization request received an invalid response.")
            } else if (error.code == 1003) { // 1003 ASAuthorizationErrorNotHandled   -  user cancelled
                alert("user cancelled")
            } else { // 1000 and others
                alert("authorization attempt failed for an unknown reason")
            }

            // Failure Callback Data Example
            // error: string "ASAUTHORIZATION_ERROR"
            // code: string 1001
            // localizedDescription: string "The operation couldn’t be completed. (com.apple.AuthenticationServices.AuthorizationError error 1001.)"
            // localizedFailureReason: string ""
            // Error Type
            // 1000 ASAuthorizationErrorUnknown   -  authorization attempt failed for an unknown reason
            // 1001 ASAuthorizationErrorCanceled   -  user cancelled
            // 1002 ASAuthorizationErrorInvalidResponse   -  authorization request received an invalid response.
            // 1003 ASAuthorizationErrorNotHandled   -  user cancelled

            // alert("Failed to Apple Login.")
            
        });
    }

    function handleSignUpWithWeibo(e: any) {
        e.preventDefault();
        AuthWeibo2.Instance.loadTokenFromStorage();
        AuthWeibo2.Instance.signUp();
        // WeiboSDK.checkClientInstalled(function () {
        //     alert('client is installed');

        // }, function () {
        //     alert('client is not installed');
        // });

        // WeiboSDK.ssoLogin(function (args) {
        //     alert('access token is ' + args.access_token);
        //     alert('userId is ' + args.userId);
        //     alert('expires_time is ' + new Date(parseInt(args.expires_time)) + ' TimeStamp is ' + args.expires_time);
        //     }, function (failReason) {
        //     alert(failReason);
        //     });
    }

    const SnsSignUpButton = () => {
        // if (process.env.REACT_APP_ENV === 'production' && isPlatform("capacitor") && isPlatform("ios")) {
        //     return null;
        // }
        return (
            <dl>
                <dt>{t("sign_up.social_media")}</dt>
                <dd className="sns">
                    <button className="facebook" onClick={(e) => handleSignUpWithFacebook(e)} >{t("login.signin_with_facebook")}</button>
                    <button className="google" onClick={(e) => handleSignUpWithGoogle(e)} >{t("login.signin_with_google")}</button>
                    {/* <button className="weibo" onClick={(e) => handleSignUpWithWeibo(e)} >{t("login.signin_with_weibo")}</button> */}
                    {isPlatform("capacitor") && isPlatform("ios")?
                    <button className="apple" onClick={(e) => handleSignUpWithApple(e)} >{t("login.signin_with_apple")}</button>
                    :<></>}
                </dd>
            </dl>
        )
    }

    return (
        <div className="layout">
            <IonToast
                isOpen={showToast1}
                onDidDismiss={() => setShowToast1(false)}
                message={showToast1Msg}
                duration={3000}
            />

            <div className="wrap_info join">
                <h6 className="sec_tit side">{t("sign_up.sec_title")}</h6>

                {/* ios SNS 회원가입 문제 해결 전에 스토어 업데이트를 하기 위해 SNS 로그인을 제외-임시 */}
                {isPlatform("capacitor") && isPlatform("ios")?
                    <></>
                    : <SnsSignUpButton></SnsSignUpButton>}
                
                
                <dl>
                    <dt>{t("sign_up.email")}</dt>
                    <dd>
                        <button className="btn_join" onClick={goToSignUpWithEmail}>{t("sign_up.sign_up_with_email")}</button>
                    </dd>
                </dl>
                {/* window.open("http://www.sto.or.kr/index", "_blank");
      } else {
        window.open("http://www.sto.or.kr/english/index", "_blank"); */}
                {/* <p className="exp">소셜 계정 로그인 또는 이메일로 회원가입하시면 디스커버서울패스의 <Link to="/app/policy/terms">이용약관</Link>, <Link to="/app/policy/privacy">개인정보취급방침</Link> 및 <Link to="/app/policy/location">위치정보서비스 약관</Link> 제3자 제공에 관하여 동의하는 것입니다.</p> */}
                <p className="exp" dangerouslySetInnerHTML={{__html:t("sign_up.exp")}}>
                </p>
                    
                <ul className="etc">
                    <li>
                        <span dangerouslySetInnerHTML={{__html:t("sign_up.already_account")}}></span>
                        <button className="btn_login" onClick={goToLoginPage}>{t("sign_up.sign_in")}</button>
                    </li>
                </ul>
            </div>

        </div>
    );
};

export default SignUp;