import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import { Link, useHistory, useLocation } from "react-router-dom";
import AxiosConfig from '../../../services/AxiosConfig';
import { IProductInfo } from "../../../services/ProductInfoService";
import PassListItem from "../../pass/PassListItem";

interface PassInfo  {
    [key: string]: any;
}

const InterestPass = () => {
    const { t, i18n } = useTranslation();
    // const location = useLocation();
    // const history = useHistory();

    const [passInfo, setPassInfo] = useState<PassInfo>();
    const [filteredPassInfo, setFilteredPassInfo] = useState<IProductInfo[]>([]);
    const [page, setPage] = useState(1); //page
	const [size, setSize] = useState(12);  //

    const fetchBookmarkPassInfo = async (page:number, size:number, sorts:[]) => {
        let url = `/api2/productInfoWithDiscount?page=${page}&size=${size}&isMyPass=1`;

        if (sorts.length > 0){
            url += (sorts || []).map((item)=>`&sort=${item}`);
        }

        const res = await AxiosConfig.get(url);

        setPassInfo(res.data);
        setFilteredPassInfo(res.data.content)
    };

    useEffect(() => {
        fetchBookmarkPassInfo(page, size, [])
        window.scrollTo(0, 0);
    }, []);

    const handlePageClick = (pageNumber: any) => {
		setPage(pageNumber)
	};


    const handleLikeClick = (e:any) => {
        alert("handleLikeClick")
    }

    const PassPager = () => {

        if (passInfo == undefined || passInfo.totalPages < 2)
            return (<></>)

        return (
            <Pagination
                innerClass={'board_pager'}
                // activeClass={'active'}
                // activeLinkClass={'active'}
                activePage={page}
                itemsCountPerPage={size}
                totalItemsCount={passInfo.totalElements}
                pageRangeDisplayed={5}
                onChange={(pageNumber)=>handlePageClick(pageNumber)}
                itemClassFirst={'btn_pprv'}
                itemClassPrev={'btn_prev'}
                itemClassNext={'btn_next'}
                itemClassLast={'btn_nnxt'}
            />
        )
    }

    if (filteredPassInfo.length > 0){
        return (
            <>
            <div id="layout">
                <ul className="wrap_passList">
                    {(filteredPassInfo || []).map((item) => 
                        <PassListItem key={item.uid} productItem={item}></PassListItem>
                    )}
                </ul>
            </div>
            <PassPager></PassPager>
            </>
        )
    }
    
    return (
        <div className="wrap_ask">
            {/* <p className="exp">관심등록한 패스상품이 없습니다.</p> */}
            <p className="exp">{t("interest.pass_none")}</p>

            {/* <a href="#" target="_self" className="btn_goToInquiry">패스상품 둘러보기</a>  */}
            <Link to="/app/pass" className="btn_goToInquiry">{t("interest.goto_pass")}</Link>
        </div>
    )

};

export default InterestPass;