import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { observe } from "mobx";

import useQueryParam from "../../components/UseQueryParam";
import AxiosConfig from '../../services/AxiosConfig';
import { GlobalInfo, globalInfoStore, LANG_TYPES } from "../../stores/GlobalInfoStore";
import SearchNoticeItem from './SearchNoticeItem';
import SearchSpotItem from './SearchSpotItem';
import SearchPassItem from './SearchPassItem';


interface SpotItem {
    [key: string]: any;
}
interface PassItem {
    [key: string]: any;
}
interface NoticeItem {
    [key: string]: any;
}

// const params = () => {
//     return new URLSearchParams(useLocation().search);
// }

const SearchV1 = (props:any) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    // const location = useLocation();

    // location.get("query")
    // const query = params.get('query');
    // const params = new URLSearchParams(useLocation().search);
    // const params = new URLSearchParams(props.location.search);
    //   const [params, setParams] = useUrlSearchParams({ checked: true });

    // const [searchKeyword, setSearchKeyword] = useQueryParam("query", (params.get("query")==null)?"":params.get("query"));
    // const [inputSearchText, setInputSearchText] = useState((params.get("query")==null)?"":params.get("query")); //input 박스
    const [inputSearchText, setInputSearchText] = useState(""); //input 박스
    //공통
    const [size, setSize] = useState(5);

    //관광지
    const [searchSpots, setSearchSpots] = useState<SpotItem>();
    const [filteredSearchSpots, setFilteredSearchSpots] = useState<SpotItem[]>();
    const [pageSpot, setPageSpot] = useState(1)
    
    //패스
    const [searchPasses, setSearchPasses] = useState<PassItem>();
    const [filteredSearchPasses, setFilteredSearchPasses] = useState<PassItem[]>();
    const [pagePass, setPagePass] = useState(1)

    //공지사항
    const [searchNotices, setSearchNotices] = useState<NoticeItem>();
    const [filteredSearchNotices, setFilteredSearchNotices] = useState<NoticeItem[]>();
    const [pageNotice, setPageNotice] = useState(1)
    
    // const [Size, setSize] = useState(5);
    // const [Size, setSize] = useState(5);





    // const [searchKeyword, setSearchKeyword] = useState(params.get("query")); // 실제 검색시 사용
    // const [searchKeyword, setSearchKeyword] = useState(params.get('query'));

    const fetchSearchV1Spot = async (page:number, size:number, sorts:[]) => {
        let url = `/api2/tourInfo?langCode=${globalInfoStore.currentLangType.langCode}&page=${page}&size=${size}`;
        // /api2/tourInfo?langCode=1&page=1&size=5&searchKeywork=63스퀘어
        // let url = `/api2/tourInfo?langCode=1&page=1&size=5`

        if (globalInfoStore.searchKeyword == null || globalInfoStore.searchKeyword == ""){
            url += `&searchKeyword=`
        } else {
            url += `&searchKeyword=${globalInfoStore.searchKeyword}`
        }
        const res = await AxiosConfig.get(url);

        setSearchSpots(res.data);
        if (page > 1){
            //TODO: add list
            const addList = res.data.content.filter((it:any) => it);
            setFilteredSearchSpots(filteredSearchSpots => [...filteredSearchSpots, ...addList])
        } else {
            
            setFilteredSearchSpots(res.data.content.filter((it:any) => it))
        }
    };
    
    const fetchSearchV1Pass = async (page:number, size:number, sorts:[]) => {
        let url = `/api2/productInfoWithDiscount?page=${page}&size=${size}`;
        

        // if (sorts.length > 0){
        //     url += (sorts || []).map((item)=>`&sort=${item}`);
        // }

        if (globalInfoStore.searchKeyword == null || globalInfoStore.searchKeyword == ""){
            url += `&searchKeyword=`
        } else {
            url += `&searchKeyword=${globalInfoStore.searchKeyword}`
        }

        const res = await AxiosConfig.get(url);

        if (page > 1){
            //TODO: add list
            const addList = res.data.content.filter((it:any) => it);
            setFilteredSearchPasses(filteredSearchPasses => [...filteredSearchPasses, ...addList])
        } else {
            setSearchPasses(res.data);
            setFilteredSearchPasses(res.data.content);
        }
        
        console.log(res.data);
    };

    const fetchSearchV1Notice = async (page:number, size:number, sorts:[]) => {
        let url = `/api2/siteNoticeInfo?page=${page}&size=${size}`;

        // if (sorts.length > 0){
        //     url += (sorts || []).map((item)=>`&sort=${item}`);
        // }

        if (globalInfoStore.searchKeyword == null || globalInfoStore.searchKeyword == ""){
            url += `&searchKeyword=`
        } else {
            url += `&searchKeyword=${globalInfoStore.searchKeyword}`
        }

        const res = await AxiosConfig.get(url);

        setSearchNotices(res.data);
        if (page > 1){
            //TODO: add list
            const addList = res.data.content.filter((it:any) => it);
            setFilteredSearchNotices(filteredSearchNotices => [...filteredSearchNotices, ...addList])
        } else {
            setSearchNotices(res.data);
            setFilteredSearchNotices(res.data.content)
        }
        
        console.log(res.data);
    };

    const onInitSearch = () => {
        setPageSpot(1);
        setPagePass(1);
        setPageNotice(1);

        fetchSearchV1Pass(pagePass, size, []);
        fetchSearchV1Spot(pageSpot, size, []);
        fetchSearchV1Notice(pageNotice, size, []);
    }

    const [searchKeyword, setSearchKeyword] = useState({});
    const searchKeyword2 = observe(globalInfoStore, "searchKeyword", change => {
        if (change.newValue != null) {
            setSearchKeyword(change.newValue)
        }
    })

//     const person = observable({
//     firstName: "Maarten",
//     lastName: "Luther"
// })

// // Observe all fields.
// const disposer = observe(person, change => {
//     console.log(change.type, change.name, "from", change.oldValue, "to", change.object[change.name])
// })

// person.firstName = "Martin"
// // Prints: 'update firstName from Maarten to Martin'

// // Ignore any future updates.
// disposer()

// // Observe a single field.
// const disposer2 = observe(person, "lastName", change => {
//     console.log("LastName changed to ", change.newValue)
// })

    useEffect(()=>{
        console.log("globalInfoStore.searchKeyword :: " + globalInfoStore.searchKeyword);
        if (globalInfoStore.searchKeyword != null) {
            setInputSearchText(globalInfoStore.searchKeyword);
        }
        onInitSearch()
    }, [searchKeyword])


    useEffect(()=>{
        if (pageSpot > 1) {
            fetchSearchV1Spot(pageSpot, size, []);
        }
    }, [pageSpot])

    useEffect(()=>{
        if (pagePass > 1) {
            fetchSearchV1Pass(pagePass, size, []);
        }
    }, [pagePass])

    useEffect(()=>{
        if (pageNotice > 1) {
            fetchSearchV1Notice(pageNotice, size, []);
        }
    }, [pageNotice])
    

    const RenderSearchSpots = () => {

        if (filteredSearchSpots == null || filteredSearchSpots.length == 0){
            return (<p>{t("search.no_results")}</p>);
        }

        return (
            <ul>
            {filteredSearchSpots.map((item:any)=>{
               return (<SearchSpotItem key={item.uid} content={item} ></SearchSpotItem>)
            })}
            </ul> 
        )
    }

    const RenderSearchPasses = () => {
        if (filteredSearchPasses == null || filteredSearchPasses.length == 0){
            return (<p>{t("search.no_results")}</p>);
        }
        return (
            <ul>
            {filteredSearchPasses.map((item:any)=>{
               return (<SearchPassItem key={item.uid} content={item}></SearchPassItem>)
            })}
            </ul> 
        )
    }
    
    const RenderSearchNotice = () => {
        if (filteredSearchNotices == null || filteredSearchNotices.length == 0){
            return (<p>{t("search.no_results")}</p>);
        }
        return (
            <ul>
            {filteredSearchNotices.map((item:any)=>{
               return (<SearchNoticeItem key={item.uid} content={item}></SearchNoticeItem>)
            })}
            </ul> 
        )
    }

    const onSearchTextChange = (e: any) => {
        console.log(e.type + ":", e.target.value);
        setInputSearchText(e.target.value);
    }
    // const onSearchTextChange = (value: string) => {
    //     console.log(value);
    //     setInputSearchText(value);
    // }

    const handleSearch = (e: any) => {
        /** TODO: 검색어 필터가 걸리고 다시 풀려고 할 경우에 대한 테스트 코드 필요...*/
        //INPUT 은 비엇고 searchKeyword는 있을 경우 filter를 제거하고 검색시도한다
        if (inputSearchText == null)  return;
        if (inputSearchText == "") { alert("검색어를 입력하세요."); return; }

        // globalInfoStore.searchKeyword = inputSearchText
        globalInfoStore.setSearchKeyword(inputSearchText);
        // onInitSearch();
    }

    const onSearchKeyDown = (e: any) => {
        if (e.keyCode === 13) {
			/** TODO: 검색어 필터가 걸리고 다시 풀려고 할 경우에 대한 테스트 코드 필요...*/
			//INPUT 은 비엇고 searchKeyword는 있을 경우 filter를 제거하고 검색시도한다
			if (inputSearchText == null)  return;
            if (inputSearchText == "") { alert("검색어를 입력하세요."); return; }
            
            // globalInfoStore.searchKeyword = inputSearchText;
            globalInfoStore.setSearchKeyword(inputSearchText);
            // setSearchKeyword(inputSearchText);
            // onInitSearch();
        }
    }

    const handleMoreSpot = () => {
        console.log("handleMorePass" + pageSpot)
        setPageSpot(pageSpot + 1)
    }

    const handleMorePass = () => {
        console.log("handleMorePass" + pagePass)
        setPagePass(pagePass + 1)
    }

    const handleMoreNotice = () => {
        console.log("handleMoreNotice" + pageNotice)
        setPageNotice(pageNotice + 1)
    }


    const getTotalCount = (searchCount:any) => {
        //  return t("main.freecount_prefix") + " " + props.content.freeCount + t("main.freecount_postfix") + " / " + t("main.discount_prefix") + " " + props.content.disCount + " " + t("main.discount_postfix");
        return t("search.total_prefix") + " " + ((searchCount == null)? 0: searchCount.totalElements ) + t("search.total_postfix");
    }


    return (
        <div className="layout">
					
            <h6 className="hidden">{t("search.unified_search")}</h6>

            <div className="wrap_search">
                <p className="tit">SEARCH</p>
                    <fieldset>
                        <legend>검색 영역</legend>
                        {/* <input type="text" title="검색" className="input_sch" placeholder="검색어를 입력하세요" */}
                        <input type="text" title="검색" className="input_sch" placeholder={t("globalnavbar.search_placeholder")}
                        value={inputSearchText}
                        onChange={onSearchTextChange}
                        // onChange={({ target: { value } }) => onSearchTextChange(value)}
                        onKeyDown={(e) => onSearchKeyDown(e)} />
                        <button className="btn_sch" onClick={handleSearch}>검색 버튼</button>
                    </fieldset>
            </div>

            <div className="wrap_result">
                <dl>
                    <dt>
                        <div style={{display:"inline"}} dangerouslySetInnerHTML={{__html:t("search.attractions")}}>
                        </div>
                        <span dangerouslySetInnerHTML={{__html:getTotalCount(searchSpots)}}></span>
                    </dt>
                    <dd>
                        <RenderSearchSpots></RenderSearchSpots>
                    </dd>
                </dl>
                {(searchSpots != null && searchSpots.totalElements>(size*pageSpot))?
                <button className="btn_more" onClick={handleMoreSpot}>{t("main.more")}</button>:""}
                <dl>
                    <dt>
                        <div  style={{display:"inline"}} dangerouslySetInnerHTML={{__html:t("search.pass_products")}}>
                        </div>
                        {/* <span>(총 {searchPasses == null? 0: searchPasses.totalElements}개)</span> */}
                        {/* <span>({t("search.total_prefix")} {searchPasses == null? 0: searchPasses.totalElements}{t("search.total_postfix")})</span> */}
                        <span dangerouslySetInnerHTML={{__html:getTotalCount(searchPasses)}}></span>
                        {/* <span>({t("search.total_prefix")} {searchPasses == null? 0: searchPasses.totalElements}{t("search.total_postfix")})</span> */}
                    </dt>
                    <dd>
                        <RenderSearchPasses></RenderSearchPasses>
                    </dd>
                </dl>
                {
                    (searchPasses != null && searchPasses.totalElements>(size*pagePass))
                    ?<button className="btn_more" onClick={handleMorePass}>{t("main.more")}</button>
                    :""
                }

                <dl>
                    <dt>
                        <div  style={{display:"inline"}} dangerouslySetInnerHTML={{__html:t("search.notice")}}>
                        </div>
                        {/* <span>({t("search.total_prefix")} {searchNotices == null? 0: searchNotices.totalElements}{t("search.total_postfix")})</span> */}
                        <span dangerouslySetInnerHTML={{__html:getTotalCount(searchNotices)}}></span>
                    </dt>
                    <dd className="notice">
                        <RenderSearchNotice></RenderSearchNotice>
                    </dd>
                </dl>
                {
                    (searchNotices != null && searchNotices.totalElements>(size*pageNotice))
                    ?<button className="btn_more" onClick={handleMoreNotice}>{t("main.more")}</button>
                    :""
                }
            </div>
        </div>
    );
};

export default SearchV1;