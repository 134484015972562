/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { Component, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import MainCardSlider from './MainCardSlider';
import ArticleMain from './ArticleMain';
import ArticleWrapEtc from "./ArticleWrapEtc";
import AxiosConfig from '../../services/AxiosConfig';
import { useWindowResize } from "beautiful-react-hooks";
// import useRootStore from "../../stores/UseRootStore";
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import { observe } from "mobx";
import { observer } from "mobx-react";

import $ from "jquery";
import ChannelService from "../../services/ChannelService";
import { isPlatform } from "@ionic/react";
// import '@capacitor-community/http';
import { Plugins } from '@capacitor/core';
import jwt from 'jsonwebtoken';
import { usePreviousDistinct } from "react-use";

// const { Http } = Plugins;
const { Storage } = Plugins;

const classNames = require("classnames");
const { useState } = React;


interface MainPageProps extends RouteComponentProps {}

// const dspLeafletKor = "https://discoverseoulpass-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/m/pdf/dsp-leaflet/dsp-leaflet-kor.pdf";
// const dspLeafletEng = "https://discoverseoulpass-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/m/pdf/dsp-leaflet/dsp-leaflet-eng.pdf";
// const dspLeafletJpn = "https://discoverseoulpass-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/m/pdf/dsp-leaflet/dsp-leaflet-jpn.pdf";
// const dspLeafletZhCht = "https://discoverseoulpass-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/m/pdf/dsp-leaflet/dsp-leaflet-zhCht.pdf";
// const dspLeafletZhChs = "https://discoverseoulpass-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/m/pdf/dsp-leaflet/dsp-leaflet-zhChs.pdf";

const Main : React.FC<MainPageProps> = (props: MainPageProps) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    // const pcSidebar = React.createRef();
    const pcSidebar = useRef(null);
    const refMainVisual = useRef(null);
    // const node = pcSidebar.current;
    // const { globalInfoStore } = useRootStore();
    const [ sidebar, setSidebar ] = useState(false);
    const [ isVisblePopup, setVisiblePopup ] = useState(false);

    const [ popupList, setPopupList ] = useState([]);
    // const [ hidePopupList, setHidePopupList ] = useState([]);

    // const [ isCookiePopupShowed, setCookiePopupShowed ] = useState(false);

    let langDescription = globalInfoStore.currentLangType.prefix2 + "Description";
    let langFilename = globalInfoStore.currentLangType.prefix2 + "Filename";
    let langFilepath = globalInfoStore.currentLangType.prefix2 + "Filepath";
    

    const Sidebar = observe(globalInfoStore, "sidebar", change => {
      setSidebar(Boolean(change.newValue));
    })
    const filter = "win16|win32|win64|mac|macintel";

    const checkVisible = ( elm:any, evalObj?:any ) => {
        evalObj = evalObj || "object visible";
        var viewportHeight = $(window).height(), // Viewport Height
            scrolltop = $(window).scrollTop(), // Scroll Top
            // scrolltop = window.scrollY,
            y = $(elm).offset().top,
            elementHeight = $(elm).height();  
        if (evalObj == "object visible"){
          return ((y < (viewportHeight + scrolltop)) && (y > (scrolltop - elementHeight)));
        }
        if (evalObj == "above"){
        return ((y < (viewportHeight + scrolltop)));
        }
    }
    let isVisible = false;

    const fetchPopupList = async () => {
      const url = `/api2/sitePopupList`;
      const res = await AxiosConfig.get(url);

      const hidePopupList = await getStoreHidePopupList();
      let removedPopupList;
      if (hidePopupList != null && hidePopupList.length > 0) {
        // let keys = hidePopupList.keys();
        const result = hidePopupList.map(uids => uids.uid);
        removedPopupList = res.data.content.filter((val:any) => {
          return result.indexOf(val.uid.toString()) == -1;
        });
      } else {
        removedPopupList = res.data.content;
      }

      setPopupList(removedPopupList);
    }

    type IHidePopupItem = {
      uid: string,
      expires: Date
    }
    /**
     * 팝업 차단 설정
     */
    const addStoreHidePopupList = async (uid:IHidePopupItem) => {
      const hidePopupList = await getStoreHidePopupList();
      hidePopupList.push(uid)
      await localStorage.setItem("hidePopupList", JSON.stringify(hidePopupList))
    };

    /**
     * 차단된 팝업 리스트 uid
     * @returns uids string
     */
    const getStoreHidePopupList = (): IHidePopupItem[] => {
      const hidePopupList = localStorage.getItem("hidePopupList");
      console.log("hidePopupList.LocalStorage:: " + hidePopupList)
      if (hidePopupList == null) {
        return [];
      }
      return JSON.parse(hidePopupList);
    }

    const expireHidePopupList = () => {
      const list = getStoreHidePopupList();
      //살아남은 list filtering
      const newList = list.filter(item=>{
        return new Date(item.expires) > new Date();
      })
      localStorage.setItem("hidePopupList", JSON.stringify(newList))
    }

    useEffect(() => {
      // setCookiePopupShowed(localStorage.isCookiePopupShowed);

      expireHidePopupList();
      
      fetchPopupList();
      if (navigator.platform) {
        if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
          //mobile alert('mobile 접속');
        } else {
          window.addEventListener("scroll", onePageSlide);
        }
      }
      return () => {
        window.removeEventListener("scroll", onePageSlide);
      };
    }, []);

    const goToAppMyPass = (e:any) => {
      // console.log("location.pathname:::" + location.pathname);
      if ("/app/signin/index/login" == location.pathname) {
        return;
      }
      history.push("/appmypass")
    }
  

    const onePageSlide = () => {
        if (checkVisible($('.mainVisual'))&&!isVisible) {
            //alert("Visible!!!");
            $("html,body").animate({scrollTop:1000}, 500); 
            // setVisible(true);
            isVisible = true;
        }
        if(isVisible){
            var scroll = $(window).scrollTop();
            //console.log(scroll);
            if(scroll == 0 ){
              // setVisible(false);
              isVisible = false;
            }
        }
    }

    const goToLiveChat = () => {
      window.open("http://discoverseoul.channel.io", "_blank")
    }

    const closePopup = (uid:number) => {
      const newPopupList = popupList.filter((e)=>{
        return e.uid != uid;
      })
      setPopupList(newPopupList);
    }

    const doNotOpenToday = (uid:number) => {
      if(localStorage.isCookieUse === 'false') {
        closePopup(uid);
        return;
      }

      const newPopupList = popupList.filter((e)=>{
        return e.uid != uid;
      })
      setPopupList(newPopupList);
      let hidePopupItem:IHidePopupItem;
      const expires = new Date();
      expires.setUTCHours(expires.getUTCHours() + 24 + 9); // utc 기준이라 1일 + 9시간을 해줌.
      hidePopupItem = {uid:uid.toString(), expires:expires}
      addStoreHidePopupList(hidePopupItem);
    }

    const handleSetCookie = async () => {
      // const { Http } = Plugins;
      console.log("handleSetCookie")
      // const ret = await Http.setCookie({
      //     url: process.env.REACT_APP_API_URL + '/cookie',
      //     key: "test",
      //     value: 'sss'
      //   });
      await Storage.set({
        key: 'name',
        value: 'Max',
      });
    }

    const handleGetCookie = async () => {
      const { value } = await Storage.get({ key: 'name' });

      alert(`Hello ${value}!`);
      // console.log("handleGetCookie")
      // const ret = await Http.getCookies({
      //   url: process.env.REACT_APP_API_URL + '/cookie'
      // });
      // let test = await ret.value.filter((item:any) => item.key.startsWith("test"));
      // console.log("test::" + test)

    }



    /**
     * 팝업
     * @param props 
     * @returns 
     */
    const Popup = (props:{content:any}) => {
      return (
        <>
        {/* <div className="popup" id="divpop1" style={{zIndex:1000-props.content.uid}}> */}
        <div className="popup" id={'divpop'+props.content.uid} style={{zIndex:props.content.uid+1000}}>
        <div className="cont">
          <a href={props.content.url}>
            <img src={props.content[langFilepath]} alt={props.content[langFilename]}></img>
          </a>
        </div>
        <button className="btn_close" onClick={(e)=>closePopup(props.content.uid)}>CLOSE</button>
        <div className="footer">
          <input type="checkbox" id={'ck'+props.content.uid} onClick={(e)=>doNotOpenToday(props.content.uid)} />
          <label htmlFor={'ck'+props.content.uid}>Do not open this window today</label>
        </div>
        </div>  
        </>
      )
    }

    // const cookieAccept = () => {
    //   localStorage.isCookiePopupShowed = true;
    //   localStorage.isCookieUse = true;
    //   setCookiePopupShowed(true);
    // }

    // const cookieReject = () => {
    //   localStorage.isCookiePopupShowed = false;
    //   localStorage.isCookieUse = false;
    //   setCookiePopupShowed(true);
    // }

    /**
     * 쿠키 팝업
     * @param props 
     * @returns 
     */
    // const CookiePopup = () => {
    //   return (
    //     <>
    //     <div className="cookie-popup-area" id='cookiePopup'>
    //       <div className="inner-cont">
    //         <div className="text-cont" dangerouslySetInnerHTML={{__html:t("main.cookie_content")}}>
    //         </div>
    //         <div className="btn-block">
    //             <a href="javascript:void(0);" className="btn-primary">
    //               <button className="btn-primary" onClick={(e)=>cookieAccept()}><em>{t("main.cookie_acept")}</em></button>
    //             </a>
    //             <a href="javascript:void(0);">
    //               <button className="btn-primary" onClick={(e)=>cookieReject()}><em>{t("main.cookie_reject")}</em></button>
    //           </a>
    //           <a href="https://www.sto.or.kr/english/privacy" target="_blank" title="open new window">
    //             <em>{t("main.cookie_info")}</em>
    //           </a>
    //         </div>
    //       </div>
    //     </div>  
    //     </>
    //   )
    // }


    // const goToPdf = (pdfUrl:string) => {
    //   console.log("goToPdf::::::")
    //   window.open(pdfUrl, "_blank")
    // }
  
    // const DownloadLeaflet = () => {

    //     if (globalInfoStore.currentLangType.i18Str === "ko"){
    //         return (<li className="li01" onClick={(e)=>goToPdf(dspLeafletKor)}><a href="javascript:void(0);" >{t("main.dsp_leaflet")}</a></li>)
    //     }
    //     if (globalInfoStore.currentLangType.i18Str === "zhChs"){
    //         return (<li className="li01" onClick={(e)=>goToPdf(dspLeafletZhChs)}><a href="javascript:void(0);" >{t("main.dsp_leaflet")}</a></li>)
    //     }
    //     if (globalInfoStore.currentLangType.i18Str === "zhCht"){
    //         return (<li className="li01" onClick={(e)=>goToPdf(dspLeafletZhCht)}><a href="javascript:void(0);" >{t("main.dsp_leaflet")}</a></li>)
    //     }
    //     if (globalInfoStore.currentLangType.i18Str === "ja"){
    //         return (<li className="li01" onClick={(e)=>goToPdf(dspLeafletJpn)}><a href="javascript:void(0);" >{t("main.dsp_leaflet")}</a></li>)
    //     }
    //     return (<li className="li01" onClick={(e)=>goToPdf(dspLeafletEng)}><a href="javascript:void(0);" >{t("main.dsp_leaflet")}</a></li>)

    // }


  return (
    <>
      {(popupList || []).map(item => (
          <Popup key={item.id} content={item}></Popup>
      ))}

      {/* {(isCookiePopupShowed ? <></> : <CookiePopup></CookiePopup>)} */}

      <section id="container">
        {/* <h6 className="hidden">t("Cancel QR Code Reader")</h6> */}
        <h6 className="hidden">본문</h6>

        <article ref={refMainVisual} className="mainVisual">
          <h6 className="hidden">상단 이미지 슬라이드</h6>

          <MainCardSlider></MainCardSlider>


          <div className="wrap_link">
              <Link to="/app/pass" className="goToPass">{t("main.buy_now")}</Link>
              <Link to="/app/guide" className="goToInfo">{t("main.learn_more")}</Link>
          </div>
        </article>
        {/* <article className="main_sns m_only">
          <h6 className="hidden">sns모음(모바일에서만 보입니다.)</h6>

          <a className="facebook" href="https://www.facebook.com/discoverseoulpass/" target="_blank"> 페이스북 </a>
          <a className="ins_e" href="https://www.instagram.com/discoverseoulpass_en/" target="_blank"> 인스타그램-영문 </a>
          <a className="ins_j" href="https://www.instagram.com/discoverseoulpass_jp/" target="_blank"> 인스타그램-일문 </a>
          <a className="yout" href="https://www.youtube.com/channel/UC41VYNJotk4Z_UkDeIheNRw/featured" target="_blank"> 유튜브 </a>
          <a className="weibo" href="https://www.weibo.com/discoverseoulpass" target="_blank"> 웨이보 </a>
          <a className="shao" href="https://www.xiaohongshu.com/user/profile/5e9942a600000000010055b6" target="_blank"> 샤오홍슈 </a>
        </article> */}

        <ArticleMain></ArticleMain>

        <ul className={classNames({pc_sidebar: true, active: sidebar})} ref={pcSidebar}>
          <li className="menu01"> <Link to="/app/pass">   {t("main.pcsidebar_buypass")} </Link>
          </li>
          <li className="menu02"> <Link to="/app/spot">   {t("main.pcsidebar_attractions")} </Link>
          </li>
          <li className="menu03" onClick={goToLiveChat}>
            <a>{t("main.pcsidebar_livechat")}</a>
          </li>
        </ul>
        <ul className={classNames({m_sidebar: true})}>
          <li className="chat" onClick={goToLiveChat}>
          </li>
            {/* <li className="qr" onClick={goToAppMyPass}> 
            </li> */}
        </ul>

        <ArticleWrapEtc></ArticleWrapEtc>
      </section>

      {/* <div className={classNames({ebook_popup: true, active: globalInfoStore.activeEbookDownload})}>
        <p>{t("main.download_guidebook")}</p>
        <button onClick={(e)=>{globalInfoStore.activeEbookDownload = false}}>닫기</button>
        <ul className="temporary">
          <DownloadLeaflet></DownloadLeaflet>
          <li className="li02"><a href="#" target="_self" download="">{t("main.dsp_guidebook")}<span>({t("main.coming_soon")})</span></a></li>
          <li className="li03"><a href="#" target="_self" download="">{t("main.seoul_plus_ebook")}<span>({t("main.coming_soon")})</span></a></li>
        </ul>
      </div>
      <div className={classNames({dimmed: true, active: globalInfoStore.activeEbookDownload})}></div> */}
    </>
    
  );
  
}

export default observer(Main);
// export default Main;
