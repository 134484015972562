import axios, { AxiosInstance } from "axios";


/**
 * 전체탭 total
 * 패스소개 탭 intro
 * 제휴 관광지 탭 tour
 * 고개센터 탭 svc
 * 패스상품 탭 product
 */
 export enum ESearchType {
    TOTAL="total",
    INTRO="intro",
    TOUR="tour",
    SVC="svc",
    PRODUCT="product"
}

export interface ISearchType {
    code: number;
    desc: string;
    type: string;
    group: string;
    subCode: ISearchSubType[];
    // subType: string[];
    // subTypeDesc: string[];
}

export interface ISearchSubType {
    resultIndex: number
    order:number
    subCode: number;
    searchType: ESearchType;
    subType: string;
    subTypeDesc: string;
    page: number;
}

export const SEARCH_SUB_TYPES:ISearchSubType[] = [
    {page:1, resultIndex:0, order:0, subCode:11, searchType:ESearchType.INTRO, subType:"OFFLINE VENDOR", subTypeDesc:"오프라인 판매처"},
    {page:1, resultIndex:1, order:1, subCode:12, searchType:ESearchType.INTRO, subType:"RECIPIENT", subTypeDesc:"수령처"},
    {page:1, resultIndex:2, order:2, subCode:21, searchType:ESearchType.TOUR, subType:"TOUR ATTRACTION", subTypeDesc:"제휴 관광지"},
    {page:1, resultIndex:5, order:3, subCode:31, searchType:ESearchType.PRODUCT, subType:"PASS PRODUCT", subTypeDesc:"패스상품"},
    {page:1, resultIndex:3, order:4, subCode:41, searchType:ESearchType.SVC, subType:"FAQ", subTypeDesc:"FAQ"},
    {page:1, resultIndex:4, order:5, subCode:42, searchType:ESearchType.SVC, subType:"NOTICE", subTypeDesc:"공지사항"},
];

export const getSearchType = (type:string):ISearchType => {
    return SEARCH_TYPES.find((item)=>{return item.type === type});
}

export const getSearchSubType = (subCode:number):ISearchSubType => {
    return SEARCH_SUB_TYPES.find((item)=>{return item.subCode === subCode});
}

export const SEARCH_TYPES:ISearchType[] = [
    {code:0, desc:"전체 탭", type:"total", group:"total", subCode:SEARCH_SUB_TYPES},
    {code:1, desc:"패스소개 탭", type:"intro", group:"intro", subCode:[getSearchSubType(11),getSearchSubType(12)]},
    {code:2, desc:"제휴관광지 탭", type:"tour", group:"tour", subCode:[getSearchSubType(21)]},
    {code:3, desc:"패스상품 탭", type:"svc", group:"svc", subCode:[getSearchSubType(31)]},
    {code:4, desc:"고객센터 탭", type:"product", group:"product", subCode:[getSearchSubType(41),getSearchSubType(42)]},
];

export interface SpotItem {
    [key: string]: any;
}
export interface PassItem {
    [key: string]: any;
}
export interface NoticeItem {
    [key: string]: any;
}

// const params = () => {
//     return new URLSearchParams(useLocation().search);
// }

/**
 * 통합검색 API 
 * http://172.27.32.1:5001/extensions/TotalSearch?searchTerm=검색키워드&searchType=검색탭&page=현재페이지&language=언어
 * 
 */
export interface ISearchV2ReqParam {
    searchTerm:string; /** // 검색 키워드    // Ex> 관광 */
    searchType?:string; /** // 검색 탭(전체, 패스소개, 제휴관광지, 고객센터, 패스상품)    // 전체 탭(default) -> total    // 패스소개 탭 -> intro    // 제휴관광지 탭 -> tour    // 고객센터 탭 -> svc    // 패스상품 탭 -> product */
    page?:string; /** // 현재 페이지    // Ex > 3    // Default : 1    한 페이지에 10개씩 노출 */
    language:string; /** // 선택 언어    // 한국어(default) -> KO    // 영어 -> ENG    // 일본어 -> JA    // 번체 -> BUN     // 간체 -> GAN */
}

// export interface ISearchV2RespResultDocument {
//     TYPE?: string;
//     UID?: string;
//     FULLPATH?: string;
//     TITLE?: string;
// }
export interface ISearchV2PopularRespResultDocument {
    KEYWORD?:string; /* 인기검색어 키워드 */
    RANKING?:string; /* 현재 순위 */
    PREV_RA?:string; /* 이전 순위 */
}

export interface ISearchV2RespResult {
  errorCode: number; /*	검색 내부 오류시, 검색 오류에 대한 내부 코드(return 코드와는 별개)	*/
  groupResult: any[]; /*	검색된 결과의 그룹 결과	*/
  resultFieldSize: number; /*	결과로 온 문서의 select된 field 개수	*/
  recommend?: any; /*	검색 결과의 추천어 목록	*/
  redirect?: any; /*	검색어에 해당하는 바로가기 목록	*/
  brokerPagingInfo?: any; /*	이전에 검색했던 하위 결과의 문서정보를 저장	*/
  data?: any; /*	검색질의와는 별개 부가적인 값	*/
  cfRecommendResultSet?: any; /*	추천 시스템의 결과 리스트	*/
  totalSize: number; /*	검색 결과의 총 크기	*/
  realSize: number; /*	검색 결과로 돌려주는 문서의 개수	*/
//   resultDocuments: ISearchV2RespResultDocument[] | ISearchV2PopularRespResultDocument[]; /*	검색된 결과 문서에 대한 정보	*/
  resultDocuments: any[]; /*	검색된 결과 문서에 대한 정보	*/
}

export interface ISearchV2RespResultSet {
    resultSize	:number;	/*	resultSet에 포함된 result의 개수	*/
    errorCode	:number;	/*	검색 내부 오류 시, 검색 오류에 대한 내부 코드 (return 코드와는 별개)	*/
    result	:ISearchV2RespResult[];	/*	검색된 결과에 관한 정보	*/
}

export interface ISearchV2Resp {
    returnCode	:number;	/*	검색 오류 코드 (양수인 경우 정상, 음수인 경우 오류) - 정상 : 1 - 검색엔진 연결 실패 : -60003 - 네트워크 에러 : -60000 - 검색 소요시간 타임아웃 : -60004 - Request Timeout : -70002 - 검색 API 오류 : 나머지 음수 값	*/
    version	:number;	/*	버전 정보	*/
    responseTime	:string;	/*	검색 응답속도(ms)	*/
    status	:number;	/*	httpStatus 값 - 응답성공 : 200 - bad request : 400 - request Timeout : 408 - internal Server error : 500	*/
    resultSet:ISearchV2RespResultSet;	/*	검색 결과를 가지고 있는 필드	*/
    error?: any;	/*	에러 발생시 에러 메시지를 담은 필드	*/
}

/**
 **  //TODO: 언어별 인기검색어를 위한 로그적재 api
 ** -> 각 언어별로 인기검색어 제공을 위해 위의 통합검색 URL을 호출 하신 후 해당언어의 해당키워드에 대해 검색결과가 한건이라도 있으면 아래의 URL로 1회 호출 해주셔야 합니다.
 **  http://172.27.32.1:5001/extensions/LogInsert?searchTerm=통합검색 키워드 &language=선택언어
 */
export  interface ISearchV2LogReqParam {
    searchTerm:string; //* 검색 키워드    // Ex> 관광 */
    language:string; //* 선택 언어    // 한국어(default) -> KO    // 영어 -> ENG    // 일본어 -> JA    // 번체 -> BUN     // 간체 -> GAN
}

/**
 * http://172.27.32.1:5001/extensions/PopularSearch?language=언어
 * ex) https://search.dsptour.com/extensions/PopularSearch?language=ENG
 */
export interface ISearchV2PopularReqParam {
    language:string; // 언어
}

export interface ISearchV2PopularResp {
    
}

export class AxiosSearchV2 {
    static readonly instance: AxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_SEARCH_API_URL,
        timeout: 5000,
    });
}