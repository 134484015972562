import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const FindPwCompleted = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <div className="layout">

            <div className="wrap_leave">
                <h6 className="sec_tit" dangerouslySetInnerHTML={{__html:t("find_pw.result_sub_title")}}></h6>
                <p className="sec_exp">{t("find_pw.result_sub_desc")}</p>

                {/* <a href="main.html" target="_self" className="btn_goToLogin">로그인</a> */}
                <Link to="/app/signin/index/login" className="btn_goToLogin">{t("globalnavbar.sign_in")}</Link>
            </div>

        </div>
    );
};

export default FindPwCompleted;