import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import AxiosConfig from '../../services/AxiosConfig';

import ArticleSpotListItem from "./ArticleSpotListItem";
import { globalInfoStore, LANG_TYPES } from "../../stores/GlobalInfoStore";
import { IProductInfo } from "../../services/ProductInfoService";
import PassListItem from "../pass/PassListItem";

const classNames = require("classnames");

const ArticleMain = () => {
    const { t, i18n } = useTranslation();

    // const [infoes, setInfoes] = useState<NoticeItem[] | (() => NoticeItem[])>();
    // const [passItems, setPassItems] = useState([]);
    // const [spotItems, setSpotItems] = useState([]);
    
    const [filteredPassItems, setFilteredPassItems] = useState<IProductInfo[]>([]);
    const [filteredSpotItems, setFilteredSpotItems] = useState([]);
    // const [notices, setNotices]: [NoticeItem[], (notices: NoticeItem[]) => void] = React.useState(defaultNotices);
    // const [posts, setPosts]: [IPost[], (posts: IPost[]) => void] = React.useState(defaultPosts);
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1); 
    const [postsPerPage] = useState(10); 
    const [searchText, setSearchText] = useState("");

    let customSlider = useRef<Slider>(null);
    const [activeSlide, setActiveSlide] = useState<number>(1);
    

    const fetchPassItems = async () => {
        setLoading(true);
        const res = await AxiosConfig.get("/api2/productInfoWithDiscount");
        // setPassItems(res.data.content);

        setFilteredPassItems(res.data.content.filter((it:any) => it.fixed == 1))
        console.log(res.data);
        setLoading(false);
    };

    const fetchSpotItems = async () => {
        setLoading(true);
        // const res = await AxiosConfig.get("/api2/tourInfo?langCode=1&page1&size=12");
        // let url = `/api2/tourInfo/${props.match.params.uid}?langCode=${globalInfoStore.currentLangType.langCode}`;
        const url = `/api2/tourInfo?langCode=${globalInfoStore.currentLangType.langCode}&page1&size=6`
        const res = await AxiosConfig.get(url);
        // setSpotItems(res.data);

        setFilteredSpotItems(res.data.content.filter((it:any) =>  it))
        console.log(res.data);
        setLoading(false);
    };

    const postLikePass = async () => {
        // var data = JSON.stringify({"type":selectedTopic.codeId,"email":email,"content":content});
  
        var data = JSON.stringify({
            // "category":selectedTopic.codeId,
            // "code":globalInfoStore.currentLangType.langCode,
            // "email":inputEmailText,
            // "question":inputTextArea
        })
  
        const res = await AxiosConfig.post("/api2/csQnaInfo",
            data,
            {
              headers: {
                  "Content-Type": "application/json",
              },
            }
        );
        if (res.status == 200 && res.data.code == "200"){
            //TODO: 패스 좋아요 후 처리
        } else {
            console.error(res.status + " " + JSON.stringify(res.data));
        }
      };

    useEffect(() => {
        fetchPassItems();
        fetchSpotItems();
    }, [globalInfoStore.currentLangType]);

    const renderPassItems = (filteredPassItems || []).map((item) =>{
        return (<PassListItem
            key={item.uid}
            productItem={item}
            ></PassListItem>)
    });

    const renderSpotItems = (filteredSpotItems || []).map((item) =>{
        return (<ArticleSpotListItem key={item.uid} content={item}></ArticleSpotListItem>)
    });

    const next = () => {
      customSlider.current.slickNext();
      // console.log("next" + customSlider);
    };
    const previous = () => {
      customSlider.current.slickPrev();
      // console.log("prev:" + customSlider);
    };

    // const [sliderSettings, setSliderSettings] = useState({
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     mobileFirst: true,
    //     responsive: [
    //         {
    //             breakpoint: 750,
    //             settings: 'unslick',
    //             dots: false,
    //             infinite: true
    //         }
    //     ]
    // });

    // [
    //   { breakpoint: 768, settings: { slidesToShow: 3 } },
    //   { breakpoint: 1024, settings: { slidesToShow: 5 } },
    //   { breakpoint: 100000, settings: "unslick" },
    // ];
    // let settings: Settings = {
    const sliderSettings: Settings = {
        dots: false,
        centerMode: true,
        infinite: true,
        arrows: false,
        lazyLoad: "progressive",
        speed:500,
        responsive: [
            { 
                breakpoint: 9999,
                settings: "unslick" 
            },
            {
                breakpoint: 750,
                settings: {
                    centerMode: false,
                    initialSlide: activeSlide - 1,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    infinite: true,
                },
            }
        ],
        beforeChange: (current, next) => {
            // console.log("beforeChange" + next+1)
            setActiveSlide(next +1)
        },
        afterChange: ((currentSlide:number) => {
        //   setCurrentSlide(currentSlide + 1);
            setActiveSlide(currentSlide+1)
        })
    };

    

    return (
        <article className="main">
            <h6 className="hidden">메인 콘텐츠</h6>

            <div className="layout">
                    {/* <span>CS:{currentSlide}</span>
                    <span>,CS2:{activeSlide}</span>
                    <span>,CS2+1:{activeSlide+1}</span>
                    <span>,OS:{oldSlide}</span> */}
                <article className="pass">
                    {/* <h3 className="main_tit" data-aos="fade-up" data-aos-duration="1500" dangerouslySetInnerHTML={{__html:t("main.buy_pass")}}></h3> */}
                    <h3 className={classNames({main_tit:true, righteous:(globalInfoStore.currentLangType.i18Str == "en")})} data-aos="fade-up" data-aos-duration="1500" dangerouslySetInnerHTML={{__html:t("main.buy_pass")}}></h3>

                    <div className="pass_slider" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                    
                        {filteredPassItems.length > 0?
                        <Slider {...sliderSettings} ref={(ref:Slider) => { customSlider.current = ref}}>
                            {renderPassItems}
                        </Slider>
                        :null}
                        
                    </div>
                    <div className="pager">
                        <button className="btn_prev" onClick={previous}>이전 이미지</button>
                        <p className="cnt">
                            <span className="current">{activeSlide}</span>
                            <span className="total">{filteredPassItems.length}</span>
                        </p>
                        <button className="btn_next" onClick={next}>다음 이미지</button>
                    </div>
                        
                    <Link to="/app/pass" className="btn_more">{t("main.more")}</Link>
                </article>
                
                {/* <ArticleInfo></ArticleInfo> */}
                
                <article className="info">
                    {/* <h3 className="main_tit" data-aos="fade-up" data-aos-duration="1500" dangerouslySetInnerHTML={{__html:t("main.info_main_tit")}}></h3> */}
                    <h3 className={classNames({main_tit:true, righteous:(globalInfoStore.currentLangType.i18Str == "en")})} data-aos="fade-up" data-aos-duration="1500" dangerouslySetInnerHTML={{__html:t("main.info_main_tit")}}></h3>
                    
                    <p className="info_exp" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300" dangerouslySetInnerHTML={{__html:t("main.info_info_exp")}}></p>
                    <div className="wrap_dl">
                        <dl className="dl01" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" dangerouslySetInnerHTML={{__html:t("main.wrap_dl01")}}>
                            
                        </dl>
                        <dl className="dl02" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" dangerouslySetInnerHTML={{__html:t("main.wrap_dl02")}}>
                            
                        </dl>
                        <dl className="dl03" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="550" dangerouslySetInnerHTML={{__html:t("main.wrap_dl03")}}>
                            
                        </dl>
                        <dl className="dl04" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600" dangerouslySetInnerHTML={{__html:t("main.wrap_dl04")}}>
                            
                        </dl>
                    </div>
                </article>

                <article className="spot">
                    <h3 className={classNames({main_tit:true, righteous:(globalInfoStore.currentLangType.i18Str == "en")})}  data-aos="fade-up" data-aos-duration="1500">{t("main.attractions")}</h3>
					<ul className="spot_list" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            {renderSpotItems}
                    </ul>
                    <Link to="/app/spot" className="btn_more">{t("main.more")}</Link>
                </article>

            </div>
        </article>
    );
};

export default ArticleMain;
