import React from "react";
import { useTranslation } from "react-i18next";
import { globalInfoStore, LANG_TYPES } from "../../stores/GlobalInfoStore";
import { useLocation } from "react-router-dom";

import partner01 from "../../resources/img/partner01.png";//  "KLOOK"
import partner02 from "../../resources/img/partner02.png";//  "kkday"
import partner03 from "../../resources/img/partner03.png";//  "ctrip"
import partner04 from "../../resources/img/partner04.png";//  "한유왕"
import partner05 from "../../resources/img/partner05.png";//  "HANCHAO"
import partner06 from "../../resources/img/partner06.png";//  "KONEST"
import partner07 from "../../resources/img/partner07.png";//  "Experia"
import partner08 from "../../resources/img/partner08.png";//  "Seoul Pass"
import AppDownload from "../../components/organisms/AppDownload";

const GuideBuyOnline = () => {
    const { t, i18n } = useTranslation();

    const location = useLocation();
    // const isShowApk = () => {
    //   if (globalInfoStore.currentLangType.i18Str == 'zhChs'){
    //     return (<li className="file"><a href="javascript:alert('Coming soon.')"><em>APK File download</em></a></li>)
    //   }
    //   return (null)
    // }
    
    return (
        <div className="wrap_use">
            <p className="use_tit">{t("buy_online.title")}</p>
            <p className="use_exp">{t("buy_online.desc")}</p>
            <div className="exp_box line" dangerouslySetInnerHTML={{__html:t("buy_online.desc2")}}>
                {/* <p>온라인 구매 시 지정한 수령처에서 바우처를 제시하고 패스를 수령합니다.</p>
                <p>오프라인 구매 시 바로 사용이 가능합니다.</p>
                <p>모바일패스 구매 시 패스를 수령하지 않고 어플로 바로 사용 가능합니다.</p> */}
            </div>

            <p className="use_tit small">{t("buy_online.small_buyweb_title")}</p>
            <p className="use_exp">{t("buy_online.small_buyweb_desc")}</p>
            <div className="step step04 web">
                <dl className="step01">
                    <dt>STEP 1</dt>
                    <dd>
                        <p className="tit">{t("buy_online.step1_1_title")}</p>
                        <p className="exp">{t("buy_online.step1_1_desc")}</p>
                    </dd>
                </dl>
                <dl className="step02">
                    <dt>STEP 2</dt>
                    <dd>
                        <p className="tit">{t("buy_online.step1_2_title")}</p>
                        <p className="exp" dangerouslySetInnerHTML={{__html:t("buy_online.step1_2_desc")}}></p>
                    </dd>
                </dl>
                <dl className="step03">
                    <dt>STEP 3</dt>
                    <dd>
                        <p className="tit">{t("buy_online.step1_3_title")}</p>
                        <p className="exp" dangerouslySetInnerHTML={{__html:t("buy_online.step1_3_desc")}}></p>
                    </dd>
                </dl>
                <dl className="step04">
                    <dt>STEP 4</dt>
                    <dd>
                        <p className="tit">{t("buy_online.step1_4_title")}</p>
                        <p className="exp" dangerouslySetInnerHTML={{__html:t("buy_online.step1_4_desc")}}></p>
                    </dd>
                </dl>
            </div>

            <p className="use_tit small">{t("buy_online.small_buyapp_title")}</p>
            <p className="use_exp">{t("buy_online.small_buyapp_desc")}</p>
            <div className="step step04 app">
                <dl className="step01">
                    <dt>STEP 1</dt>
                    <dd>
                        <p className="tit">{t("buy_online.step2_1_title")}</p>
                        <p className="exp">{t("buy_online.step2_1_desc")}</p>
                    </dd>
                </dl>
                <dl className="step02">
                    <dt>STEP 2</dt>
                    <dd>
                        <p className="tit">{t("buy_online.step2_2_title")}</p>
                        <p className="exp" dangerouslySetInnerHTML={{__html:t("buy_online.step2_2_desc")}}></p>
                    </dd>
                </dl>
                <dl className="step03">
                    <dt>STEP 3</dt>
                    <dd>
                        <p className="tit"> {t("buy_online.step2_3_title")}</p>
                        <p className="exp" dangerouslySetInnerHTML={{__html:t("buy_online.step2_3_desc")}}></p>
                    </dd>
                </dl>
                <dl className="step04">
                    <dt>STEP 4</dt>
                    <dd>
                        <p className="tit">{t("buy_online.step2_4_title")}</p>
                        <p className="exp" dangerouslySetInnerHTML={{__html:t("buy_online.step2_4_desc")}}></p>
                    </dd>
                </dl>
            </div>
            {/* <ul className="apk">
                <li className="apple"><a href="https://apps.apple.com/kr/app/id1131125136"><b>App Store</b> download</a></li>
                <li className="google"><a href="https://play.google.com/store/apps/details?id=com.travolution.discover"><b>Google Play</b> download</a></li>
                {isShowApk()}
            </ul> */}
            { (location.pathname.startsWith('/appnv')) ? <></> :
            <AppDownload type="bold"></AppDownload> }

            <p className="use_tit small">{t("buy_online.other_affiliates_title")}</p>
            <p className="use_exp" dangerouslySetInnerHTML={{__html:t("buy_online.other_affiliates_desc")}}></p>
            <div className="exp_box center">
                <div className="m_box">
                    <img src={partner01} alt="KLOOK" />
                    <img src={partner02} alt="kkday" />
                    <img src={partner03} alt="ctrip" />
                    <img src={partner04} alt="한유왕" />
                    <img src={partner05} alt="HANCHAO" />
                    <img src={partner06} alt="KONEST" />
                    <img src={partner07} alt="Experia" />
                    <img src={partner08} alt="Seoul Pass" />
                </div>
            </div>

            <p className="use_tit small">{t("buy_online.refund_title")}</p>
            <div className="exp_box last" dangerouslySetInnerHTML={{__html:t("buy_online.refund_desc")}}>
            </div>

        </div>
    );
};

export default GuideBuyOnline;