import { atom, selector, selectorFamily, useRecoilState, useRecoilValue } from 'recoil';
import { ISearchV2RespResult } from '../pages/search/ISearchV2';
import AxiosConfig from './AxiosConfig';

export interface ICodeInfo {
  groupCd: string;
  codeId: number;
  codeCd: string;
  codeName: string;
  createDate: string;
  codeName1: string;
  codeName2: string;
  codeName3: string;
  codeName4: string;
  codeName5: string;
}

type ICodeInfoParams = {
  groupCd: string;
}

export const codeInfoAtom = atom<ICodeInfo[]>({
    key: 'codeInfoAtomState', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
});


export const codeInfoSelector =  selector<ICodeInfo[]>({
    key: 'codeInfo', // unique ID (with respect to other atoms/selectors)
    get: async ({get}) => {
        const response = await fetchCodeInfoes();
        return response;
    }
});

export const codeInfoByGroupCd = selectorFamily<ICodeInfo[], ICodeInfoParams>({
  key: 'codeInfoByGroupCd', // unique ID (with respect to other atoms/selectors)
  get: (params: ICodeInfoParams) => async () => {
    const codeInfoes = await fetchCodeInfoes();
		const filteredCodeInfoByGroupCd = codeInfoes.filter((item:ICodeInfo)=> {
			return item.groupCd === params.groupCd
		})
		return filteredCodeInfoByGroupCd;
  }
});

export const fetchCodeInfoes = async ():Promise<ICodeInfo[]> => {
    let url = "/api2/codeInfo";
    const res = await AxiosConfig.get(url);
    if (res.status === 200) {
      //console.log("CodeInfoService::6::" + res.data)
      return res.data;
    }
    return [];
}

export const offlineResultAtom = atom<ISearchV2RespResult>({
    key: 'offlineResultAtomState', // unique ID (with respect to other atoms/selectors)
    default: {} as ISearchV2RespResult, // default value (aka initial value)
});
export const recipientResultAtom = atom<ISearchV2RespResult>({
    key: 'recipientResultAtomState', // unique ID (with respect to other atoms/selectors)
    default: {} as ISearchV2RespResult, // default value (aka initial value)
});
export const tourResultAtom = atom<ISearchV2RespResult>({
    key: 'tourResultAtomState', // unique ID (with respect to other atoms/selectors)
    default: {} as ISearchV2RespResult, // default value (aka initial value)
});
export const productResultAtom = atom<ISearchV2RespResult>({
    key: 'productResultAtomState', // unique ID (with respect to other atoms/selectors)
    default: {} as ISearchV2RespResult, // default value (aka initial value)
});
export const faqResultAtom = atom<ISearchV2RespResult>({
    key: 'faqResultAtomState', // unique ID (with respect to other atoms/selectors)
    default: {} as ISearchV2RespResult, // default value (aka initial value)
});
export const noticeResultAtom = atom<ISearchV2RespResult>({
    key: 'noticeResultAtomState', // unique ID (with respect to other atoms/selectors)
    default: {} as ISearchV2RespResult, // default value (aka initial value)
});
