import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ProfileContentLeaveIdCompleted = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <div className="layout">
					
            {/* <div className="wrap_leave">
                <h6 className="sec_tit">회원탈퇴가 완료되었습니다.</h6>
                <p className="sec_exp">그동안 디스커버서울패스 서비스를<br />이용해 주셔서 감사합니다.</p>

                <Link to="/" target="_self" className="btn_goToMain">메인으로 가기</Link>
            </div> */}
            <div className="wrap_leave">
                <h6 className="sec_tit">{t("profile_content_leave_completed.title")}</h6>
                <p className="sec_exp" dangerouslySetInnerHTML={{__html:t("profile_content_leave_completed.desc")}}></p>

                <Link to="/" target="_self" className="btn_goToMain">{t("profile_content_leave_completed.btn_go_main")}</Link>
            </div>

        </div>
    );
};

export default ProfileContentLeaveIdCompleted;