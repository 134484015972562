import React, { useEffect, useState, Component, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { render } from "react-dom";
import { Link, useHistory, useLocation } from "react-router-dom";
import Pagination from "react-js-pagination";
// import ReactDOM from "react-dom";
// import Slider from "react-slick";
// import GlobalNavBar from "./GlobalNavBar";
import AxiosConfig from '../../services/AxiosConfig';
import SpotListItem from "./SpotListItem";
import SpotTourNotice from "./SpotTourNotice";
import { globalInfoStore, LANG_TYPES } from "../../stores/GlobalInfoStore";
import { useQueryParam, NumberParam, StringParam, withDefault, useQueryParams } from 'use-query-params';

const classNames = require("classnames");

//'contents-category', 1, 'sub-code1', '고궁'
//'contents-category', 2, 'sub-code2', '박물관'
//'contents-category', 3, 'sub-code3', '액티비티'
//'contents-category', 4, 'sub-code4', '원데이클래스'
//'contents-category', 5, 'sub-code5', '공연'
//'contents-category', 6, 'sub-code6', '여행서비스'
//'contents-category', 7, 'sub-code7', '교통'
//'contents-category', 8, 'sub-code8', '문화/전시'
//'contents-category', 9, 'sub-code9', '쇼핑'




interface ContentsCategory {
	subCode?:number,
	id:string,
	title:string
}

interface ISpotInfo  {
    [key: string]: any;
}

// interface ISearchParam {
// 	category: number;
// 	subFilter: string;
// 	searchKeyword: string;
// 	page:number;
// }

const Spot = (props:any) => {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const urlParam= location.search;
	console.log("urlParam::" + urlParam); //?searchKeyword=홍길동 & subCode=1 & page=1

	const params = new URLSearchParams(urlParam);
	let paramSearchKeyword = params.get("searchKeyword");
	// let paramSubCode = params.get("subCode");
	// let paramPage = params.get("page");
	// let paramSubFilter  =params.get('subFilter');

	const SPOT_MENU = [
		{subCode:0,id:'li01',title:t("spot.cate_all")},
		{subCode:1,id:'li02',title:t("spot.cate_history")},
		{subCode:2,id:'li03',title:t("spot.cate_museum")},
		{subCode:3,id:'li04',title:t("spot.cate_entertainment")},
		{subCode:4,id:'li05',title:t("spot.cate_transportation")},
		{subCode:5,id:'li06',title:t("spot.cate_travel")},
		{subCode:6,id:'li07',title:t("spot.cate_experience")},
		{subCode:7,id:'li08',title:t("spot.cate_performance")},
		{subCode:8,id:'li09',title:t("spot.cate_shopping")},
		{subCode:9,id:'li10',title:t("spot.cate_others")},
		// {subCode:10,id:'li11',title:t("spot.cate_one_day_class")}
	];


	const [spotInfo, setSpotInfo] = useState<ISpotInfo>(); //객체
	const [filteredSpotItems, setFilteredSpotItems] = useState([]); //res.data.content의 배열만 만 추출
	// const [spotTourNotices, setSpotTourNotices] = useState([]); //객체
	const [filteredSpotTourNotices, setFilteredSpotTourNotices] = useState([]); //객체

	// console.log("paramSubFilter:: " + paramSubFilter);
	// const [category, setCategory] = useQueryParam("category", NumberParam);//subcode 카테고리
	// const [category, setCategory] = useQueryParam("category", withDefault(NumberParam, (paramSubCode==null)?null:Number(paramSubCode)));//subcode 카테고리
	// const [subFilter, setSubFilter] = useQueryParam("subFilter", withDefault(StringParam, (paramSubFilter==null)?"all":paramSubFilter));
	// const [inputSearchText, setInputSearchText] = useState(paramQuery==null?"":paramQuery);
	// const inputSearchText = useMemo(()=>{},[]);
	// const colorKor = useMemo(() => getColorKor(color), [color]);
	// const [searchKeyword, setSearchKeyword] = useQueryParam("query", withDefault(StringParam, paramQuery==null?"":paramQuery));
	// const [page, setPage] = useQueryParam("page", withDefault(NumberParam, (paramPage==null)?1:Number(paramPage))); //page

	// const [category, setCategory] = useQueryParams("category", NumberParam);//subcode 카테고리
	const [queryParam, setQueryParam] = useQueryParams( {
		"category": NumberParam,
		"subFilter": StringParam,
		"searchKeyword": StringParam,
		"page": NumberParam,
	});
	const { category, subFilter, searchKeyword, page } = queryParam;

	const [inputSearchText, setInputSearchText] = useState(paramSearchKeyword);
	
	if ( inputSearchText != null && inputSearchText != undefined && inputSearchText != "") {
		if (searchKeyword != null && searchKeyword != undefined && searchKeyword != "") {
			paramSearchKeyword = searchKeyword
		} else {
			// setInputSearchText("a")
			paramSearchKeyword = ""
		}
	} 


	// console.log("paramSearchKeyword::" + paramSearchKeyword);
	
	// if (paramSearchKeyword!=null&&paramSearchKeyword!=undefined) {
	// 	setInputSearchText(paramSearchKeyword)
	// }
	

	const [isShowMobileTabMenu, setIsShowMobileTabMenu] = useState(false);
	
	// const [size, setSize] = useState(18);  //관광지 아이템이 모바일은 2개씩 보이고 웹은 3개씩 보이므로 6의 배수로 설정.
	const size = 18;  //관광지 아이템이 모바일은 2개씩 보이고 웹은 3개씩 보이므로 6의 배수로 설정.
	// const [sorts, setSorts] = useState([]); //sort['updateDate,desc','uid,asc']
	// const [totalPages, setTotalPages] = useState(0);

	const [totalCount, setTotalCount] = useState(0)


	/**
	 * 샘플: @URL http://localhost:28082/api2/contentsInfo?page=1&size=20&sort=updateDate
	 * @param page 
	 * @param size 
	 * @param sorts 
	 */
	const fetchSpots = async (page:number, size:number, sorts:[]) => {
		// setQuery({
		// 	"category": paramSubCode == null ? null : Number(paramSubCode),
		// 	"subFilter": paramSubFilter == null ? "all" : paramSubFilter,
		// 	"searchKeyword": paramQuery == null ? "" : paramQuery,
		// 	"page": paramPage == null ? 1 : Number(paramPage),
		// }, "push");
	// const fetchSpots = async (contentsCategory:ContentsCategory, sorts:[]) => {
		// let url = `/api2/contentsInfo?page=${page}&size=${size}`;
		// let url = `/api2/tourInfo?langCode=${globalInfoStore.currentLangType.langCode}&page=${page}&size=${size}`;
		let url = `/api2/tourInfo?langCode=${globalInfoStore.currentLangType.langCode}&size=${size}`;
		// setFilteredPassItems(res.data.content.filter((it:any) => it.fixed == 'Y'))
		// let url = `/api2/contentsInfo?subcode=${subcode}&page=${page}&size=${size}`;

		if ( page != undefined ) {
			url += `&page=${page}`;
		}

		if (sorts.length > 0){
			url += (sorts || []).map((item)=>`&sort=${item}`);
		} 

		// if (category != null && category.id != 'li01') { //카테고리임..
		// 	url += `&subCode=${category.subCode}`;
		// }
		if (category != null && category != undefined) { //카테고리임..
			url += `&subCode=${category}`;
		}

		if (searchKeyword != "" && searchKeyword != undefined){
            url += `&searchKeyword=${searchKeyword}`
        }

		if (subFilter == "free"){
			url += `&code=1`
		} else if (subFilter == "discount") {
			url += `&code=2`
		}

		// if (searchKeyword != ""){
        //     url += `&searchKeyword=${searchKeyword}`
        // }

        console.info("url>>>>>" + url);
        const res = await AxiosConfig.get(url);
        console.log(res.data);
		setSpotInfo(res.data);
		// setTotalPages(res.data.totalPages);
		setTotalCount(res.data.totalElements);
		setFilteredSpotItems(res.data.content.filter((it:any) => it))
	};

	
	const fetchSpotTourNotice = async () => {
		const url = `/api2/tourNotice`;
		const res = await AxiosConfig.get(url);

		if (res.status == 200) {
			// setSpotTourNotices(res.data);
			setFilteredSpotTourNotices(res.data.content.filter((it: any) => it));
		}
	}


    useEffect(() => {
		fetchSpots(page, size, []);
		fetchSpotTourNotice();
		// searchSpot
		window.scrollTo(0, 0);
	}, [page, subFilter, category, searchKeyword]);

	// useEffect(() => {
	// 	setInputSearchText(paramSearchKeyword)
	// 	console.log("paramSearchKeyword", paramSearchKeyword)}
	// , [inputSearchText]) //add the state in dependency array and this useEffect will run whenever state changes//
	
	/**
	 * 샘플: @URL http://localhost:28082/api2/contentsInfo?page=1&size=20&sort=updateDate
	 * @param page 
	 * @param size 
	 * @param sorts 
	 */
	// const fetchSpotSearch = async (page:number, size:number, sorts:[]) => {
	// 	// const fetchSpots = async (contentsCategory:ContentsCategory, sorts:[]) => {
	// 		setLoading(true);
	// 		// let url = `/api2/contentsInfo/search?page=${page}&size=${size}&text=${inputSearchText}`;
	// 		let url = `/api2/tourInfo?page=${page}&size=${size}&text=${inputSearchText}`;

	// 		console.info("url>>>>>" + url);
	// 		const res = await AxiosConfig.get(url);
	// 		console.log(res.data);
	// 		setSpotInfo(res.data);
	// 		setTotalPages(res.data.totalPages);
	// 		setFilteredSpotItems(res.data.content.filter((it:any) => it))
	// 		setLoading(false);
	// 	};

	const isSubMenuActive = (activeSubCode:number) => {

		// if (activeName.id == category.id) {
		if (activeSubCode == category) {
			// setTabTitle("전체");//FIXME: 무한루프에 빠짐.
			return true;
		}
		
		return false;
	}

	const handleSetCategory = (category2:number) => {
		setQueryParam({
			"category": category2,
			"subFilter": queryParam.subFilter,
			"searchKeyword": queryParam.searchKeyword,
			"page": 1,
		}, "push");

		// console.log("category::" + category2)
	}

	const toggleMobileTabMenu = () => {
		setIsShowMobileTabMenu(!isShowMobileTabMenu);
	}

	// const handleClickLikePass = () => {
    //     alert("handleClickLikePass")
    // }

	const renderSpotTourNoticeItems = (filteredSpotTourNotices || []).map((item) =>{
        return (<SpotTourNotice key={item.uid} content={item} ></SpotTourNotice>)
    });

	const renderSpotItems = (filteredSpotItems || []).map((item) =>{
        return (<SpotListItem key={item.uid} content={item} ></SpotListItem>)
    });

	const handlePageClick = (pageNumber: any) => {
		setQueryParam({
			"category": queryParam.category,
			"subFilter": queryParam.subFilter,
			"searchKeyword": queryParam.searchKeyword,
			"page": pageNumber,
		}, "push");
		// page = pageNumber
	};

	const onSearchTextChange = (e: any) => {
        // console.log(e.type + "::", e.target.value);
        console.log("change::", e.target.value);
		// inputSearchText = e.target.value
		setInputSearchText(e.target.value)
	}
	
	const handleSearch = (e: any) => {
        /** TODO: 검색어 필터가 걸리고 다시 풀려고 할 경우에 대한 테스트 코드 필요...*/
        //INPUT 은 비엇고 searchKeyword는 있을 경우 filter를 제거하고 검색시도한다
        if (inputSearchText == null)  return;
        if (inputSearchText == "" && searchKeyword == "") { alert("검색어를 입력하세요."); return; }

		// setQuery({
		// 	"category": query.category,
		// 	"subFilter": query.subFilter,
		// 	"searchKeyword": inputSearchText,
		// 	"page": query.page,
		// }, "push");

		setQueryParam({
			"category": undefined,
			"subFilter": undefined,
			"searchKeyword": inputSearchText,
			"page": 1,
		}, "push");
    }

    const onSearchKeyDown = (e: any) => {
        if (e.keyCode === 13) {
			/** TODO: 검색어 필터가 걸리고 다시 풀려고 할 경우에 대한 테스트 코드 필요...*/
			//INPUT 은 비엇고 searchKeyword는 있을 경우 filter를 제거하고 검색시도한다
			if (inputSearchText == null)  return;
			if (inputSearchText == "" && searchKeyword == "") { alert("검색어를 입력하세요."); return; }

			setQueryParam({
				"category": undefined,
				"subFilter": undefined,
				"searchKeyword": inputSearchText,
				"page": 1,
			}, "push");
        }
    }
	
    const handleSubFilter = (pSubFilter:string) => {
		setQueryParam({
			"category": queryParam.category,
			"subFilter": pSubFilter,
			"searchKeyword": queryParam.searchKeyword,
			"page": 1,
		}, "push");
	}

	const isSubFilterActive = (pSubFilter:string) => {
		if (subFilter == pSubFilter)
			return true;
		return false;
	}

	const getTotalCount = () => {
		return t("spot.total_prefix") + " " + totalCount + t("spot.total_postfix")
	}

	const SpotPager = () => {

        if (spotInfo == undefined || spotInfo.totalPages < 2)
            return (<></>)

        return (
            <Pagination
			    innerClass={'board_pager'}
				// activeClass={'active'}
				// activeLinkClass={'active'}
                activePage={page}
                itemsCountPerPage={size}
				totalItemsCount={spotInfo.totalElements}
                pageRangeDisplayed={5}
				onChange={(pageNumber)=>handlePageClick(pageNumber)}
				itemClassFirst={'btn_pprv'}
				itemClassPrev={'btn_prev'}
				itemClassNext={'btn_next'}
				itemClassLast={'btn_nnxt'}
            />
        )
	}

  return (
	<div className="layout">

		{/* <SpotTourNotice></SpotTourNotice> */}
		{renderSpotTourNoticeItems}

		{/* <!-- span은 모바일에서만 보입니다. 클릭한 li의 클래스명과 텍스트를 함께 가져와 주세요. --> */}
		<div className={classNames({top_select:true, m_active:isShowMobileTabMenu})} onClick={toggleMobileTabMenu}>
			<span className='li01'>{(category == null)?SPOT_MENU[0].title:SPOT_MENU[category].title}</span>
			<ul>
				<li className={classNames({li01:true, active:isSubMenuActive(null)})} onClick={()=>handleSetCategory(null)} >{SPOT_MENU[0].title}</li>
				<li className={classNames({li02:true, active:isSubMenuActive(SPOT_MENU[1].subCode)})} onClick={()=>handleSetCategory(SPOT_MENU[1].subCode)} >{SPOT_MENU[1].title}</li>
				<li className={classNames({li03:true, active:isSubMenuActive(SPOT_MENU[2].subCode)})} onClick={()=>handleSetCategory(SPOT_MENU[2].subCode)} >{SPOT_MENU[2].title}</li>
				<li className={classNames({li04:true, active:isSubMenuActive(SPOT_MENU[3].subCode)})} onClick={()=>handleSetCategory(SPOT_MENU[3].subCode)} >{SPOT_MENU[3].title}</li>
				<li className={classNames({li05:true, active:isSubMenuActive(SPOT_MENU[4].subCode)})} onClick={()=>handleSetCategory(SPOT_MENU[4].subCode)} >{SPOT_MENU[4].title}</li>
				<li className={classNames({li06:true, active:isSubMenuActive(SPOT_MENU[5].subCode)})} onClick={()=>handleSetCategory(SPOT_MENU[5].subCode)} >{SPOT_MENU[5].title}</li>
				<li className={classNames({li07:true, active:isSubMenuActive(SPOT_MENU[6].subCode)})} onClick={()=>handleSetCategory(SPOT_MENU[6].subCode)} >{SPOT_MENU[6].title}</li>
				<li className={classNames({li08:true, active:isSubMenuActive(SPOT_MENU[7].subCode)})} onClick={()=>handleSetCategory(SPOT_MENU[7].subCode)} >{SPOT_MENU[7].title}</li>
				<li className={classNames({li09:true, active:isSubMenuActive(SPOT_MENU[8].subCode)})} onClick={()=>handleSetCategory(SPOT_MENU[8].subCode)} >{SPOT_MENU[8].title}</li>
				<li className={classNames({li10:true, active:isSubMenuActive(SPOT_MENU[9].subCode)})} onClick={()=>handleSetCategory(SPOT_MENU[9].subCode)} >{SPOT_MENU[9].title}</li>
				{/* <li className={classNames({li11:true, active:isSubMenuActive(SPOT_MENU[10].subCode)})} onClick={()=>handleSetCategory(SPOT_MENU[10].subCode)} >{SPOT_MENU[10].title}</li> */}
			</ul>
		</div>

		<div className="wrap_sch">
			<fieldset>
				<legend>관광지명 검색</legend>
				<input 
					type="text" title="검색" className="input_sch"
					placeholder={t("spot.enter_name_of_attraction")}
					value={inputSearchText}
					onChange={onSearchTextChange}
					onKeyDown={onSearchKeyDown} />
				<button className='btn_sch' onClick={handleSearch}>검색</button>

				{/* <input type="text" title="검색" className="input_sch" placeholder={t("pass.search_placeholder")}
                    onChange={onSearchTextChange}
                    onKeyDown={(e) => onSearchKeyDown(e)} />
                    <button className="btn_sch" onClick={handleSearch}>검색</button> */}
			</fieldset>
			<div className="left">
  				{/* <span>{t("spot.total_prefix")} {totalCount}{t("spot.total_postfix")}</span> */}
  				<span dangerouslySetInnerHTML={{__html:getTotalCount()}}></span>
				{/* {console.log("subFilter::" + subFilter)} */}
				<button className={classNames({active:isSubFilterActive(undefined)})} onClick={e=>handleSubFilter(undefined)}>{t("spot.spot_all")}</button>
				<button className={classNames({active:isSubFilterActive("free")})}  onClick={e=>handleSubFilter("free")}>{t("spot.spot_free")}</button>
				<button className={classNames({active:isSubFilterActive("discount")})}  onClick={e=>handleSubFilter("discount")}>{t("spot.spot_discount")}</button>
			</div>
		</div>

		<ul className="spot_list">
			{renderSpotItems}
		</ul>

		{/* <ul class="board_pager">
			<li class="btn_pprv"><a href="#" target="_self">맨 앞으로</a></li>
			<li class="btn_prev"><a href="#" target="_self">앞으로</a></li>
			<li class="active"><a href="#" target="_self">1</a></li>
			<li><a href="#" target="_self">2</a></li>
			<li><a href="#" target="_self">3</a></li>
			<li><a href="#" target="_self">4</a></li>
			<li><a href="#" target="_self">5</a></li>
			<li class="btn_next"><a href="#" target="_self">뒤로</a></li>
			<li class="btn_nnxt"><a href="#" target="_self">맨 뒤로</a></li>
		</ul> */}

		<SpotPager></SpotPager>
		
	</div>
  );
};

export default Spot;