import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

const AppMyPassEmpty = () => {
	const { t, i18n } = useTranslation();
	const history = useHistory();

    return (
        <div id="app" className="empty">
			{/* <div className="header">
				<h3 className="tit righteous">MY PASS</h3>
				<button className="btn_close" onClick={history.goBack}>닫기</button>
          		<button className="btn_enroll" onClick={goToPassRegister}>{t("app_my_pass.pass_register")}</button>
			</div> */}

			{/* -------------<p>등록된 패스가 없습니다. <br />패스 구매 또는 등록 후 이용해주세요.</p> */}
			<p dangerouslySetInnerHTML={{__html:t("app_my_pass.no_registered_pass")}}></p>

			<div className="wrap_btn">
				{/* <a href="#" className="btn_register">패스 등록</a>
				<a href="#" className="btn_purchase">패스 구매</a> */}
				<Link to="/appmypass/register" className="btn_register">{t("app_my_pass.register_pass")}</Link>
				<Link to="/app/pass" className="btn_purchase">{t("app_my_pass.buy_pass")}</Link>
			</div>
		
		</div>
    );
};

export default AppMyPassEmpty;