/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { render } from "react-dom";

import Slider from "react-slick";
// import useRootStore from "../../stores/UseRootStore";
// import { globalInfoStore } from "../stores/GlobalInfoStore";
import { observer } from "mobx-react";
import { remove } from "mobx";
import { lang } from "moment";
import { LANG_TYPES, ILanguage, ISiteLanguageInfo } from "../../stores/GlobalInfoStore";
import { useCookie, useLocalStorage } from "react-use";
import { globalInfoStore } from '../../stores/GlobalInfoStore';
// import { AuthFacebook3 } from "../../services/AuthServiceFacebook3";
// import { AuthGoogle3 } from "../../services/AuthServiceGoogle3";
import { AuthFacebook4 } from "../../services/AuthServiceFacebook4";
import { AuthGoogle4 } from "../../services/AuthServiceGoogle4";
import { isPlatform } from "@ionic/react";
import { Plugins } from '@capacitor/core';
import "@codetrix-studio/capacitor-google-auth";
import { FacebookLogin } from "@capacitor-community/facebook-login";
import AxiosConfig from '../../services/AxiosConfig';
// import '@capacitor-community/http';
import { now } from "jquery";
import AppDownload from "../../components/organisms/AppDownload";
import useDspLocalStorage from "../../services/UseDspLocalStorage";
import { isProduction } from "../../services/Util";
import { isMobile } from "react-device-detect";

// const { Http } = Plugins;
// import UseBodyClass from "../../services/UseBodyClass";

const classNames = require("classnames");

const GlobalNavBar = () => {   
	const history = useHistory();
	const location = useLocation();
	const [redirectPage, setRedirectPage, removeRedirectPage] = useDspLocalStorage('redirectPage', {});
	const { t, i18n } = useTranslation();

	let depth1Ref = useRef(Array(10));
	const [showSelectLanguage, setShowSelectLanguage] = useState(false);
    const [inputSearchText, setInputSearchText] = useState("");
	const [siteLanguageInfo, setSiteLanguageInfo] = useState<ISiteLanguageInfo[]>([])
    const fetchSiteLanguageInfo = async () => {
      const url = `/api2/siteLanguageInfo`;
      const res = await AxiosConfig.get(url);
	  if (res.status === 200) {
      	  setSiteLanguageInfo(res.data);
	  }
    }
	useEffect(() => {
		fetchSiteLanguageInfo()
		return () => {}
	}, [])


	const openDepth2 = (ev:any) =>  {
		ev.preventDefault();
		depth1Ref.current.map(el=>{
			console.log(el.classList.remove('m_active'));
		})

		ev.target.parentNode.classList.add('m_active');
	}

    const onSearchTextChange = (e: any) => {
        setInputSearchText(e.target.value);
    }

	const handleSearch = (e: any) => {
		toggleGnb();

		globalInfoStore.setSearchKeyword(inputSearchText);
		history.push({
			pathname: '/app/search/index',
			search: `?page=1&searchKeyword=${inputSearchText}&searchType=total`
		})

	}

	const handleSearchV1 = (e: any) => {
		toggleGnb();

		globalInfoStore.setSearchKeyword(inputSearchText);
		history.push({
			pathname: '/app/searchV1/index',
			search: `?searchKeyword=${inputSearchText}`
		})

	}

	const onSearchKeyDown = (e: any) => {
		if (e.keyCode === 13) { //enter key
			handleSearch(e);
		}
	}

	const onSearchKeyDownV1 = (e: any) => {
		if (e.keyCode === 13) { //enter key
			handleSearchV1(e);
		}
	}


	const toggleGnb = () => {
		globalInfoStore.activeGnb = !globalInfoStore.activeGnb;
	}
	const [isFocusAtSearch, setFocusAtSearch] = useState(false);
	const handleOnFocus = () => {
		setFocusAtSearch(true);
	}

	const handleOnBlur = () => {
		setFocusAtSearch(false);
	}

	const goToHome = () => {
		globalInfoStore.activeGnb = false;
    	history.replace("/app/main")
  	}

    const IsShowApk = () => {
		if (globalInfoStore.currentLangType.i18Str == 'zhChs'){
		  return (<li className="file"><a href="https://play.google.com/store/apps/details?id=com.travolution.discover"><em>APK File download</em></a></li>)
		}
		return (null)
	}

	const isLangActive = (item:ISiteLanguageInfo) => {
		return item.status === 1
	}

	const LanguageList = () => {
		if (siteLanguageInfo === undefined || siteLanguageInfo.length < 1) {
			return null;
		}

		// lang이 비활성화 상태일경우 영어로 기본설정.
		if (globalInfoStore.currentLangType.i18Str !== "en" && siteLanguageInfo[globalInfoStore.currentLangType.langCode - 1].status === 2) {
			globalInfoStore.handleSetLang(LANG_TYPES[1]); //영어로 설정
		}

		return (
			<>
				{isLangActive(siteLanguageInfo[0])?<li id="ko" className={classNames({active:(globalInfoStore.currentLangType.i18Str == "ko")})} onClick={()=>globalInfoStore.handleSetLang(LANG_TYPES[0])} >한국어</li>:null}
				{isLangActive(siteLanguageInfo[1])?<li id="en" className={classNames({active:(globalInfoStore.currentLangType.i18Str == "en")})} onClick={()=>globalInfoStore.handleSetLang(LANG_TYPES[1])} >English</li>:null}
				{isLangActive(siteLanguageInfo[2])?<li id="ja" className={classNames({active:(globalInfoStore.currentLangType.i18Str == "ja")})} onClick={()=>globalInfoStore.handleSetLang(LANG_TYPES[2])} >日文</li>:null}
				{isLangActive(siteLanguageInfo[3])?<li id="zh-chs" className={classNames({active:(globalInfoStore.currentLangType.i18Str == "zhChs")})} onClick={()=>globalInfoStore.handleSetLang(LANG_TYPES[3])} >中文(简体)</li>:null}
				{isLangActive(siteLanguageInfo[4])?<li id="zh-cht" className={classNames({active:(globalInfoStore.currentLangType.i18Str == "zhCht")})} onClick={()=>globalInfoStore.handleSetLang(LANG_TYPES[4])} >中文(繁體)</li>:null}
			</>
		)
	}

	const handleSignIn = () => {
		setRedirectPage(location.pathname);
		history.replace("/app/signin/index/login")
		toggleGnb()
	}
	const handleSignUp = () => {
		setRedirectPage(location.pathname);
		history.replace("/app/signin/index/signup")
		toggleGnb()
	}
	const handleMyPage = () => {
		history.replace("/app/mypage")
		toggleGnb();
	}
	const handleSignOut = async () => {
		removeRedirectPage();
		await globalInfoStore.removeDspJwt();

		if (isPlatform("capacitor")) {
			await Plugins.FacebookLogin.logout();
			await Plugins.GoogleAuth.signOut();
		} else {
			AuthFacebook4.Instance().internalEndSessionCallback();
			AuthGoogle4.Instance().internalEndSessionCallback();
		}
		history.replace({
			pathname: '/app/main'
		})
		toggleGnb();
	}

	const goToLiveChat = () => {
		window.open("http://discoverseoul.channel.io", "_blank")
	  }
	
    return (

		<div className={classNames({gnb:true, active:true, focus:isFocusAtSearch})} id="gnb">

			<a className="btn_home" onClick={goToHome}>홈으로</a>

			<div className="util">
				<ul className="personal">
					{ !globalInfoStore.getDspJwt() && <li><a onClick={handleSignIn}>{t("globalnavbar.sign_in")}</a></li>}
				    { !globalInfoStore.getDspJwt() && <li><a onClick={handleSignUp}>{t("globalnavbar.sign_up")}</a></li>}
					{ globalInfoStore.getDspJwt() && <li><a onClick={handleMyPage}>{t("globalnavbar.mypage")}</a></li>}
					{ globalInfoStore.getDspJwt() && <li><a onClick={handleSignOut}>{t("globalnavbar.sign_out")}</a></li>}
				</ul>
				<div className={classNames({ select: true, active: showSelectLanguage })}  onClick={()=>{setShowSelectLanguage(!showSelectLanguage)}}>
					<p>language</p>
					<ul className="dropdown">
						<LanguageList></LanguageList>
					</ul>
				</div>
				<p className={classNames({seoul_logo:true, active: globalInfoStore.appHeader })}></p>
			</div>

			<div className="left">
				<p className="gnb_tit">MENU</p>
				<ul>
					<li ref={(ref) => { depth1Ref.current[0] = ref }} className={classNames({depth1:true, m_active:(location.pathname.startsWith("/app/guide/index"))})}>
						<Link to="/app/guide/index/passguide" onClick={toggleGnb}>{t("globalnavbar.about_pass")}</Link>
						{/* <a href="#" onClick={openDepth2}>{t("globalnavbar.pass_introduce")}</a>
						<ul className="depth2">
							<li className={classNames({active:(location.pathname.startsWith("/app/guide/index/passguide"))})}><Link to="/app/guide/index/passguide" onClick={toggleGnb}>{t("globalnavbar.about_pass")}</Link></li>
						</ul> */}
					</li>
					<li ref={(ref) => { depth1Ref.current[1] = ref }} className={classNames({depth1:true, m_active:(location.pathname.startsWith("/app/guide/index/buy"))})}>
						<a href="#" onClick={openDepth2}>{t("globalnavbar.how_to_purchase")}</a>
						<ul className="depth2">
							<li className={classNames({active:(location.pathname.startsWith("/app/guide/index/buy/online"))})}><Link to="/app/guide/index/buy/online" onClick={toggleGnb}>{t("globalnavbar.howtopurchase_online")}</Link></li>
							<li className={classNames({active:(location.pathname.startsWith("/app/guide/index/buy/offline"))})}><Link to="/app/guide/index/buy/offline" onClick={toggleGnb}>{t("globalnavbar.howtopurchase_offline")}</Link></li>
							<li className={classNames({active:(location.pathname.startsWith("/app/guide/index/buy/cu"))})}><Link to="/app/guide/index/buy/cu" onClick={toggleGnb}>{t("globalnavbar.howtopurchase_cu")}</Link></li>
							<li className={classNames({active:(location.pathname.startsWith("/app/guide/index/buy/takepass"))})}><Link to="/app/guide/index/buy/takepass" onClick={toggleGnb}>{t("globalnavbar.howtopurchase_pickup")}</Link></li>
						</ul>
					</li>
					<li ref={(ref) => { depth1Ref.current[2] = ref }} className={classNames({depth1:true, m_active:(location.pathname.startsWith("/app/guideindex/how"))})}>
						<a href="#" onClick={openDepth2}>{t("globalnavbar.how_to_use")}</a>
						<ul className="depth2">
							<li className={classNames({active:(location.pathname.startsWith("/app/guide/index/how/freeentry"))})}><Link to="/app/guide/index/how/freeentry" onClick={toggleGnb}>{t("globalnavbar.howtouse_freeentry")}</Link></li>
							<li className={classNames({active:(location.pathname.startsWith("/app/guide/index/how/coupon"))})}><Link to="/app/guide/index/how/coupon" onClick={toggleGnb}>{t("globalnavbar.howtouse_coupon")}</Link></li>
							<li className={classNames({active:(location.pathname.startsWith("/app/guide/index/how/gift"))})}><Link to="/app/guide/index/how/gift" onClick={toggleGnb}>{t("globalnavbar.howtouse_gift")}</Link></li>
							<li className={classNames({active:(location.pathname.startsWith("/app/guide/index/how/registerpass"))})}><Link to="/app/guide/index/how/registerpass" onClick={toggleGnb}>{t("globalnavbar.howtouse_registerpass")}</Link></li>
						</ul>
					</li>
					<li ref={(ref) => { depth1Ref.current[3] = ref }} className={classNames({depth1:true, m_active:(location.pathname.startsWith("/app/pass"))})}>
						<Link to="/app/pass" onClick={toggleGnb}>{t("globalnavbar.buy_pass_subtitle")}</Link>
						{/* <a href="#" onClick={openDepth2}>{t("globalnavbar.buy_pass_title")}</a>
						<ul className="depth2">
							<li className={classNames({active:(location.pathname.startsWith("/app/pass"))})}><Link to="/app/pass" onClick={toggleGnb}>{t("globalnavbar.buy_pass_subtitle")}</Link></li>
						</ul> */}
					</li>
					<li ref={(ref) => { depth1Ref.current[4] = ref }} className={classNames({depth1:true, m_active:(location.pathname.startsWith("/app/spot/index"))})}>
						<Link to="/app/spot/index/list" onClick={toggleGnb}>{t("globalnavbar.attraction_info")}</Link>
						{/* <a href="#" onClick={openDepth2}>{t("globalnavbar.attractions")}</a>
						<ul className="depth2">
							<li className={classNames({active:(location.pathname.startsWith("/app/spot/index/list"))})}><Link to="/app/spot/index/list" onClick={toggleGnb}>{t("globalnavbar.attraction_info")}</Link></li>
						</ul> */}
					</li>
					<li ref={(ref) => { depth1Ref.current[5] = ref }} className={classNames({depth1:true, m_active:(location.pathname.startsWith("/app/spot/transportation"))})}>
						<a href="#" onClick={openDepth2}>{t("globalnavbar.attraction_transportation")}</a>
						<ul className="depth2">
							<li className={classNames({active:(location.pathname.startsWith("/app/spot/transportation/index/topup"))})}><Link to="/app/spot/transportation/index/topup" onClick={toggleGnb}>{t("globalnavbar.attraction_transportation_topup")}</Link></li>
							<li className={classNames({active:(location.pathname.startsWith("/app/spot/transportation/index/arex"))})}><Link to="/app/spot/transportation/index/arex" onClick={toggleGnb}>{t("globalnavbar.attraction_transportation_arex")}</Link></li>
							<li className={classNames({active:(location.pathname.startsWith("/app/spot/transportation/index/bikeseoul"))})}><Link to="/app/spot/transportation/index/bikeseoul" onClick={toggleGnb}>{t("globalnavbar.attraction_transportation_ttareungi")}</Link></li>
							<li className={classNames({active:(location.pathname.startsWith("/app/spot/transportation/index/airportbus"))})}><Link to="/app/spot/transportation/index/airportbus" onClick={toggleGnb}>{t("globalnavbar.attraction_transportation_airportbus")}</Link></li>
						</ul>
					</li>
					<li ref={(ref) => { depth1Ref.current[6] = ref }} className={classNames({depth1:true, m_active:(location.pathname.startsWith("/app/spot/arex"))})}>
						<Link to="/app/spot/arex" onClick={toggleGnb}>{t("globalnavbar.arexqr")}</Link>
						{/* <a href="#" onClick={openDepth2}>{t("globalnavbar.arexqr")}</a>
						<ul className="depth2">
							<li className={classNames({active:(location.pathname.startsWith("/app/spot/arex"))})}><Link to="/app/spot/arex" onClick={toggleGnb}>{t("globalnavbar.arexqr")}</Link></li>
						</ul> */}
					</li>
					<li ref={(ref) => { depth1Ref.current[7] = ref }} className={classNames({depth1:true, m_active:(location.pathname.startsWith("/app/spot/others"))})}>
						<a href="#" onClick={openDepth2}>{t("globalnavbar.attraction_others")}</a>
						<ul className="depth2">
							<li className={classNames({active:(location.pathname.startsWith("/app/spot/others/index/esim"))})}><Link to="/app/spot/others/index/esim" onClick={toggleGnb}>{t("globalnavbar.attraction_others_eSIM")}</Link></li>
						</ul>
					</li>
					<li ref={(ref) => { depth1Ref.current[8] = ref }} className={classNames({depth1:true, m_active:(location.pathname.startsWith("/app/cs"))})}>
						<a href="#" onClick={openDepth2}>{t("globalnavbar.support")}</a>
						<ul className="depth2">
							<li onClick={goToLiveChat}><a>{t("globalnavbar.start_live_chat")}</a></li>
							<li className={classNames({active:(location.pathname.startsWith("/app/cs/index/faq"))})}><Link to="/app/cs/index/faq" onClick={toggleGnb}>{t("globalnavbar.faq")}</Link></li>
							<li className={classNames({active:(location.pathname.startsWith("/app/cs/index/onetoone"))})}><Link to="/app/cs/index/onetoone" onClick={toggleGnb}>{t("globalnavbar.onetoone")}</Link></li>
							<li className={classNames({active:(location.pathname.startsWith("/app/cs/index/notice"))})}><Link to="/app/cs/index/notice" onClick={toggleGnb}>{t("globalnavbar.notice")}</Link></li>
							<li className={classNames({active:(location.pathname.startsWith("/app/cs/index/expirechecker"))})}><Link to="/app/cs/index/expirechecker" onClick={toggleGnb}>{t("globalnavbar.expirechecker")}</Link></li>
						</ul>
					</li>
					{ 
						(isMobile == true) ?
						<>
							<li ref={(ref) => { depth1Ref.current[9] = ref }} className={classNames({depth1:true, m_active:false})}>
								<a href="#" onClick={openDepth2}>{t("globalnavbar.app_download")}</a>
								<ul className="depth2">
									<div className="apk">
										<li className="apple"><a href="https://apps.apple.com/kr/app/id1131125136" target='_blank'><b>App Store</b> download</a></li>
										<li className="google"><a href="https://play.google.com/store/apps/details?id=com.travolution.discover" target='_blank'><b>Google Play</b> download</a></li>
										<li className="file"><a href="https://discoverseoulpass-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/native/apk/app-release.apk" target='_blank'>APK File download</a></li>
									</div>
								</ul>
							</li>
						</>
						: 
						<>
							<li ref={(ref) => {  }} className={classNames({depth1:true, m_active:false})}>
								<a href="#"></a>
								&nbsp;
							</li>
						</>
					}
					
				</ul>
			</div>
			<div className="right">
				<div className="top">
					<p className="gnb_tit">SEARCH</p>
						
						<fieldset>
							<legend>검색 영역 V2</legend>
							<input type="text" title="Search V2" className="input_sch" placeholder={t("globalnavbar.search_placeholder")}
							onFocus={handleOnFocus}
							onBlur={handleOnBlur}
							onChange={onSearchTextChange}
							onKeyDown={(e) => onSearchKeyDown(e)} />
							<button className="btn_sch" onClick={handleSearch}>검색 버튼 V2</button>
						</fieldset>
						
				</div>
				<div className="bottom">
					<ul className="sns">
						<li className="facebook"><a href="https://www.facebook.com/discoverseoulpass/" target="_blank">페이스북</a></li>
						<li className="ins_e"><a href="https://www.instagram.com/discoverseoulpass_en/" target="_blank">인스타그램-영문</a></li>
						<li className="ins_j"><a href="https://www.instagram.com/discoverseoulpass_jp/" target="_blank">인스타그램-일문</a></li>
						<li className="yout"><a href="https://www.youtube.com/channel/UC41VYNJotk4Z_UkDeIheNRw/featured" target="_blank">유튜브</a></li>
						<li className="weibo"><a href="https://www.weibo.com/discoverseoulpass" target="_blank">웨이보</a></li>
						<li className="shao"><a href="https://www.xiaohongshu.com/user/profile/5e9942a600000000010055b6" target="_blank">샤오홍슈</a></li>
					</ul>
					<AppDownload type="em"></AppDownload>
				</div>
				<p className="copy">Copyright.SEOUL TOURISM ORGANIZATION. ALL Rights Reserved.</p>

			</div>

		</div>

    );
}

export default observer(GlobalNavBar);
// export default GlobalNavBar;
