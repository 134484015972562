import React from "react";
import { useTranslation } from "react-i18next";
import { Route } from 'react-router';
import LoginRedirectSignInFacebook from "../auth/RedirectSignInFacebook";
import LoginRedirectSignUpFacebook from "../auth/RedirectSignUpFacebook";
import LoginRedirectSignInGoogle from "../auth/RedirectSignInGoogle";
import LoginRedirectSignUpGoogle from "../auth/RedirectSignUpGoogle";
// import LoginRedirectSignInWeibo from "../auth/_RedirectSignInWeibo";
// import LoginRedirectSignUpWeibo from "../auth/_RedirectSignUpWeibo";
import RedirectWeibo from "../auth/RedirectWeibo";
// import LoginRedirectSignUpWeibo from "../auth/_RedirectSignUpWeibo";
import Navigation from '../navigation/Navigation';
import FindId from './FindId';
import FindPw from './FindPw';
import LoginPage from './LoginPage';
import SignUp from './SignUp';
import SignUpWithEmail from './SignUpWithEmail';
import SignUpWithSns from './SignUpWithSns';
import FindIdCompleted from './FindIdCompleted';
import FindPwCompleted from './FindPwCompleted';
import RedirectGoogle from "../auth/RedirectGoogle";
import RedirectFacebook from "../auth/RedirectFacebook";




const SignInIndex = () => {
  const { t, i18n } = useTranslation();
  

	function SignInTitleLogin() {
    return (
      <div className="layout">
        <h2 className="page_tit">{t("login.signin")}</h2>
      </div>
    );
  }

  function SignInTitleSignup() {
    return (
      <div className="layout">
        <h2 className="page_tit">{t("login.signup")}</h2>
      </div>
    );
  }

  function SignInTitleFindId() {
    return (
      <div className="layout">
        <h2 className="page_tit">{t("login.forgot_email")}</h2>
      </div>
    );
  }

  // function SignInTitleFindIdCompleted() {
  //   return (
  //     <div className="layout">
  //       <h2 className="page_tit">이메일(아이디) 찾기</h2>
  //     </div>
  //   );
  // }

  function SignInTitleFindPw() {
    return (
      <div className="layout">
        <h2 className="page_tit">{t("login.forgot_password")}</h2>
      </div>
    );
  }

  // function SignInTitleFindPwCompleted() {
  //   return (
  //     <div className="layout">
  //       <h2 className="page_tit">이메일(아이디) 찾기</h2>
  //     </div>
  //   );
  // }

  // function depth1Login() {
  //   return (<span>로그인</span>);
  // }

  // function depth1SignUp() {
  //   return (<span>로그인</span>);
  // }

  // function depth1FindId() {
  //   return (<span>이메일(아이디) 찾기</span>);
  // }

  // function depth1FindPw() {
  //   return (<span>로그인</span>);
  // }

    return (
		<section id="container" className="sub">
			<h6 className="hidden">본문</h6>

			<article className="bg sub05">

        <Route exact path="/app/signin/index/login" component={SignInTitleLogin} />
				<Route path="/app/signin/index/signup" component={SignInTitleSignup} />
				<Route path="/app/signin/index/findid" component={SignInTitleFindId} />
				{/* <Route path="/app/signin/index/findid/complete" component={SignInTitleFindIdCompleted} /> */}
				<Route path="/app/signin/index/findpw" component={SignInTitleFindPw} />
				{/* <Route path="/app/signin/index/findpw/complete" component={SignInTitleFindPwCompleted} /> */}
			</article>

			<Navigation></Navigation>

			<article id="content">
				<Route path="/app/signin/index/login" component={LoginPage} />
				<Route exact path="/app/signin/index/signup" component={SignUp} />
				<Route exact path="/app/signin/index/findid" component={FindId} />
        <Route exact path="/app/signin/index/findid/complete" component={FindIdCompleted} />
				<Route exact path="/app/signin/index/findpw" component={FindPw} />
				<Route exact path="/app/signin/index/findpw/complete" component={FindPwCompleted} /> 
        <Route exact path="/app/signin/index/signup/withemail" component={SignUpWithEmail} />
        <Route exact path="/app/signin/index/signup/withsns" component={SignUpWithSns} />

        {/* <Route exact path="/app/signin/index/signup/googleoidc/redirect" component={LoginRedirectGoogleOIDC} /> */}
        <Route exact path="/app/signin/index/signin/google/redirect" component={RedirectGoogle} />
        <Route exact path="/app/signin/index/signin/facebook/redirect" component={RedirectFacebook} />
        {/* <Route exact path="/app/signin/index/signup/google/redirect" component={LoginRedirectSignUpGoogle} /> */}
        {/* <Route exact path="/app/signin/index/signin/facebook/redirect" component={LoginRedirectSignInFacebook} />
        <Route exact path="/app/signin/index/signup/facebook/redirect" component={LoginRedirectSignUpFacebook} /> */}
        {/* <Route exact path="/app/signin/index/signin/weibo/redirect" component={LoginRedirectSignInFacebook} /> */}
        {/* <Route exact path="/app/signin/index/signup/weibo/redirect" component={LoginRedirectSignUpFacebook} /> */}
        {/* <Route exact path="/app/loginredirect/weibo" component={LoginRedirectWeibo} /> 가능한지 확인 안 됨. weibo 계정이 필요할듯..*/}
        {/* <Route exact path="/app/member/cb_weibo" component={LoginRedirectWeibo} />             */}
        <Route exact path="/app/signin/index/weibo/redirect" component={RedirectWeibo} />            
        {/* <Route exact path="/app/signin/index/signin/weibo/redirect" component={LoginRedirectSignInWeibo} />            
        <Route exact path="/app/signin/index/signup/weibo/redirect" component={LoginRedirectSignUpWeibo} />             */}

			</article>

		</section>

    );
};

export default SignInIndex;