import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { globalInfoStore } from "../../../stores/GlobalInfoStore";
import { isPlatform } from "@ionic/react";


const SupportPage = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        if (globalInfoStore.getDspJwt() == null) {
            history.push('/')
        }
        return () => {}
    }, [globalInfoStore.getDspJwt()])

    const phoneCall = () => {
        return false;
      }
    
    return (
        <div className="layout">
					
            <h6 className="sec_tit">{t("supportpage.title")}</h6>
            <p className="sec_exp">{t("supportpage.desc")}</p>

            <ul className="wrap_personal li05 support">
                <li><Link to="/app/cs/index/faq"><span dangerouslySetInnerHTML={{__html:t("supportpage.faq")}}></span></Link></li>
                <li><a href="http://discoverseoul.channel.io" target="_blank"><span dangerouslySetInnerHTML={{__html:t("supportpage.livechat")}}></span></a></li>
                <li><Link to="/app/cs/index/onetoone"><span dangerouslySetInnerHTML={{__html:t("supportpage.email")}}></span></Link></li>
                {
                    ((isPlatform("capacitor") && isPlatform("android")) || (isPlatform("capacitor") && isPlatform("ios"))) ?
                        <>
                            <li><a href="tel:+82 1644-1060" target="_blank"><span dangerouslySetInnerHTML={{__html:t("supportpage.call")}}></span></a></li>
                        </>
                        :
                        <>
                            <li><a href="tel:+82 1644-1060"><span dangerouslySetInnerHTML={{__html:t("supportpage.call")}}></span></a></li>
                        </>
                }
                    
                {/* } */}
                <li><Link to="/app/mypage/inquiry"><span dangerouslySetInnerHTML={{__html:t("supportpage.inquery")}}></span></Link></li>
            </ul>
        </div>
    );
};

export default SupportPage;