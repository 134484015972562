import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { globalInfoStore } from './stores/GlobalInfoStore';

// const CSS_KO_EN = require("./resources/css/koEn.css");
// // const CSS_EN = "./resources/css/koEn.css";
// const CSS_JA = require("./resources/css/jp.css");
// const CSS_ZH_CHS = require("./resources/css/ch01.css");
// const CSS_ZH_CHT = require("./resources/css/ch02.css");

const GlobalStyle_KoEn = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

@font-face {
    /* IBMPlexSansKR-Light */
    font-family: 'IBM';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/IBMPlexSansKR-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    /* IBMPlexSansKR-Text */
    font-family: 'IBM';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/IBMPlexSansKR-Text.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    /* IBMPlexSansKR-Medium */
    font-family: 'IBM-Medium';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/IBMPlexSansKR-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    /* IBMPlexSansKR-SemiBold */
    font-family: 'IBM';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/IBMPlexSansKR-SemiBold.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    /* IBMPlexSansKR-Bold */
    font-family: 'IBM';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/IBMPlexSansKR-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}



body {font-family: 'IBM Plex Sans', 'IBM', '맑은 고딕', 'Malgun Gothic', 돋움, Dotum, 'Apple SD Gothic Neo', sans-serif;}
.righteous {font-family: 'Righteous', cursive;}
`;
const GlobalStyle_JP = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

body {font-family: 'Noto Sans JP', '맑은 고딕', 'Malgun Gothic', 돋움, Dotum, 'Apple SD Gothic Neo', sans-serif;}
.righteous {font-family: 'Righteous', cursive;}
`;
const GlobalStyle_ZH_CHS = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');



body {font-family: 'Noto Sans SC', '맑은 고딕', 'Malgun Gothic', 돋움, Dotum, 'Apple SD Gothic Neo', sans-serif;}
.righteous {font-family: 'Righteous', cursive;}
`;
const GlobalStyle_ZH_CHT = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

/* @font-face {
    font-family: 'Microsoft JhengHei';
    font-style: normal;
    font-weight: 400;
    src: url(../font/microsoft-jhenghei.ttf) format('truetype');
}

@font-face {
    font-family: 'Microsoft JhengHei';
    font-style: normal;
    font-weight: 600;
    src: url(../font/microsoft-jhenghei-bold.ttf) format('truetype');
} */


body {font-family: 'Noto Sans TC', '맑은 고딕', 'Malgun Gothic', 돋움, Dotum, 'Apple SD Gothic Neo', sans-serif;}
.righteous {font-family: 'Righteous', cursive;}
`;



const GlobalCss = () => {

  if (globalInfoStore.currentLangType.i18Str == "ja") {
    return (
      <GlobalStyle_JP></GlobalStyle_JP>
    );
  } else if (globalInfoStore.currentLangType.i18Str == "zhChs") {
    return (
      <GlobalStyle_ZH_CHS></GlobalStyle_ZH_CHS>
    );
  } else if (globalInfoStore.currentLangType.i18Str == "zhCht") {
    return (
      <GlobalStyle_ZH_CHT></GlobalStyle_ZH_CHT>
    );
  }

  return (
    <GlobalStyle_KoEn></GlobalStyle_KoEn>
  );
};

export default GlobalCss;