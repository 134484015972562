import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, Route, useLocation, useHistory, Redirect } from "react-router-dom";
import BoardPager from '../../../components/pager/BoardPager';
import { PrivateRoute } from "../../../routes/PrivateRoute";
import InterestPass from "./InterestPass";
import InterestSpot from "./InterestSpot";


const classNames = require("classnames");

const Interest = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const BOOKMARK_TOUR = "/app/mypage/interest/spot";
    const BOOKMARK_PASS = "/app/mypage/interest/pass";

    useEffect(()=>{
    }, [])

    const isSubMenuActive = (activeName:string) => {
		if ( activeName == location.pathname ) {
			return true;
		} 
		return false;
    }

    return (
        <div className="layout">

            <div className="wrap_like">
                <ul className="tabs">

                    <li className={classNames({active:isSubMenuActive(BOOKMARK_TOUR)})}>
                        <Link className="btn_tab" to="/app/mypage/interest/spot" data-name="tab_content1">{t("interest.tab_content1")}</Link>
                    </li>
                     <li className={classNames({active:isSubMenuActive(BOOKMARK_PASS)})}>
                        <Link className="btn_tab" to="/app/mypage/interest/pass" data-name="tab_content2">{t("interest.tab_content2")}</Link>
                    </li>

                </ul>
                
                {/* <PrivateRoute exact path="/app/mypage/interest" render={()=><Redirect to="/app/mypage/interest/spot" />} /> */}

                {/* <PrivateRoute exact path="/app/mypage/interest" render={()=><Redirect to="/app/mypage/interest/spot" />} /> */}
                

                <div className="tab_area">
                    <PrivateRoute exact path="/app/mypage/interest/spot" component={InterestSpot} />
					<PrivateRoute exact path="/app/mypage/interest/pass" component={InterestPass} />
                </div>
            </div>


        </div>
    );
};

export default Interest;