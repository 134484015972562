import React, { createRef, Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, useHistory } from 'react-router';
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import Spot from './Spot';
import SpotTourNotice from './SpotTourNotice';
import SpotViewNotice from './SpotViewNotice';
import SpotViewRecommendItem from './SpotViewRecommendItem';
import AxiosConfig from '../../services/AxiosConfig';
import { globalInfoStore, LANG_TYPES } from "../../stores/GlobalInfoStore";
import ScrollBound from '../../components/ScrollBound';
import SpotListItem from "./SpotListItem";
import SpotViewHoliday from "./SpotViewHoliday";
import { urlify, getBR } from "../../services/Util";
import { set } from "react-ga";
import { Plugins } from '@capacitor/core';
import { isPlatform } from "@ionic/react";
const { Clipboard } = Plugins;


const classNames = require("classnames");

interface SpotItem {
    [key: string]: any;
}

interface Item {
    [key: string]: any;
}

interface IHolidayInfo {
	contentUid: number;//1
	createDate: string;//"2020-11-27T00:08:25"
	etc: string;//"0"
	everyday: string;//"0"
	fri: string;//"0"
	mon: string;//"0"
	newYear: string;//"0"
	sat: string;//"0"
	status: number;//1
	sun: string;//"0"
	thanksGiving: string;//"0"
	thu: string;//"0"
	tue: string;//"1"
	uid: number;//16
	updateDate: string;//"2021-07-16T09:23:24"
	wed: string;//"0"
	workerUid: number;//41303
}

interface IRelationHourInfo {
	closed: string;
	code: number;
	contentsUid: number;
	createDate: string;
	hour: string;
	storeUid: number;
	subCode: number;
	uid: number;
	updateDate: string;
	weekCode: null
}

interface IWorkingMonthInfo {
	contentUid: number;
	createDate: string;
	endMonth: string;
	endTime: string;
	startMonth: string;
	status: number;
	uid: number;
	updateDate: string;
	workTime: string;
	workerUid: number;
}

const SpotView = (props:any) => {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const [likeActive, setLikeActive] = useState(false);
	const [googleMapOnly, setGoogleMapOnly] = useState(false);

	const SpotSubCodes = [
		// {subCode:0,id:'li01',title:'전체'},
		{ subCode: 0, cate: "cate00",  id: "li01",  title: t("spot.cate_all")},
		{ subCode: 1, cate: "cate01",  id: "li02",  title: t("spot.cate_history")},
		{ subCode: 2, cate: "cate02",  id: "li03",  title: t("spot.cate_museum")},
		{ subCode: 3, cate: "cate03",  id: "li04",  title: t("spot.cate_entertainment")},
		{ subCode: 4, cate: "cate04",  id: "li05",  title: t("spot.cate_transportation")},
		{ subCode: 5, cate: "cate05",  id: "li06",  title: t("spot.cate_travel")},
		{ subCode: 6, cate: "cate06",  id: "li07",  title: t("spot.cate_experience")},
		{ subCode: 7, cate: "cate07",  id: "li08",  title: t("spot.cate_performance")},
		{ subCode: 8, cate: "cate08",  id: "li09",  title: t("spot.cate_shopping")},
		{ subCode: 9, cate: "cate09",  id: "li10",  title: t("spot.cate_others")},
		{ subCode: 10,cate: "cate10",  id: "li11", title: t("spot.cate_one_day_class")}
	  ];

	// let customSlider = React.useRef()
	let customSlider = useRef(Array(1));
	const [spotItem, setSpotItem] = useState<SpotItem>();
	const [spotTourNotices, setSpotTourNotices] = useState([]); //객체
	const [filteredSpotTourNotices, setFilteredSpotTourNotices] = useState([]); //객체

	const [tourInfo, setTourInfo] = useState<Item>();
	const [holidayInfo, setHolidayInfo] = useState<IHolidayInfo[]>();
	const [nearByTourInfoList, setNearByTourInfoList] = useState([]);
	const [fileList, setFileList] = useState([]);
	const [relationHourInfo, setRelationHourInfo] = useState<IRelationHourInfo[]>([]);
	const [workingMonthInfo, setWorkingMonthInfo] = useState<IWorkingMonthInfo[]>([]);
	// const [uid, setUid] = useState();
	// const [langCode, setLangCode] = useState(1);//1=한국어
	const { uid } = props.match.params;
    const iframeMap = useRef(null);

	const scrollEvent = (event:any) => {
		console.log("iframe scroll!!!!!!!!!!!!!!!")
	}

	const handleIframeLoad = () => {
		iframeMap.current.addEventListener('scroll', (e:any)=> {
			scrollEvent(e)
		})
	}


    const divWrapRef = React.useRef<HTMLDivElement>(null) // note the passed in `null` arg
	// let targetElement:any = null;


	const fetchSpot = async () => {
		// let url = `/api2/tourInfo/${props.match.params['uid']}`;
		let url = `/api2/tourInfo/${props.match.params.uid}?langCode=${globalInfoStore.currentLangType.langCode}`;
		// let url = `/api2/tourInfo/233?langCode=${langCode}`;
		const res = await AxiosConfig.get(url);
		setSpotItem(res.data)
		setTourInfo(res.data.tourInfo)
		setLikeActive(res.data.tourInfo.bookmarkUid == 1)
		setHolidayInfo(res.data.holidayInfo)
		setNearByTourInfoList(res.data.nearByTourInfoList)
		setFileList(res.data.tourInfo.fileInfo)
		setRelationHourInfo(res.data.relationHourInfo)
		if (res.data.workingMonthInfo != null) {
			setWorkingMonthInfo(res.data.workingMonthInfo)
		}
		
        console.log(res.data);
	}

	const fetchSpotTourNotice = async () => {
		const url = `/api2/tourNotice`;
		const res = await AxiosConfig.get(url);

		if (res.status == 200) {
			setSpotTourNotices(res.data);
			setFilteredSpotTourNotices(res.data.content.filter((it: any) => it));
		}
	}

	// const fetchNearByTourInfoList = async () => {
	// 	let url = `/api2/tourInfo/${props.match.params.uid}?langCode=${globalInfoStore.currentLangType.langCode}`;
	// 	const res = await AxiosConfig.get(url);
	// 	setNearByTourInfoList(res.data.nearByTourInfoList)
    // }


    useEffect(() => {
		fetchSpot();
		fetchSpotTourNotice();
		// window.scrollTo({
		// 	top: 0,
		// 	left: 0,
		// 	behavior: "smooth"
		//   })
		// document.body.scrollTop = 0

		document.getElementById('root').scrollTo(0, 0);

		return () => {
			// iframeMap.current.removeEventListener('scroll', scrollEvent)
		}
		
	}, [props.match.params.uid, globalInfoStore.currentLangType]);

	// useEffect(() => {
	// 	if (tourInfo != null && tourInfo.bookmarkUid > 0) {
	// 		setLikeActive(true)
	// 	  } else {
	// 		setLikeActive(false)
	// 	  }
	// }, [likeActive]);


     /** 
     * @param productUid
     * @return null
     * @response {   "code": "200",   "contentsUid": 40,   "bookmarkUid": 1,   "desc": "정상" }
     */
    const postLikeSpot = async () => {
		// var data = JSON.stringify({"type":selectedTopic.codeId,"email":email,"content":content});
		console.log("tourInfo.uid " + tourInfo.uid)
		var data = JSON.stringify({
			"contentsUid":tourInfo.uid
		})
  
		const res = await AxiosConfig.post("/api2/bookmarkInfo",
			data,
			{
			  headers: {
				  "Content-Type": "application/json",
			  },
			}
		);
		if (res.status == 200 && res.data.code == "200"){
			tourInfo.bookmarkUid = 1
			setLikeActive(true);
		} else {
		  console.error(res.status + " " + JSON.stringify(res.data));
		}
	};
  
	/**
	 *  {   "code": "200",   "desc": "정상" }
	 */
	const deleteLikeSpot = async () => {
		const res = await AxiosConfig.delete(`/api2/bookmarkInfo/${tourInfo.uid}`);
		if (res.status == 200 && res.data.code == "200"){
			tourInfo.bookmarkUid = 0
			setLikeActive(false);
		} else {
		  console.error(res.status + " " + JSON.stringify(res.data));
		}
	};
	  
	const handleItemClick = (spotId:number) => {
		  history.push("/app/spot/index/view/" + spotId)
	}

	const handleLikeSpot = async (e: any) => {
		if (likeActive == true) {
			deleteLikeSpot();
		} else {
			postLikeSpot();
		}
	};

	const handleCopyClipBoard = async (e: any) => {
		try {
			if(isPlatform("capacitor")) {
				Clipboard.write({
					string: tourInfo.address,
				  });
			} else {
				await navigator.clipboard.writeText(tourInfo.address);
			}
			
			alert(t("spot.copy_address"));
		  } catch (e) {

		  }
	};

	const handleGoToNaverMap = async (e: any) => {
		window.open(tourInfo.naver_map_url, "_blank", "noopener, noreferrer");
	};

	const handleGoToGoogleMap = async (e: any) => {
		window.open(tourInfo.google_map_url, "_blank", "noopener, noreferrer");
	};


    // useEffect(() => {
    //   const fetchPass = async () => {};
    //   fetchPass();
    //   };
    // }, [globalInfoStore.currentLangType]);

    const getImage = (fullpath:string) => {

      if (fullpath == null || fullpath.length < 10 ||
             (!fullpath.startsWith("https://") && !fullpath.startsWith("http://"))
         ) {
           return require("../../resources/img/noimage.png")
      }

       return fullpath;
    }

    const getSubCode = (subCode:number) => {
      return SpotSubCodes.find(item => item.subCode == subCode );
    //   return result.title;
    }


    const state = {
      currentSlide: 1
    };

    const next = () => {
      customSlider.current[0].slickNext();
    }
    const previous = () => {
      customSlider.current[0].slickPrev();
    }

	const SpotItemHeader = () => {

		if((tourInfo.google_map_url != null && tourInfo.google_map_url != '') && (tourInfo.naver_map_url == null || tourInfo.naver_map_url == '')) {
			setGoogleMapOnly(true);
		}

		if (tourInfo == undefined || tourInfo.uid == null)
			return (<></>);
		return (
			<div className="header">
				{tourInfo.subCode!=null?<div className={getSubCode(tourInfo.subCode).cate}>{getSubCode(tourInfo.subCode).title}</div>
				:<div className={getSubCode(0).cate}>{getSubCode(0).title}</div>}
				<div className="titArea">
					{/* <p className="tit">{tourInfo.title}(티켓 교환처: 정문 매표소 28/번 창구)</p> */}
					<p className="tit">{tourInfo.title}</p>
					<p className="tags">
						{tourInfo.code == 1 ? <span className="tag free">{t("spot.spot_free")}</span>: ""}
						{tourInfo.code == 2 ? <span className="tag discount">{t("spot.spot_discount")}</span>: ""}
						{/* <!-- <span className="discount">할인</span> --> */}
						{tourInfo.hot==true?<span className="hot">HOT</span>:""}
						{tourInfo.subCode!=null?<span className="sort">{getSubCode(tourInfo.subCode).title}</span>:""}
						{tourInfo.plusEdition==true?<span>{t("spot.seoul_pass_edition")}</span>:""}
						{tourInfo.fastEnter==true?<span>{t("spot.fast_track")}</span>:""}
					</p>
					<span className={classNames({like:true, active:likeActive})} onClick={(e) => handleLikeSpot(e)}>북마크</span>

					<span className="clipboard" onClick={(e) => handleCopyClipBoard(e)}>클립보드 복사</span>
					{ (tourInfo.naver_map_url != null && tourInfo.naver_map_url != '') ? <span className="navermap" onClick={(e) => handleGoToNaverMap(e)}></span> : "" }
					{ (tourInfo.google_map_url != null && tourInfo.google_map_url != '') ? <span className={classNames({googlemap:true, only:googleMapOnly})} onClick={(e) => handleGoToGoogleMap(e)}>구글 맵</span> : "" }
				</div>
			</div>
		)
	}

	const getWeekdayLang = (weekcode:number) => {

		if (globalInfoStore.currentLangType.i18Str == "ko"){
			switch (weekcode) {
				case 1: return "월요일";
				case 2: return "화요일";
				case 3: return "수요일";
				case 4: return "목요일";
				case 5: return "금요일";
				case 6: return "토요일";
				case 7: return "일요일";
				default: throw "요일 코드가 없음.";
			}
		} else {
			switch (weekcode) {
				case 1: return "MON";
				case 2: return "TUE";
				case 3: return "WED";
				case 4: return "THU";
				case 5: return "FRI";
				case 6: return "SAT";
				case 7: return "SUN";
				default: throw "NO WEEKDAY CODE";
			}
		}
	}

	const RelationHourInfo = () => {
		if (spotItem == undefined || relationHourInfo == undefined) {
			return (<></>);
		}
		let IS_WEEKDAY_SAME_TIME = 1; // 같은시간은 1

		//입장마감시간 추가
		if (relationHourInfo.length == 1 && relationHourInfo[0].subCode == IS_WEEKDAY_SAME_TIME){
			return (<>
				{/* {(globalInfoStore.currentLangType.i18Str=="ko")?"요일별 동일":"Same by weekday"}<br/> */}
				{t("spot.same_by_weekday") + " " + relationHourInfo[0].hour}
				<br/>
				{/* {(relationHourInfo[0].closed != null && relationHourInfo[0].closed.trim() != "")?" " + {t} + " " + relationHourInfo[0].closed:""} */}
				{(relationHourInfo[0].closed != null && relationHourInfo[0].closed.trim() != "")?` ${t("spot.last_entry")}: ${relationHourInfo[0].closed}`:``}
			</>)
		}

		const result = relationHourInfo.map((item) =>{
			return (<Fragment key={item.uid}>
				{getWeekdayLang(item.weekCode) + " " + item.hour}&nbsp;
				<br/>
				{/* {(item.closed != null && item.closed.trim() != "")?" " + "입장마감시간:"  + " " + item.closed:""}<br/> */}
				{(item.closed != null && item.closed.trim() != "")?` ${t("spot.last_entry")}: ${item.closed}`:``}
				<br/>
			</Fragment>)
		})

		return (
				<>
				{result}
				</>
			)
		// // let result = "";
		// const result = relationHourInfo.map((element: any) => {
		// 	return (element.weekCode + " " + element.hour + "<br/>");
		// });
		// return result;
	}

	const get3LetterMonth = (month:string):string => {
		let date = new Date(new Date().getFullYear(), Number(month) - 1, new Date().getDate()); // 2020-06-21
		// let longMonth = date.toLocaleString('en-us', { month: 'long' }); /* June */
		let shortMonth = date.toLocaleString('en-us', { month: 'short' }); /* Jun */
		// let narrowMonth = date.toLocaleString('en-us', { month: 'narrow' }); /* J */
		return shortMonth;
	}

	const makeWorkingMonthInfoAsString = (startMonth:string, endMonth:string, workTime:string, endTime:string):string => {
		if (globalInfoStore.currentLangType.i18Str === "en") {
			return get3LetterMonth(startMonth) + " ~ "
			+  get3LetterMonth(endMonth) + " "
			+  workTime + "  " + t("spot.last_entry2") + " "
			+  endTime
		}

		return startMonth + t("spot.month") + " ~ "
		+  endMonth + t("spot.month") + " "
		+  workTime + "  " + t("spot.last_entry2") + " "
		+  endTime
	}

	const WorkingMonthInfo = () => {
		if (workingMonthInfo == null || workingMonthInfo.length == 0 ) {
			return (<></>)
		}
		return (<>
					<b>{t("spot.monthly_operating_hours")}</b>
					<p>
						{workingMonthInfo.map((element:any) => {
								return (
									<>
									{ makeWorkingMonthInfoAsString(element.startMonth 
									,  element.endMonth 
									,  element.workTime 
									,  element.endTime)} 
										<br/>
									</>
							)
						})}

						{/* 1월 ~ 6월 09:00 ~ 20:00 (입장마감 : 19:00)<br />
						7월 ~ 12월 09:00 ~ 22:00 (입장마감 : 19:00) */}
					</p>
				</>)
	}

	const changeSocialUrl = (str:string):string => {
        const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/);
        let string = str;

        if(urlPattern.test(string)){
          string = string.replace("https://","").replace("http://","");
          string = `http://${string}`;
        }

		return string;
	}


	const renderSpotTourNoticeItems = (filteredSpotTourNotices || []).map((item) =>{
        return (<SpotTourNotice key={item.uid} content={item} ></SpotTourNotice>)
    });

	const SpotSlider = () => {

		if (spotItem == undefined || fileList == undefined) {
			// return <></>
			return (<div>
				<img src={require("../../resources/img/noimage.png")} alt="관광지 이미지" />
			</div>)
		}

		const sliderSettings ={
			// lazyLoad: true,
			// export type LazyLoadTypes = "ondemand" | "progressive";
			// lazyLoad: "ondemand",
			centerMode: true,
			infinite: true,
			arrows: false,
			initialSlide: 0,
			responsive: [
			  {
				breakpoint: 10000,
				settings: {
					centerMode: false,
				//   centerPadding: "20px",
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: false,
					infinite: false,
				},
			  }
			]
			// ,
			// afterChange: ((currentSlide:number) => {
			//   setCurrentSlide(currentSlide + 1);
			// })
		};

		return (
			<Slider {...sliderSettings} ref={(ref) => { customSlider.current[0] = ref}}>
				{fileList.map((fileItem:any) => {
					return (
						<div key={fileItem.uid} style={{position:"relative"}}>
							{
								tourInfo.opened==="1"?
								null
								:
								<div className="closed">
									{/* Temporarily Closed2 */}
								</div>
							}
							<img src={fileItem.fullpath} alt="슬라이드 이미지 aaa" />
						</div>
					)
				})}
			</Slider>
		)
	}



	if (spotItem === undefined || tourInfo === undefined || holidayInfo === undefined) {
		return <></>
	}
    return (

				// <h6 className="hidden">콘텐츠 영역</h6>
					// 				{/* <!-- cate01 ~ cate10까지 있음. --> */}
					// {/* <div className="cate03">tourInfo.title엔터테인먼트</div> */}
		<div className="layout">

			{renderSpotTourNoticeItems}


			<div className="wrap_spotInfo">
				
				<SpotItemHeader></SpotItemHeader>

				<div className="imgArea">
					<div className="slide">
						<SpotSlider></SpotSlider>
						<button className="btn_prev" onClick={previous}>이전 슬라이드</button>
						<button className="btn_next" onClick={next}>다음 슬라이드</button>
					</div>
					{/* <div className="map">
						<iframe src={`${process.env.REACT_APP_API_URL}/html/map?lang=${globalInfoStore.currentLangType.langGis}&lat=${tourInfo.latitude}&lon=${tourInfo.longitude}&tourinfoUid=${tourInfo.uid}&title=${encodeURIComponent(tourInfo.title)}`}
							width="100%" height="100%" />
					</div> */}
				</div>
			</div>

			{/* {if (tourIn)} */}
			<SpotViewNotice notice={tourInfo.notice}></SpotViewNotice>

			

			<div className="wrap_content">
				<dl>
					{/* <dt>디스커버서울패스 혜택</dt> */}
					<dt>{t("spot.dsp_benefits")}</dt>
					<dd dangerouslySetInnerHTML={ {__html:getBR(tourInfo.benefits)}} >
					</dd>
				</dl>
				<dl>
					<dt>{t("spot.information")}</dt>
					<dd>
						<b>{t("spot.operating_hours")}</b>
						<p>
							<RelationHourInfo></RelationHourInfo>
							{/* 월요일 09:00 ~ 20:00 (입장마감 : 19:00)<br />
							화요일 09:00 ~ 20:00 (입장마감 : 19:00)<br />
							수요일 09:00 ~ 20:00 (입장마감 : 19:00)<br />
							목요일 09:00 ~ 20:00 (입장마감 : 19:00)<br />
							금요일 09:00 ~ 20:00 (입장마감 : 19:00)<br />
							토요일 09:00 ~ 20:00 (입장마감 : 19:00)<br />
							일요일 09:00 ~ 20:00 (입장마감 : 19:00)<br /> */}
						</p>
						<WorkingMonthInfo></WorkingMonthInfo>
						<b>{t("spot.closed")}</b>

						<SpotViewHoliday tourInfo={tourInfo} holidayInfo={holidayInfo[0]}></SpotViewHoliday>
						<br />
						
						<b>{t("spot.please_note")}</b>
						<p dangerouslySetInnerHTML={ {__html:urlify(tourInfo.tips)}} >
							{/* 19:00 입장마감<br />
							정문 매표소 28번 창구에서 티켓교환(티켓 교환 가능시간: 09:30 ~ 19:00) */}
						</p>
						<b>{t("spot.booking")}</b>
						<p dangerouslySetInnerHTML={ {__html:urlify(tourInfo.reservation)}} ></p>
					</dd>
				</dl>
				<dl>
					<dt>{t("spot.overview")}</dt>
					<dd>
						<b>{t("spot.address")}</b>
						<p>{tourInfo.address}</p>
						<b>{t("spot.contact")}</b>
						<p>{tourInfo.tel}</p>
						<b>{t("spot.website")}</b>
						<p><a href={changeSocialUrl(tourInfo.homepage)} target="_blank">{tourInfo.homepage}</a></p>
					</dd>
				</dl>
				<dl>
					<dt>{t("spot.introduction")}</dt>
					<dd dangerouslySetInnerHTML={ {__html:getBR(tourInfo.description)}} >
					</dd>
				</dl>
			</div>

			<p className="spot_tit">{t("spot.attractions_nearby")}</p>
			<ul className="spot_list">
				{nearByTourInfoList.map((item:any)=>{
					return (
						<SpotViewRecommendItem key={item.uid} content={item} ></SpotViewRecommendItem>
					)
				})}
			</ul>
		</div>

    );
};

export default SpotView;
