import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { AxiosRequestor } from '../../services/AxiosService';
import AxiosConfig from '../../services/AxiosConfig';
import { notifyListeners } from 'mobx/dist/internal';
import Axios, { CancelTokenSource } from 'axios';

import PassSidebarItem from './PassSidebarItem';
import samplePassSidebar from '../../data/samplePassSidebar.json'
import { globalInfoStore } from "../../stores/GlobalInfoStore";
// import useDspLocalStorage from "../../services/UseDspLocalStorage";
import Slider, { Settings } from "react-slick";
import { useLocalStorage } from "react-use";

const PassSidebar = (props:{show:any}) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    // const [page, setPage] = useState(1); //page
    // const [size, setSize] = useState(4);

    const [recentPass, setRecentPass, removeRecentPass] = useLocalStorage('recentPass', []);
    
    // const [infoes, setInfoes] = useState([]);
    let customSlider = useRef(Array(1));
    // let customSlider = useRef(null);
    const [currentSlide, setCurrentSlide] = useState<number>(1);
    
    useEffect(() => {
        // setInfoes(samplePassSidebar)

        

        // setInfoes(value)
    }, [globalInfoStore.currentLangType, recentPass]);

    const handlePassDetail = (itemUid:any) => {
        // setValue(item)
        history.push("/app/pass/view/"+itemUid)
    }

    const handleDelete = (itemUid: string) => {
        removeRecentPassIndex(itemUid);
    }

    const removeRecentPassIndex = (itemUid:string) => {
        const index = recentPass.findIndex((it)=> it.uid == itemUid);
        recentPass.splice(index, 1);
        setRecentPass(recentPass);
        if(recentPass.length == 0) {
            // console.log("props.show(false)")
            props.show(false)
        }
    }
    
    //TODO: 이미지 경로를 동적으로 생성하지 못함..
    // return (
    //     <div className="slide">
    //         {/* <img src={require(`../../resources/img/${props.imgSrc}.png`)} alt={props.alt} /> */}
    //         <img src={props.imgSrc} />
    //         {/* <ImageSrc src={props.imgSrc}  alt={props.alt} /> */}
    //         <button onClick={()=>handleDelete(props.itemUid)}>삭제하기</button>
    //     </div>
    // );

    const passSidebarItems = (recentPass || []).map((item) =>{
		const currentLang = "ko";
		let langAsk = "koAsk";
		let langAnswer = "koAnswer";

		// return (
		// 	// <PassSidebarItem imgSrc={item.imgSrc} alt={item.alt} itemNumber={item.productUid}></PassSidebarItem>
        //     <PassSidebarItem imgSrc={item.fullpath} itemUid={item.uid}></PassSidebarItem>
        // )
        
        return (
            <div className="slide">
                {/* <img src={require(`../../resources/img/${props.imgSrc}.png`)} alt={props.alt} /> */}
                <img src={item.fullpath} onClick={()=>handlePassDetail(item.uid)}/>
                {/* <ImageSrc src={props.imgSrc}  alt={props.alt} /> */}
                <button onClick={()=>handleDelete(item.uid)}>삭제하기</button>
            </div>
        );
    });

    const next = () => {
        customSlider.current[0].slickNext();
        // console.log("next" + customSlider);
      };
      const previous = () => {
        customSlider.current[0].slickPrev();
        // console.log("prev:" + customSlider);
      };
  
    
    let settings: Settings = {
        infinite: true,
        slidesToScroll: 1,
        rows: 4,
        slidesPerRow: 1,
        arrows: false,
        afterChange: ((currentSlide:number) => {
          setCurrentSlide(currentSlide + 1);
        })
      };

    const [sliderSettings, setSliderSettings] = useState<Settings>(settings);

    return (
        
        <div className="sidebar">
            <div className="slider">
                {/* <Slider>
                    {passSidebarItems}
                </Slider> */}
                <Slider {...sliderSettings} ref={(ref:Slider) => { customSlider.current[0] = ref}}>
                    {passSidebarItems}
                </Slider>
            </div>

            <div className="bottom">
                <button className="btn_prev" onClick={previous}>이전 페이지</button>
                <p className="cnt">
                    {/* <span className="current">1</span> */}
                    <span className="current">{currentSlide}</span>
                    /
                    {/* <span className="total"> {Math.floor((recentPass.length-1)/4)+1}</span> */}
                    <span className="total"> {Math.floor((recentPass.length - 1) / 4) + 1}</span>
                    
                </p>
                <button className="btn_next" onClick={next}>다음 페이지</button>
            </div>
        </div>
    );
};

export default PassSidebar;