import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Router, useHistory, withRouter } from "react-router-dom";
//import { useCookie } from "react-use";
import validator from 'validator' 
import AxiosConfig from '../../services/AxiosConfig'
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import jwt from 'jsonwebtoken';

const classNames = require("classnames");

export interface IQuestionType {
	groupCd: string,
	codeId: number,
	codeCd: string,
	codeName: string
}


const OneToOne = () => {
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const getQuestionTypes = () => {
		return [
			{groupCd:"question-type", codeId: 1, codeCd: 'question-type1', codeName: t("inquiry.topic_item1")},// '일반사항'
            {groupCd:"question-type", codeId: 2, codeCd: 'question-type2', codeName: t("inquiry.topic_item2")},// '환불 및 재발행'
            {groupCd:"question-type", codeId: 3, codeCd: 'question-type3', codeName: t("inquiry.topic_item3")},// '관광지 방문'
            {groupCd:"question-type", codeId: 4, codeCd: 'question-type4', codeName: t("inquiry.topic_item4")},// '교통카드 이용'
            {groupCd:"question-type", codeId: 5, codeCd: 'question-type5', codeName: t("inquiry.topic_item5")},// '어플리케이션'
            {groupCd:"question-type", codeId: 6, codeCd: 'question-type6', codeName: t("inquiry.topic_item6")},// '패스 구매'
            {groupCd:"question-type", codeId: 7, codeCd: 'question-type7', codeName: t("inquiry.topic_item7")},// '기타'
		]
    }
    
 //   const [dspJwt, updateCookie, deleteCookie] = useCookie('dspJwt');
    
    const [inputEmailText, setInputEmailText] = useState("");
    const [inputTextArea, setInputTextArea] = useState("");
    const [email, setEmail] = useState("");
    const [content, setContent] = useState("");
    const [selectedTopic, setSelectedTopic] = useState<IQuestionType>(getQuestionTypes()[0])
    const [isShowMobileTabMenu, setShowMobileTabMenu] = useState(false);
    const [isFocusAtSearch, setFocusAtSearch] = useState(false);
    const decoded:any = jwt.decode(globalInfoStore.getDspJwt(), {complete: true});
    // topic_item1	General	일반사항	General	General	General
    // topic_item6	Purchase	패스 구매	Purchase	Purchase	Purchase
    // topic_item3	Pass Usage	관광지 이용	Pass Usage	Pass Usage	Pass Usage
    // topic_item4	Additional Offers	부가서비스	Additional Offers	Additional Offers	Additional Offers
    // topic_item2	Refunds and Reissuing	환불 및 재발행	Refunds and Reissuing	Refunds and Reissuing	Refunds and Reissuing
    // topic_item5	Application	애플리케이션	Application	Application	Application
    // topic_item7	Others	기타	Others	Others	Others
    //TODO: 번호조정...

    // postInquiry
    const postInquiry = async () => {
        // var data = JSON.stringify({"type":selectedTopic.codeId,"email":email,"content":content});

        var data = JSON.stringify({
            "category":selectedTopic.codeId,
            "code":globalInfoStore.currentLangType.langCode,
            "email":inputEmailText,
            "question":inputTextArea
        })

        const res = await AxiosConfig.post("/api2/csQnaInfo",
            data,
            {
            headers: {
                "Content-Type": "application/json",
            },
            }
        );
        if (res.status == 200 && res.data.code == "200"){
            history.replace("/app/cs/index/onetoone/complete");
        }
    };

    useEffect(() => {
        
        // console.log(decoded.header);
        console.log("decoded.payload.email = " + decoded.payload.email)
        setInputEmailText(decoded.payload.email)
		const types = getQuestionTypes()
		const newFilter = types.find(it=>{
			if (it.codeId == selectedTopic.codeId) {
				return true;
			} 
			return false;
		})
		// initFaqTypes();
		setSelectedTopic(newFilter);
	}, [globalInfoStore.currentLangType]);
     
    const handleSetFilterName = (questionType:IQuestionType) => {
		if (questionType == null) {
			setSelectedTopic(getQuestionTypes()[0])
			return;
		}

		setSelectedTopic(questionType);
	}

    const toggleMobileTabMenu = () => {
		setShowMobileTabMenu(!isShowMobileTabMenu);
	}


    const handleOnFocus = () => {
    //   setFocusAtSearch(true);
    };
    const handleOnBlur = () => {
    //   setFocusAtSearch(false);
            //TODO: 이메일 주소 확인
        if (!validator.isEmail(inputEmailText)) {
            alert("email Error")
        }
        
    };
    const onEmailTextChange = (e: any) => {
      console.log(e.type + ":", e.target.value);
      setInputEmailText(e.target.value);
    };

    const onTextAreaChange = (e: any) => {
        console.log(e.type + ":", e.target.value);
        setInputTextArea(e.target.value);
    };


    const onEmailKeyDown = (e: any) => {
		if (e.keyCode === 13) {

		}
	}

    const handleSubmit = (e: any) => {
        let emailError = "";
        
        //TODO: 이메일 주소 확인
        if (!validator.isEmail(inputEmailText)) {
        //   emailError = "email Error!";
          alert("email Error")
        }
        if (emailError) {
          return false;
        }

        //TODO: 10글자 이상인지 확인아니면 alert

        if (inputTextArea.length < 10){
            alert("Please enter at least 10 characters.")
            return;
        }
        
        // setEmail(inputEmailText);
        // setContent(inputTextArea);
        postInquiry()
	}

    return (
        <div className="layout">
            
            <h6 className="sec_tit">{t("inquiry.title")}</h6>
            <p className="sec_exp">{t("inquiry.desc")}</p>

            <div className="wrap_form">
                <dl>
                    <dt>{t("inquiry.topic_title")}</dt>
                    {/* <dd className="sort active"> */}
                    <dd className={classNames({sort:true, active:isShowMobileTabMenu})} onClick={toggleMobileTabMenu}>
                        <span>{selectedTopic.codeName}</span>
                        <ul>
                            <li onClick={()=>handleSetFilterName(getQuestionTypes()[0])}>{t("inquiry.topic_item1")}</li>
                            <li onClick={()=>handleSetFilterName(getQuestionTypes()[1])}>{t("inquiry.topic_item2")}</li>
                            <li onClick={()=>handleSetFilterName(getQuestionTypes()[2])}>{t("inquiry.topic_item3")}</li>
                            <li onClick={()=>handleSetFilterName(getQuestionTypes()[3])}>{t("inquiry.topic_item4")}</li>
                            <li onClick={()=>handleSetFilterName(getQuestionTypes()[4])}>{t("inquiry.topic_item5")}</li>
                            <li onClick={()=>handleSetFilterName(getQuestionTypes()[5])}>{t("inquiry.topic_item6")}</li>
                            <li onClick={()=>handleSetFilterName(getQuestionTypes()[6])}>{t("inquiry.topic_item7")}</li>
                        </ul>
                    </dd>
                </dl>
                <dl>
                    <dt>{t("inquiry.reply_email")}</dt>
                    <dd>
                        {/* <input type="text" title="이메일 입력" /> */}
                        <input type="text" title="이메일 입력" placeholder=""
                            value={inputEmailText} 
							onFocus={handleOnFocus}
							onBlur={handleOnBlur}
							onChange={onEmailTextChange}
							onKeyDown={(e) => onEmailKeyDown(e)} />
                    </dd>
                </dl>
                <dl>
                    <dt>{t("inquiry.inquiry")}</dt>
                    <dd>
                        <textarea  value={inputTextArea} placeholder={t("inquiry.inquiry_placeholder")}
                            onChange={onTextAreaChange}
                        ></textarea>
                    </dd>
                </dl>

                <button onClick={handleSubmit}>{t("inquiry.btn_send")}</button>
            </div>

            <div className="bottom_box" dangerouslySetInnerHTML={{__html:t("inquiry.bottom_box")}}>

            </div>
        

        </div>
    );
}

export default withRouter(OneToOne);