import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import PopularKeyword from "./PopularKeyword";
import RecentKeyword from "./RecentKeyword";
// import axios, { AxiosResponse } from 'axios';
// import AxiosConfig from '../../services/AxiosConfig';
import { useLocalStorage } from "react-use";
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import { getSearchType, ESearchType, ISearchV2Resp, ISearchV2RespResult, AxiosSearchV2, ISearchSubType, SEARCH_SUB_TYPES, getSearchSubType } from "./ISearchV2";
import { ConsoleLogObserver } from "ionic-appauth";
import { FaqResult, NoticeResult, OfflineResult, ProductResult, RecipientResult, TourResult } from "./SearchV2Component";
import SearchNoResult from "./SearchNoResult";
import { resultsAriaMessage } from "react-select/src/accessibility";
import { useRecoilState } from "recoil";
import { faqResultAtom, noticeResultAtom, offlineResultAtom, productResultAtom, recipientResultAtom, tourResultAtom } from "../../services/CodeInfoService";

const classNames = require("classnames");

const FETCH_SIZE_V2 = 8;

const Search = (props:any) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [isShowMobileTabMenu, setShowMobileTabMenu] = useState(false);

    const urlParam= location.search;
    const [recentKeyword, setRecentKeyword, removeRecentKeyword] = useLocalStorage('recentKeyword', []);

    const [totalResult, setTotalResult] = useState<ISearchV2Resp>();

    const [offlineResult, setOfflineResult] = useState<ISearchV2RespResult>();
    const [recipientResult, setRecipientResult] = useState<ISearchV2RespResult>();
    const [tourResult, setTourResult] = useState<ISearchV2RespResult>();
    const [productResult, setProductResult] = useState<ISearchV2RespResult>();
    const [faqResult, setFaqResult] = useState<ISearchV2RespResult>();
    const [noticeResult, setNoticeResult] = useState<ISearchV2RespResult>();


    // const pageOf = {
    //     offlinePage:1,
    //     recipientPage:1,
    //     tourPage:1,
    //     productPage:1,
    //     faqPage:1,
    //     noticePage:1
    // };

    const [offlinePage, setOfflinePage] = useState<number>(1);
    const [recipientPage, setRecipientPage] = useState<number>(1);
    const [tourPage, setTourPage] = useState<number>(1);
    const [productPage, setProductPage] = useState<number>(1);
    const [faqPage, setFaqPage] = useState<number>(1);
    const [noticePage, setNoticePage] = useState<number>(1);

    // const [offlineResult, setOfflineResult] = useRecoilState<ISearchV2RespResult>(offlineResultAtom);
    // const [recipientResult, setRecipientResult] = useRecoilState<ISearchV2RespResult>(recipientResultAtom);
    // const [tourResult, setTourResult] = useRecoilState<ISearchV2RespResult>(tourResultAtom);
    // const [productResult, setProductResult] = useRecoilState<ISearchV2RespResult>(productResultAtom);
    // const [faqResult, setFaqResult] = useRecoilState<ISearchV2RespResult>(faqResultAtom);
    // const [noticeResult, setNoticeResult] = useRecoilState<ISearchV2RespResult>(noticeResultAtom);



    // {code:0, desc:"전체 탭", type:"total", group:"total", subCode:SEARCH_SUB_TYPES},
    // {code:1, desc:"패스소개 탭", type:"intro", group:"intro", subCode:[getSearchSubType(11),getSearchSubType(12)]},
    // {code:2, desc:"제휴 관광지 탭", type:"tour", group:"tour", subCode:[getSearchSubType(21)]},
    // {code:3, desc:"고개센터 탭", type:"svc", group:"svc", subCode:[getSearchSubType(31)]},
    // {code:4, desc:"패스상품 탭", type:"product", group:"product", subCode:[getSearchSubType(41),getSearchSubType(42)]},



	// const params = new URLSearchParams(urlParam);
    // let paramSearchKeyword = "";
    // if (params.get("searchKeyword") != null ) {
    //     paramSearchKeyword = params.get("searchKeyword")
    // }
    // if (params.get("searchType") != null ) {
    //     paramSearchKeyword = params.get("searchType")
    // }

    const [queryParam, setQueryParam] = useQueryParams( {
		"searchKeyword": StringParam,
        // "searchType": StringParam,
		// "page": NumberParam, //!FIXME 카테로기별 more 페이지를 유지할 것인지.
        // "size": NumberParam 
	});

    // const { searchKeyword, searchType, page } = queryParam;
    const { searchKeyword } = queryParam;

    // const [setPageOfSearchType, setPageOfSearchType] = useState()

    const [ searchType, setSearchType ] = useState('total');
    const [inputSearchText, setInputSearchText] = useState(""); //input 박스

    // console.log("searchKeyword:::::" + searchKeyword + "::::page:::::" + page);


    const findResultIndexBySubType = (totalResult:ISearchV2Resp, subType:string) => {

        for (let i = 0; i < totalResult.resultSet.result.length ; i++ ){
            if (totalResult.resultSet.result[i].resultDocuments.length < 1) {
                return 0;
            }
            if (totalResult.resultSet.result[i].resultDocuments[0].TYPE == subType){
                return i;
            }
        }
    }

    const fetchSearch = async (pSearchType:string, pPage:number, pSize:number) => {
        let url = process.env.REACT_APP_SEARCH_API_URL + `/extensions/TotalSearch?`;
        // searchTerm=검색키워드&searchType=검색탭&page=현재페이지&language=언어
        url = url + `searchTerm=` + encodeURIComponent(searchKeyword);
        url = url + `&searchType=${pSearchType}`;
        url = url + `&page=${pPage}`;
        url = url + `&size=${pSize}`;
        url = url + `&language=${globalInfoStore.currentLangType.searchLang}`;
        console.log("fetchSearch URL:::" + url);

        //*FIXME: CORS 에러 발생가능, 디스커버 서울패스 앱과 웹에서는 모두 로그인 상태인 경우, Authorization Header를 사용중이라 허용하지 않으면 오류 발생
        //const res = await AxiosConfig.get(url); 

        //cors 를 피하기 위해 Authorization을 제거해버림.
        const res = await AxiosSearchV2.instance.get(url,
            {
                headers: {'Content-Type':'application/json;charset=UTF-8'}
            }
         );

        if (res.status == 200 && res.data.status == 200) {
            setTotalResult(res.data);

            // const indexOfOffline = findResultIndexBySubType(res.data, "OFFLINE VENDOR");
            // const indexOfRecipient = findResultIndexBySubType(res.data, "RECIPIENT");
            // const indexOfTour = findResultIndexBySubType(res.data, "TOUR ATTRACTION");
            // const indexOfProduct = findResultIndexBySubType(res.data, "PASS PRODUCT");
            // const indexOfFaq = findResultIndexBySubType(res.data, "FAQ");
            // const indexOfNotice = findResultIndexBySubType(res.data, "NOTICE");

            // setOfflineResult(res.data.resultSet.result[indexOfOffline]);
            // setRecipientResult(res.data.resultSet.result[indexOfRecipient]);
            // setTourResult(res.data.resultSet.result[indexOfTour]);
            // setProductResult(res.data.resultSet.result[indexOfProduct]);
            // setFaqResult(res.data.resultSet.result[indexOfFaq]);
            // setNoticeResult(res.data.resultSet.result[indexOfNotice]);

            setOfflineResult(res.data.resultSet.result[0]);
            setRecipientResult(res.data.resultSet.result[1]);
            setTourResult(res.data.resultSet.result[2]);
            setProductResult(res.data.resultSet.result[5]);
            setFaqResult(res.data.resultSet.result[3]);
            setNoticeResult(res.data.resultSet.result[4]);

            const totalResultCount = res.data.resultSet.result[0].totalSize + res.data.resultSet.result[1].totalSize + res.data.resultSet.result[2].totalSize + res.data.resultSet.result[5].totalSize + res.data.resultSet.result[3].totalSize + res.data.resultSet.result[4].totalSize;
            if (totalResultCount > 0) {
                postLogInsert(searchKeyword);
            }
            // setPopularKeywords(res.data.resultSet.result[0].resultDocuments);
        }
        // setPopularKeywords(res.data.resultSet.result[0].resultDocuments);
    };

    const fetchSearchMore = async (pSearchSubCode:number) => {

        let pPage = 1;
        let pSize = FETCH_SIZE_V2
        let pSearchType = "";

        switch (pSearchSubCode) {
            case 11:
                pPage = offlinePage+1;
                pSearchType = ESearchType.INTRO;
                break;
            case 12:
                pPage = recipientPage+1;
                pSearchType = ESearchType.INTRO;
                break;
            case 21:
                pPage = tourPage+1;
                pSearchType = ESearchType.TOUR;
                break;
            case 31:
                pPage = productPage+1;
                pSearchType = ESearchType.PRODUCT;
                break;
            case 41:
                pPage = faqPage+1;
                pSearchType = ESearchType.SVC;
                break;
            case 42:
                pPage = noticePage+1;
                pSearchType = ESearchType.SVC;
                break;
        }

        // console.log("pPage:::::" + pPage + "  ::: " + pageOf.faqPage)

        let url = process.env.REACT_APP_SEARCH_API_URL + `/extensions/TotalSearch?`;
        // searchTerm=검색키워드&searchType=검색탭&page=현재페이지&language=언어
        url = url + `searchTerm=` + encodeURIComponent(searchKeyword);
        url = url + `&searchType=${pSearchType}`;
        url = url + `&page=${pPage}`;
        url = url + `&size=${pSize}`;
        url = url + `&language=${globalInfoStore.currentLangType.searchLang}`;
        console.log("fetchSearch URL:::" + url);

        //*FIXME: CORS 에러 발생가능, 디스커버 서울패스 앱과 웹에서는 모두 로그인 상태인 경우, Authorization Header를 사용중이라 허용하지 않으면 오류 발생
        //const res = await AxiosConfig.get(url); 

        //cors 를 피하기 위해 Authorization을 제거해버림.
        const res = await AxiosSearchV2.instance.get(url,
            {
                headers: {'Content-Type':'application/json;charset=UTF-8'}
            }
        );
        console.log("fetchSearch URL:::" + url);

        if (res.status == 200 && res.data.status == 200) {

            switch (pSearchSubCode) {
                case 11:
                    offlineResult.resultDocuments.push(...res.data.resultSet.result[0].resultDocuments);
                    setOfflinePage(pPage)
                    break;
                case 12:
                    recipientResult.resultDocuments.push(...res.data.resultSet.result[1].resultDocuments);
                    setRecipientPage(pPage)
                    break;
                case 21:
                    tourResult.resultDocuments.push(...res.data.resultSet.result[0].resultDocuments);
                    setTourPage(pPage)
                    break;
                case 31:
                    productResult.resultDocuments.push(...res.data.resultSet.result[0].resultDocuments);
                    setProductPage(pPage)
                    break;
                case 41:
                    faqResult.resultDocuments.push(...res.data.resultSet.result[0].resultDocuments);
                    setFaqPage(pPage)
                    break;
                case 42:
                    noticeResult.resultDocuments.push(...res.data.resultSet.result[1].resultDocuments);
                    setNoticePage(pPage)
                    break;
            }
        }
    };

    const postLogInsert = async (searchTerm:string) => {
        let url = process.env.REACT_APP_SEARCH_API_URL + `/extensions/LogInsert?`;
        url = url + `searchTerm=${searchTerm}`;
        url = url + `&language=` + globalInfoStore.currentLangType.searchLang;
        console.log("LogInsert URL:::" + url);
        const res = await AxiosSearchV2.instance.get(url);
    }


    useEffect(() => {
        // setInputSearchText(params.get("searchKeyword"))      //!FIXME 
        setInputSearchText(searchKeyword);  
        fetchSearch(searchType, 1, FETCH_SIZE_V2);
        return () => {}
    }, [globalInfoStore.currentLangType, queryParam])

    useEffect(() => {
        console.log("useEffect page!!!!!!!!")
        return () => {}
    }, [offlinePage,
        recipientPage,
        tourPage,
        productPage,
        faqPage,
        noticePage])

    const onSearchTextChange = (e: any) => {
        console.log(e.type + ":", e.target.value);
        setInputSearchText(e.target.value);
    }

    // console.log("recentKeyword2:::" + JSON.stringify(recentKeyword) + "props:::" + props.searchKeyword);
    
    // ! FIXME: 무한루프 주의
    const pushRecentKeyword = (newSearchKeyword:string) => {
        if (newSearchKeyword == null || newSearchKeyword == "") return;

        recentKeyword.indexOf(newSearchKeyword) === -1 ? recentKeyword.unshift(newSearchKeyword) : console.log("This item already exists");
        setRecentKeyword(recentKeyword);
    }
    
    const countBySearchTypes = (pTotalResult:ISearchV2Resp, indexes:number[]) => {
        if (totalResult == null ) return 0 ;

        let totalSize = 0;
        indexes.forEach(item=>(totalSize += pTotalResult.resultSet.result[item].totalSize))
        return totalSize;
    }

    const countBySearchTypes2 = (results:ISearchV2RespResult[]) => {
        if (totalResult == null ) return 0 ;

        let totalSize = 0;

        if (results == null || results.length < 1) return 0
        results.forEach(item=>(totalSize += item.totalSize))
        return totalSize;
    }
    

    const SearchTypeTab = (props:{pSearchType:ESearchType, title:string, indexes:number[]}) => {
        if (totalResult == null || totalResult.resultSet == null || props.indexes.length < 1) {
        return (
            <p className={classNames({active:(props.pSearchType==searchType)})} onClick={(e)=>{handleSearchType(e, props.pSearchType)}}>
                {props.title}(0)
            </p>
        )}
        // let totalSize = 0;
        // props.indexes.forEach(item=>(totalSize += totalResult.resultSet.result[item].totalSize))
        // countBySearchTypes(props.indexes)
        return (
            <p className={classNames({active:(props.pSearchType==searchType)})} onClick={(e)=>{handleSearchType(e, props.pSearchType)}}>
                {props.title}({countBySearchTypes(totalResult, props.indexes)})
            </p>
        );
    }

    // const SearchTypeTab2 = (props:{pSearchType:string, title:string, count:number}) => {
    //     // if (totalResult == null || totalResult.resultSet == null || props.indexes.length < 1) {
    //     // return (
    //     //     <p className={classNames({active:(props.pSearchType==searchType)})} onClick={(e)=>{handleSearchType(e, props.pSearchType)}}>
    //     //         {props.title}(0)
    //     //     </p>
    //     // )}
    //     // let totalSize = 0;
    //     // props.indexes.forEach(item=>(totalSize += totalResult.resultSet.result[item].totalSize))
    //     // countBySearchTypes(props.indexes)
    //     return (
    //         <p className={classNames({active:(props.pSearchType==searchType)})} onClick={(e)=>{handleSearchType(e, props.pSearchType)}}>
    //             {props.title}({props.count})
    //         </p>
    //     );
    // }

    

    const handleSearchType = (e: any, pSearchType: string) => {
        setSearchType(pSearchType);
        // setQueryParam({
        //     "searchKeyword": inputSearchText,
        //     // "searchType": searchType,
        //     "page": 1 //!FIXME 카테로기별 more 페이지를 유지할 것인지.
		// }, "push");
    }
    
    const handleSearch = (e: any) => {
        /** TODO: 검색어 필터가 걸리고 다시 풀려고 할 경우에 대한 테스트 코드 필요...*/
        //INPUT 은 비엇고 searchKeyword는 있을 경우 filter를 제거하고 검색시도한다
        if (inputSearchText == null)  return;
        if (inputSearchText == "") { alert("검색어를 입력하세요."); return; }

        // paramSearchKeyword = inputSearchText;
        pushRecentKeyword(inputSearchText);
        setQueryParam({
            "searchKeyword": inputSearchText,
            // "searchType": "total",
            // "page": 1  //!FIXME 카테로기별 more 페이지를 유지할 것인지.
		}, "push");
        fetchSearch(searchType, 1, FETCH_SIZE_V2);
    }

    const toggleMobileTabMenu = () => {
		setShowMobileTabMenu(!isShowMobileTabMenu);
	}

    const onSearchKeyDown = (e: any) => {
        if (e.keyCode === 13) {
			/** TODO: 검색어 필터가 걸리고 다시 풀려고 할 경우에 대한 테스트 코드 필요...*/
			//INPUT 은 비엇고 searchKeyword는 있을 경우 filter를 제거하고 검색시도한다
            handleSearch(e);
        }
    }

    if (
        totalResult == null ||
        offlineResult  == null ||
        recipientResult  == null ||
        tourResult  == null ||
        productResult  == null ||
        faqResult  == null ||
        noticeResult  == null
    ) {
        return (<></>)
    }

    const getCurrentSearchTypeText = () => {
        switch (searchType) {
            case ESearchType.INTRO: return t("search_v2.about")  + " (" + countBySearchTypes(totalResult, [0,1] ) + ")";
            case ESearchType.TOUR: return t("search_v2.attractions")  + " (" + countBySearchTypes(totalResult, [2] ) + ")";
            case ESearchType.PRODUCT: return t("search_v2.products")  + " (" + countBySearchTypes(totalResult, [5] ) + ")";
            case ESearchType.SVC: return t("search_v2.customer_service")  + " (" + countBySearchTypes(totalResult, [3,4] ) + ")";
            default: return t("search_v2.all")  + " (" + countBySearchTypes(totalResult, [0,1,2,3,4,5] ) + ")";
        }
    }

    const goMore = (restUrl:string) => {
        history.push({
            pathname: restUrl,
        })
    }

    // const getMore = (pSearchType: ISearchSubType, requestPage: number) => {
    //     fetchSearchMore(pSearchType.searchType, requestPage, FETCH_SIZE_V2);
    // }

    return (
        <div className="layout">
					
            <h6 className="hidden">{t("search.unified_search")}</h6>

            <div className="wrap_search">
                <p className="tit">SEARCH</p>
                    <fieldset>
                        <legend>검색 영역</legend>
                        {/* <input type="text" title="검색" className="input_sch" placeholder="검색어를 입력하세요" */}
                        <input type="text" title="검색" className="input_sch" placeholder={t("globalnavbar.search_placeholder")}
                        value={inputSearchText}
                        onChange={onSearchTextChange}
                        onKeyDown={(e) => onSearchKeyDown(e)} />
                        <button className="btn_sch" onClick={handleSearch}>검색 버튼</button>

                        {/* <!-- s: 21.09.13 추가 --> */}
                        {/* 최근검색어 */}
                        {/* {console.log("recentKeyword1:::" + queryParam.searchKeyword)} */}
                        <RecentKeyword key={1}></RecentKeyword>
                        
                        {/* 인기검색어 */}
                        <PopularKeyword key={2}></PopularKeyword>
                        {/* <!-- e: 21.09.13 추가 --> */}
                    </fieldset>
            </div>

            <div className="wrap_result">
                {/* <!-- s: 21.09.13 추가 --> */}
                {/* <div className="sch_btns"> */}
                <div className={classNames({sch_btns:true, m_active:isShowMobileTabMenu})} onClick={toggleMobileTabMenu}>
                {/* <div className={classNames({sch_btns:true, m_active:true})}> */}

						{/* <span className="m_only">{getValueByKey(currentCodeInfo, globalInfoStore.currentLangType.codename)}</span>

						<div className="m_bg">
							<p className={classNames({hidden:false, active:(getFaqTypes(0).codeId===currentCodeInfo.codeId)})} onClick={()=>handleSetFilterName(getFaqTypes(0))}>{getValueByKey(getFaqTypes(0), globalInfoStore.currentLangType.codename)}</p>
							<p className={classNames({hidden:false, active:(getFaqTypes(1).codeId===currentCodeInfo.codeId)})} onClick={()=>handleSetFilterName(getFaqTypes(1))}>{getValueByKey(getFaqTypes(1), globalInfoStore.currentLangType.codename)}</p>
							<p className={classNames({hidden:false, active:(getFaqTypes(2).codeId===currentCodeInfo.codeId)})} onClick={()=>handleSetFilterName(getFaqTypes(2))}>{getValueByKey(getFaqTypes(2), globalInfoStore.currentLangType.codename)}</p>
							<p className={classNames({hidden:false, active:(getFaqTypes(3).codeId===currentCodeInfo.codeId)})} onClick={()=>handleSetFilterName(getFaqTypes(3))}>{getValueByKey(getFaqTypes(3), globalInfoStore.currentLangType.codename)}</p>
							<p className={classNames({hidden:false, active:(getFaqTypes(4).codeId===currentCodeInfo.codeId)})} onClick={()=>handleSetFilterName(getFaqTypes(4))}>{getValueByKey(getFaqTypes(4), globalInfoStore.currentLangType.codename)}</p>
							<p className={classNames({hidden:false, active:(getFaqTypes(5).codeId===currentCodeInfo.codeId)})} onClick={()=>handleSetFilterName(getFaqTypes(5))}>{getValueByKey(getFaqTypes(5), globalInfoStore.currentLangType.codename)}</p>
							<p className={classNames({hidden:false, active:(getFaqTypes(6).codeId===currentCodeInfo.codeId)})} onClick={()=>handleSetFilterName(getFaqTypes(6))}>{getValueByKey(getFaqTypes(6), globalInfoStore.currentLangType.codename)}</p>
							<p className={classNames({hidden:false, active:(getFaqTypes(7).codeId===currentCodeInfo.codeId)})} onClick={()=>handleSetFilterName(getFaqTypes(7))}>{getValueByKey(getFaqTypes(7), globalInfoStore.currentLangType.codename)}</p>
							<p className={classNames({hidden:false, active:(getFaqTypes(8).codeId===currentCodeInfo.codeId)})} onClick={()=>handleSetFilterName(getFaqTypes(8))}>{getValueByKey(getFaqTypes(8), globalInfoStore.currentLangType.codename)}</p>
						</div> */}


                     {/* <span className="m_only">{getValueByKey(currentCodeInfo, globalInfoStore.currentLangType.codename)}</span> */}
                    <span className="m_only">{getCurrentSearchTypeText()}</span>

                    <div className="m_bg">
                        <SearchTypeTab pSearchType={ESearchType.TOTAL} title={t("search_v2.all")} indexes={[0,1,2,3,4,5]}></SearchTypeTab>
                        <SearchTypeTab pSearchType={ESearchType.INTRO} title={t("search_v2.about")} indexes={[0,1]}></SearchTypeTab>
                        <SearchTypeTab pSearchType={ESearchType.TOUR} title={t("search_v2.attractions")} indexes={[2]}></SearchTypeTab>
                        <SearchTypeTab pSearchType={ESearchType.PRODUCT} title={t("search_v2.products")} indexes={[5]}></SearchTypeTab>
                        <SearchTypeTab pSearchType={ESearchType.SVC} title={t("search_v2.customer_service")} indexes={[3,4]}></SearchTypeTab> 

                        {/* <SearchTypeTab2 pSearchType={getSearchType("total").type} title={"전체"} count={countBySearchTypes2([offlineResult,recipientResult,tourResult,productResult,faqResult,noticeResult])}></SearchTypeTab2>
                        <SearchTypeTab2 pSearchType={getSearchType("intro").type} title={"패스 소개"} count={countBySearchTypes2([offlineResult,recipientResult])}></SearchTypeTab2>
                        <SearchTypeTab2 pSearchType={getSearchType("tour").type} title={"제휴 관광지"} count={countBySearchTypes2([tourResult])}></SearchTypeTab2>
                        <SearchTypeTab2 pSearchType={getSearchType("product").type} title={"패스상품"} count={countBySearchTypes2([productResult])}></SearchTypeTab2>
                        <SearchTypeTab2 pSearchType={getSearchType("svc").type} title={"고객센터"} count={countBySearchTypes2([faqResult,noticeResult])}></SearchTypeTab2> */}
                    </div>
                </div>
                {/* <!-- e: 21.09.13 추가 및 수정 --> */}

                {
                (countBySearchTypes2([offlineResult,recipientResult,tourResult,productResult,faqResult,noticeResult])==0)
                ?
                <SearchNoResult searchTerm={searchKeyword} ></SearchNoResult>
                :
                <>
                    {(ESearchType.TOTAL == searchType || ESearchType.INTRO == searchType )?
                        <div className="sch_box">
                            <p className="cate">{t("search_v2.about")} <span dangerouslySetInnerHTML={{__html:t("search_v2.total_prefix") + " " + countBySearchTypes(totalResult, [0,1]) + t("search_v2.total_postfix")}}></span></p>

                            <OfflineResult offlineResult={offlineResult} searchTerm={searchKeyword}></OfflineResult>

                            {(offlineResult.totalSize>offlineResult.resultDocuments.length)?<button className="btn_more" onClick={(e)=>{fetchSearchMore(11)}}>{t("search_v2.more")}</button>:<></>}

                            <RecipientResult recipientResult={recipientResult} searchTerm={searchKeyword}></RecipientResult>

                            {(recipientResult.totalSize>recipientResult.resultDocuments.length)?<button className="btn_more" onClick={(e)=>{fetchSearchMore(12)}}>{t("search_v2.more")}</button>:<></>}

                        </div>:<></>
                    }

                    {(ESearchType.TOTAL == searchType || ESearchType.TOUR == searchType )?
                        <div className="sch_box">
                            <p className="cate">{t("search_v2.attractions")} <span dangerouslySetInnerHTML={{__html:t("search_v2.total_prefix") + " " + countBySearchTypes(totalResult, [2]) + t("search_v2.total_postfix")}}></span></p>
                            
                            <TourResult tourResult={tourResult} searchTerm={searchKeyword}></TourResult>

                            {(tourResult.totalSize>tourResult.resultDocuments.length)?<button className="btn_more" onClick={(e)=>{fetchSearchMore(21)}}>{t("search_v2.more")}</button>:<></>}

                        </div>:<></>
                    }

                    {(ESearchType.TOTAL == searchType || ESearchType.PRODUCT == searchType )?
                        <div className="sch_box">
                            <p className="cate">{t("search_v2.products")} <span dangerouslySetInnerHTML={{__html:t("search_v2.total_prefix") + " " + countBySearchTypes(totalResult, [5]) + t("search_v2.total_postfix")}}></span></p>

                            <ProductResult productResult={productResult} searchTerm={searchKeyword}></ProductResult>

                            {(productResult.totalSize>productResult.resultDocuments.length)?<button className="btn_more" onClick={(e)=>{fetchSearchMore(31)}}>{t("search_v2.more")}</button>:<></>}

                        </div>:<></>
                    }
                    {(ESearchType.TOTAL == searchType || ESearchType.SVC == searchType )?
                        <div className="sch_box">
                            <p className="cate">{t("search_v2.customer_service")} <span dangerouslySetInnerHTML={{__html:t("search_v2.total_prefix") + " " + countBySearchTypes(totalResult, [3,4]) + t("search_v2.total_postfix")}}></span></p>

                            <FaqResult faqResult={faqResult} searchTerm={searchKeyword}></FaqResult>

                            {/* {(faqResult.totalSize>FETCH_SIZE_V2)?<button className="btn_more" onClick={(e)=>{goMore("/app/cs/index/faq")}}>{t("search_v2.more")}</button>:<></>} */}
                            {(faqResult.totalSize>faqResult.resultDocuments.length)?<button className="btn_more" onClick={(e)=>{fetchSearchMore(41)}}>{t("search_v2.more")}</button>:<></>}

                            <NoticeResult noticeResult={noticeResult} searchTerm={searchKeyword}></NoticeResult>

                            {(noticeResult.totalSize>noticeResult.resultDocuments.length)?<button className="btn_more" onClick={(e)=>{fetchSearchMore(42)}}>{t("search_v2.more")}</button>:<></>}
                            
                        </div>:<></>
                    }
                    </>
                }

            </div>


        </div>
    );
};

export default Search;