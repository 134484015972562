//import React from "react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AppDownload from "../../components/organisms/AppDownload";
import { isPlatform } from "@ionic/react";
import { useLocation } from "react-router-dom";


const OtherseSIM = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [isShowPopup, setShowPopup] = useState<boolean>(false);

    const getGraph = () => {
        return t("common.curlang");
    };

    
    return (
        <div className="wrap_use">
            <p className="use_tit">{t("how_eSIM.title")}</p>
            <div className="exp_box" dangerouslySetInnerHTML={{__html:t("how_eSIM.desc")}}></div>

            <div className="step row04 esim">
                <dl className="step01">
                    <dt>STEP 1</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_eSIM.step1_cont")}}></dd>
                </dl>
                <dl className="step02">
                    <dt>STEP 2</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_eSIM.step2_cont")}}></dd>
                </dl>
                <dl className="step03">
                    <dt>STEP 3</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_eSIM.step3_cont")}}></dd>
                </dl>
                <dl className="step04" onClick={(e) => { setShowPopup(true) }}>
                    <dt>STEP 4</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_eSIM.step4_cont")}}></dd>
                </dl>
            </div>

            <div className="scroll">
                ※ {t("how_eSIM.graph1_desc")}
                { getGraph() == 'en' ? <div className="esimgraph1en"></div> : ''}
                { getGraph() == 'ja' ? <div className="esimgraph1jpn"></div> : ''}
                { getGraph() == 'zhChs' ? <div className="esimgraph1ch"></div> : ''}
                { getGraph() == 'zhCht' ? <div className="esimgraph1chz"></div> : ''}
            </div>
            
            <div className="scroll">
                ※ {t("how_eSIM.graph2_desc")}
                { getGraph() == 'en' ? <div className="esimgraph2en"></div> : ''}
                { getGraph() == 'ja' ? <div className="esimgraph2jpn"></div> : ''}
                { getGraph() == 'zhChs' ? <div className="esimgraph2ch"></div> : ''}
                { getGraph() == 'zhCht' ? <div className="esimgraph2chz"></div> : ''}
            </div>

            <div className="scroll">
                ※ {t("how_eSIM.graph3_desc")}
                { getGraph() == 'en' ? <div className="esimgraph3en"></div> : ''}
                { getGraph() == 'ja' ? <div className="esimgraph3jpn"></div> : ''}
                { getGraph() == 'zhChs' ? <div className="esimgraph3ch"></div> : ''}
                { getGraph() == 'zhCht' ? <div className="esimgraph1chz"></div> : ''}
            </div>

            {isPlatform("capacitor") || location.pathname.startsWith('/appnv') ? null
            :
                <div className="exp_box use03">
                    <span>{t("how_arex.app_download")}</span>
                    <AppDownload type="bold"></AppDownload>
                </div>
             }

            <div className="imgpopup" hidden={!isShowPopup}>
                <button className="btn_close" onClick={(e)=>{ setShowPopup(false) }}>닫기</button>
                <div className="cont">
                    <img src={require("../../resources/img/use33org.png")} alt="eSIM 바우처 이미지" />
                </div>
            </div>

        </div>
    );
};

export default OtherseSIM;