import React, { useEffect, useRef, useState } from "react";
import * as IFrame from "../../components/iframe-form";
import { useTranslation } from "react-i18next";
import { Link, Router, useHistory, useLocation, withRouter } from "react-router-dom";
//import { useCookie } from "react-use";
import {v4 as uuid} from "uuid";
import validator from 'validator'
import AxiosConfig from '../../services/AxiosConfig'
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import jwt from 'jsonwebtoken';
// import { isMobileDevice } from "react-select/src/utils";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { EasypayReqForm, EasypayApproveReq } from "./IEasypayWeb";
import { Config } from "../../components/iframe-form";
import { EasypayMobileReqForm, EasypayMobileApproveReq } from "./IEasypayMobile";
import { isPlatform } from "@ionic/react";

/**
 * https://www.npmjs.com/package/react-iframe-form?activeTab=readme
 * @param props {content:PassItem}
 */
const EasypayIframeView = (props:{content:any, user:any, productAmt:any, orderNo:string, easypayApproveReq?:EasypayApproveReq, onClose:any}) => {

    const history = useHistory();
    // const location = useLocation();
    const { t, i18n } = useTranslation();
 //   const [dspJwt, updateCookie, deleteCookie] = useCookie('dspJwt');
    const decoded:any = jwt.decode(globalInfoStore.getDspJwt(), {complete: true});
    const email = decoded.payload.email;
    const uid = decoded.payload.uid;

    let langTitle = "koTitle"; //default
    langTitle = globalInfoStore.currentLangType.title2;
    let address = t("footer.address");

    // const [orderNo, setOrderNo] = useState("")   //가맹점 주문번호

    const postEasypayWebRequest = async (easypayRequest:EasypayApproveReq) => {

        //TODO IFRAME으로 데이터 날려야함. 결과 처리 필요
        var jsonString = JSON.stringify(easypayRequest)

        setfConfig({
            url: process.env.REACT_APP_API_URL + "/easypay/web/easypay_request",
            method:"POST",
            data:{jsonString}
        })
    };

    const postEasypayMobileRequest = async (easypayRequest:EasypayMobileApproveReq) => {

        //TODO IFRAME으로 데이터 날려야함. 결과 처리 필요
        var jsonString = JSON.stringify(easypayRequest)

        setfConfig({
            url: process.env.REACT_APP_API_URL + "/easypay/mobile/easypay_request",
            method:"POST",
            data:{jsonString}
        })
    };




    const makeOrderNo = () => {
        const today = new Date();
        const year  = today.getFullYear();
        const month = today.getMonth() + 1;
        let monthStr:string;
        const date  = today.getDate();
        let dateStr:string;
        const time  = today.getTime();

        if(month < 10)
        {
            monthStr = "0" + month;
        }

        if(date < 10)
        {
            dateStr = "0" + date;
        }

        // return "DSP" + year + month + date + time + "TEST";
        return "DSP" + year + month + date + time;

    }

    let orderNo = makeOrderNo();

    const f_init_web = ():EasypayReqForm => {

        console.log("process.env.REACT_APP_API_URL :: " + process.env.REACT_APP_API_URL + "/easypay/web/normal/order_res");
        return {
            /* 서울패스 DSP */
            EP_mall_id        : process.env.REACT_APP_EP_MALL_ID,                              //가맹점 ID
            // EP_mall_nm        : "Discover Seoul Pass",                    //가맹점명
            EP_mall_nm        : encodeURIComponent("Discover Seoul Pass"),
            EP_order_no       : orderNo ,   //가맹점 주문번호
                                                                                    //결제수단(select)
            EP_currency       : "00",                                    //통화코드 : 00-원
            EP_product_nm     : encodeURIComponent(props.content[langTitle]),
            EP_product_amt    : props.productAmt,                             //상품금액
                                                                                    //가맹점 return_url(윈도우 타입 선택 시, 분기)
            EP_lang_flag      : globalInfoStore.currentLangType.easypay,                                    //언어: KOR / ENG / CHN / JPN
            EP_charset        : "UTF-8",                                 //가맹점 Charset: EUC-KR(default) / UTF-8
            EP_user_id        : email,                               //가맹점 고객 ID
            EP_memb_user_no   : uid,                           //가맹점 고객 일련번호
            EP_user_nm        : encodeURIComponent(props.user.name),                                //가맹점 고객명
            EP_user_mail      : email,                                  //가맹점 고객 이메일
            EP_user_phone1    : props.user.phoneNumber,                            //가맹점 고객 번호1
            EP_user_phone2    : "",                           //가맹점 고객 번호2
            EP_user_addr      : encodeURIComponent(address),                    //가맹점 고객 주소
            EP_product_type   : "0",                                     //상품정보구분 : 0-실물, 1-서비스
            EP_product_expr   : "",                              //서비스기간 : YYYYMMDD

            /* EP_return_url의 도메인 주소를 생략할수 없음. 생략할경우 https://testpg.easypay.co.kr/easypay/web/normal/order_res 가 호출됨. */
            // EP_return_url     : "https://newm.discoverseoulpass.com/easypay/web/normal/order_res",
            EP_return_url     : process.env.REACT_APP_API_URL + "/easypay/web/normal/order_res",      // localhost는 origin 이 null로 들어올수있음.
            // EP_return_url     :  process.env.REACT_APP_API_URL + "/easypay/web/normal/order_res",      // API_URL은 사용불가
            // EP_return_url     :  "http://localhost:3000/easypay/web/normal/order_res",      // Return 받을 URL (HTTP부터 입력)

            /*--신용카드--*/
            // EP_usedcard_code  : "",            //사용가능한 카드 LIST( DSP는 아메스, 마스터, 비자, 다이너스)
            EP_usedcard_code  : process.env.REACT_APP_EP_USEDCARD_CODE,            //사용가능한 카드 LIST( DSP는 아메스, 마스터, 비자, 다이너스)
            EP_quota          : "",                                      //할부개월

            //무이자 여부(Y/N) (select)
            // EP_noinst_flag    : "",                                   // 무이자 여부 (Y/N) Y:무이자, N:일반, 빈값:DB조회
            // EP_noinst_term    : "029-02:03",                             //무이자기간 noinst_flag 값이 Y인 경우 필수 FORMAT->카드코드-할부개월:할부개월,카드코드-할부개월 EXAMPLE->029-02:03,031-03:04:06
                                                                                    //카드사포인트 사용여부(select)
            // EP_point_card     : "029-40",                                //포인트카드 LIST
                                                                                    //조인코드(select)
                                                                                    //국민 앱카드 사용(select)
            //common 에 이미 포함됨
            // EP_window_type:"iframe" , //프론트에서 필요없음
            // EP_mall_id:process.env.REACT_APP_EP_MALL_ID,
            // EP_order_no:"" ,
            // EP_product_nm:"테스트상품" ,
            // EP_product_amt:"1"

            EP_pay_type: "11",  //결제수단
            EP_cert_type:"", //인증타입

            EP_vacct_bank:"", //  가상계좌 사용가능한 은행 LIST
            EP_vacct_end_date:"", //  입금 만료 날짜
            EP_vacct_end_time:"", //  입금 만료 시간
            EP_prepaid_cp:"",// 선불카드 CP // FORMAT->코드:코드: ... :코드 EXAMPLE->CCB:ECB // 빈값 : DB조회

        }
    }

    const f_init_mobile = ():EasypayMobileReqForm => {

        return {
                sp_mall_id	: process.env.REACT_APP_EP_MALL_ID, //	가맹점ID	8	●	KICC발급하는 가맹점 구분값
                sp_mall_nm	: encodeURIComponent("Discover Seoul Pass"), //	가맹점명	50	△	인증창에 표시할 가맹점 명
                sp_order_no	: orderNo, //	가맹점 주문번호	40	O	주문에 대하여 유일한 값이여야 한다.
                sp_pay_type	: "11", //	결제수단	2	●	"11:신용카드
                                    // 21:계좌이체
                                    // 22:가상계좌
                                    // 31:휴대폰
                                    // 50:선불결제
                                    // 60:간편결제
                                    // 81:배치인증"

                sp_currency	: "00", //	통화코드	2	O	00:원
                sp_product_nm	: encodeURIComponent(props.content[langTitle]), //	상품명	40	●	"상품의 정보를 간략하게(40byte 이내) 입력
                                        // 상품 정보 내용에 & 문자를 입력하시면 오류가 발생하오니, 절대 입력하지 마십시오."

                sp_product_amt	: props.productAmt, //	상품금액	10	O	21억 이하, 문자 및 특수문자 허용 불가
                sp_return_url	: process.env.REACT_APP_API_URL + "/easypay/mobile/mobile/order_res", //	가맹점 CALLBACK URL	400	O	인증응답 받을 가맹점URL
                sp_lang_flag	: globalInfoStore.currentLangType.easypay, //	언어	3	△	KOR(default) : 한국어, ENG : 영어, CHN : 중국어, JPN : 일본어. 신용카드만 적용
                sp_charset	: "UTF-8", //	가맹점 CharSet	10	△	EUC-KR(default) / UTF-8
                sp_user_type	: "2", //	사용자구분	1	△	1:일반,2:회원
                sp_user_id	: email, //	가맹점 고객ID	20	△
                sp_memb_user_no	: uid, //	가맹점 고객일련번호	20	△
                sp_user_nm	: encodeURIComponent(props.user.name), //	가맹점 고객명	20	△
                sp_user_mail	: email, //	가맹점 고객 E-mail	30	△	"escr_type 값이 K인 경우 필수
                                        // 에스크로 사용 시 고객에게 확정/거절 메일 보내줌."

                sp_user_phone1	: props.user.phoneNumber, //	가맹점 고객 연락처1	20	△	구매자 연락처(‘-‘없이 입력)
                sp_user_phone2	: "", //	가맹점 고객 연락처2	20	△	구매자 연락처(‘-‘없이 입력)
                sp_user_addr	: encodeURIComponent(address), //	가맹점 고객 주소	200	△
                sp_user_define1	: "", //	추가필드	64	△	BC바우처 결제 시 바우처 물품정보 필드, 인증 응답 파라미터에 불포함
                sp_user_define2	: "", //	추가필드	64	△	인증 응답 파라미터에 불포함.
                sp_user_define3	: "", //	추가필드	32	△	인증 응답 파라미터에 불포함.
                sp_user_define4	: "", //	추가필드	32	△	인증 응답 파라미터에 불포함.
                sp_user_define5	: "", //	추가필드	64	△	인증 응답 파라미터에 불포함.
                sp_user_define6	: "", //	추가필드	64	△	인증 응답 파라미터에 불포함.
                sp_reserved1	: "", //	추가필드	가변	△	인증 응답 파라미터에 포함
                sp_reserved2	: "", //	추가필드	가변	△	인증 응답 파라미터에 포함
                sp_reserved3	: "", //	추가필드	가변	△	인증 응답 파라미터에 포함
                sp_reserved4	: "", //	추가필드	가변	△	인증 응답 파라미터에 포함
                sp_mobilereserved1	: "", //	추가필드	가변	△	인증 응답 파라미터에 포함
                sp_mobilereserved2	: "", //	추가필드	가변	△	인증 응답 파라미터에 포함
                sp_product_type	: "", //	상품정보구분	1	△	0:실물,1:컨텐츠
                sp_product_expr	: "", //	서비스 기간	8	△	YYYYMMDD (전일자 설정 불가)
                sp_app_scheme	: "", //	가맹점APP scheme	가변	△	가맹점에서 iOS 모바일APP으로 서비스 시 필수입력
                sp_window_type	: "", //	윈도우 타입	가변	△	iframe : layer popup, 그외 : submit
                sp_disp_cash_yn	: "", //	현금영수증 입력필드 노출	가변	△	N : 비노출, 계좌이체, 가상계좌, 휴대폰만 적용
                        /*--신용카드--*/
                sp_usedcard_code	: process.env.REACT_APP_EP_USEDCARD_CODE,
                //sp_usedcard_code	: "046:049:050:048", //	사용가능한 카드 LIST	가변	△	"3-3. 신용카드 인증창 호출시 제어코드
                                            // FORMAT->카드코드:카드코드: ... :카드코드
                                            // EXAMPLE->029:027:031
                                            // 가맹점에서 노출할 카드만 취사 선택
                                            // 빈값:DB조회"
                sp_quota	: "", //	할부개월	2	△	"FORMAT->할부개월:할부개월:...:할부개월
                                        // EXAMPLE->00:03:06:...:10
                                        // 00:일시불, 나머지:해당 할부개월
                                        // 노출할 할부개월 선택
                                        // 빈값:DB조회"
                sp_os_cert_flag	: "", //	해외안심클릭 사용여부	1	△	"2:사용, 3:BC 매입 해외안심클릭 사용, JCB의 경우 keyin으로 진행됨
                                            // 대행가맹점은 Visa3DSecure
                                            // 자체가맹점은 해당값이 2 인 경우 Visa3DSecure, 아닌 경우 keyin"
                sp_noinst_flag	: "", //	무이자 여부 (Y/N)	1	△	Y:무이자, N:일반, 빈값:DB조회
                sp_noinst_term	: "", //	무이자 기간	가변	△	"noinst_flag 값이 Y인 경우 필수
                                            // FORMAT->카드코드-할부개월:할부개월,카드코드-할부개월
                                            // EXAMPLE->029-02:03,031-03:04:06"
                sp_set_point_card_yn	: "", //	카드사포인트 사용여부 (Y/N)	1	△	Y:사용,그외:미사용
                sp_point_card	: "", //	포인트카드 LIST	가변	△	"set_point_card_yn 값이 Y인 경우 필수.
                                        // 카드사와 포인트 특약 맺은 부분이 있을 경우 사용.
                                        // FORMAT->카드코드-더할할부개월,카드코드-더할할부개월
                                        // EXAMPLE->029-40,031-60"
                sp_join_cd	: "", //	조인코드	4	△	3-7. 제휴 서비스 코드
                sp_kmotion_useyn	: "", //	국민앱카드 사용유무	1	△	Y:사용, N:미사용, 빈 값: DB조회
                sp_cert_type	: "", //	인증타입	1	△	빈값 :일반, 0 :인증, 1 :비인증
                sp_cpon_useyn	: "", //	즉시할인 사용여부	가변	△	N:즉시할인 비노출, 그외: 노출
        }
    }

    const easypayWebOrderRes = (event:any) => {

            // source          : "easypay-iframe-result",
            // epResCd         : "<%=request.getParameter("epResCd") %>",           // 응답코드          (CA, CAO, CC, CCO, CPC)--%>
            // EP_res_msg        : res_msg,                                             // 응답메세지
            // EP_tr_cd          : "<%=request.getParameter("EP_tr_cd") %>",            // 결제 요청구분
            // EP_ret_pay_type   : "<%=request.getParameter("EP_ret_pay_type") %>",     // 결제수단
            // EP_ret_complex_yn : "<%=request.getParameter("EP_ret_complex_yn") %>",   // 복합결제 여부 (Y/N)
            // EP_card_code      : "<%=request.getParameter("EP_card_code") %>",        // 카드코드 (ISP:KVP카드코드 MPI:카드코드)
            // EP_eci_code       : "<%=request.getParameter("EP_eci_code") %>",         // MPI인 경우 ECI코드
            // EP_card_req_type  : "<%=request.getParameter("EP_card_req_type") %>",    // 거래구분 (0:일반, 1:ISP, 2:MPI, 3:UPOP)
            // EP_save_useyn     : "<%=request.getParameter("EP_save_useyn") %>",       // 카드사 세이브 여부 (Y/N)
            // EP_trace_no       : "<%=request.getParameter("EP_trace_no") %>",         // 추적번호
            // EP_sessionkey     : "<%=request.getParameter("EP_sessionkey") %>",       // 세션키
            // EP_encrypt_data   : "<%=request.getParameter("EP_encrypt_data") %>",     // 암호화전문
            // EP_spay_cp        : "<%=request.getParameter("EP_spay_cp") %>",          // 간편결제 CP 코드
            // EP_card_prefix    : "<%=request.getParameter("EP_card_prefix") %>",      // 신용카드prefix
            // EP_card_no_7      : "<%=request.getParameter("EP_card_no_7") %>",        // 신용카드 앞 7자리
            console.log("from easypay order_res.jsp" + event.data)
            if (event.data.source == "easypay-web-order-res") {
                //if (e.origin !== "http://example.org:8080") return;
                const epResCd = event.data.EP_res_cd == null ? "" : event.data.EP_res_cd
                // console.log("from easypay order_res.jsp2 : " + epResCd);
                //debugger;
                // setResultData(event.data);
                if (epResCd == "0000"){ //0000: 인증응답이 0000이면 정상 - 승인요청처리 필요
                    //정상결제시 서버에 결과 저장

                    const jwt = globalInfoStore.getDspJwt();
                    if (jwt == null){
                        alert("You must log in.");
                        return;
                    }

                    const req:EasypayApproveReq = {
                        jwt          : jwt,
                        tr_cd        : event.data.EP_tr_cd,   // [필수]요청구분
                        trace_no     : event.data.EP_trace_no,// [필수]추적고유번호
                        mall_id      : process.env.REACT_APP_EP_MALL_ID,// 20210311 추가 // [필수]몰아이디 - 서버에도 있음.
                        // order_no     : event.data.EP_order_no    ,// [필수]주문번호  - order_res 에는 orderNo가 없음.
                        order_no     : orderNo    ,// [필수]주문번호
                        encrypt_data : event.data.EP_encrypt_data,// [필수]암호화 데이타
                        sessionkey   : event.data.EP_sessionkey  // [필수]암호화키
                    }

                    postEasypayWebRequest(req);
                    // history.push(`/app/pass/finish/${passItem.uid}/${orderId}`)
                } else { //0000아니면 다 오류.
                    console.log("오류코드 :" + epResCd + " "  + event.data.EP_res_msg )
                }
            }
    }

    const easypayMobileOrderRes = (event:any) => {

            // source                : "easypay-mobile-order-res",
            // sp_res_cd             : "<%=getNullToSpace(request.getParameter("sp_res_cd"))           %>",  // [필수]응답코드
            // sp_res_msg            : "<%=getNullToSpace(request.getParameter("sp_res_msg"))          %>",  // [필수]응답메세지
            // sp_tr_cd              : "<%=getNullToSpace(request.getParameter("sp_tr_cd"))            %>",  // [필수]결제창 요청구분
            // sp_ret_pay_type       : "<%=getNullToSpace(request.getParameter("sp_ret_pay_type"))     %>",  // [필수]결제수단
            // sp_trace_no           : "<%=getNullToSpace(request.getParameter("sp_trace_no"))         %>",  // [선택]추적번호
            // sp_order_no           : "<%=getNullToSpace(request.getParameter("sp_order_no"))         %>",  // [필수]가맹점 주문번호
            // sp_sessionkey         : "<%=getNullToSpace(request.getParameter("sp_sessionkey"))       %>",  // [필수]세션키
            // sp_encrypt_data       : "<%=getNullToSpace(request.getParameter("sp_encrypt_data"))     %>",  // [필수]암호화전문
            // sp_mall_id            : "<%=getNullToSpace(request.getParameter("sp_mall_id"))          %>",  // [필수]가맹점 ID
            // sp_mobilereserved1    : "<%=getNullToSpace(request.getParameter("sp_mobilereserved1"))  %>",  // [선택]여유필드
            // sp_mobilereserved2    : "<%=getNullToSpace(request.getParameter("sp_mobilereserved2"))  %>",  // [선택]여유필드
            // sp_reserved1          : "<%=getNullToSpace(request.getParameter("sp_reserved1"))        %>",  // [선택]여유필드
            // sp_reserved2          : "<%=getNullToSpace(request.getParameter("sp_reserved2"))        %>",  // [선택]여유필드
            // sp_reserved3          : "<%=getNullToSpace(request.getParameter("sp_reserved3"))        %>",  // [선택]여유필드
            // sp_reserved4          : "<%=getNullToSpace(request.getParameter("sp_reserved4"))        %>",  // [선택]여유필드

            // // <!--신용카드-->
            // sp_card_code          : "<%=getNullToSpace(request.getParameter("sp_card_code"))        %>",  // [필수]카드코드
            // sp_eci_code           : "<%=getNullToSpace(request.getParameter("sp_eci_code"))         %>",  // [선택]ECI코드(MPI인 경우)
            // sp_card_req_type      : "<%=getNullToSpace(request.getParameter("sp_card_req_type"))    %>",  // [필수]거래구분
            // sp_save_useyn         : "<%=getNullToSpace(request.getParameter("sp_save_useyn"))       %>",  // [선택]카드사 세이브 여부
            // sp_card_prefix        : "<%=getNullToSpace(request.getParameter("sp_card_prefix"))      %>",  // [선택]신용카드 Prefix
            // sp_card_no_7          : "<%=getNullToSpace(request.getParameter("sp_card_no_7"))        %>",  // [선택]신용카드번호 앞7자리

            // // <!--간편결제-->
            // sp_spay_cp            : "<%=getNullToSpace(request.getParameter("sp_spay_cp"))          %>",  // [선택]간편결제 CP코드

            // // <!--선불카드-->
            // sp_prepaid_cp         : "<%=getNullToSpace(request.getParameter("sp_prepaid_cp"))       %>"
            console.log("from easypay order_res.jsp" + event.data)
            if (event.data.source == "easypay-mobile-order-res") {
                //if (e.origin !== "http://example.org:8080") return;
                const epResCd = event.data.sp_res_cd == null ? "" : event.data.sp_res_cd
                console.log("from easypay order_res.jsp2 : " + epResCd);
                //debugger;
                // setResultData(event.data);
                if (epResCd == "0000"){ //0000: 인증응답이 0000이면 정상 - 승인요청처리 필요
                    //정상결제시 서버에 결과 저장
                    const jwt = globalInfoStore.getDspJwt();
                    if (jwt == null){
                        alert("You must log in.");
                        return;
                    }
                    
                    const req:EasypayMobileApproveReq = {
                        jwt          : jwt,
                        tr_cd        : event.data.sp_tr_cd,   // [필수]요청구분
                        trace_no     : event.data.sp_trace_no,// [필수]추적고유번호
                        mall_id      : process.env.REACT_APP_EP_MALL_ID,// 20210311 추가 // [필수]몰아이디 - 서버에도 있음.
                        // order_no     : event.data.sp_order_no    ,// [필수]주문번호  - order_res 에는 orderNo가 없음.
                        order_no     : orderNo    ,// [필수]주문번호
                        encrypt_data : event.data.sp_encrypt_data,// [필수]암호화 데이타
                        sessionkey   : event.data.sp_sessionkey  // [필수]암호화키
                    }

                    postEasypayMobileRequest(req);
                    // history.push(`/app/pass/finish/${passItem.uid}/${orderId}`)
                } else { //0000아니면 다 오류.
                    console.log("오류코드 :" + epResCd + " "  + event.data.EP_res_msg )
                }
            }
    }

    const receiveMessage = (event:any) => {
        //if (e.origin !== "http://example.org:8080") return;
        // console.log("from /easypay/web/result.jsp: " + JSON.stringify(event.data));
        if (event.data.source == "easypay-web-order-res") {
            console.log("IFrame from /easypay/web/result.jsp: " + JSON.stringify(event.data));
            easypayWebOrderRes(event)
        } else if (event.data.source == "easypay-mobile-order-res") {
            easypayMobileOrderRes(event)
        }
    }

    const isApp = (navigator.userAgent.indexOf("DiscoverSeoulPass")!==-1)


    const [fConfig, setfConfig] = useState<Config>({
        url: (isApp?process.env.REACT_APP_API_URL + "/easypay/mobile/mobile/order_req":process.env.REACT_APP_API_URL + "/easypay/web/normal/iframe_req"),
        //url: "https://testpg.easypay.co.kr/webpay/EasypayCard_Web.js", JamesPay
        method:"POST",
        data:f_init_web()
    });
    
    useEffect(() => {
        // TODO: 웹 or 모바일웹 화면 사이즈에 따라 구분
        // const MOBILE_WIDTH = 0;//TODO: 추후 모바일사이즈에 맞게 수정. 0이 아님
        if (isPlatform("capacitor") || isPlatform("android") || isPlatform("ios")) {
            //모바일웹 용 결제 페이지
            let furl = process.env.REACT_APP_API_URL + "/easypay/mobile/mobile/order_req";
            let fdata = f_init_mobile();
            setfConfig({
                url:furl,
                method:"POST",
                data:fdata
            })

        } else {
            let furl = process.env.REACT_APP_API_URL + "/easypay/web/normal/iframe_req";
            let fdata = f_init_web();
            setfConfig({
                url:furl,
                method:"POST",
                data:fdata
            })
        }


    }, []);


    useEffect(() => {
        window.addEventListener("message", receiveMessage)
        // clean up
        return () => window.removeEventListener("message", receiveMessage)
    }, []);



    //TODO: 앱은 별도로 기능 구현 필요.
    const handleCloseIframe = () => {
        props.onClose("강제닫기")

        // const req:EasypayApproveReq = {
        //     tr_cd        : "event.data.tr_cd",   // [필수]요청구분
        //     trace_no     : "event.data.EP_trace_no",// [필수]추적고유번호
        //     // mall_id      : string;// [필수]몰아이디
        //     // order_no     : event.data.EP_order_no    ,// [필수]주문번호  - order_res 에는 orderNo가 없음.
        //     order_no     : "orderNo"    ,// [필수]주문번호
        //     encrypt_data : "event.data.EP_encrypt_data",// [필수]암호화 데이타
        //     sessionkey   : "event.data.EP_sessionkey"  // [필수]암호화키
        // }

        // postEasypayRequest(req)
    }


    const submitOnMount = React.useCallback((form?: HTMLFormElement) => {
        console.log(JSON.stringify(fConfig))
        if (form != null) {
            form.submit();
        }

    }, [fConfig.data, fConfig.url]);

    const btnStyle = {
        bottom: "0",
        left: "0",
        width: "100%",
        height: "30px",
        backgroundColor: "#353280",
        color: "#fff",
        fontSize: "20px",
        lineHeight: 1.5
    }

    const name = "paymentForm";
    return (
        <>
            {/* <IFrame.AsyncForm */}
            <button style={btnStyle} onClick={handleCloseIframe}>CLOSE</button>

            <iframe name={name} width="100%" height="100%" />
            {/* <iframe name={name} /> */}
            <form target={name} action={fConfig.url} method={fConfig.method} ref={submitOnMount}>
                {Object.keys(fConfig.data).map((key) => (
                    <input
                        type={"hidden"}
                        name={key}
                        key={key}
                        value={
                            ("string" === typeof fConfig.data[ key ])
                                ? fConfig.data[ key ]
                                : JSON.stringify(fConfig.data[ key ])
                        }
                    />
                ))}
            </form>
        </>
    )

};

export default EasypayIframeView;
