import { isPlatform } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import { ConsoleLogObserver, AuthService, AuthActionBuilder, AUTHORIZATION_RESPONSE_KEY } from 'ionic-appauth';
import { CapacitorBrowser, CapacitorSecureStorage } from 'ionic-appauth/lib/capacitor';

import { AxiosRequestor } from './AxiosService';
import { StorageBackend, Requestor, BaseTokenRequestHandler, AuthorizationServiceConfiguration, AuthorizationNotifier, TokenResponse, AuthorizationRequestJson, AuthorizationRequest, DefaultCrypto, GRANT_TYPE_AUTHORIZATION_CODE, TokenRequestJson, TokenRequest, GRANT_TYPE_REFRESH_TOKEN, AuthorizationResponse, AuthorizationError, LocalStorageBackend, JQueryRequestor, TokenRequestHandler, StringMap } from '@openid/appauth';
import { DspAuthorizationRequestHandler } from './DspAuthorizationRequestHandler';

const { App } = Plugins;

const TOKEN_RESPONSE_KEY = "token_response";
const AUTH_EXPIRY_BUFFER = 10 * 60 * -1;  // 10 mins in seconds

export class AuthGoogle3 extends AuthService {

  private static authSignIn: AuthGoogle3 | undefined;
  private static authSignUp: AuthGoogle3 | undefined;

  requestHandler : DspAuthorizationRequestHandler;

  authServiceConfig: AuthorizationServiceConfiguration | undefined;
  authRequest: AuthorizationRequest | undefined;

  /**
   * "signin" | "signup"
   */
  public static Instance(signInOrSignUp:string): AuthGoogle3 {
    if (signInOrSignUp == "signin"){
      if (!AuthGoogle3.authSignIn) {
        AuthGoogle3.authSignIn = new AuthGoogle3(signInOrSignUp);
        JSON.stringify(AuthGoogle3.authSignIn.authConfig);
      }
      return AuthGoogle3.authSignIn
    } 
    if (!AuthGoogle3.authSignUp) {
      AuthGoogle3.authSignUp = new AuthGoogle3(signInOrSignUp);
      JSON.stringify(AuthGoogle3.authSignUp.authConfig);
    }
    return AuthGoogle3.authSignUp
  }

  constructor(signInOrSignUp:string) {

    const browser = new CapacitorBrowser();
    const storage = new CapacitorSecureStorage();
    const requestor = new AxiosRequestor();

    super(browser, storage, requestor)

    this.requestHandler =  new DspAuthorizationRequestHandler(browser, storage);
    this.setupAuthorizationNotifier()
    console.info("AuthServiceGoogle3->signInOrSignUp::: " + signInOrSignUp);
    this.changeToAuthConfigWithGoogle(signInOrSignUp)
// "${process.env.REACT_APP_GOOGLE_ANDROID_CLIENT_ID}://app/signin/index/signin/google/redirect?
// state=NIBEAAlHPw
// &code=4/0AY0e-g7LI8xnFrv9VyiRhacIXe6vgvK_i-9GSsI3x1WEeUOcbr5uGxV7njQCNMfwQ_i_sQ
// &scope=email%20profile%20https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile%20openid
// &authuser=0&prompt=consent"
    if (isPlatform('capacitor')) {
      App.addListener('appUrlOpen', (data: any) => {
        console.log("AuthGoogle3 :: appUrlOpen:: data:: " + JSON.stringify(data.url));
        console.log("this.authRequest?.redirectUri:: " + this.authRequest?.redirectUri);
        if (data.url !== undefined) {
          debugger;
          console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!" + data.url);
          // this.authorizationCallback(data.url)
        }
      });
    }
  }

  /**
   * Google OAuth 2.0
   */
  changeToAuthConfigWithGoogle(signInOrSignUp:string) {
    const isApp = (navigator.userAgent.indexOf("DiscoverSeoulPass")!== -1)

    // "authorization_endpoint": "https://accounts.google.com/o/oauth2/v2/auth",
    // "device_authorization_endpoint": "https://oauth2.googleapis.com/device/code",
    // "token_endpoint": "https://oauth2.googleapis.com/token",
    // "userinfo_endpoint": "https://openidconnect.googleapis.com/v1/userinfo",
    // "revocation_endpoint": "https://oauth2.googleapis.com/revoke",

    // https://accounts.google.com/.well-known/openid-configuration

    this.authServiceConfig = new AuthorizationServiceConfiguration({
      authorization_endpoint: "https://accounts.google.com/o/oauth2/v2/auth", // Spring OAuth - tokenUri
      // token_endpoint: "https://oauth2.googleapis.com/token", // Spring OAuth - tokenUri
      token_endpoint: process.env.REACT_APP_API_URL  + `/api/auth/oauth/google/accessToken`, // Spring OAuth - tokenUri
      revocation_endpoint: "https://oauth2.googleapis.com/revoke", //FIXME 
      // end_session_endpoint:"com.appauth.demo://endSession",
      // end_session_endpoint:"com.appauth.demo://endSession/google",
      end_session_endpoint: `${process.env.REACT_APP_GOOGLE_WEB_CLIENT_ID}://app/signin/index/${signInOrSignUp}/google/redirect`,
      userinfo_endpoint: "https://openidconnect.googleapis.com/v1/userinfo", // Spring OAuth - userInfoUri
    })

    this.setConfiguration(this.authServiceConfig);

    if (isPlatform('capacitor') && isPlatform('android')){
      this.authConfig = {
        client_id: process.env.REACT_APP_GOOGLE_ANDROID_CLIENT_ID, // Android의 클라이언트 ID 
        server_host: 'https://t.google.com/',
        redirect_url: `com.travolution.discover:${process.env.REACT_APP_GOOGLE_ANDROID_CLIENT_ID}://app/signin/index/${signInOrSignUp}/google/redirect`,
        end_session_redirect_url: `com.travolution.discover:${process.env.REACT_APP_GOOGLE_ANDROID_CLIENT_ID}://app/signin/index/${signInOrSignUp}/google/redirect`,
        scopes: 'email profile',
        pkce: false
      }
    } else if (isPlatform('capacitor') && isPlatform('ios')){
      this.authConfig = {
        client_id: process.env.REACT_APP_GOOGLE_IOS_CLIENT_ID, // IOS의 클라이언트 ID 
        server_host: 'https://t.google.com/',
        redirect_url: `com.Travolution.DiscoverSeoulPass:${process.env.REACT_APP_GOOGLE_IOS_CLIENT_ID}://app/signin/index/${signInOrSignUp}/google/redirect`,
        end_session_redirect_url: `com.Travolution.DiscoverSeoulPass:${process.env.REACT_APP_GOOGLE_IOS_CLIENT_ID}://app/signin/index/${signInOrSignUp}/google/redirect`,
        scopes: 'email profile',
        pkce: false
      }
    } else {
      this.authConfig = {
        client_id: process.env.REACT_APP_GOOGLE_WEB_CLIENT_ID,
        server_host: 'https://t.google.com/', //oauth 일경우에 사용이 안되는듯
        // redirect_url: "https://www.discoverseoulpass.com/loginredirect/google",
        redirect_url: window.location.origin + `/app/signin/index/${signInOrSignUp}/google/redirect`,
        end_session_redirect_url: window.location.origin + '/app/endredirect/google',
        scopes: 'email profile',
        pkce: false
      }
    }
  }


  protected setupAuthorizationNotifier() {
    let notifier = new AuthorizationNotifier();
    this.requestHandler.setAuthorizationNotifier(notifier);
    notifier.setAuthorizationListener((request, response, error) => this.onAuthorizationNotification(request, response, error));
  }

  protected async performAuthorizationRequest(authExtras?: StringMap): Promise<void> {
    let requestJson: AuthorizationRequestJson = {
      response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
      client_id: this.authConfig.client_id,
      redirect_uri: this.authConfig.redirect_url,
      scope: this.authConfig.scopes,
      extras: authExtras
    }

    let request = new AuthorizationRequest(requestJson, new DefaultCrypto(), this.authConfig.pkce);
    console.info("AuthGoogle3:: " + JSON.stringify(requestJson));

    if (this.authConfig.pkce)
      await request.setupCodeVerifier();

    // return this.requestHandler.performAuthorizationRequest(await this.configuration, request);       
    return this.requestHandler.performAuthorizationRequest(this.authServiceConfig!, request);
  }

  async signIn(authExtras?: StringMap) {
    await this.performAuthorizationRequest(authExtras).catch((response) => {
      this.notifyActionListers(AuthActionBuilder.SignInFailed(response));
    })
  }

  async signUp(authExtras?: StringMap) {
    await this.performAuthorizationRequest(authExtras).catch((response) => {
      this.notifyActionListers(AuthActionBuilder.SignUpFailed(response));
    })
  }


  protected onAuthorizationNotification(request: AuthorizationRequest, response: AuthorizationResponse | null, error: AuthorizationError | null) {
    let codeVerifier: string | undefined = (request.internal != undefined && this.authConfig.pkce) ? request.internal.code_verifier : undefined;

    if (response != null) {
      this.requestAccessToken(response.code, codeVerifier);
    } else if (error != null) {
      throw new Error(error.errorDescription);
    } else {
      throw new Error("Unknown Error With Authentication");
    }
  }

  protected async requestAccessToken(code: string, codeVerifier?: string): Promise<void> {
    let requestJSON: TokenRequestJson = {
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code: code,
      refresh_token: undefined,
      redirect_uri: this.authConfig.redirect_url,
      client_id: this.authConfig.client_id,
      extras: (codeVerifier) ? {
        "code_verifier": codeVerifier
      } : {}

    }

    let token: TokenResponse = await this.tokenHandler.performTokenRequest(this.authServiceConfig!, new TokenRequest(requestJSON));
    await this.storage.setItem(TOKEN_RESPONSE_KEY, JSON.stringify(token.toJson()));
    this.notifyActionListers(AuthActionBuilder.SignInSuccess(token))
  }

  public authorizationCallback(callbackUrl: string): void {
    this.internalAuthorizationCallback(callbackUrl).catch((response) => {
      this.notifyActionListers(AuthActionBuilder.SignInFailed(response));
    });
  }

  protected async internalAuthorizationCallback(url: string) {
    this.browser.closeWindow();
    await this.storage.setItem(AUTHORIZATION_RESPONSE_KEY, url);
    return this.requestHandler.completeAuthorizationRequestIfPossible();
  }

  public async internalEndSessionCallback() {
    this.browser.closeWindow();
    this.storage.removeItem(TOKEN_RESPONSE_KEY);
    this.notifyActionListers(AuthActionBuilder.SignOutSuccess());
  }

  public async loadTokenFromStorage() {
    await this.internalLoadTokenFromStorage().catch((response) => {
      this.notifyActionListers(AuthActionBuilder.LoadTokenFromStorageFailed(response));
    });
  }


}
