import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ProfileContentIndex = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <div className="layout">
					
            {/* <h6 className="sec_tit">회원정보 관리</h6>
            <p className="sec_exp">가입하신 회원정보를 변경하거나 탈퇴할 수 있습니다.</p>

            <ul className="wrap_personal">
                <li className="step01"><Link to="/app/mypage/profile/modify">개인정보 수정</Link></li>
                <li className="step02"><Link to="/app/mypage/profile/password">비밀번호 변경</Link></li>
                <li className="step03"><Link to="/app/mypage/profile/leaveid">회원 탈퇴</Link></li>
            </ul> */}
            <h6 className="sec_tit">{t("profile_content_index.title")}</h6>
            <p className="sec_exp">{t("profile_content_index.desc")}</p>

            <ul className="wrap_personal">
                <li className="step01"><Link to="/app/mypage/profile/modify">{t("profile_content_index.menu1")}</Link></li>
                <li className="step02"><Link to="/app/mypage/profile/password">{t("profile_content_index.menu2")}</Link></li>
                <li className="step03"><Link to="/app/mypage/profile/leaveid">{t("profile_content_index.menu3")}</Link></li>
            </ul>
        </div>
    );
};

export default ProfileContentIndex;