import { isPlatform } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import { ConsoleLogObserver, AuthService, AuthActionBuilder, AUTHORIZATION_RESPONSE_KEY } from 'ionic-appauth';
import { CapacitorBrowser, CapacitorSecureStorage } from 'ionic-appauth/lib/capacitor';

import { AxiosRequestor } from './AxiosService';
import { StorageBackend, Requestor, BaseTokenRequestHandler, AuthorizationServiceConfiguration, AuthorizationNotifier, TokenResponse, AuthorizationRequestJson, AuthorizationRequest, DefaultCrypto, GRANT_TYPE_AUTHORIZATION_CODE, TokenRequestJson, TokenRequest, GRANT_TYPE_REFRESH_TOKEN, AuthorizationResponse, AuthorizationError, LocalStorageBackend, JQueryRequestor, TokenRequestHandler, StringMap } from '@openid/appauth';
import { DspAuthorizationRequestHandler } from './DspAuthorizationRequestHandler';

const { App } = Plugins;

const TOKEN_RESPONSE_KEY = "token_response";
const AUTH_EXPIRY_BUFFER = 10 * 60 * -1;  // 10 mins in seconds

export class AuthWeibo3 extends AuthService {
  
  // private static auth: AuthWeibo3 | undefined;
  // private static authSignIn: AuthWeibo3 | undefined;
  // private static authSignUp: AuthWeibo3 | undefined;

  authServiceConfig: AuthorizationServiceConfiguration | undefined;
  authRequest: AuthorizationRequest | undefined;

  // public static get Instance(): AuthWeibo3 {
  //   console.log("AuthWeibo3:: Instance 1")
  //   if (!AuthWeibo3.auth) {
  //     console.log("AuthWeibo3:: Instance 2")
  //     AuthWeibo3.auth = new AuthWeibo3();
  //     console.log("AuthWeibo3:: Instance 3")
  //   }
  //   console.log("AuthWeibo3:: Instance 4")
  //   return AuthWeibo3.auth;
  // }

    /**
   * "signin" | "signup"
   */
  // public static Instance(signInOrSignUp:string): AuthWeibo3 {
  //   if (signInOrSignUp == "signin"){
  //     if (!AuthWeibo3.authSignIn) {
  //       AuthWeibo3.authSignIn = new AuthWeibo3(signInOrSignUp);
  //       JSON.stringify(AuthWeibo3.authSignIn.authConfig);
  //     }
  //     return AuthWeibo3.authSignIn
  //   } 
  //   if (!AuthWeibo3.authSignUp) {
  //     AuthWeibo3.authSignUp = new AuthWeibo3(signInOrSignUp);
  //     JSON.stringify(AuthWeibo3.authSignUp.authConfig);
  //   }
  //   return AuthWeibo3.authSignUp
  // }
  
  constructor() {
    const browser = new CapacitorBrowser();
    const storage = new CapacitorSecureStorage();
    const requestor = new AxiosRequestor();

    super(browser, storage, requestor)

    this.requestHandler =  new DspAuthorizationRequestHandler(browser, storage);
    this.setupAuthorizationNotifier()
    this.changeToAuthConfigWithWeibo()
   
    App.addListener('appUrlOpen', (data: any) => {
      console.log("AuthWeibo3 :: appUrlOpen:: data.url:: " + data.url);
      console.log("this.authRequest?.redirectUri:: " + this.authRequest?.redirectUri);
      if (data.url !== undefined) {
        debugger;
        console.log(data.url);
        this.authorizationCallback(data.url)
      }
    });
  }

  /**
   * FACEBOOK
   */
  changeToAuthConfigWithWeibo() {
    const isApp = (navigator.userAgent.indexOf("DiscoverSeoulPass")!== -1)


    //웹용 키
    // defined('WB_AKEY') OR define('WB_AKEY', '1172425756' );
    // defined('WB_SKEY') OR define('WB_SKEY', 'ff1676bd422b91d016fa789e0c9d25ac' );
    // defined('WB_CALLBACK_URL') OR define('WB_CALLBACK_URL', HTTP_HOST . '/member/cb_weibo' );

    // https://api.weibo.com/oauth2/authorize
    // ?client_id=123050457758183
    // &redirect_uri=http://www.example.com/response
    // &response_type=code

    // https://api.weibo.com/oauth2/access_token
    // ?client_id=YOUR_CLIENT_ID
    // &client_secret=YOUR_CLIENT_SECRET
    // &grant_type=authorization_code
    // &redirect_uri=YOUR_REGISTERED_REDIRECT_URI
    // &code=CODE

    // tokeninfo = https://api.weibo.com/oauth2/get_token_info

//     요청 매개 변수
//  	필수	유형 및 범위	기술
// access_token	진실	끈	OAuth 인증 방법은 필수 매개 변수이며 OAuth 인증 후 획득됩니다.
// uid	그릇된	int64	조회 할 사용자 ID입니다.
// screen_name	그릇된	끈	조회 할 사용자의 별명입니다.

//请求
//https://api.weibo.com/oauth2/authorize?client_id=123050457758183&redirect_uri=http://www.example.com/response&response_type=code

//同意授权后会重定向
//http://www.example.com/response&code=CODE


    this.authServiceConfig = new AuthorizationServiceConfiguration({
      authorization_endpoint:"https://api.weibo.com/oauth2/authorize", // Spring OAuth - tokenUri
      // token_endpoint:"https://api.weibo.com/oauth2/access_token", // Spring OAuth - tokenUri
      token_endpoint: process.env.REACT_APP_API_URL  + `/api/auth/oauth/weibo/accessToken`, // Spring OAuth - tokenUri
      revocation_endpoint:"https://api.weibo.com/oauth2/revokeoauth2", //FIXME 
      end_session_endpoint: 'com.travolution.discover://endSession/weibo',
      userinfo_endpoint:"https://api.weibo.com/2/users/show.json", // Spring OAuth - userInfoUri
    })

    this.setConfiguration(this.authServiceConfig);

    this.authRequest = new AuthorizationRequest({
      response_type:AuthorizationRequest.RESPONSE_TYPE_CODE,
      client_id: process.env.REACT_APP_WEIBO_AKEY,//"729572004", // client_id:"123050457758183",
      // redirect_uri:"http://localhost:3000/loginredirect/weibo",
      // redirect_uri:"https://otsa-dm81-iama.discoverseoulpass.com/loginredirect/weibo", //바꿔야함.
      redirect_uri: window.location.origin + "/app/signin/index/weibo/redirect",
      scope: 'all', // scope: 'email'
      state:""
    })

    if (isPlatform('capacitor') && isPlatform('android')){
      console.log("AuthWeibo3:: Android")
      this.authConfig = {
        client_id: process.env.REACT_APP_WEIBO_ANDROID_APP_KEY, // Android의 클라이언트 ID 
        server_host: 'https://www.weibo.com/',
        redirect_url: `com.travolution.discover:${process.env.REACT_APP_WEIBO_ANDROID_APP_KEY}://callback`,
        end_session_redirect_url: `com.travolution.discover:${process.env.REACT_APP_WEIBO_ANDROID_APP_KEY}://app/signin/index/weibo/endSession`,
        scopes: 'all', // scope: 'email'
        pkce: false
      }
    } else if (isPlatform('capacitor') && isPlatform('ios')){
      console.log("AuthWeibo3:: IOS")
      // WbSdk.install(this, new AuthInfo(
      //   this,
      //   "1575198517",
      //   "http://www.sina.com/",
      //   "email,direct_messages_read,direct_messages_write,"
      //           + "friendships_groups_read,friendships_groups_write,statuses_to_me_read,"
      //           + "follow_app_official_microblog," + "invitation_write"));
      this.authConfig = {
        client_id: process.env.REACT_APP_WEIBO_IOS_APP_KEY, //'1575198517', // IOS의 클라이언트 ID 
        server_host: 'http://www.sina.com/',
        redirect_url: `com.Travolution.DisconverSeoulPass:${process.env.REACT_APP_WEIBO_IOS_APP_KEY}://app/signin/index/weibo/redirect`,
        end_session_redirect_url: `com.Travolution.DisconverSeoulPass:${process.env.REACT_APP_WEIBO_IOS_APP_KEY}://app/signin/index/weibo/endSession`,
        // scopes: 'email,direct_messages_read,direct_messages_write,friendships_groups_read,friendships_groups_write,statuses_to_me_read,follow_app_official_microblog,invitation_write', // scope: 'email'
        scopes: 'all', // scope: 'email'
        pkce: false
      }
    } else {
      console.log("AuthWeibo3:: Web")
      this.authConfig = {
        //授权 : 권한을 부여하다 (https://zh.dict.naver.com/#/search?query=%E6%8E%88%E6%9D%83&range=all)
        //取消 : 취소 (https://zh.dict.naver.com/#/search?query=%E5%8F%96%E6%B6%88&range=all)
        // 윤허하다 https://zh.dict.naver.com/#/search?query=%E5%85%81%E8%AE%B8
        // 무시하다 https://zh.dict.naver.com/#/search?query=%E5%BF%BD%E7%95%A5

        client_id: process.env.REACT_APP_WEIBO_WEB_APP_KEY, // 웹 어플리케이션
        server_host: 'https://www.weibo.com/', //oauth 일경우에 사용이 안되는듯
        // redirect_url: "http://localhost:3000/loginredirect/weibo",
        // redirect_url:"https://www.discoverseoulpass.com/member/cb_weibo",
        redirect_url: window.location.origin + "/app/signin/index/weibo/redirect",
        end_session_redirect_url: window.location.origin + '/app/endredirect/weibo',
        scopes: 'all', // scope: 'email'
        pkce: false
      }

      //성공 결과 https://www.discoverseoulpass.com/member/cb_weibo?state=S7Kkit17Fz&code=b25eeab1ab84f6d3fea3a80f782cc880
    }
  }

  // protected async performAuthorizationRequest(authExtras?: StringMap) : Promise<void> {  
  //     return this.requestHandler.performAuthorizationRequest(this.authServiceConfig!, this.authRequest!);       
  // }
  
  protected setupAuthorizationNotifier() {
    let notifier = new AuthorizationNotifier();
    this.requestHandler.setAuthorizationNotifier(notifier);
    notifier.setAuthorizationListener((request, response, error) => this.onAuthorizationNotification(request, response, error));
  }

  protected async performAuthorizationRequest(authExtras?: StringMap): Promise<void> {
    let requestJson: AuthorizationRequestJson = {
      response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
      client_id: this.authConfig.client_id,
      redirect_uri: this.authConfig.redirect_url,
      scope: this.authConfig.scopes,
      extras: authExtras
    }

    let request = new AuthorizationRequest(requestJson, new DefaultCrypto(), this.authConfig.pkce);

    if (this.authConfig.pkce)
      await request.setupCodeVerifier();

    // return this.requestHandler.performAuthorizationRequest(await this.configuration, request);       
    return this.requestHandler.performAuthorizationRequest(this.authServiceConfig!, request);
  }

  async signIn(authExtras?: StringMap) {
    await this.performAuthorizationRequest(authExtras).catch((response) => {
      this.notifyActionListers(AuthActionBuilder.SignInFailed(response));
    })
  } 

  async signUp(authExtras?: StringMap) {
    await this.performAuthorizationRequest(authExtras).catch((response) => {
      this.notifyActionListers(AuthActionBuilder.SignUpFailed(response));
    })
  }


  protected onAuthorizationNotification(request: AuthorizationRequest, response: AuthorizationResponse | null, error: AuthorizationError | null) {
    let codeVerifier: string | undefined = (request.internal != undefined && this.authConfig.pkce) ? request.internal.code_verifier : undefined;

    if (response != null) {
      this.requestAccessToken(response.code, codeVerifier);
    } else if (error != null) {
      throw new Error(error.errorDescription);
    } else {
      throw new Error("Unknown Error With Authentication");
    }
  }

  protected async requestAccessToken(code: string, codeVerifier?: string): Promise<void> {
    let requestJSON: TokenRequestJson = {
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code: code,
      refresh_token: undefined,
      redirect_uri: this.authConfig.redirect_url,
      client_id: this.authConfig.client_id,
      extras: (codeVerifier) ? {
        "code_verifier": codeVerifier
      } : {}

    }

    let token: TokenResponse = await this.tokenHandler.performTokenRequest(this.authServiceConfig!, new TokenRequest(requestJSON));
    await this.storage.setItem(TOKEN_RESPONSE_KEY, JSON.stringify(token.toJson()));
    this.notifyActionListers(AuthActionBuilder.SignInSuccess(token))
  }

  public authorizationCallback(callbackUrl: string): void {
    this.internalAuthorizationCallback(callbackUrl).catch((response) => {
      this.notifyActionListers(AuthActionBuilder.SignInFailed(response));
    });
  }

  protected async internalAuthorizationCallback(url: string) {
    this.browser.closeWindow();
    await this.storage.setItem(AUTHORIZATION_RESPONSE_KEY, url);
    return this.requestHandler.completeAuthorizationRequestIfPossible();
  }

  public async internalEndSessionCallback() {
    this.browser.closeWindow();
    this.storage.removeItem(TOKEN_RESPONSE_KEY);
    this.notifyActionListers(AuthActionBuilder.SignOutSuccess());
  }

  public async loadTokenFromStorage() {
    await this.internalLoadTokenFromStorage().catch((response) => {
      this.notifyActionListers(AuthActionBuilder.LoadTokenFromStorageFailed(response));
    });
  }
}
