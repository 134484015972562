import { isPlatform } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import { ConsoleLogObserver, AuthService, AuthActionBuilder, AUTHORIZATION_RESPONSE_KEY } from 'ionic-appauth';
import { CapacitorBrowser, CapacitorSecureStorage } from 'ionic-appauth/lib/capacitor';

import { AxiosRequestor } from './AxiosService';
import { StorageBackend, Requestor, BaseTokenRequestHandler, AuthorizationServiceConfiguration, AuthorizationNotifier, TokenResponse, AuthorizationRequestJson, AuthorizationRequest, DefaultCrypto, GRANT_TYPE_AUTHORIZATION_CODE, TokenRequestJson, TokenRequest, GRANT_TYPE_REFRESH_TOKEN, AuthorizationResponse, AuthorizationError, LocalStorageBackend, JQueryRequestor, TokenRequestHandler, StringMap } from '@openid/appauth';
import { DspAuthorizationRequestHandler } from './DspAuthorizationRequestHandler';

const { App } = Plugins;

const TOKEN_RESPONSE_KEY = "token_response";
const AUTH_EXPIRY_BUFFER = 10 * 60 * -1;  // 10 mins in seconds

export class AuthFacebook4 extends AuthService {

  private static auth: AuthFacebook4 | undefined;

  requestHandler : DspAuthorizationRequestHandler;

  authServiceConfig: AuthorizationServiceConfiguration | undefined;
  authRequest: AuthorizationRequest | undefined;

  /**
   * "signin" | "signup"
   */
   public static Instance(): AuthFacebook4 {
    if (!AuthFacebook4.auth) {
      AuthFacebook4.auth = new AuthFacebook4();
    }
    return AuthFacebook4.auth;
  }

  constructor() {

    const browser = new CapacitorBrowser();
    const storage = new CapacitorSecureStorage();
    const requestor = new AxiosRequestor();

    super(browser, storage, requestor)

    this.requestHandler =  new DspAuthorizationRequestHandler(browser, storage);
    this.setupAuthorizationNotifier()
    this.changeToAuthConfigWithFacebook()

    if (isPlatform('capacitor')) {
      App.addListener('appUrlOpen', (data: any) => {
        console.log("AuthFacebook4 :: appUrlOpen:: data.url:: " + data.url);
        console.log("this.authRequest?.redirectUri:: " + this.authRequest?.redirectUri);
        if (data.url !== undefined) {
          debugger;
          console.log(data.url);
          this.authorizationCallback(data.url)
        }
      });
    }
  }

  /**
   * FACEBOOK
   */
  changeToAuthConfigWithFacebook() {
    const isApp = (navigator.userAgent.indexOf("DiscoverSeoulPass") !== -1)

    this.authServiceConfig = new AuthorizationServiceConfiguration({
      authorization_endpoint: "https://www.facebook.com/dialog/oauth", // Spring OAuth - tokenUri
      token_endpoint: process.env.REACT_APP_API_URL  + `/api/auth/oauth/facebook/accessToken`, // Spring OAuth - tokenUri
      revocation_endpoint: "https://oauth2.googleapis.com/revoke", //FIXME 
      end_session_endpoint: "com.travolution.discover://endSession/facebook",
      userinfo_endpoint: "https://graph.facebook.com/me?fields=id,name,email", // Spring OAuth - userInfoUri
    })

    this.setConfiguration(this.authServiceConfig);

    this.authRequest = new AuthorizationRequest({
      response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
      client_id: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
      redirect_uri: window.location.origin + `/app/signin/index/signin/facebook/redirect`,
      scope: "email public_profile",
      state: ""
    })

    if (isPlatform('capacitor') && isPlatform('android')) {
      this.authConfig = {
        client_id: process.env.REACT_APP_FACEBOOK_CLIENT_ID, // Android의 클라이언트 ID 
        server_host: 'https://www.facebook.com/',
        redirect_url: `com.travolution.discover:${process.env.REACT_APP_FACEBOOK_CLIENT_ID}://app/signin/index/signin/facebook/redirect`,
        end_session_redirect_url: 'com.travolution.discover://endSession/facebook',
        scopes: 'email public_profile',
        pkce: false
      }      
    } else if (isPlatform('capacitor') && isPlatform('ios')) {
      this.authConfig = {
        client_id: process.env.REACT_APP_FACEBOOK_CLIENT_ID, // IOS의 클라이언트 ID 
        server_host: 'https://www.facebook.com/',
        redirect_url: `com.Travolution.DisconverSeoulPass:${process.env.REACT_APP_FACEBOOK_CLIENT_ID}://app/signin/index/signin/facebook/redirect`,
        end_session_redirect_url: 'com.Travolution.DisconverSeoulPass://endSession/facebook',
        scopes: 'email public_profile',
        pkce: false
      }
    } else {
      this.authConfig = {
        client_id: process.env.REACT_APP_FACEBOOK_CLIENT_ID, // 웹 어플리케이션
        server_host: 'https://www.facebook.com/', //oauth 일경우에 사용이 안되는듯
        // redirect_url: "https://www.discoverseoulpass.com/loginredirect/facebook",
        redirect_url: window.location.origin + `/app/signin/index/signin/facebook/redirect`,
        // end_session_redirect_url: window.location.origin + '/endredirect',
        end_session_redirect_url: window.location.origin + '/app/endredirect/facebook',
        scopes: 'email public_profile',
        pkce: false
      }
    }

    
  }


  protected setupAuthorizationNotifier() {
    let notifier = new AuthorizationNotifier();
    this.requestHandler.setAuthorizationNotifier(notifier);
    notifier.setAuthorizationListener((request, response, error) => this.onAuthorizationNotification(request, response, error));
  }

  protected async performAuthorizationRequest(authExtras?: StringMap): Promise<void> {
    let requestJson: AuthorizationRequestJson = {
      response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
      client_id: this.authConfig.client_id,
      redirect_uri: this.authConfig.redirect_url,
      scope: this.authConfig.scopes,
      extras: authExtras
    }

    let request = new AuthorizationRequest(requestJson, new DefaultCrypto(), this.authConfig.pkce);
    console.info("AuthFacebook4:: " + JSON.stringify(requestJson));

    if (this.authConfig.pkce)
      await request.setupCodeVerifier();

    // return this.requestHandler.performAuthorizationRequest(await this.configuration, request);       
    return this.requestHandler.performAuthorizationRequest(this.authServiceConfig!, request);
  }

  async signIn(authExtras?: StringMap) {
    await this.performAuthorizationRequest(authExtras).catch((response) => {
      this.notifyActionListers(AuthActionBuilder.SignInFailed(response));
    })
  }

  async signUp(authExtras?: StringMap) {
    await this.performAuthorizationRequest(authExtras).catch((response) => {
      this.notifyActionListers(AuthActionBuilder.SignUpFailed(response));
    })
  }


  protected onAuthorizationNotification(request: AuthorizationRequest, response: AuthorizationResponse | null, error: AuthorizationError | null) {
    let codeVerifier: string | undefined = (request.internal != undefined && this.authConfig.pkce) ? request.internal.code_verifier : undefined;

    if (response != null) {
      this.requestAccessToken(response.code, codeVerifier);
    } else if (error != null) {
      throw new Error(error.errorDescription);
    } else {
      throw new Error("Unknown Error With Authentication");
    }
  }

  protected async requestAccessToken(code: string, codeVerifier?: string): Promise<void> {
    let requestJSON: TokenRequestJson = {
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code: code,
      refresh_token: undefined,
      redirect_uri: this.authConfig.redirect_url,
      client_id: this.authConfig.client_id,
      extras: (codeVerifier) ? {
        "code_verifier": codeVerifier
      } : {}

    }

    let token: TokenResponse = await this.tokenHandler.performTokenRequest(this.authServiceConfig!, new TokenRequest(requestJSON));
    await this.storage.setItem(TOKEN_RESPONSE_KEY, JSON.stringify(token.toJson()));
    this.notifyActionListers(AuthActionBuilder.SignInSuccess(token))
  }

  public authorizationCallback(callbackUrl: string): void {
    this.internalAuthorizationCallback(callbackUrl).catch((response) => {
      this.notifyActionListers(AuthActionBuilder.SignInFailed(response));
    });
  }

  protected async internalAuthorizationCallback(url: string) {
    this.browser.closeWindow();
    await this.storage.setItem(AUTHORIZATION_RESPONSE_KEY, url);
    return this.requestHandler.completeAuthorizationRequestIfPossible();
  }

  public async internalEndSessionCallback() {
    this.browser.closeWindow();
    this.storage.removeItem(TOKEN_RESPONSE_KEY);
    this.notifyActionListers(AuthActionBuilder.SignOutSuccess());
  }

  public async loadTokenFromStorage() {
    await this.internalLoadTokenFromStorage().catch((response) => {
      this.notifyActionListers(AuthActionBuilder.LoadTokenFromStorageFailed(response));
    });
  }


}
