import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import {useHistory} from "react-router-dom";
import AxiosConfig from '../../services/AxiosConfig';
import { globalInfoStore } from "../../stores/GlobalInfoStore";

// TODO: 추후에 Spot.tsx 와 중복 처리
const classNames = require("classnames");



const SpotViewRecommendItem = (props:any) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [likeActive, setLikeActive] = useState(false);

    const SpotSubCodes = [
      // {subCode:0,id:'li01',title:'전체'},
      {subCode:1,id:'li02',title:t("spot.cate_history")},
      {subCode:2,id:'li03',title:t("spot.cate_museum")},
      {subCode:3,id:'li04',title:t("spot.cate_entertainment")},
      {subCode:4,id:'li05',title:t("spot.cate_transportation")},
      {subCode:5,id:'li06',title:t("spot.cate_travel")},
      {subCode:6,id:'li07',title:t("spot.cate_experience")},
      {subCode:7,id:'li08',title:t("spot.cate_performance")},
      {subCode:8,id:'li09',title:t("spot.cate_shopping")},
      {subCode:9,id:'li10',title:t("spot.cate_others")},
      {subCode:10,id:'li11',title:t("spot.cate_one_day_class")}
    ]
         /** 
     * @param productUid
     * @return null
     * @response {   "code": "200",   "contentsUid": 40,   "bookmarkUid": 1,   "desc": "정상" }
     */
    const postLikeSpot = async () => {
      // var data = JSON.stringify({"type":selectedTopic.codeId,"email":email,"content":content});
      console.log("props.content.uid " + props.content.uid)
      var data = JSON.stringify({
          "contentsUid":props.content.uid
          // "category":selectedTopic.codeId,
          // "code":globalInfoStore.currentLangType.langCode,
          // "email":inputEmailText,
          // "question":inputTextArea
      })

      const res = await AxiosConfig.post("/api2/bookmarkInfo",
          data,
          {
            headers: {
                "Content-Type": "application/json",
            },
          }
      );
      if (res.status == 200 && res.data.code == "200"){
          props.content.bookmarkUid = 1
          setLikeActive(true);
      } else {
        console.error(res.status + " " + JSON.stringify(res.data));
      }
    };

    /**
     *  {   "code": "200",   "desc": "정상" }
     */
    const deleteLikeSpot = async () => {
      const res = await AxiosConfig.delete(`/api2/bookmarkInfo/${props.content.uid}`);
      if (res.status == 200 && res.data.code == "200"){
          props.content.bookmarkUid = 0
          setLikeActive(false);
      } else {
        console.error(res.status + " " + JSON.stringify(res.data));
      }
    };
    
    const handleItemClick = async (e:any, spotId:number) => {
        history.push("/app/spot/index/view/" + spotId)
    }

    const handleLikeSpot = async (e:any) => {
      e.stopPropagation()
      if (likeActive == true) {
        deleteLikeSpot()
      } else {
        postLikeSpot()
      }

    };

    useEffect(() => {
      if (props.content.bookmarkUid > 0) {
        setLikeActive(true)
      } else {
        setLikeActive(false)
      }
      
    }, [globalInfoStore.currentLangType, likeActive]);

    const getImage = (fullpath:string) => {
      
      if (fullpath == null || fullpath.length < 10 || 
             (!fullpath.startsWith("https://") && !fullpath.startsWith("http://"))
         ) {
           return require("../../resources/img/noimage.png")
      } 

       return fullpath;
    }

    const getSubCodeTitle = (subCode:number) => {
      const result = SpotSubCodes.find(item => item.subCode == subCode );
      return result.title;
    }

    return (

        <li className={classNames({closed:(props.content.opened==="2")})}  onClick={(e) => handleItemClick(e, props.content.uid)}>
          {/* code == 1 --> 무료
          code == 2 --> 할인가맹점 */}
          {props.content.code == 1 ? <span className="tag free">{t("spot.spot_free")}</span>: ""}
          {props.content.code == 2 ? <span className="tag discount">{t("spot.spot_discount")}</span>: ""}

          <div className="img">
            { props.content.fileInfo != null && props.content.fileInfo.length > 0 ? <img src={getImage(props.content.fileInfo[0].fullpath)} alt="관광지 이미지" />:""}
            { props.content.fileInfo == null ? <img src={require("../../resources/img/noimage.png")} alt="관광지 이미지" />:""}
          </div>

          <div className="cont">
            <p className="tit">{props.content.title}</p>
            {/* <p className="tit">{props.content[globalInfoStore.currentLangType.title2]}</p> */}
            <div className="tags">
              {props.content.hot?<span className="hot">HOT</span>:""}
              {props.content.subCode!=null?<span className="sort">{getSubCodeTitle(props.content.subCode)}</span>:""}
              {props.content.plusEdition?<span>{t("spot.seoul_pass_edition")}</span>:""}
              {props.content.fastEnter?<span>{t("spot.fast_track")}</span>:""}
            </div>

            <span className={classNames({like:true, active:props.content.bookmarkUid>0})} onClick={(e) => handleLikeSpot(e)}>좋아요</span>
          </div>
        </li>
    );
};

export default SpotViewRecommendItem;