import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { Link } from "react-router-dom";
import OthersESIM from './OtherseSIM';

const classNames = require("classnames");

const OthersIndex = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    
    const OTHERS_01 = "/app/spot/others/index/esim";

    const [isShowMobileTabMenu, setIsShowMobileTabMenu] = useState(false);

    

    const [filterName, setFilterName] = useState('');

    const isSubMenuActive = (activeName:string) => {
		if ( activeName == location.pathname ) {
			return true;
		} 
		return false;
    }
    
    const handleSetFilterName = (filterName:string) => {
		setFilterName(filterName);
    }
    
    const toggleMobileTabMenu = () => {
		setIsShowMobileTabMenu(!isShowMobileTabMenu);
	}


    return (
        <div className="layout">

            <div className={classNames({use_tab:true, m_active:isShowMobileTabMenu})} onClick={toggleMobileTabMenu}>

                {isSubMenuActive(OTHERS_01)?<span className="m_only">{t("guide.eSIM")}</span>:""}
                
                <div className="m_bg">
                    <Link to="/app/spot/others/index/esim" className={classNames({active:isSubMenuActive(OTHERS_01)})}>{t("guide.eSIM")}</Link>
                </div>
            </div>

            <Switch>
				<Route exact path="/app/spot/others/index" render={()=><Redirect to="/app/spot/others/index/esim"/>} />
                <Route path="/app/spot/others/index/esim" component={OthersESIM} />
            </Switch>
         </div>
    );
};

export default OthersIndex;