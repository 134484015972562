import React from "react";
import { useTranslation } from "react-i18next";
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import { StoreInfo } from "./GuideBuyOffline";

const SliderOfflineItem = (props:{content:StoreInfo}) => {
    const { t, i18n } = useTranslation();
    
    return (
        <dl className="slide">
            <dt>
                <p>{props.content.relationStoreContentsInfo.find( it => it.code == globalInfoStore.currentLangType.langCode).title}</p>
            </dt>
            <dd className="clear">
                { (props.content.fullpath)? <img className="clear" src={props.content.fullpath} alt={props.content.title + " 이미지"}/> : ''}
                <p>
                    <span>{t("buy_offline.contact")}</span>
                    <span>{props.content.tel}</span>
                </p>
                <p>
                    <span>{t("buy_offline.operating_hours")}</span>
                    <span>{props.content.relationHourInfo[0].hour}</span>
                </p>
                <p>
                    <span>{t("buy_offline.holiday")}</span>
                    <span>{props.content.relationStoreContentsInfo.find( it => it.code == globalInfoStore.currentLangType.langCode).holiday}</span>
                </p>
                <p>
                    <span>{t("buy_offline.address")}</span>
                    <span>{props.content.relationStoreContentsInfo.find( it => it.code == globalInfoStore.currentLangType.langCode).address}</span>
                </p>
                <p>
                    <span></span>
                    <a className="gToMap" href={props.content.mapUrl} target="_blank">{t("buy_offline.go_to_map")}</a> 
                </p>
            </dd>
        </dl>
    )
}

export default SliderOfflineItem;