import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, Link } from "react-router-dom";
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import { ISearchV2Resp, ISearchV2RespResult } from "./ISearchV2";

const classNames = require("classnames");


/**
 * 1-1 패스소개 검색결과 (제외 html의 번역 컨텐트 이므로 검색 불가)
 * @returns 
 */
// const PassIntro = (props:{totalResult:ISearchV2RespResult, searchTerm:string}) => {
//     return (
//         <dl>
//             <dt>패스소개 검색결과<span>(3)</span></dt>
//             <dd>
//                 <ul>
//                     <li><a href="#" target="_self">패스 소개 &gt; 이용방법 &gt; 따릉이</a></li>
//                     <li>
//                         <a href="#" target="_self">패스 소개 &gt; 패스혜택</a>
//                         <p>디스커버서울패스는 한 장의 카드로 서울 시내 주요 관광지를 각각 24시간, 48시간, 72시간 동안 이용할 수 있는 <em>체험</em>형 서울시 최초의 '자유이용권' 관광패스입니다.</p>
//                     </li>
//                     <li>
//                         <a href="#" target="_self">패스 소개 &gt; 구매방법 &gt; 온라인 구매</a>
//                         <p>패스 유효기간(패스 발행일로부터 5년) 이내 환불이 가능합니다. 단, 1회라도 관광지 혜택 <em>체험</em>을 이용하거나, 교통 충전/탑승 등을 한 경우, 패스가 활성화되어 환불이 불가능합니다.</p>
//                     </li>
//                 </ul>
//             </dd>
//         </dl>);
// }

export const highlightText = (resultText:string, searchText:string) => {
    const regex = new RegExp("(" + searchText + ")", "gi");
    try {
        return resultText.replaceAll(regex, "<em>$1</em>");
    } catch (er) {
        console.error(er);
    }
    return resultText;
}


//   changeInput = (e) => {
//     let value = e.target.value;
//     let txt = document.getElementById("myText").innerText;
//     let idx = txt.indexOf(value);
//     if(idx >= 0) {
//       let newText = [txt.substring(0, idx), <strong>{txt.substring(idx, idx + value.length)}</strong>, txt.substring(idx + value.length)];
//       this.setState({inputValue: value, text: newText});
//     } else {
//       this.setState({inputValue: value, text: this.initialText});
//     }    
//   }

/**
 * 1그룹 2번째 오프라인 판매처 검색결과
 * @returns 
 */
export const OfflineResult = (props:{offlineResult:ISearchV2RespResult, searchTerm:string}) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    if (props.offlineResult == null || props.offlineResult == null || props.offlineResult.totalSize < 1) {
        return (
        <dl>
            {/* <dt>오프라인 판매처 검색결과<span>(0)</span></dt> */}
            <dt>{t("search_v2.retailer_results")}<span>(0)</span></dt>
            <dd>
                <p className="no_result">{t("search.no_results")}</p>
            </dd>
        </dl>);
    }

    const handleOfflineItem = (uid:number) => {
        history.push({
			pathname: '/app/guide/index/buy/offline',
			search: `?uid=${uid}`
		})
    }

    return (
        <dl>
            {/* <dt>오프라인 판매처 검색결과<span>({props.offlineResult.totalSize})</span></dt> */}
            <dt>{t("search_v2.retailer_results")}<span>({props.offlineResult.totalSize})</span></dt>
            <dd>
                <ul>
                    {
                        props.offlineResult.resultDocuments.map((item)=>{return (
                            <li key={item.UID}  onClick={(e)=>{handleOfflineItem(item.UID)}}><a dangerouslySetInnerHTML={{__html:highlightText(item.TITLE, props.searchTerm)}}></a></li>
                        )})
                    }
                </ul>
            </dd>
        </dl>
        );
}

export const RecipientResult = (props:{recipientResult:ISearchV2RespResult, searchTerm:string}) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    if (props.recipientResult == null || props.recipientResult == null || props.recipientResult.totalSize < 1) {
        return (
        <dl>
            {/* <dt>수령처 검색결과<span>(0)</span></dt> */}
            <dt>{t("search_v2.pick_up_results")}<span>(0)</span></dt>
            <dd>
                <p className="no_result">{t("search.no_results")}</p>
            </dd>
        </dl>);
    }

    const handleRecipientItem = (uid:number) => {
        history.push({
			pathname: '/app/guide/index/buy/takepass',
			search: `?uid=${uid}`
		})
    }

    return (
        <dl>
            {/* <dt>수령처 검색결과<span>({props.recipientResult.totalSize})</span></dt> */}
            <dt>{t("search_v2.pick_up_results")}<span>({props.recipientResult.totalSize})</span></dt>
            <dd>
                <ul>
                    {
                        props.recipientResult.resultDocuments.map((item)=>{return (
                            // <li key={item.UID}><a href="#" target="_self">{item.TITLE}</a></li>
                            // <li key={item.UID}><a href="#" target="_self">{item.EN_TITLE}</a></li>
                            // <li key={item.UID} onClick={(e)=>{handleRecipientItem(item.UID)}}><a>{item.EN_TITLE}</a></li>
                            <li key={item.UID} onClick={(e)=>{handleRecipientItem(item.UID)}}><a dangerouslySetInnerHTML={{__html:highlightText(item[globalInfoStore.currentLangType.searchPrefix1 +"TITLE"], props.searchTerm)}}></a></li>
                        )})
                    }
                </ul>
            </dd>
        </dl>);
}



export const TourResult = (props:{tourResult:ISearchV2RespResult, searchTerm:string}) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    if (props.tourResult == null || props.tourResult == null || props.tourResult.totalSize < 1) {
        return (
        <dl>
            {/* <dt>제휴 관광지 검색결과<span>(0)</span></dt> */}
            <dt>{t("search_v2.attraction_results")}<span>(0)</span></dt>
            <dd>
                <p className="no_result">{t("search.no_results")}</p>
            </dd>
        </dl>);
    }

    const handleTourItem = (uid:number) => {
        history.push({
			pathname: `/app/spot/index/view/${uid}`,
			// search: `?page=1&searchKeyword=${inputSearchText}&searchType=total`
		})
    }

    return (
        <dl>
            {/* <dt>제휴 관광지 검색결과<span>({props.tourResult.totalSize})</span></dt> */}
            <dt>{t("search_v2.attraction_results")}<span>({props.tourResult.totalSize})</span></dt>
            
            <dd className="spot">
                <ul>
                    {
                        props.tourResult.resultDocuments.map((item)=>{return (
                            <li key={item.UID} onClick={(e)=>{handleTourItem(item.UID)}}>
                                <img src={item.FULLPATH} alt={item.TITLE} />
                                <a dangerouslySetInnerHTML={{__html:highlightText(item.TITLE, props.searchTerm)}}></a>
                            </li>
                        )})
                    }
                </ul>
            </dd>
        </dl>
    )
}

export const ProductResult = (props:{productResult:ISearchV2RespResult, searchTerm:string}) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    if (props.productResult == null || props.productResult == null || props.productResult.totalSize < 1) {
        return (
        <dl>
            {/* <dt>패스상품 검색결과<span>(0)</span></dt> */}
            <dt>{t("search_v2.product_results")}<span>(0)</span></dt>
            <dd>
                <p className="no_result">{t("search.no_results")}</p>
            </dd>
        </dl>);
    }

    const handlePassItem = (uid:number) => {
        history.push({
			pathname: `/app/pass/view/${uid}`,
			// search: `?page=1&searchKeyword=${inputSearchText}&searchType=total`
		})
    }


    return (
        <dl>
            {/* <dt>패스상품 검색결과<span>({props.productResult.totalSize})</span></dt> */}
            <dt>{t("search_v2.product_results")}<span>({props.productResult.totalSize})</span></dt>
            <dd className="pass">
                <ul>
                    {
                        props.productResult.resultDocuments.map((item)=>{return (
                            // <li key={item.UID} onClick={}>
                            <li key={item.UID} onClick={(e)=>handlePassItem(item.UID)}>
                                <img src={item.FULLPATH} alt={item.EN_TITLE} />
                                <a dangerouslySetInnerHTML={{__html:highlightText(item[globalInfoStore.currentLangType.searchPrefix1 +"TITLE"], props.searchTerm)}}></a>
                            </li>
                        )})
                    }
                </ul>
            </dd>
        </dl>
    )
}

export const FaqResult = (props:{faqResult:ISearchV2RespResult, searchTerm:string}) => {
    const { t, i18n } = useTranslation();

    // useEffect(() => {
    //     return () => {
    //     }
    // }, [props.faqResult.resultDocuments.length])
    
    if (props.faqResult == null || props.faqResult == null || props.faqResult.totalSize < 1) {
        return (
        <dl>
            {/* <dt>FAQ 검색결과<span>(0)</span></dt> */}
            <dt>{t("search_v2.faq_results")}<span>(0)</span></dt>
            <dd>
                <p className="no_result">{t("search.no_results")}</p>
            </dd>
        </dl>);
    }
    return (
        <dl>
            {/* <dt>FAQ 검색결과<span>({props.faqResult.totalSize})</span></dt> */}
            <dt>{t("search_v2.faq_results")}<span>({props.faqResult.totalSize})</span></dt>
            <dd className="notice">
                <ul>
                    {
                        props.faqResult.resultDocuments.map((item)=>{return (
                            // <li key={item.UID}><Link to={"/app/cs/index/faq"} >{item.EN_ASK}</Link></li>
                            // <li key={item.UID}><Link to={"/app/cs/index/faq?uid=" + item.UID} >{item.EN_ASK}</Link></li>
                            <li key={item.UID}><Link to={"/app/cs/index/faq?uid=" + item.UID} dangerouslySetInnerHTML={{__html:highlightText(item[globalInfoStore.currentLangType.searchPrefix1 +"ASK"], props.searchTerm)}}></Link></li>
                        )})
                    }
                </ul>
            </dd>
        </dl>
    )
}

export const NoticeResult = (props:{noticeResult:ISearchV2RespResult, searchTerm:string}) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const location = useLocation();


    if (props.noticeResult == null || props.noticeResult == null || props.noticeResult.totalSize < 1) {
        return (
        <dl>
            {/* <dt>공지사항 검색결과<span>(0)</span></dt> */}
            <dt>{t("search_v2.notice_results")}<span>(0)</span></dt>
            <dd>
                <p className="no_result">{t("search.no_results")}</p>
            </dd>
        </dl>);
    }
    return (
        <dl>
            {/* <dt>공지사항 검색결과<span>({props.noticeResult.totalSize})</span></dt> */}
            <dt>{t("search_v2.notice_results")}<span>({props.noticeResult.totalSize})</span></dt>
            <dd className="notice">
                <ul>
                    {
                        props.noticeResult.resultDocuments.map((item)=>{return (
                            // <li key={item.UID}><Link to={"/app/cs/index/notice/view/" + item.UID} >{item.EN_TITLE}</Link></li>
                            <li key={item.UID}><Link to={"/app/cs/index/notice/view/" + item.UID} dangerouslySetInnerHTML={{__html:highlightText(item[globalInfoStore.currentLangType.searchPrefix1 +"TITLE"], props.searchTerm)}}></Link></li>
                        )})
                    }
                </ul>
            </dd>
        </dl>
    )
}