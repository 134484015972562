import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Slider, { Settings } from "react-slick";
import AxiosConfig from '../../services/AxiosConfig';
import { ProductInfoService } from "../../services/ProductInfoService";
// import useRootStore from "../../stores/UseRootStore";
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import AppMyPassCardSlideItem from "../app-mypass/AppMyPassCardSlideItem";
import AppMyPassEmpty from "../app-mypass/AppMyPassEmpty";
import { IMyPass } from "../mypage/mypass/MyPass";
import AppMyPassMobileSlideItem from "./AppMyPassMobileSlideItem";

const classNames = require("classnames");

const AppMyPass = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  // const globalInfoStore = useContext(GlobalInfoStore);
  // const { globalInfoStore } = useRootStore();
  let customSlider = useRef<Slider>(null);
  const [activeSlide, setActiveSlide] = useState<number>(1);
  // const [myPass, setMyPass] = useState<IMyPass>()
  const [filteredMyPass, setFilteredMyPass] = useState<IMyPass[]>([])
  // const [productInfoList, setProductInfoList] = useState<IProductInfo[]>();

  const [isShowPopup, setShowPopup] = useState<boolean>(false);
  const [isAttention, setIsAttention] = useState<boolean>(false);

  const [isShowEsimPopup1, setShowEsimPopup1] = useState<boolean>(false);
  const [isShowEsimPopup2, setShowEsimPopup2] = useState<boolean>(false);

  const divWrapRef = React.useRef<HTMLDivElement>(null) // note the passed in `null` arg
  let targetElement:any = null;

  const [callbackFunc, setCallbackFunc] = useState();
  const [callbackEsimFunc, setCallbackEsimFunc] = useState();

  // const { t, i18n } = useTranslation();
//   useEffect(() => {
  //   const fetchPass = async () => {};
  //   fetchPass();

  //   targetElement = divWrapRef.current;
  //   // enableBodyScroll(targetElement);
  //   disableBodyScroll(targetElement);
  // }, [globalInfoStore.currentLangType]);

  const fetchMyPass = async () => {
    const url = `/api2/myPass?size=100`;
    const res = await AxiosConfig.get(url);

    if (res.status == 200) {
      var isEsimUse = false;
      res.data.content.filter((it:any) => {
        if(it.passtypeUid === 2 || it.passtypeUid === 4 || it.passtypeUid === 6) {
          isEsimUse = true;
        }
      });

      if(isEsimUse)
        checkEsimGuidePopup();

      // setMyPass(res.data);
      // setFilteredMyPass(res.data.content) //FIXME: NULL 값이 존재함. API
      setFilteredMyPass(res.data.content.filter((it:any) => it != null))
      if (res.data.content.length < 1) {
        // history.replace("/")
        history.replace("/appmypass/register");
      }
    }
  }
  
  useEffect(() => {
    fetchMyPass();
    // setProductInfoList(ProductInfoService.Instance().productInfoList)
    targetElement = divWrapRef.current;
    enableBodyScroll(targetElement);
    return () => {
      disableBodyScroll(targetElement);
    };
  }, [globalInfoStore.currentLangType]);


  const state = {
    currentSlide: 1
  };

  const next = () => {
    customSlider.current.slickNext();
  }
  const previous = () => {
    customSlider.current.slickPrev();
  }

  const handleShowPopup = (callback:any) => {
    setShowPopup(true);
    setCallbackFunc(() => callback);
  }

  const handleHidePopup = () => {
    setShowPopup(false);
  }

  const handleIsAttention = () => {
    setIsAttention(!isAttention);
  }

  const handleShowEsimPopup = (callback:any) => {
    setShowEsimPopup2(true);
    setCallbackEsimFunc(() => callback);
  }

  const handleHideEsimPopup = () => {
    setShowEsimPopup2(false);
  }

  // const doCallback = () => {
  //   callbackFunc();
  // }

  // const [sliderSettings, setSliderSettings] = useState({
  //   centerMode: true,
  //   infinite: true,
  //   arrows: false,
  //   responsive: [
  //     {
  //       breakpoint: 750,
  //       settings: {
  //         centerPadding: "20px",
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         infinite: false,
  //       },
  //     },
  //     {
  //       breakpoint: 10000,
  //       settings: {
  //         centerPadding: "20px",
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: false,
  //       },
  //     }
  //   ],
  //   afterChange: ((currentSlide:number) => {
  //     setCurrentSlide(currentSlide + 1);
  //   })
  // });

  let i = 0;
  const goToPassEmpty= () => {
    //console.log("i : " + i);
    if (i > 7){
      history.push("/appmypass/empty");
    }
    
    if (i == 0){
      setTimeout(()=>{
        i = 0;
        //console.log("i 가 초기화됨.")
      }, 4000)
    }
    i++;
    
  }

  const goToPassRegister= () => {
    history.push("/appmypass/register");
  }

  const sliderSettings: Settings = {
      dots: false,
      centerMode: true,
      infinite: true,
      arrows: false,
      lazyLoad: "progressive",
      speed:500,
      responsive: [
          { 
              breakpoint: 9999,
              settings: "unslick" 
          },
          {
              breakpoint: 750,
              settings: {
                  centerMode: false,
                  initialSlide: activeSlide - 1,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: false,
                  infinite: true,
              },
          }
      ],
      beforeChange: (current, next) => {
          setActiveSlide(next +1)
      },
      afterChange: ((currentSlide:number) => {
          setActiveSlide(currentSlide+1)
      })
  };


  const doNotOpenToday = () => {
    const expires = new Date();
    expires.setUTCHours(expires.getUTCHours() + 24 + 9); // utc 기준이라 1일 + 9시간을 해줌.

    localStorage.setItem("esimguidedaycheck", JSON.stringify(expires));
    setShowEsimPopup1(false);
  }

  const checkEsimGuidePopup = () => {
    const esimguidedaycheck = localStorage.getItem("esimguidedaycheck");
    const expires = JSON.parse(esimguidedaycheck);

    if(new Date() > new Date(expires)) {
      setShowEsimPopup1(true);
    }
  }


	if (filteredMyPass.length == 0) {
		return <>.</>
	}

  return (
      // <div id="app" ref={divWrapRef}>
      <div id="app">
        <div className="header">
          <h3 className="tit righteous" onClick={goToPassEmpty}>MY PASS</h3>
          <button className="btn_close" onClick={history.goBack}>닫기</button>
          <button className="btn_enroll" onClick={goToPassRegister}>{t("app_my_pass.pass_register")}</button>
        </div>


        <div className="my_pass">
          {/* <MyPassSlider></MyPassSlider> */}

          <Slider {...sliderSettings} ref={(ref:Slider) => { customSlider.current = ref}}>
              {filteredMyPass.map((it) => {
                //console.log("it.qrCode" + it.qrCode);
                if (it.passtypeCode === 1) { //상품 유형 (1: 실물, 2: 모바일)
                  return (<AppMyPassCardSlideItem key={it.uid} productItem={it} productInfoList={ProductInfoService.Instance().productInfoList} />)
                }
                return (<AppMyPassMobileSlideItem key={it.uid} productItem={it} productInfoList={ProductInfoService.Instance().productInfoList} doShowPopup={handleShowPopup} doHidePopup={handleHidePopup} doShowEsimPopup={handleShowEsimPopup} doHideEsimPopup={handleHideEsimPopup}/>)
            })}
          </Slider>
         
        </div>

        <div className={classNames({popup: isShowPopup})} hidden={!isShowPopup}>
            {/* <button className="btn_close" onClick={(e)=>{setShowPopup(false)}}>닫기</button> */}
            <div className="cont pd50">
                <p className="txtR">{t("arexqr.popup_title")}</p>
                <p className="txt">{t("arexqr.popup_ment1")}</p>
                <p className="txtR">{t("arexqr.popup_ment11")}</p>
                <p className="txt">{t("arexqr.popup_ment2")}</p>

                <div className="input_box">
                    <input type="checkbox" id="ck" checked={isAttention} onChange={handleIsAttention} />
                    <label htmlFor="ck">{t("arexqr.popup_check")}</label>
                </div>

                <button className="btn500" onClick={(e)=>{setShowPopup(false)}}>{t("arexqr.popup_cancel")}</button>
                <button className="btn50" disabled={!isAttention} onClick={callbackFunc}>{t("arexqr.popup_issue")}</button>
            </div>
        </div>

        <div className={classNames({popup: isShowEsimPopup1})} hidden={!isShowEsimPopup1}>
            <button className="btn_close" onClick={(e)=>{setShowEsimPopup1(false)}}>닫기</button>
            <div className="cont pd50">
                <p className="txt">{t("how_eSIM.popup1")}</p>
                <p className="txt"> &nbsp; </p>
                <div className="">
                    <input type="checkbox" id="ckEsim" onClick={doNotOpenToday} />
                    <label htmlFor="ckEsim">Do not open this window today</label>
                </div>

            </div>
        </div>

        <div className={classNames({popup: isShowEsimPopup2})} hidden={!isShowEsimPopup2}>
            {/* <button className="btn_close" onClick={(e)=>{setShowEsimPopup2(false)}}>닫기</button> */}
            <div className="cont pd50">
                <p className="txtS" dangerouslySetInnerHTML={{__html:t("how_eSIM.popup2")}}></p>

                <button className="btn500" onClick={(e)=>{setShowEsimPopup2(false)}}>{t("arexqr.popup_cancel")}</button>
                <button className="btn51"  onClick={callbackEsimFunc}>{t("app_my_pass.esim")}</button>
            </div>
        </div>

        <div className="pager">
          {/* <button className="btn_prev" onClick={() => customeSlider?.current?.slickPrev()}> */}
          <button className="btn_prev" onClick={previous}>
            이전
          </button>
          <p className="cnt">
            <span className="current">{activeSlide}</span>
            <span className="total">{filteredMyPass.length}</span>
          </p>
          {/* <button className="btn_next" onClick={() => customeSlider?.current?.slickNext()}> */}
          <button className="btn_next" onClick={next}>
            다음
          </button>
        </div>


      </div>
  );
};

export default observer(AppMyPass);
// export default AppMyPass;
