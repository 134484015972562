import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { globalInfoStore, LANG_TYPES } from "../../stores/GlobalInfoStore";

import intro_pass03 from '../../resources/img/intro_pass03.png';
import intro_pass04 from '../../resources/img/intro_pass04.png';

const classNames = require("classnames");

const Guide = () => {
	const { t, i18n } = useTranslation();

	let customSlider = useRef(Array(1));
	const [currentSlide, setCurrentSlide] = useState<number>(1);

	const next = () => {
		customSlider.current[0].slickNext();
		// console.log("next" + customSlider);
	};
	const previous = () => {
		customSlider.current[0].slickPrev();
		// console.log("prev:" + customSlider);
	};

    const [sliderSettings, setSliderSettings] = useState({
		// centerMode: true,
		infinite: true,
		mobileFirst: true,
		arrows: false,
		responsive: [
		  {
			breakpoint: 750,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1,
			  infinite: false,
			},
		  },
		  {
			breakpoint: 10000,
			settings: {
			  slidesToShow: 2,
			  slidesToScroll: 2,
			  infinite: true,
			},
		  }
		],
		afterChange: ((currentSlide:number) => {
		  setCurrentSlide(currentSlide + 1);
		})
	  });
	
    return (

        <div className="layout">

			{/* <h3 className="intro_tit" data-aos="fade-up" data-aos-duration="1500" dangerouslySetInnerHTML={{__html:t("guide.what_is_dsp")}}></h3> */}
			<h3 className={classNames({intro_tit:true, righteous:(globalInfoStore.currentLangType.i18Str == "en")})}  data-aos="fade-up" data-aos-duration="1500" dangerouslySetInnerHTML={{__html:t("guide.what_is_dsp")}}></h3>
			<p className="intro_exp" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200" dangerouslySetInnerHTML={{__html:t("guide.dsp_desc")}}></p>

			<div className="wrap_intro">
				<dl className="dl01" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
					<dt>{t("guide.free_entry_title")}</dt>
					<dd dangerouslySetInnerHTML={{__html:t("guide.free_entry_desc")}}></dd>
				</dl>
				<dl className="dl02" data-aos="fade-up" data-aos-duration="1500">
					<dt>{t("guide.discount_title")}</dt>
					<dd dangerouslySetInnerHTML={{__html:t("guide.discount_desc")}}></dd>
				</dl>
				<dl className="dl03" data-aos="fade-up" data-aos-duration="1500">
					<dt>{t("guide.transportation_title")}</dt>
					<dd dangerouslySetInnerHTML={{__html:t("guide.transportation_desc")}}></dd>
				</dl>
				<dl className="dl04" data-aos="fade-up" data-aos-duration="1500">
					<dt>{t("guide.mobile_title")}</dt>
					<dd dangerouslySetInnerHTML={{__html:t("guide.mobile_desc")}}></dd>
				</dl>
			</div>

			<div className="wrap_slide">
					<div className="th">
						<p>{t("guide.table_title1")}</p>
						<p>{t("guide.table_title2")}</p>
						<p>{t("guide.table_title3")}</p>
						<p>{t("guide.table_title4")}</p>
						<p>{t("guide.table_title5")}</p>
						{/* <p>{t("guide.table_title6")}</p> */}
						<p dangerouslySetInnerHTML={{__html:t("guide.table_title6")}}></p>
					</div>
					<Slider className="intro_slider" {...sliderSettings} ref={(ref) => { customSlider.current[0] = ref}}>
						<div className="slide card">
							<h4>{t("guide.card_pass_title")}</h4>
							<img src={intro_pass03} alt="실물패스 이미지" />
							<p className="side" dangerouslySetInnerHTML={{__html:t("guide.card_pass_side_new")}}></p>
							<div dangerouslySetInnerHTML={{__html:t("guide.card_pass_side_content")}}></div>
							
							{/* <ul className="mobile">
								<li className="li01" dangerouslySetInnerHTML={{__html:t("guide.card_pass_desc1")}}></li>
								<li className="li02" dangerouslySetInnerHTML={{__html:t("guide.card_pass_desc2")}}></li>
								<li className="li03" dangerouslySetInnerHTML={{__html:t("guide.card_pass_desc3")}}></li>
							</ul> */}
						</div>


						<div className="slide mobile">
							<h4>{t("guide.mobile_pass_title")}</h4>
							<img src={intro_pass04} alt="모바일 패스 이미지" />
							<p className="side" dangerouslySetInnerHTML={{__html:t("guide.mobile_pass_side_new")}}></p>
							<div dangerouslySetInnerHTML={{__html:t("guide.mobile_pass_side_content")}}></div>

							{/* <ul className="mobile">
								<li className="li01" dangerouslySetInnerHTML={{__html:t("guide.mobile_pass_desc1")}}></li>
								<li className="li02" dangerouslySetInnerHTML={{__html:t("guide.mobile_pass_desc2")}}></li>
								<li className="li03" dangerouslySetInnerHTML={{__html:t("guide.mobile_pass_desc3")}}></li>
							</ul> */}
						</div>
					</Slider>
					
				<div className="pager">
					<button className="btn_prev"  onClick={previous}>이전 이미지</button>
					<button className="btn_next"  onClick={next}>다음 이미지</button>
				</div>
				<div className="benefit" dangerouslySetInnerHTML={{__html:t("guide.mobile_pass_benefit")}}></div>
				{/* <ul className="benefit">
					<li className="li01" dangerouslySetInnerHTML={{__html:t("guide.pass_desc1")}}></li>
					<li className="li02" dangerouslySetInnerHTML={{__html:t("guide.pass_desc2")}}></li>
					<li className="li03" dangerouslySetInnerHTML={{__html:t("guide.pass_desc3")}}></li>
				</ul> */}
			</div>
			
		</div>
    )
};

export default Guide;