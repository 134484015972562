export const getValueByKey = (object:any, key:string) => {
    return object[key];
}

export const getKeyByValue = (object:any, value:any) => {
    return Object.keys(object).find(key => object[key] === value);
}

/**
 * @returns 오늘 + 1년 을 더한 Date를 반환
 */
export const getNextYear = () => {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate();
    return new Date(year + 1, month, day);
}

export const getNext3Months = () => {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate();
    return new Date(year, month+3, day);
}

export const isProduction = ():boolean => {
    return process.env.REACT_APP_ENV === "production"
}

/**
 * 텍스트 안의 url을 클릭이 가능한 형태로 변경
 * @param text 
 * @returns 
 */
export const urlify = (text:string) => {
    if (text == null) return "";

    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '">' + url + '</a>';
    })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
}


export const getBR = (text:string) => {
    if (text == null) return "";
    
    return text.replace(/\\r\\n|\\n|\\r/gm, '<br />');
}

export const noop = () => {};

// export function on<T extends Window | Document | HTMLElement | EventTarget>(
//   obj: T | null,
//   ...args: Parameters<T['addEventListener']> | [string, Function | null, ...any]
// ): void {
//   if (obj && obj.addEventListener) {
//     obj.addEventListener(...(args as Parameters<HTMLElement['addEventListener']>));
//   }
// }

// export function off<T extends Window | Document | HTMLElement | EventTarget>(
//   obj: T | null,
//   ...args: Parameters<T['removeEventListener']> | [string, Function | null, ...any]
// ): void {
//   if (obj && obj.removeEventListener) {
//     obj.removeEventListener(...(args as Parameters<HTMLElement['removeEventListener']>));
//   }
// }

export const isBrowser = typeof window !== 'undefined';

export const isNavigator = typeof navigator !== 'undefined';