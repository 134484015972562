import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import AxiosConfig from '../../../services/AxiosConfig';
import { codeInfoAtom, ICodeInfo } from "../../../services/CodeInfoService";
import { globalInfoStore } from "../../../stores/GlobalInfoStore";
import { IOrderInfo } from "../../pass/PassFinish";
import OrderHistoryItem from './OrderHistoryItem';

export interface IOrderHistory {
	[key: string]: any;
}


const OrderHistory = () => {
	const { t, i18n } = useTranslation();

	const [orderHistory, setOrderHistory] = useState<IOrderHistory>()
	const [filteredOrderHistory, setFilteredOrderHistory] = useState<IOrderInfo[]>([])

	const [page, setPage] = useState(1); //page
	const [size, setSize] = useState(10);  //
	const [sorts, setSorts] = useState([]); //sort['updateDate,desc','uid,asc']

	const [filteredPayTypes, setFilteredPayTypes] = useState<ICodeInfo[]>(null);
	const [codeInfoes, setCodeInfoes] = useRecoilState<ICodeInfo[]>(codeInfoAtom);

	const fetchCodeInfoes = async () => {
		if (codeInfoes.length < 1) {
			let url = "/api2/codeInfo";
			const res = await AxiosConfig.get(url);
			if (res.status === 200) {
				setCodeInfoes(res.data)
			}
			filteringCodeInfo(res.data)
		} else {
			filteringCodeInfo(codeInfoes)
		}
	}

	const filteringCodeInfo = (codeInfoList:ICodeInfo[]) => {
		const filteredCodeInfoByPayment = codeInfoList.filter((item:ICodeInfo)=> {
			return item.groupCd === "payment"
		})
		setFilteredPayTypes(filteredCodeInfoByPayment);
	}

	const fetchOrderHistory = async (page:number, size:number, sorts:[]) => {
		let url = `/api2/orderInfo?page=${page}&size=${size}`;

        if (sorts.length > 0){
            url += (sorts || []).map((item)=>`&sort=${item}`);
        }
		const res = await AxiosConfig.get(url);
		setOrderHistory(res.data);
		setFilteredOrderHistory(res.data.content.filter((it:any) => it))
	}

	useEffect(() => {
		fetchCodeInfoes();
		fetchOrderHistory(page, size, []);
		window.scrollTo(0, 0);
    }, [globalInfoStore.currentLangType, page]);

	useEffect(() => {
    }, [filteredPayTypes]);

	const OrderHistoryItems = () => {
		if (filteredPayTypes == null || filteredPayTypes.length < 1) {
			return null;
		}

		return (
			<>
				{(filteredOrderHistory || []).map((item:IOrderInfo)=>{
					return (<OrderHistoryItem key={item.uid} orderInfo={item} filteredCodeInfoes={filteredPayTypes}></OrderHistoryItem>)
				})}
			</>
		);
	}

	const handlePageClick = (pageNumber: any) => {
		setPage(pageNumber)
	};

	const NoticePager = () => {

        if (orderHistory == undefined || orderHistory.totalPages < 2)
            return (<></>)

        return (
            <Pagination
                innerClass={'board_pager'}
                // activeClass={'active'}
                // activeLinkClass={'active'}
                activePage={page}
                itemsCountPerPage={size}
                totalItemsCount={orderHistory.totalElements}
                pageRangeDisplayed={5}
                onChange={(pageNumber)=>handlePageClick(pageNumber)}
                itemClassFirst={'btn_pprv'}
                itemClassPrev={'btn_prev'}
                itemClassNext={'btn_next'}
                itemClassLast={'btn_nnxt'}
            />
        )
	}
	
	if (filteredOrderHistory.length < 1) {
		return (
			<div className="layout">
					
				<div className="wrap_ask">
					<p className="exp" dangerouslySetInnerHTML={{__html:t("order_history.no_history")}}></p>

					<Link to="/app/pass" className="btn_goToInquiry">{t("main.buy_now")}</Link>
				</div>
			</div>
		)
	}
	
    return (

		<div className="layout">
			<div className="wrap_purchase">
				{/* {renderOrderHistoryItems} */}
				<OrderHistoryItems></OrderHistoryItems>
			</div>

			<NoticePager></NoticePager>
		</div>

    );
};

export default OrderHistory;