import { isPlatform } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import { useRecoilState } from "recoil";
import AxiosConfig from '../../../services/AxiosConfig';
import { getValueByKey } from "../../../services/Util";
import { codeInfoAtom, ICodeInfo } from "../../../services/CodeInfoService";
import { globalInfoStore, ICountryInfo } from "../../../stores/GlobalInfoStore";
import { IOrderInfo } from "../../pass/PassFinish";
import Barcode from "./Barcode";

const classNames = require("classnames");

interface IVoucher {
	[key: string]: any;
}

const OrderVoucher = (props:any) => {
	const { t, i18n } = useTranslation();
    const history = useHistory();
	const componentPrintRef = React.useRef(null);
	// const [voucher, setVoucher] = useState()
	// const [userInfo, setUserInfo] = useState()
	// const [storeInfo, setStoreInfo] = useState()
	const [orderInfo, setOrderInfo] = useState<IOrderInfo>(null);
	const [buyerInfo, setBuyerInfo] = useState<IVoucher>();
	const [receiveInfo, setReceiveInfo] = useState<IVoucher>();
	// const [orderNo, setOrderNo] = useState("");
	// const [filteredPayTypes, setFilteredPayTypes] = useState<ICodeInfo[]>(null);
	const [codeInfoes, setCodeInfoes] = useRecoilState<ICodeInfo[]>(codeInfoAtom);
	const [filteredCodeInfo, setFilteredCodeInfo] = useState<ICodeInfo>(null);

	const [countryInfo, setCountryInfo] = useState<ICountryInfo>();
	const [listOfCountry, setListOfCountry] = useState<ICountryInfo[]>([]);

	const fetchListOfCountry = async () => {
        const res = await AxiosConfig.get("/api2/countryInfo");
        setListOfCountry(res.data);
        // setOrderedListOfCountry(res.data.content.filter((it:any) => it.fixed == 'Y')); // 기본정렬은 telCode 로 되어 잇음.
    };

	const fetchCodeInfoes = async () => {
		if (codeInfoes.length < 1) {
			let url = "/api2/codeInfo";
			const res = await AxiosConfig.get(url);
			if (res.status === 200) {
				setCodeInfoes(res.data)
			}
		}
	}

	// const filteringCodeInfo = (codeInfoList:ICodeInfo[]) => {
	// 	const filteredCodeInfoByPayment = codeInfoList.find((item:ICodeInfo)=> {
	// 		return item.groupCd === "payment" && item.codeCd === orderInfo.paymentMethod;
	// 	})
	// 	// setFilteredPayTypes(filteredCodeInfoByPayment);
	// 	// setFilteredCodeInfo(filteredCodeInfoByPayment.find((item) => {
	// 	// 	return item.codeCd === orderInfo.paymentMethod;
	// 	// }));
	// }

	useEffect(() => {
		if (orderInfo == null || codeInfoes.length < 1 || listOfCountry.length < 1 || buyerInfo == null) { return; }

		setFilteredCodeInfo(codeInfoes.find((item:ICodeInfo)=> {
			return item.groupCd === "payment" && item.codeCd === orderInfo.paymentMethod;
		}));
		setCountryInfo(listOfCountry.find((item:ICountryInfo)=> {
			return item.uid === buyerInfo.telCountryUid;
		}));

	}, [codeInfoes, orderInfo, buyerInfo, listOfCountry])

	// const paymentMethodString = filteredPayTypes.find((item) => {
	// 	return item.codeCd === orderInfo.paymentMethod;
	// });
	// const filteredCodeInfo = filteredPayTypes.find((item) => {
	// 	return item.codeCd === orderInfo.paymentMethod;
	//   });

	const fetchVoucher = async () => {
		// const url = `/api/`;
		const url = `/api2/voucher/${props.match.params.uid}`;
		const res = await AxiosConfig.get(url);

		if (res.status == 200) {
			setOrderInfo(res.data.orderInfo);
			setBuyerInfo(res.data.buyerInfo);
			setReceiveInfo(res.data.receiveInfo);
		} else {
			console.error(res.status + " " + JSON.stringify(res.data));
	    }
	}

	useEffect(() => {
		fetchListOfCountry();
		fetchCodeInfoes();
		fetchVoucher();
		return () => {
			// cleanup
		}
	}, [])

	const goToOrderHistory = () => {
        history.push("/app/mypage/orderhistory");
    }

    const onBeforeGetContentResolve = React.useRef<(() => void) | null>(null);

	const [loading, setLoading] = React.useState(false);
	const [text, setText] = React.useState("old boring text");

	const handleAfterPrint = React.useCallback(() => {
		console.log("`onAfterPrint` called"); // tslint:disable-line no-console
	}, []);

	const handleBeforePrint = React.useCallback(() => {
		console.log("`onBeforePrint` called"); // tslint:disable-line no-console
	}, []);

	const handleOnBeforeGetContent = React.useCallback(() => {
		console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
		setLoading(true);
		setText("Loading new text...");

		return new Promise<void>((resolve) => {
			onBeforeGetContentResolve.current = resolve;

			setTimeout(() => {
			setLoading(false);
			setText("New, Updated Text!");
			resolve();
			}, 2000);
		});
	}, [setLoading, setText]);

	const reactToPrintContent = React.useCallback(() => {
		return componentPrintRef.current;
	}, [componentPrintRef.current]);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: "AwesomeFileName",
		onBeforeGetContent: handleOnBeforeGetContent,
		onBeforePrint: handleBeforePrint,
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	});

	React.useEffect(() => {
	if (text === "New, Updated Text!" && typeof onBeforeGetContentResolve.current === "function") {
		onBeforeGetContentResolve.current();
	}
	}, [onBeforeGetContentResolve.current, text]);



	if (orderInfo == null ||buyerInfo == null ||receiveInfo == null || countryInfo == null) {
		return <></>;
	}

    return (

		<article id="content">
			<h6 className="hidden"></h6>
			{/* <div className="layout">
				<div className="wrap_voucher">
						<button className="btn_print" onClick={handlePrint}>{t("order_voucher.print")}</button>
				</div>	
			</div> */}
			
			<div className="layout" ref={componentPrintRef} >

				<div className="wrap_voucher">
					{isPlatform("capacitor")?"":
					<button className="btn_print" onClick={handlePrint}>{t("order_voucher.print")}</button>
					}
					{/* <button className="btn_print" onClick={handlePrint}>{t("order_voucher.print")}</button> */}

					<p className="title">{t("order_voucher.title")}</p>
					<p className="exp">{t("order_voucher.desc")}</p>

					<div className="voucher">
						<p className="header">{t("order_voucher.order_information")}</p>
						<dl>
							<dt className="th">
								<p dangerouslySetInnerHTML={{__html:t("order_voucher.order_no")}}></p>
							</dt>
							{/* <dd className="td barcode"> */}
							<dd className="td">
								<p className="number">{orderInfo.referenceCode}</p>
								{/* <img src="img/barcode.png" alt="바코드" /> */}
								{/* <svg ref={inputRef} />; */}
								<Barcode orderNo={orderInfo.referenceCode}></Barcode>
							</dd>
						</dl>
						<dl>
							<dt className="th"><p>{t("order_voucher.purchase_date")}</p></dt>
							<dd className="td">
								{/* <p><Moment format="YYYY-MM-DD HH:mm:ss">{moment.utc(orderInfo.purchaseDate).add(-9, 'hours')}</Moment>&nbsp;Asia/Seoul</p> */}
								<p><Moment format="YYYY-MM-DD HH:mm:ss">{orderInfo.purchaseDate}</Moment>&nbsp;Asia/Seoul</p>
							</dd>
						</dl>
						<dl>
							<dt className="th"><p>{t("order_voucher.product_of_purchase")}</p></dt>
							<dd className="td">
								<p>
									{/* <span className="banner card">실물패스 {orderInfo.passtypeUid}</span> */}
									<span className="banner card">{t("order_voucher.card_pass")}</span>
									{/* <span className="banner time24">{getPasstypeDesc(orderInfo.passtypeUid).hourTag}H</span> */}
									<span className={classNames({banner:true, 
										time24:(orderInfo.passtypeTime=="24"),
										time48:(orderInfo.passtypeTime=="48"),
										time72:(orderInfo.passtypeTime=="72")})} >
											{orderInfo.passtypeTime}H
									</span>
									<span className="tit">{getValueByKey(orderInfo, globalInfoStore.currentLangType.title2)}</span>
								</p>
								<p className="cost">{orderInfo.amount>1? orderInfo.amount + "X": ""} KRW {orderInfo.finalPrice.toLocaleString()}</p>
							</dd>
						</dl>
						<dl>
							<dt className="th"><p>{t("order_voucher.discount")}</p></dt>
							<dd className="td">
								{/* <p>{orderInfo.couponUid} -  / KRW {orderInfo.discountPrice} {t("order_voucher.discount")}</p> */}
								<p> KRW {orderInfo.discountPrice.toLocaleString()} {t("order_voucher.discount")}</p>
								<p className="cost">KRW {(orderInfo.amount * orderInfo.discountPrice).toLocaleString()}</p>
							</dd>
						</dl>
						<dl className="total">
							<dt className="th"><p>{t("order_voucher.total_price")}</p></dt>
							<dd className="td">
								<p className="cost">KRW {orderInfo.finalPrice.toLocaleString()}</p>
							</dd>
						</dl>
						<dl>
							<dt className="th"><p>{t("order_voucher.payment_method")}</p></dt>
							<dd className="td">
								<p>
								{
									getValueByKey(filteredCodeInfo, globalInfoStore.currentLangType.codename)
								}
								</p>
							</dd>
						</dl>
					</div>
				</div>
				<div className="wrap_voucher">
					<div className="voucher">
						<p className="header">{t("order_voucher.buyer_information")}</p>
						<dl>
							<dt className="th"><p>{t("order_voucher.name")}</p></dt>
							<dd className="td"><p>{buyerInfo.name}</p></dd>
						</dl>
						<dl>
							<dt className="th"><p>{t("order_voucher.email")}</p></dt>
							<dd className="td"><p>{buyerInfo.name}</p></dd>
						</dl>
						<dl>
							<dt className="th"><p>{t("order_voucher.phone_no")}</p></dt>
							{/* <dd className="td"><p>+85-111-123-5678</p></dd> */}
							<dd className="td"><p>{countryInfo.telCode}-{buyerInfo.tel}</p></dd>
						</dl>
					</div>
				</div>
				{/* TODO: 모바일일때는 수령장소를 볼수 없음. 모바일에서는 바우처에 접근이 불가능함 */}

					{/* // "koTitle": "명동 관광정보센터",
					// "koAddress": "-",
					// "koPosition": "-",
					// "koWorktime": "-",
					// "koHoliday": "-",
					let langTitle = globalInfoStore.currentLangType.prefix1 + "Title";
					let langAddress = globalInfoStore.currentLangType.prefix1 + "Address";
					let langPosition = globalInfoStore.currentLangType.prefix1 + "Position";
					let langWorktime = globalInfoStore.currentLangType.prefix1 + "Worktime";
					let langHoliday = globalInfoStore.currentLangType.prefix1 + "Holiday"; */}
				<div className="wrap_voucher">
					<div className="voucher">
						<p className="header">{t("order_voucher.pickup_information")}</p>
						<dl>
							<dt className="th"><p>{t("order_voucher.pickup_date")}</p></dt>
							<dd className="td">
								<p>
								<Moment format="YYYY-MM-DD H:mm:ss">{orderInfo.expectedReceiptDate}</Moment>
								</p>
							</dd>
						</dl>
						<dl>
							<dt className="th"><p>{t("order_voucher.pickup_location")}</p></dt>
							<dd className="td place">
								<p>{receiveInfo[globalInfoStore.currentLangType.title2]}</p>
								<dl>
									<dt>{t("order_voucher.address")}</dt>
									<dd>{receiveInfo[globalInfoStore.currentLangType.prefix1 + "Address"]}</dd>
								</dl>
								<dl>
									<dt>{t("order_voucher.location")}</dt>
									<dd>{receiveInfo[globalInfoStore.currentLangType.prefix1 + "Position"]}</dd>
								</dl>
								<dl>
									<dt>{t("order_voucher.operating_hours")}</dt>
									<dd>{receiveInfo[globalInfoStore.currentLangType.prefix1 + "Worktime"]}</dd>
								</dl>
								<dl>
									<dt>{t("order_voucher.closed")}</dt>
									<dd>{receiveInfo[globalInfoStore.currentLangType.prefix1 + "Holiday"]}</dd>
								</dl>
								<img src={receiveInfo.fullpath} alt="수령장소 이미지" className="place_img" />
							</dd>
						</dl>
					</div>
				</div>

				<button className="btn_goToList" onClick={goToOrderHistory}>{t("order_voucher.list")}</button>

			</div>
		</article>

    );
};

export default OrderVoucher;