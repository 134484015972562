import axios, { AxiosResponse } from 'axios';
import { globalInfoStore } from '../stores/GlobalInfoStore';
import jwt from 'jsonwebtoken';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});


// Where you would set stuff like your 'Authorization' header, etc ...
// instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';

// Also add/ configure interceptors && all the other cool stuff

// instance.defaults.baseURL = 'https://jsonplaceholder.typicode.com';
// instance.defaults.baseURL = process.env.REACT_APP_API_URL
instance.defaults.baseURL = process.env.REACT_APP_API_URL
// instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
instance.defaults.headers.post['Content-Type'] = 'application/json';

instance.interceptors.request.use(request => {
    var jwt = globalInfoStore.getDspJwt();
    // console.log(jwt);
    if (jwt != null && typeof jwt == "string"){
        request.headers.common['Authorization'] = 'Bearer ' + jwt;
    }
    return request;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    console.log(response);
    // Edit response config
    return response;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

export default instance;