import React from "react";
import { useTranslation } from "react-i18next";
import Moment from 'react-moment';
import { Link } from "react-router-dom";
import { globalInfoStore, LANG_TYPES } from "../../stores/GlobalInfoStore";

const SearchNoticeItem = (props:{content:any}) => {

    return (
        <li>
            <Link to={{
                pathname: `/app/cs/index/notice/view/${props.content.uid}`
            }}>{props.content[globalInfoStore.currentLangType.title]}</Link>
            <span>
                <Moment format="YYYY-MM-DD">
                        {props.content['updateDate']}
                </Moment>
            </span>
        </li>
    )

};

export default SearchNoticeItem;