import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Moment from 'react-moment';
import moment from "moment";
import { Link } from 'react-router-dom';
import AxiosConfig from '../../../services/AxiosConfig';
import { getValueByKey } from '../../../services/Util';
import { ICodeInfo } from '../../../services/CodeInfoService';
import { globalInfoStore, PRODUCT_INFO_TYPES } from '../../../stores/GlobalInfoStore';
import { IOrderInfo } from '../../pass/PassFinish';
// import AxiosConfig from '../../services/AxiosConfig'
interface OrderHistoryData {
    [key: string]: any;
}

// easypay의 pay_type 11:신용카드21:계좌이체22:가상계좌31:휴대폰50:선불결제60:간편결제81:배치인증

interface OrderHistoryItemProps {
    orderInfo:IOrderInfo;
    filteredCodeInfoes:ICodeInfo[];
}

const OrderHistoryItem: React.FC<OrderHistoryItemProps> = (props: OrderHistoryItemProps) => {
  const { t, i18n } = useTranslation();

  // const [result, setResult] = useState("")
  const [orderStatus, setOrderStatus] = useState(props.orderInfo.status);

  // const postRefund = async () => {
  //     const url = `/api/`;
  //     const res = await AxiosConfig.post(url);
  // }

  useEffect(() => {
    // effect
    console.log(t("common.curlang"));
    return () => {
      // cleanup
    };
  }, [orderStatus]);

  const handleClick = (e: any) => {};

  const getPassTitle = () => {
    const lang = t("common.curlang");
    switch(lang) {
      case "ja" : return props.orderInfo.jaTitle;
      case "zhChs" : return props.orderInfo.zhCnTitle;
      case "zhCht" : return props.orderInfo.zhTwTitle; 
      default: 
        return props.orderInfo.enTitle;
    }
  };

  const getAmount = () => {
    if (props.orderInfo.amount == null) {
      return null;
    }

    return (
      t("order_history.total_count_prefix") +
      " " +
      props.orderInfo.amount +
      t("order_history.total_count_postfix")
    );
  };

  const isShowVoucher = () => {
    if (
      props.orderInfo.passtypeUid == 2 ||
      props.orderInfo.passtypeUid == 4 ||
      props.orderInfo.passtypeUid == 6 ||
      props.orderInfo.passtypeUid == 8 ||
      props.orderInfo.status == 5
    ) {
      return null;
    }
    if (
      props.orderInfo.status == 1 ||
      props.orderInfo.status == 2 ||
      props.orderInfo.status == 5
    ) {
      //* 주문 상태( 1. 주문접수, 2. 수령완료, 5. 환불요청, 6. 환불완료, 7. 폐기 )
      return (
        // <Link to={`/app/mypage/ordervoucher/${props.orderInfo.orderUid}`} className="btn_viewVoucher">
        <Link
          to={`/app/mypage/ordervoucher/${props.orderInfo.uid}`}
          className="btn_viewVoucher"
        >
          {t("order_history.show_voucher")}
        </Link>
      );
    }
    return null;
  };

  const renderProductTag = () => {
    // console.log(props.orderInfo.uid + props.orderInfo.passtypeUid);
    if (props.orderInfo.passtypeUid > 8) {
      return null;
    }
    const info: OrderHistoryData = PRODUCT_INFO_TYPES.find(
      (it) => it.passtypeUid == props.orderInfo.passtypeUid
    );

    // props.orderInfo[globalInfoStore.currentLangType.tag]
    // let cardTag:string;
    // switch (globalInfoStore.currentLangType.tag) {
    //     case "koTag": cardTag = info.koTag; break;
    //     case "jaTag": cardTag = info.jaTag; break;
    //     case "zhChsTag": cardTag = info.zhChsTag; break;
    //     case "zhChtTag": cardTag = info.zhChtTag; break;
    //     default: case "enTag": cardTag = info.enTag; break;
    // }

    return (
      <>
        <span className="banner card">
          {info[globalInfoStore.currentLangType.tag]}
        </span>
        <span className="banner time24">{info.time}</span>
      </>
    );
  };

  const postOrderRequestRefund = async () => {
    const url = `/api2/orderRequestRefund`;
    const data = JSON.stringify({
      orderNo: props.orderInfo.uid,
      dspOrderNo: props.orderInfo.referenceCode,
    });
    const res = await AxiosConfig.post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    
    if (res.status == 200) {
      if(res.data.code == '-3001') {
        alert(res.data.desc);
        window.location.reload();
        return ;
      }
      props.orderInfo.status = 5;
      setOrderStatus(5);
      
    } else {
      console.log(res);
      window.location.reload();
    }
  };

  const postOrderCancelRefund = async () => {
      const url = `/api2/orderCancelRefund`;
      const data = JSON.stringify({
        dspOrderNo: props.orderInfo.referenceCode,
      });
      const resr = await AxiosConfig.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      }).then(res => {
        if (res.status == 200) {
          // setResult(res.data);
          const info: OrderHistoryData = PRODUCT_INFO_TYPES.find(
            (it) => it.passtypeUid == props.orderInfo.passtypeUid
          );
          if(info.code == 1) {
            props.orderInfo.status = 1;
            setOrderStatus(1);
          } else {
            setOrderStatus(2);
            props.orderInfo.status = 2;
          }
        } else {
          console.log(res);
        }
      }).catch(function (error) {
        window.location.reload();
      });
  
  };

  const handleRefund = async () => {
    // if(window.confirm(t("order_history.refund_alert"))) {
    //   const purchaseDate = moment(props.orderInfo.purchaseDate, 'YYYY-MM-DD');
    //   const today = moment(new Date(), 'YYYY-MM-DD');
    //   const remainDays = moment.duration(today.diff(purchaseDate)).asDays();
  
    //   if(remainDays >= 89.0) {
    //     const alertMsg = t("order_history.refund_limit");
    //     alert(alertMsg);
    //   } else {
    //     postOrderRequestRefund();
    //   }
    // } 

    // let url = `https://adminapi.discoverseoulpass.com/api/qr`;

    // if(process.env.REACT_APP_ENV === "development") {
    //   url = `http://fxcable.iptime.org:18081/api/qr`;
    // }

    // const data = JSON.stringify({
    //   dspOrderNo: props.orderInfo.referenceCode,
    // });
    // const res = await AxiosConfig.post(url, data, {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
    // if (res.status === 200) {
            
    // } else {
    //   console.log(res);
    // }

    
    try {
      const purchaseDate = moment(props.orderInfo.purchaseDate, 'YYYY-MM-DD');
      const today = moment(new Date(), 'YYYY-MM-DD');
      const remainDays = moment.duration(today.diff(purchaseDate)).asDays();
      
      if(remainDays >= 89.0) {
        alert(t("order_history.refund_limit"));
      } else {
        const url = `/api2/esimrefund/${props.orderInfo.uid}`;
        const res = await AxiosConfig.get(url);
      
        //console.log('::::::::: '+res.data.code);
        if (res.status === 200) {
          if(res.data.code === '3000') {  //이심이 없는 경우 환불
    
            if(window.confirm(t("order_history.refund_alert"))) {
              postOrderRequestRefund();
            }

          } else if(res.data.code === '3001') {  //이심이 있는 경우 환불
    
            if(window.confirm(t("how_eSIM.refundesim"))) {
              postOrderRequestRefund();
            }

          } else {
            //환불 불가
            alert(t("how_eSIM.norefund"));
          }
    
        } else {
          //고객 센터로 문의
          alert(t("how_eSIM.esiminquiry"));
        }
      }
    } catch (error) {
      console.error(error);
    }
    
  };

  const handleCancelRefund = () => {
    if(window.confirm(t("order_history.refund_refundcancel_alert"))) {
      postOrderCancelRefund();
    }
  };

  const btnRefundStatus = () => {
    // const refundDate = props.orderInfo.refundDate;
    // const finalRefundDate = props.orderInfo.finalRefundDate;

    // console.log("refundDate" + refundDate + finalRefundDate)
    // if (props.orderInfo.refundDate == null && props.orderInfo.finalRefundDate == null){
      const info: OrderHistoryData = PRODUCT_INFO_TYPES.find(
        (it) => it.passtypeUid == props.orderInfo.passtypeUid
      );

      if(info === undefined) {  return ; }

      if(props.orderInfo.used_count > 0) { return null; }
      if(props.orderInfo.gift_count > 0) { return null; }
      
      if(info.code === 1) {
        if (
          (orderStatus === 1 && props.orderInfo.visitCount === null)
        ) {
          return (
            <button className="btn_refund" onClick={handleRefund}>
              {t("order_history.refund")}
            </button>
          );
        }
        else if (
          (orderStatus === 2)
        ) {
          return "";
        } else if (orderStatus === 5) {
          return (
            <button className="btn_refunding" onClick={handleCancelRefund}>
              {t("order_history.refund_processing")}
            </button>
          );
        } else if (orderStatus === 6) {
          return (
            <button className="btn_completed">
              {t("order_history.refund_completed")}
            </button>
          );
        }
      } else {
        if (
          (orderStatus === 1 || orderStatus === 2) &&
          props.orderInfo.visitCount === null
        ) {
          return (
            <button className="btn_refund" onClick={handleRefund}>
              {t("order_history.refund")}
            </button>
          );
        } else if (
          (orderStatus === 1 || orderStatus === 2) &&
          props.orderInfo.visitCount !== null
        ) {
          // return (<button className="btn_refund" >{t("order_history.refund_cannot")}</button>)
          return "";
        } else if (orderStatus === 5) {
          return (
            <button className="btn_refunding" onClick={handleCancelRefund}>
              {t("order_history.refund_processing")}
            </button>
          );
        } else if (orderStatus === 6) {
          return (
            <button className="btn_completed">
              {t("order_history.refund_completed")}
            </button>
          );
        }
      }
    // else if (refundDate != null && finalRefundDate == null) {
    //     return (<button className="btn_refund" onClick={handleCancelRefund} >환불요청취소</button>)
    // }
    return null;
  };

  const btnRefundCancelStatus = () => {
    const info: OrderHistoryData = PRODUCT_INFO_TYPES.find(
      (it) => it.passtypeUid == props.orderInfo.passtypeUid
    );

    if (orderStatus === 5 && (props.orderInfo.iccid_num === null || props.orderInfo.iccid_num === undefined)) {
      return (
        <button className="btn_refund_cancel" onClick={handleCancelRefund}>
          {t("order_history.refund_cancel")}
        </button>
      );
    }

    return null;
  };
  //     i18Str:'ko',
  // i18Str:'en',
  // i18Str:'ja',
  // i18Str:'zhChs',
  // i18Str:'zhCht',

  const filteredCodeInfo = props.filteredCodeInfoes.find((item) => {
    return item.codeCd === props.orderInfo.paymentMethod;
  });

  return (
    <div
      className="purchase"
      onClick={(e) => {
        handleClick(e);
      }}
    >
      <p className="p_num">
        <span>{t("order_history.ordernum")}</span>
        <span>{props.orderInfo.referenceCode}</span>
        {/* <span>{props.orderInfo.uid}</span> */}
      </p>

      <p className="date">
        {t("order_history.buy_date")}&nbsp;&nbsp;{" "}
        <Moment format="YYYY-MM-DD HH:mm:ss">
          {props.orderInfo.purchaseDate}
        </Moment>
        &nbsp;
          Asia/Seoul
      </p>
      {renderProductTag()}
      <p className="tit">{getPassTitle()}</p>
      <p className="tit">
        {getValueByKey(props.orderInfo, globalInfoStore.currentLangType.codename)}{" "}
        <span dangerouslySetInnerHTML={{ __html: getAmount() }}></span>
      </p>

      <p className="payment">
        <span>{t("order_history.payment_method")}</span>
        {
          getValueByKey(filteredCodeInfo, globalInfoStore.currentLangType.codename)
        }
        {" "}
      </p>

      <div className="etc">
        <p className="cost">
          KRW {props.orderInfo.finalPrice.toLocaleString()}
        </p>
        {isShowVoucher()}
        {/* {btnRefundCancelStatus()} */}
        {btnRefundStatus()}
      </div>
      <p className="exp">{t("order_history.refund_warn")}</p>
    </div>
  );
};

export default OrderHistoryItem;