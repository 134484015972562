import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const FindIdCompleted = (props:any) => {
    const { t, i18n } = useTranslation();
    const [email, setEmail] = useState(props.location.state.email); //props.location.state.email
    
    
    return (
        <div className="layout">
					
            <div className="wrap_leave">
                <h6 className="sec_tit">{t("find_id.result_sub_title")}</h6>
                <p className="sec_exp">{email}</p>

                <Link to="/app/signin/index/login" className="btn_goToLogin">{t("globalnavbar.sign_in")}</Link>
                

            </div>

        </div>
    );
};

export default FindIdCompleted;