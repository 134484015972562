import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { globalInfoStore, LANG_TYPES } from "../../stores/GlobalInfoStore";

const SearchPassItem = (props:{content:any}) => {
    const { t, i18n } = useTranslation();
    
    return (
        <li>
            <Link to={{
                pathname: `/app/pass/view/${props.content.uid}`
            }}>
            {props.content[globalInfoStore.currentLangType.title2]}</Link>
        </li>
    );
};

export default SearchPassItem;