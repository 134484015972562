import React from "react";
import { useTranslation } from "react-i18next";
import { globalInfoStore } from "../../stores/GlobalInfoStore";

const SpotViewHoliday = (props:{tourInfo:any, holidayInfo:any}) => {
    const { t, i18n } = useTranslation();
	console.log(JSON.stringify(props.holidayInfo))

	// 	    ```json
	// "everyday": "0",
	// "mon": "1",
	// "tue": "0",
	// "wed": "0",
	// "thu": "0",
	// "fri": "0",
	// "sat": "0",
	// "sun": "0",
	// "newYear": "1",
	// "thanksGiving": "1",
	// "etc": "0",
	// ```
	if (props.holidayInfo === undefined || props.holidayInfo.length < 1){
		return null;
	}

    return (
		<>
			{/* {props.holidayInfo.everyday !== undefined && props.holidayInfo.everyday === "1"?(<p>All days</p>):null} */}
			{props.holidayInfo.everyday !== undefined && props.holidayInfo.everyday === "1"?(<p>{t("spot.closed_desc")}</p>):null}
			{props.holidayInfo.mon !== undefined && props.holidayInfo.mon === "1"?(<p>Every Monday</p>):null}
			{props.holidayInfo.tue !== undefined && props.holidayInfo.tue === "1"?(<p>Every Tuesday</p>):null}
			{props.holidayInfo.wed !== undefined && props.holidayInfo.wed === "1"?(<p>Every Wednesday</p>):null}
			{props.holidayInfo.thu !== undefined && props.holidayInfo.thu === "1"?(<p>Every Thursday</p>):null}
			{props.holidayInfo.fri !== undefined && props.holidayInfo.fri === "1"?(<p>Every Friday</p>):null}
			{props.holidayInfo.sat !== undefined && props.holidayInfo.sat === "1"?(<p>Every Saturday</p>):null}
			{props.holidayInfo.sun !== undefined && props.holidayInfo.sun === "1"?(<p>Every Sunday</p>):null}
			{props.holidayInfo.newYear !== undefined && props.holidayInfo.newYear === "1"?(<p>Lunar New Year's Day</p>):null}
			{props.holidayInfo.thanksGiving !== undefined && props.holidayInfo.thanksGiving === "1"?(<p>Korean Thanksgiving Day</p>):null}

			{/* 기타 휴일 직접입력된 정보 */}
        	{props.holidayInfo.etc === "1"?(<p>{props.tourInfo.holiday}</p>):null}
		</>
    )
    
};

export default SpotViewHoliday;