import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// import { Auth } from '../services/AuthService';
import { RouteComponentProps } from 'react-router';
import { AuthActions, AuthObserver } from 'ionic-appauth';
import { AuthGoogle3 } from '../../services/AuthServiceGoogle3';

interface EndRedirectPageProps extends RouteComponentProps {}

const EndRedirect : React.FC<EndRedirectPageProps> = (props: EndRedirectPageProps) => {
    const { t, i18n } = useTranslation();

    let observer: AuthObserver;

    useEffect(() => {
        const callbackUrl = window.location.origin + props.location.pathname +  props.location.search;
        console.log("EndRedirect callbackUrl: " + callbackUrl);
        AuthGoogle3.Instance("signin").authorizationCallback(callbackUrl);
        observer = AuthGoogle3.Instance("signin").addActionListener((action) => {
            if(action.action === AuthActions.SignOutSuccess ||
                action.action === AuthActions.SignInFailed){
                    props.history.replace('landing');
            }
        });
        return () => {
            AuthGoogle3.Instance("signin").removeActionObserver(observer);
        }
    }, [])
    
    return (
            <p>Signing out...</p>
    ); 
};

export default EndRedirect;
