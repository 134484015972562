

import React, { Component, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { render } from "react-dom";
import { Link } from "react-router-dom";
// import ReactDOM from "react-dom";
import Slider from "react-slick";
import GlobalNavBar from "./GlobalNavBar";
import AxiosConfig from '../../services/AxiosConfig';
import Moment from 'react-moment';
import { globalInfoStore, LANG_TYPES } from "../../stores/GlobalInfoStore";

const classNames = require("classnames");

interface NoticeInfo  {
    [key: string]: any;
}

const dspLeafletKor = "https://kr.object.gov-ncloudstorage.com/discoverseoulpass/m/pdf/dsp-leaflet/dsp-leaflet-eng.pdf";
const dspLeafletEng = "https://kr.object.gov-ncloudstorage.com/discoverseoulpass/m/pdf/dsp-leaflet/dsp-leaflet-eng.pdf";
const dspLeafletJpn = "https://kr.object.gov-ncloudstorage.com/discoverseoulpass/m/pdf/dsp-leaflet/dsp-leaflet-jpn.pdf";
const dspLeafletZhCht = "https://kr.object.gov-ncloudstorage.com/discoverseoulpass/m/pdf/dsp-leaflet/dsp-leaflet-zhCht.pdf";
const dspLeafletZhChs = "https://kr.object.gov-ncloudstorage.com/discoverseoulpass/m/pdf/dsp-leaflet/dsp-leaflet-zhChs.pdf";


const ArticleWrapEtc = () => {
    const { t, i18n } = useTranslation();
    // const [page, setPage] = useState(1); //page
	// const [size, setSize] = useState(10);  //

    const [normalNoticeInfoes, setNormalNoticeInfoes] = useState<NoticeInfo>();
    const [filteredNormalInfoes, setFilteredNormalInfoes] = useState([]);
    const fetchNormalNoticeInfoes = async (page:number, size:number, sorts:[]) => {
        let url = `/api2/siteNoticeInfo?page=${page}&size=${size}`;

        if (sorts.length > 0){
            url += (sorts || []).map((item)=>`&sort=${item}`);
        }
        const res = await AxiosConfig.get(url);

        setNormalNoticeInfoes(res.data);
        setFilteredNormalInfoes(res.data.content)
        console.log(res.data);
    };

    useEffect(() => {
        fetchNormalNoticeInfoes(1, 5, []);
    }, [globalInfoStore.currentLangType]);

    const renderNormalNotices = (filteredNormalInfoes || []).map((item) =>{
    
        return (
            <li key={item.uid}>
                <Link to={{
                    pathname: `/app/cs/index/notice/view/${item.uid}`
                }} className="tit">{item[globalInfoStore.currentLangType.title]}</Link>
                <span className="date">
                    <Moment format="YYYY.MM.DD">
                        {item['updateDate']}
                    </Moment>
                </span>
            </li>
        )
    });


    const renderVisitseoulPage = () => {
        const url = `https://${globalInfoStore.currentLangType.full}.visitseoul.net/`;

        return (<a href={url} target="_blank">{t("main.seoul_tourism_official_website")}</a>)
    }
    const renderStoPage = () => {
        if (globalInfoStore.currentLangType.i18Str == "ko"){
            return (<a href="http://www.sto.or.kr/index" target="_blank">{t("main.about_us")}</a>)
        }
        return (<a href="http://www.sto.or.kr/english/index" target="_blank">{t("main.about_us")}</a>)
    }

    const goToPdf = (pdfUrl:string) => {
        window.open(pdfUrl, "_blank")
      }
    
    const DownloadLeaflet = () => {

        if (globalInfoStore.currentLangType.i18Str === "ko"){
            return goToPdf(dspLeafletKor)
        }
        if (globalInfoStore.currentLangType.i18Str === "zhChs"){
            return goToPdf(dspLeafletZhChs)
        }
        if (globalInfoStore.currentLangType.i18Str === "zhCht"){
            return goToPdf(dspLeafletZhCht)
        }
        if (globalInfoStore.currentLangType.i18Str === "ja"){
            return goToPdf(dspLeafletJpn)
        }
        return goToPdf(dspLeafletEng)

    }


    //TODO: 모바일만 동작 반응형에서 체크 필요.
    return (
      <article className="wrap_etc">
        <h6 className="hidden">공지사항, 고객센터, 라이브챗 정보</h6>

        <div className="etc">
            <div className="notice">
            <h4 className="etc_tit">{t("main.notice")}</h4>
            <Link to="/app/cs/index/notice" className="btn_more">{t("main.more")}</Link>

            <ul>
                {renderNormalNotices}
            </ul>
            </div>
            <div className="voc">
            <h4 className="etc_tit">{t("main.support")}</h4>
            <dl>
                <dt>{t("main.operating_hours")}</dt>
                <dd>{t("main.weekdaym")} 09:00 - 18:00 </dd>
            </dl>
            <dl>
                <dt>{t("main.contact")}</dt>
                <dd>+82 2 1644 1060</dd>
            </dl>
            <dl>
                <dt>E-mail</dt>
                <dd>support@<br></br>discoverseoulpass.com</dd>
            </dl>
            </div>
            <div className="chat">
            <h4 className="etc_tit">{t("main.live_chat")}</h4>
            <dl>
                <dt>{t("main.operating_hours")}</dt>
                <dd>{t("main.weekday")}09:00 - 18:00</dd>
            </dl>
            <dl>
                <dt>{t("main.lunch_time")}</dt>
                <dd>{t("main.weekday")}12:00 - 13:00</dd>
            </dl>
            <dl>
                <dt>Timezone</dt>
                <dd>Asia / Seoul</dd>
            </dl>
            </div>
        </div>
        <ul>
                                
            <li className="li01">
                <a onClick={(e)=>DownloadLeaflet()}>{t("main.download_guidebook")}</a>
            </li>
            <li className="li02">
                {renderVisitseoulPage()}
            </li>
            <li className="li03">
                {renderStoPage()}
            </li>
            <li className="li04">
                <Link to="/app/guide/index/buy">{t("main.how_to_buy_a_pass")}</Link>
            </li>
        </ul>
        </article>
    );
}

export default ArticleWrapEtc;



