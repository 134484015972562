import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider, { Settings } from "react-slick";
import AxiosConfig from '../../services/AxiosConfig';
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import AccordionOfflineItem from "./GuideBuyOfflineAccordionItem";
import SliderOfflineItem from "./GuideBuyOfflineSliderItem";

import office01 from "../../resources/img/office01.png" // 명동관광센터 이미지"
import office02 from "../../resources/img/office02.png" // 인천국제공항 제1터미널 이미지"
import office03 from "../../resources/img/office03.png" // 인천국제공항 제1터미널 이미지"
import office04 from "../../resources/img/office04.png" // 인천국제공항 제2터미널"
import office05 from "../../resources/img/office05.png" // 인천국제공항 제2터미널"
import office06 from "../../resources/img/office06.png" // 김포공항"
import GuideBuyTakepassItem from "./GuideBuyTakepassItem";


const GuideBuyTakepass = () => {
    const { t, i18n } = useTranslation();
    
    const [page, setPage] = useState(1); //page
	const [size, setSize] = useState(30);  //
	const [sorts, setSorts] = useState([]); //sort['updateDate,desc','uid,asc']
    const [pickupLocations, setPickupLocations] = useState({})
    const [filteredPickupLocations, setFilteredPickupLocations] = useState([])



    // const fetchPickupLocations = async (page:number, size:number, sorts:[]) => {
    //     const url = `/api/`;
    //     const res = await AxiosConfig.get(url);
    //     setPickupLocations(res.data);
    //     setFilteredPickupLocations(res.data.content);
    // }

    const fetchPickupLocations = async (page:number, size:number, sorts:[]) => {
        let url = `/api2/receiveInfo/list?page=${page}&size=${size}`;

        if (sorts.length > 0){
            url += (sorts || []).map((item)=>`&sort=${item}`);
        }

        const res = await AxiosConfig.get(url);

        setPickupLocations(res.data);
        setFilteredPickupLocations(res.data.content)
        console.log(res.data);
    };

    useEffect(() => {
        fetchPickupLocations(page, size, []);
        return () => {
            
        }
    }, [page])

    const renderGuideBuyTakepassItem = (filteredPickupLocations || []).map((item)=>{
        if(item.uid === 5) { item.mapUrl = 'https://naver.me/FS5dwtpi'; } //Gimpo Int. Airport(Exit 1 on 1F)
        else if(item.uid === 8) { item.mapUrl = 'https://naver.me/GNyGu4aJ'; } //명동관광정보센터
        else if(item.uid === 11) { item.mapUrl = 'https://naver.me/G0IPQQnt'; } //Incheon Int. Airport Terminal 1(gate2)
        else if(item.uid === 12) { item.mapUrl = 'https://naver.me/F3OcekYK'; } //Incheon Int. Airport Terminal 1(gate13)
        else if(item.uid === 14) { item.mapUrl = 'https://naver.me/Fee0Yfq5'; } //Incheon Int. Airport Terminal 2(GATE 4~5)
        else if(item.uid === 28) { item.mapUrl = 'https://naver.me/FCbP4LAB'; } //Seoul Tourism Plaza Tourist Information Center

        return (
            <GuideBuyTakepassItem
                key={item.uid}
                content={item}
            />
        )
    });

    return (
        <div className="wrap_use">
            <p className="use_tit">{t("pass_pickup.title")}</p>
            <p className="use_exp">{t("pass_pickup.desc")}</p>
            <div className="exp_box last" dangerouslySetInnerHTML={{__html:t("pass_pickup.desc2")}}>
                {/* <p>수령 전, 각 수령처별 영업시간 및 휴일을 사전 확인 바랍니다.</p>
                <p>반드시 사전 지정한 수령 예약일에 방문하시길 바라며, 불가피하게 수령일자 변경이 필요하다면 고객센터로 사전 연락 바랍니다.</p>
                <p>본인 확인을 위해 여권 등의 신분증을 확인할 수 있습니다.</p> */}
            </div>
            <ul className="receipt">
                <li className="li01"><span>{t("pass_pickup.receipt1")}</span></li>
                <li className="li02"><span>{t("pass_pickup.receipt2")}</span></li>
            </ul>

            <p className="use_tit">{t("pass_pickup.subtitle")}<span dangerouslySetInnerHTML={{__html:t("pass_pickup.go_map")}}></span></p>
            <p className="use_exp">{t("pass_pickup.subdesc")}</p>

            <div className="receipt_spot">
                {renderGuideBuyTakepassItem}
            </div>

        </div>
    );
};

export default GuideBuyTakepass;