import React from "react";
import { useTranslation } from "react-i18next";

const GuideHowFreeEntry = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <div className="wrap_use">
            <p className="use_tit">{t("how_mobilepass.enter_title")}</p>
            {/* <!-- <p className="use_exp">디스커버서울패스에서 지정된 관광지를 입장하실 때 패스를 제시하고 입장하실 수 있습니다. 모바일 패스 사용자의 경우에는 카드 대신 모바일 패스를 제시하고 입장하게 됩니다.</p> --> */}
            <div className="exp_box" dangerouslySetInnerHTML={{__html:t("how_mobilepass.enter_desc")}}>

            </div>
            <div className="step enter">
                <dl className="step01">
                    <dt>STEP 1</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_mobilepass.enter_step1")}}>

                    </dd>
                </dl>
                <dl className="step02">
                    <dt>STEP 2</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_mobilepass.enter_step2")}}>
                    </dd>
                </dl>
                <dl className="step03">
                    <dt>STEP 3</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_mobilepass.enter_step3")}}>
                    </dd>
                </dl>
            </div>
        </div>
    );
};

export default GuideHowFreeEntry;