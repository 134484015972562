import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AppDownload from "../../components/organisms/AppDownload";
import { isProduction } from "../../services/Util";
import { globalInfoStore } from "../../stores/GlobalInfoStore";

const TransportationTopUp = () => {
    const { t, i18n } = useTranslation();

    const IsShowApk = () => {
      // console.log(JSON.stringify(globalInfoStore.currentLangType.i18Str));
      if (globalInfoStore.currentLangType.i18Str == 'zhChs'){ //중문 간제 제거
        return (<li className="file"><a href="https://play.google.com/store/apps/details?id=com.travolution.discover"><em>APK File download</em></a></li>)
      }
      return (null)
    }
    
    return (
        <div className="wrap_use">
            <p className="use_tit">{t("how_cardpass.trans_title")}</p>
            <p className="use_exp">{t("how_cardpass.trans_subtitle")}</p>
            <div className="exp_box" dangerouslySetInnerHTML={{__html:t("how_cardpass.trans_desc")}}>
            </div>
            <div className="step card">
                <dl className="step01">
                    <dt>STEP 1</dt>
                    <dd>
                        <p className="tit">{t("how_cardpass.trans_step1_title")}</p>
                        <p className="exp" dangerouslySetInnerHTML={{__html:t("how_cardpass.trans_step1_desc")}}></p>
                    </dd>
                </dl>
                <dl className="step02">
                    <dt>STEP 2</dt>
                    <dd>
                        <p className="tit">{t("how_cardpass.trans_step2_title")}</p>
                        <p className="exp" dangerouslySetInnerHTML={{__html:t("how_cardpass.trans_step2_desc")}}></p>
                    </dd>
                </dl>
                <dl className="step03">
                    <dt>STEP 3</dt>
                    <dd>
                        <p className="tit">{t("how_cardpass.trans_step3_title")}</p>
                        <p className="exp" dangerouslySetInnerHTML={{__html:t("how_cardpass.trans_step3_desc")}}></p>
                    </dd>
                </dl>
            </div>
        </div>
    );
};

export default TransportationTopUp;