import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import AxiosConfig from '../../services/AxiosConfig'
import { globalInfoStore } from "../../stores/GlobalInfoStore";

type IFindPwInfo = {
    email:string;
    telCountryUid:string;
    tel:string;
}

const FindPw = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [findPwInfo, setFindPwInfo] = useState<IFindPwInfo>({
        email: "",
        telCountryUid: "",
        tel: ""
    });
    const [listOfCountry, setListOfCountry] = useState([]);
    let lang = "en";

    const fetchListOfCountry = async () => {
        const res = await AxiosConfig.get("/api2/countryInfo");
        setListOfCountry(res.data);
    };

    useEffect(() => {
        fetchListOfCountry();
    },[globalInfoStore.currentLangType])

    const renderListOfCountry = (listOfCountry || []).map((item) => {
        return (
            <option key={item.uid} value={item.uid}>
              {item[lang]}({item.telCode}) 
            </option>
          )
    });

    const postFindPw = async () => {
        if (findPwInfo.email === "") {
            alert("please input a valid email address");
            return;
        }
        // if (findPwInfo.telCountryUid < 1) {return;}
        if (findPwInfo.telCountryUid == t("sign_up_with_email.select_phone_no") || findPwInfo.telCountryUid == "" ) {
            alert("Please Select Phone Country.");
            return;
        }
        if (findPwInfo.tel.length < 5) {
            alert("please input a valid phone number");
            return;
        }
        //alert(JSON.stringify(findPwInfo));return;

        const url = `/api2/memberInfo/findPw`;
        const data = JSON.stringify(findPwInfo);
        const res = await AxiosConfig.post(url,
            data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            });
        if (res.status == 200 ) {  
            if (res.data.code === "210") {//210 임시 비밀번호 발급 완료
                history.push({
                    pathname: '/app/signin/index/findpw/complete'
                });
            } else if (res.data.code === "-204") {
                alert("Account Not Found.")    
            }
        } else {
            alert("Bad Request")
        }
    }

    
    return (
        <div className="layout">
	
            <div className="wrap_info findpw">
                <h6 className="sec_tit side">{t("find_pw.title")}</h6>
                <p className="sec_exp side" dangerouslySetInnerHTML={{__html:t("find_pw.sub_title")}}></p>

                <em className="top_exp">* {t("find_pw.required")}</em>

                <dl>
                    <dt>{t("find_pw.email")} <em>*</em></dt>
                    <dd>
                        <input type="text" title="이메일" value={findPwInfo.email} onChange={e => setFindPwInfo({email:e.target.value, telCountryUid: findPwInfo.telCountryUid, tel: findPwInfo.tel})} autoComplete="false" />
                    </dd>
                    {findPwInfo.email==""?<dd className="txt_alert">{t("find_pw.alert_input_email")}</dd>:<></>}
                </dl>
                <dl>
                    <dt>{t("sign_up_with_email.phone_no")} <em>*</em></dt>
                    <dd>
                        <select className="phone"  onChange={e => setFindPwInfo({email:findPwInfo.email, telCountryUid: e.target.value, tel: findPwInfo.tel})} value={findPwInfo.telCountryUid}>
                            <option>{t("sign_up_with_email.select_phone_no")}</option>
                            {renderListOfCountry}
                        </select>
                        <input type="text" title="전화번호" value={findPwInfo.tel} onChange={e => setFindPwInfo({email:findPwInfo.email, telCountryUid: findPwInfo.telCountryUid, tel: e.target.value})} autoComplete="false" />
                    </dd>
                </dl>
                <button className="btn_find" onClick={postFindPw}>{t("find_pw.btn_find_pw")}</button>
            </div>

        </div>
    );
};

export default FindPw;