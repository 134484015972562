import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const FaqItem = (props:{ask:string, answer:string, isActive?:boolean}) => {
// const FaqItem = (props:any) => {
    const replacedAnswer = props.answer.replace(new RegExp('\r?\n','g'), '<br />');
    const { t, i18n } = useTranslation();
    
    // const [setActive, setActiveState] = useState("");
    const aa =  ((props.isActive!=null&&props.isActive)?"active":"");
    const [setActive, setActiveState] = useState(aa);
    function toggleAccordion() {
        setActiveState(setActive === "" ? "active" : "");
    }

    return (
        <dl className={`accordion ${setActive}`} onClick={toggleAccordion}>
            <dt>
                <span>Q</span>
                <p>{props.ask}</p>
            </dt>
            <dd className="clear" dangerouslySetInnerHTML={{__html:replacedAnswer}}>
                {/* {item[langAnswer]} */}
                {/* {props.answer} */}
            </dd>
        </dl>
    );
};

export default FaqItem;