import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider, { Settings } from "react-slick";
import AxiosConfig from '../../services/AxiosConfig';
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import AccordionOfflineItem from "./GuideBuyOfflineAccordionItem";
import SliderOfflineItem from "./GuideBuyOfflineSliderItem";


import office01 from "../../resources/img/office01.png" // 명동관광센터 이미지"
import office02 from "../../resources/img/office02.png" // 인천국제공항 제1터미널 이미지"
import office03 from "../../resources/img/office03.png" // 인천국제공항 제1터미널 이미지"
import office04 from "../../resources/img/office04.png" // 인천국제공항 제2터미널"
import office05 from "../../resources/img/office05.png" // 인천국제공항 제2터미널"
import office06 from "../../resources/img/office06.png" // 김포공항"


const GuideBuyTakepassItem = (props:{content:any}) => {
    const { t, i18n } = useTranslation();


    // "koTitle": "명동 관광정보센터",
    // "koAddress": "-",
    // "koPosition": "-",
    // "koWorktime": "-",
    // "koHoliday": "-",
    let langTitle = globalInfoStore.currentLangType.prefix1 + "Title";
    let langAddress = globalInfoStore.currentLangType.prefix1 + "Address";
    let langPosition = globalInfoStore.currentLangType.prefix1 + "Position";
    let langWorktime = globalInfoStore.currentLangType.prefix1 + "Worktime";
    let langHoliday = globalInfoStore.currentLangType.prefix1 + "Holiday";
    
    return (
        
                <dl>
                    <dt>
                        {/* <img src={office01} alt="명동관광센터 이미지" /> */}
                        <img src={props.content.fullpath} alt={props.content[langTitle] + " 이미지"} />
                    </dt>
                    <dd>
                        <p className="tit">{props.content[langTitle]}</p>
                        <ul>
                            <li>
                                <span>{t("pass_pickup.address")}</span>
                                <span>{props.content[langAddress]}</span>
                            </li>
                            <li>
                                <span></span>
                                <span><a className="gToMap2" href={props.content.mapUrl} target="_blank">{t("buy_offline.go_to_map")}</a>
                                </span>
                            </li>
                            <li>
                                <span>{t("pass_pickup.location")}</span>
                                <span>{props.content[langPosition]}</span>
                            </li>
                            <li>
                                <span>{t("pass_pickup.operating_hours")}</span>
                                <span>{props.content[langWorktime]}</span>
                            </li>
                            <li>
                                <span>{t("pass_pickup.holiday")}</span>
                                <span>{props.content[langHoliday]}</span>
                            </li>
                        </ul>
                    </dd>
                </dl>
                
    );
};

export default GuideBuyTakepassItem;