import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import ProfileNav from './ProfileNav';
import {BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import ProfileNavPassword from './profile/ProfileNavPassword';
import ProfileNavModify from './profile/ProfileNavModify';
import ProfileNavLeaveId from './profile/ProfileNavLeaveId';
import ProfileContentIndex from './profile/ProfileContentIndex';
import ProfileContentPassword from './profile/ProfileContentPassword';
import ProfileContentModify from './profile/ProfileContentModify';
import ProfileContentLeaveId from './profile/ProfileContentLeaveId';
import ProfileContentLeaveIdCompleted from './profile/ProfileContentLeaveIdCompleted';
import Interest from './interest/Interest';
import Navigation from '../navigation/Navigation';
import OrderHistory from './order-history/OrderHistory';
import MyPass from './mypass/MyPass';
import MyEsim from './esim/MyEsim';
import InquiryList from './inquiry/InquiryList';
import { PrivateRoute } from '../../routes/PrivateRoute';
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import ScrollToTop from "../../components/ScrollToTop";
import OrderVoucher from "./order-history/OrderVoucher";
import MyPage from "./MyPage";
import SupportPage from './support/SupportPage';

const MypageIndex = () => {
	const { t, i18n } = useTranslation();

	const [pageTitle, setPageTitle] = useState("");

    useEffect(() => {

	}, [globalInfoStore.currentLangType])

    return (
        <section id="container" className="sub">
			<h6 className="hidden">본문</h6>

			<article className="bg sub05">
				<div className="layout">
					<h2 className="page_tit">{t("navigation.app_mypage")}</h2>
				</div>
			</article>

			<Navigation></Navigation>

			<article id="content">
				
				<PrivateRoute exact path="/app/mypage/menu" component={MyPage} />
				<PrivateRoute exact path="/app/mypage/profile/index" component={ProfileContentIndex} />
				<PrivateRoute exact path="/app/mypage/profile/password" component={ProfileContentPassword} />
				<PrivateRoute exact path="/app/mypage/profile/modify" component={ProfileContentModify} />
				<PrivateRoute exact path="/app/mypage/profile/leaveid" component={ProfileContentLeaveId} />
				<Route exact path="/app/mypage/profile/leaveid/complete" component={ProfileContentLeaveIdCompleted} />
				<PrivateRoute exact path="/app/mypage/mypass" component={MyPass} />
				<PrivateRoute exact path="/app/mypage/myesim" component={MyEsim} />
				<PrivateRoute exact path="/app/mypage/inquiry" component={InquiryList} />
				<PrivateRoute path="/app/mypage/interest" component={Interest} />
				<PrivateRoute exact path="/app/mypage/orderhistory" component={OrderHistory} />
				<PrivateRoute exact path="/app/mypage/ordervoucher/:uid" component={OrderVoucher} />
				<PrivateRoute exact path="/app/mypage/support/index" component={SupportPage} />
			</article>

		</section>
    );
};

export default MypageIndex;