import moment from "moment";
import "moment/locale/ko";
import React, { useState } from "react";
import Moment from "react-moment";
import { useInterval } from "react-use";
import { IMyPass } from "../mypage/mypass/MyPass";
import { IProductInfo } from "../../services/ProductInfoService";

// const classNames = require("classnames");

const RemainTimer = (props:{expireDate:any, productItem:IMyPass, productInfoList:IProductInfo[]}) => {
    const [seconds, setSeconds] = useState(Date.now());

    // useInterval
    useInterval(() => {
        setSeconds(Date.now());
    }, 1000);

    // const exp = moment(props.expireDate, 'YYYY-MM-DDThh:mm:ss');
    // const exp = moment(expireDate);
    // const expireDate: any = new Date("2021-02-11T00:00:00");
    // const expireDate2: any = new Date(props.expireDate);
    // const nowTimeFormat: any = new Date(seconds);
    // props.content.expireDate

    // 1,2  = 24시간    3,4 = 48시간   5,6 = 72시간
    if (props.expireDate == null ) {
        switch (props.productItem.passtypeUid) {  
            case 1: case 2: return (<>24:00:00</>);
            case 3: case 4: return (<>48:00:00</>);
            case 5: case 6: return (<>72:00:00</>);
            default: return (<>--:--:--</>);
        }
    }

    const exp = moment(props.expireDate, 'YYYY-MM-DDThh:mm:ss');
    const momentNow = moment();

    let addDays;
    switch (props.productItem.passtypeUid) {  // 1 = 24시간 ,3 = 48시간,5 = 72시간
        case 1: case 2: addDays = 1; break;
        case 3: case 4: addDays = 2; break;
        case 5: case 6: addDays = 3; break;
    }

    if (exp.isBefore(momentNow)) {
        return (<>00:00:00</>)
    } else if (exp.isAfter(momentNow.add(addDays, 'days'))) {
        // moment().add(addDays, 'days').calendar();
        switch (props.productItem.passtypeUid) {  
            case 1: case 2: return (<>24:00:00</>);
            case 3: case 4: return (<>48:00:00</>);
            case 5: case 6: return (<>72:00:00</>);
        }
    }

    // const tempExpDate: any = new Date(props.expireDate);

    return (
        <>
        <Moment duration={seconds} date={props.expireDate} format="hh:mm:ss"></Moment>
        </>
    );
};

export default RemainTimer;