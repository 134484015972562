import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const PolicyTerms = () => {
  const { t, i18n } = useTranslation();
  
  return (
		<>

			<h6 className="tit_terms">{t("policy.title_terms")}</h6>
			
			<div className="wrap_use terms" dangerouslySetInnerHTML={{__html:t("policy.body_terms")}}></div>
		</>

  );
};

export default PolicyTerms;
