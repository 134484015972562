import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { NumberParam, useQueryParams } from 'use-query-params';
import AxiosConfig from '../../services/AxiosConfig';
import { codeInfoByGroupCd, ICodeInfo } from "../../services/CodeInfoService";
import { getValueByKey } from "../../services/Util";
import { globalInfoStore } from '../../stores/GlobalInfoStore';
import FaqItem from './FaqItem';
// import REACT_APP_API_URL ;

const classNames = require("classnames");

export interface IFaqType {
	groupCd: string,
	codeId: number,
	codeCd: string,
	codeName: string
}

const Faq = () => {
	const { t, i18n } = useTranslation();
	const [infoes, setInfoes] = useState([]);
	const [filteredInfoes, setFilteredInfoes] = useState([]);
	const filteredCodeInfo = useRecoilValue<ICodeInfo[]>(codeInfoByGroupCd({groupCd:"faq-category"}));
	const getFaqTypes = (codeId:number):ICodeInfo => {
		const faqType:ICodeInfo =  filteredCodeInfo.find((item:ICodeInfo)=> {
			return item.codeId === codeId
		})
		return faqType;
	}
	const [queryParam, setQueryParam] = useQueryParams( {
		"uid": NumberParam
	});

    // const { searchKeyword, searchType, page } = queryParam;
    const { uid } = queryParam;
	const initCodeInfo = filteredCodeInfo.find(it=>{return it.codeId === 0})
	const [currentCodeInfo, setCurrentCodeInfo] = useState<ICodeInfo>(initCodeInfo);
	// const [filterName, setFilterName] = useState<ICodeInfo>(filteredCodeInfo.find(it=>{return it.codeId === 0}));
	const [isShowMobileTabMenu, setShowMobileTabMenu] = useState(false);

	const fetchPosts = async () => {
		const res = await AxiosConfig.get("/api2/siteFaqInfo?size=1000");
		setInfoes(res.data.content);
		setFilteredInfoes(res.data.content);
	};

	useEffect(() => {
		fetchPosts();
	}, []);

	const fixedFaqs = (filteredInfoes || []).map((item) =>{
		return (
			<FaqItem key={item.uid} ask={item[globalInfoStore.currentLangType.ask]} answer={item[globalInfoStore.currentLangType.answer]} isActive={item.uid == uid}></FaqItem>
		)
	});

	const handleSetFilterName = async (faqType:ICodeInfo) => {
		
		// if (faqType == null) {
		// 	setCurrentCodeInfo(getFaqTypes(0))
		// 	// setFilteredInfoes(infoes);
		// 	return;
		// }
		// console.log('faqType = ' + JSON.stringify(faqType));
		// setCurrentCodeInfo(faqType);
		// if (faqType.codeId === 0) {
		// 	setFilteredInfoes(infoes);
		// } else {
		// 	setFilteredInfoes(infoes.filter(it => it.category === faqType.codeId));
		// }
		var res = null;
		if (faqType.codeId === 0) {
			res = await AxiosConfig.get("/api2/siteFaqInfo?size=1000");
		} else {
			res = await AxiosConfig.get("/api2/siteFaqInfo?size=1000&category=" + faqType.codeId);
		}
		setCurrentCodeInfo(faqType);
		setInfoes(res.data.content);
		setFilteredInfoes(res.data.content);
	}

	const toggleMobileTabMenu = () => {
		setShowMobileTabMenu(!isShowMobileTabMenu);
	}

	const renderFaqCategoryItems = () => {
		// Sort filteredCodeInfo by codeId in ascending order
		const sortedFilteredCodeInfo = [...filteredCodeInfo].sort((a, b) => a.codeId - b.codeId);
	
		return sortedFilteredCodeInfo.map((item) => {
			return (
				<p
					key={item.codeId}
					className={classNames({
						hidden: false,
						active: item.codeId === currentCodeInfo.codeId,
					})}
					onClick={() => handleSetFilterName(item)}
				>
					{getValueByKey(item, globalInfoStore.currentLangType.codename)}
				</p>
			);
		});
	};

    return (
				<div className="layout">
					<div className={classNames({faq_tab:true, m_active:isShowMobileTabMenu})} onClick={toggleMobileTabMenu}>
						<span className="m_only">{getValueByKey(currentCodeInfo, globalInfoStore.currentLangType.codename)}</span>

						<div className="m_bg">
							{/* <p className={classNames({hidden:false, active:(getFaqTypes(0).codeId===currentCodeInfo.codeId)})} onClick={()=>handleSetFilterName(getFaqTypes(0))}>{getValueByKey(getFaqTypes(0), globalInfoStore.currentLangType.codename)}</p>
							<p className={classNames({hidden:false, active:(getFaqTypes(1).codeId===currentCodeInfo.codeId)})} onClick={()=>handleSetFilterName(getFaqTypes(1))}>{getValueByKey(getFaqTypes(1), globalInfoStore.currentLangType.codename)}</p>
							<p className={classNames({hidden:false, active:(getFaqTypes(2).codeId===currentCodeInfo.codeId)})} onClick={()=>handleSetFilterName(getFaqTypes(2))}>{getValueByKey(getFaqTypes(2), globalInfoStore.currentLangType.codename)}</p>
							<p className={classNames({hidden:false, active:(getFaqTypes(3).codeId===currentCodeInfo.codeId)})} onClick={()=>handleSetFilterName(getFaqTypes(3))}>{getValueByKey(getFaqTypes(3), globalInfoStore.currentLangType.codename)}</p>
							<p className={classNames({hidden:false, active:(getFaqTypes(4).codeId===currentCodeInfo.codeId)})} onClick={()=>handleSetFilterName(getFaqTypes(4))}>{getValueByKey(getFaqTypes(4), globalInfoStore.currentLangType.codename)}</p>
							<p className={classNames({hidden:false, active:(getFaqTypes(5).codeId===currentCodeInfo.codeId)})} onClick={()=>handleSetFilterName(getFaqTypes(5))}>{getValueByKey(getFaqTypes(5), globalInfoStore.currentLangType.codename)}</p>
							<p className={classNames({hidden:false, active:(getFaqTypes(6).codeId===currentCodeInfo.codeId)})} onClick={()=>handleSetFilterName(getFaqTypes(6))}>{getValueByKey(getFaqTypes(6), globalInfoStore.currentLangType.codename)}</p>
							<p className={classNames({hidden:false, active:(getFaqTypes(7).codeId===currentCodeInfo.codeId)})} onClick={()=>handleSetFilterName(getFaqTypes(7))}>{getValueByKey(getFaqTypes(7), globalInfoStore.currentLangType.codename)}</p>
							<p className={classNames({hidden:false, active:(getFaqTypes(8).codeId===currentCodeInfo.codeId)})} onClick={()=>handleSetFilterName(getFaqTypes(8))}>{getValueByKey(getFaqTypes(8), globalInfoStore.currentLangType.codename)}</p> */}
							{renderFaqCategoryItems()}
						</div>
					 </div>

					 <div className="tab_area">
					    {fixedFaqs}
					</div>

				</div>
    );
};

export default Faq;