import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { AxiosRequestor } from '../../services/AxiosService';
import AxiosConfig from '../../services/AxiosConfig';
import { notifyListeners } from 'mobx/dist/internal';
import Axios, { CancelTokenSource } from 'axios';
import Moment from 'react-moment';
import { globalInfoStore, LANG_TYPES } from "../../stores/GlobalInfoStore";
import { HiddenEasterEgg } from "react-hidden-easter-egg";

const classNames = require("classnames");
interface NoticeInfo  {
    [key: string]: any;
}

const Notice = () => {
    const { t, i18n } = useTranslation();

    // const [infoes, setInfoes] = useState<NoticeItem[] | (() => NoticeItem[])>();
    // const [infoes, setInfoes] = useState([]);
    const [fixedNoticeInfoes, setFixedNoticeInfoes] = useState<NoticeInfo>();
    const [normalNoticeInfoes, setNormalNoticeInfoes] = useState<NoticeInfo>();
    // const [filteredInfoes, setFilteredInfoes] = useState([]);
    const [filteredFixedInfoes, setFilteredFixedInfoes] = useState([]);
    const [filteredNormalInfoes, setFilteredNormalInfoes] = useState([]);
    // const [notices, setNotices]: [NoticeItem[], (notices: NoticeItem[]) => void] = React.useState(defaultNotices);
    // const [posts, setPosts]: [IPost[], (posts: IPost[]) => void] = React.useState(defaultPosts);
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1); 
    // const [postsPerPage] = useState(10); 
    const [page, setPage] = useState(1); //page
	const [size, setSize] = useState(10);  //
	const [sorts, setSorts] = useState([]); //sort['updateDate,desc','uid,asc']
	const [totalPages, setTotalPages] = useState(0);
    const [inputSearchText, setInputSearchText] = useState("");
    const [searchKeyword, setSearchKeyword] = useState("");

    const fetchFixedNoticeInfoes = async () => {
        setLoading(true);
        const res = await AxiosConfig.get("/api2/siteNoticeInfo?fixed=Y");
        setFixedNoticeInfoes(res.data);
        setFilteredFixedInfoes(res.data.content)
        console.log(res.data);
        setLoading(false);
    };

    const fetchNormalNoticeInfoes = async (page:number, size:number, sorts:[]) => {
        setLoading(true);
        let url = `/api2/siteNoticeInfo?page=${page}&size=${size}`;

        if (sorts.length > 0){
            url += (sorts || []).map((item)=>`&sort=${item}`);
        }

        if (searchKeyword != ""){
            url += `&searchKeyword=${searchKeyword}`
        }

        const res = await AxiosConfig.get(url);

        setNormalNoticeInfoes(res.data);
        setFilteredNormalInfoes(res.data.content)
        console.log(res.data);
        setLoading(false);
    };

    useEffect(() => {
        fetchFixedNoticeInfoes();
    }, [globalInfoStore.currentLangType]);

    useEffect(() => {
        fetchNormalNoticeInfoes(page, size, []);
        window.scrollTo(0, 0);
    }, [globalInfoStore.currentLangType, page]);

    const fixedNotices = (filteredFixedInfoes || []).map((notice) =>{
        return (
            <p>
                {/* <span className="sort">공지</span> */}
                <span className={classNames({sort: true, event: (notice.category == 2)})} > {(notice.category == 2)?t("notice.label_event"):t("notice.label_notice")}</span>
                {/* <a className="tit" href="#" target="_self">코로나19로 인한 제휴시설 운영시간 변동 안내</a> */}
                {/* <Link to="/app/cs/index/notice/view" className="tit">{notice[globalInfoStore.currentLangType.title]}</Link> */}
                <Link to={{
                    pathname: `/app/cs/index/notice/view/${notice.uid}`
                }} className="tit">{notice[globalInfoStore.currentLangType.title]}</Link>
                <span className="date">
                    <Moment format="YYYY-MM-DD">
                            {notice['updateDate']}
                    </Moment>
                </span>
            </p>
        )
    });

    const normalNotices = (filteredNormalInfoes || []).map((notice) =>{
        return (
        <li>
            <span className={classNames({sort: true, event: (notice.category == 2)})} > {(notice.category == 2)?t("notice.label_event"):t("notice.label_notice")}</span>
            <Link to={{
                    pathname: `/app/cs/index/notice/view/${notice.uid}`
                }} className="tit">{notice[globalInfoStore.currentLangType.title]}</Link>
            <span className="date">
                <Moment format="YYYY-MM-DD">
                        {notice['updateDate']}
                </Moment>
            </span>
        </li>
        )
    });

	const handlePageClick = (pageNumber: any) => {
		setPage(pageNumber)
	};


    const onSearchTextChange = (e: any) => {
        console.log(e.type + ":", e.target.value);
        setInputSearchText(e.target.value);
    }

    const handleSearch = (e: any) => {
        // alert("handleSearch::" +searchText)
        setSearchKeyword(inputSearchText)
        fetchNormalNoticeInfoes(page, size, []);
    }

    const onSearchKeyDown = (e: any) => {
        if (e.keyCode === 13) {
            console.log('enter')
            setSearchKeyword(inputSearchText)
            fetchNormalNoticeInfoes(page, size, []);
        }
    }

    // /** jwt 이스터 */
    const handleStorageActive = () => {
        let input = prompt("Discover Seoul Jw", "");
        //jwt localstorage 에 저장
        globalInfoStore.setDspJwt(input)
        console.log(input);
    }

    // let key = [76, 79, 76];
    // const [ck, setCk] = useState(0);
    // console.log("ck::" + ck);
    // let max = key.length;

    // var lol = function () {
    //     alert("lol")
    // };


    // function onStorageActive2(e:any) {
    //     // type 'lol' on your keyboard
    //     if (e.keyCode === key[ck]) {
    //         console.log("lol 1 keycode " + e.keyCode)
    //         setCk(ck+1);
    //     }
    //     console.log("lol 2  ck:" + ck)
    //     if (e.which === key[ck]) {
    //         console.log("lol 2  ck:" + ck)
    //         setCk(ck+1);
    //     } else {
    //         console.log("lol 3")
    //         setCk(0);
    //     }
    //     console.log ("lol 3 ck:" + ck + " max:" + max);
    //     if (ck >= max) {
    //         console.log("lol 4")
    //         lol();
    //         // ck = 0;
    //         setCk(0);
    //     }
    //     console.log("lol 5")
    // }

	const getTotalCount = () => {
        if (normalNoticeInfoes == null)
            return (t("notice.total_count_prefix") + " 0" + t("notice.total_count_postfix"))
        return (t("notice.total_count_prefix") + " " + normalNoticeInfoes.totalElements + t("notice.total_count_postfix"))
		// return (<span className="cnt">{t("notice.total_prefix") + " " + normalNoticeInfoes.totalElements + t("notice.total_postfix")}</span>)
	}

    // const NoticeCount = () => {
    //     if (normalNoticeInfoes == null)
    //         return (<span className="cnt">총 0 개</span>)
    //     return (<span className="cnt">총 {normalNoticeInfoes.totalElements}개</span>)
    // }

    const NoticePager = () => {

        if (normalNoticeInfoes == undefined || normalNoticeInfoes.totalPages < 2)
            return (<></>)

        return (
            <Pagination
                innerClass={'board_pager'}
                // activeClass={'active'}
                // activeLinkClass={'active'}
                activePage={page}
                itemsCountPerPage={size}
                totalItemsCount={normalNoticeInfoes.totalElements}
                pageRangeDisplayed={5}
                onChange={(pageNumber)=>handlePageClick(pageNumber)}
                itemClassFirst={'btn_pprv'}
                itemClassPrev={'btn_prev'}
                itemClassNext={'btn_next'}
                itemClassLast={'btn_nnxt'}
            />
        )
    }

    return (
        <div className="layout">
            
            <div className="wrap_sch">
                <span className="cnt" dangerouslySetInnerHTML={{__html:getTotalCount()}}></span>
                {/* <NoticeCount></NoticeCount> */}
                <fieldset>
                    <legend>공지사항 검색</legend>
                    <input type="text" title="검색" className="input_sch" placeholder={t("notice.search_placeholder")}
                    onChange={onSearchTextChange}
                    onKeyDown={(e) => onSearchKeyDown(e)}
                    // onKeyUp={(e) => onStorageActive2(e)}
                     />
                    <button className="btn_sch" onClick={handleSearch}>검색</button>
                </fieldset>
            </div>

            <div className="board_fix">
                {fixedNotices}
            </div>

            <ul className="board_list">
                {normalNotices}
            </ul>

            {/* <ul className="board_pager">
                <li className="btn_pprv"><Link to="#" target="_self">맨 앞으로</Link></li>
                <li className="btn_prev"><Link to="#" target="_self">앞으로</Link></li>
                <li className="active"><Link to="#" target="_self">1</Link></li>
                <li><Link to="#" target="_self">2</Link></li>
                <li><Link to="#" target="_self">3</Link></li>
                <li><Link to="#" target="_self">4</Link></li>
                <li><Link to="#" target="_self">5</Link></li>
                <li className="btn_next"><Link to="#" target="_self">뒤로</Link></li>
                <li className="btn_nnxt"><Link to="#" target="_self">맨 뒤로</Link></li>
            </ul> */}

            <NoticePager></NoticePager>
                
            <HiddenEasterEgg code={[1, 2, 1, 2]} resetEggMs={3000} codeMode="MOBILE_CLICK" cb={() => {console.log("finish")}}>
                <div style={{width:"200px", height:"50px"}} onClick={()=>{handleStorageActive()}}></div>
            </HiddenEasterEgg>

        </div>
    );
};

export default Notice;