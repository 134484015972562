import React from "react";
import { useTranslation } from "react-i18next";
import AppDownload from "../../components/organisms/AppDownload";
import { useLocation } from "react-router-dom";

const GuideHowRegisterPass = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const getGraph = () => {
        return t("common.curlang");
    };

    const getStepclass = (str:string) => {
        return str + t("common.curlang");
        //return str + "en";
    };

    
    return (
        <div className="wrap_use">
            <p className="use_tit">{t("guide.register_pass")}</p>
            <p className="use_exp">{t("guide.register_pass_subTitle")}</p>
            <div className="exp_box " dangerouslySetInnerHTML={{__html:t("guide.register_pass_desc")}}>
            </div>

            
            <div className="step step04 registerPass">
                <dl className={getStepclass("step01")}>
                    <dt>STEP 1</dt>
                    <dd><p className="exp">{t("guide.register_pass_step1")}</p></dd>
                </dl>
                <dl className={getStepclass("step02")}>
                    <dt>STEP 2</dt>
                    <dd><p className="exp">{t("guide.register_pass_step2")}</p></dd>
                </dl>
                <dl className={getStepclass("step03")}>
                    <dt>STEP 3</dt>
                    <dd><p className="exp">{t("guide.register_pass_step3")}</p></dd>
                </dl>
                <dl className={getStepclass("step04")}>
                    <dt>STEP 4</dt>
                    <dd><p className="exp">{t("guide.register_pass_step4")}</p></dd>
                </dl>
            </div>

            { (location.pathname.startsWith('/appnv')) ? <></> :
                <AppDownload type="bold"></AppDownload>
            }
        </div>
    );
};

export default GuideHowRegisterPass;