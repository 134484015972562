import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Router, Switch, useLocation } from 'react-router';
import { Link } from "react-router-dom";
import { getMenuPlacement } from 'react-select/src/components/Menu';
import GuideBuyOffline from './GuideBuyOffline';
import GuideBuyOnline from './GuideBuyOnline';
import GuideBuyTakepass from './GuideBuyTakepass';
import GuideBuyCU from './GuideBuyCU';

const classNames = require("classnames");

const GuideBuyIndex = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const PASSGUIDE_BUY_01 = "/app/guide/index/buy/online";
	const PASSGUIDE_BUY_02 = "/app/guide/index/buy/offline";
	const PASSGUIDE_BUY_03 = "/app/guide/index/buy/takepass";
    const PASSGUIDE_BUY_04 = "/app/guide/index/buy/cu";

    const [isShowMobileTabMenu, setIsShowMobileTabMenu] = useState(false);

    const [filterName, setFilterName] = useState('');

    const isSubMenuActive = (activeName:string) => {

		if ( activeName == location.pathname ) {
			return true;
		} 
		return false;
    }
    
    const handleSetFilterName = (filterName:string) => {
		setFilterName(filterName);
	}

    const toggleMobileTabMenu = () => {
		setIsShowMobileTabMenu(!isShowMobileTabMenu);
	}

    return (
        <div className="layout">

            {/* <div className="use_tab howTo " onClick={toggleMobileTabMenu}> */}
            {/* <div className="use_tab howTo m_active" onClick={toggleMobileTabMenu}> */}
            <div className={classNames({use_tab:true, howTo:true, m_active:isShowMobileTabMenu})} onClick={toggleMobileTabMenu}>
            {/* <div className="use_tab howTo" > */}

                {isSubMenuActive(PASSGUIDE_BUY_01)?<span className="m_only">{t("guide.online_purchase")}</span>:""}
                {isSubMenuActive(PASSGUIDE_BUY_02)?<span className="m_only">{t("guide.offline_purchase")}</span>:""}
                {isSubMenuActive(PASSGUIDE_BUY_04)?<span className="m_only">{t("guide.online_cu")}</span>:""}
                {isSubMenuActive(PASSGUIDE_BUY_03)?<span className="m_only">{t("guide.pass_pickup")}</span>:""}
                
                <div className="m_bg">
                    <Link to="/app/guide/index/buy/online" className={classNames({active:isSubMenuActive(PASSGUIDE_BUY_01)})} >{t("guide.online_purchase")}</Link>
                    <Link to="/app/guide/index/buy/offline" className={classNames({active:isSubMenuActive(PASSGUIDE_BUY_02)})} >{t("guide.offline_purchase")}</Link>
                    <Link to="/app/guide/index/buy/cu" className={classNames({active:isSubMenuActive(PASSGUIDE_BUY_04)})} >{t("guide.online_cu")}</Link>
                    <Link to="/app/guide/index/buy/takepass" className={classNames({active:isSubMenuActive(PASSGUIDE_BUY_03)})} >{t("guide.pass_pickup")}</Link>
                </div>
            </div>

            <Switch>
                <Route exact path="/app/guide/index/buy" render={()=><Redirect to="/app/guide/index/buy/online"/>} />
                <Route path="/app/guide/index/buy/online" component={GuideBuyOnline} />
                <Route path="/app/guide/index/buy/offline" component={GuideBuyOffline} />
                <Route path="/app/guide/index/buy/cu" component={GuideBuyCU} />
                <Route path="/app/guide/index/buy/takepass" component={GuideBuyTakepass} />
            </Switch>
         </div>
    );
};

export default GuideBuyIndex;