import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, useLocation } from 'react-router';
import { Link } from "react-router-dom";
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import PolicyLocation from './PolicyLocation';
import PolicyTerms from './PolicyTerms';

const classNames = require("classnames");

const PolicyIndex = () => {
	const { t, i18n } = useTranslation();
	const location = useLocation();

	const POLICY_01 = "/appnv/policy/terms";
    const POLICY_02 = "/appnv/policy/location";

	const [isShowMobileTabMenu, setIsShowMobileTabMenu] = useState(false);

  	useEffect(() => {

	}, [globalInfoStore.currentLangType])

	const isSubMenuActive = (activeName:string) => {
		if ( activeName == location.pathname ) {
			return true;
		} 
		return false;
    }

	const toggleMobileTabMenu = () => {
		setIsShowMobileTabMenu(!isShowMobileTabMenu);
	}

    return (
        <section id="container" className="sub">

			<article id="content">
				<div className="layout">

					<Route path="/appnv/policy/terms" component={PolicyTerms} />
					{/* <Route path="/appnv/policy/location" component={PolicyLocation} /> */}
				</div>
			</article>

		</section>
    );
};

export default PolicyIndex;
