// import { useUserStore } from "../stores2/UserContext";
import jwt from 'jsonwebtoken';
import React from "react";
import { Redirect, Route } from "react-router-dom";
import useDspLocalStorage from "../services/UseDspLocalStorage";
import { globalInfoStore } from "../stores/GlobalInfoStore";


export const PrivateRoute = ({ component:Component, render, previous, ...rest } : any) => {
    const [privateRoutePrev, setPrivateRoutePrev, removePrivateRoutePrev] = useDspLocalStorage('PrivateRoutePrev', JSON.stringify(rest));
    const [redirectPage, setRedirectPage, removeRedirectPage] = useDspLocalStorage('redirectPage', {});

    // useEffect(() => {
    //     console.log("PrivateRoute::4::" + JSON.stringify(rest)) 
    //     setRedirectPage(rest.path);
    //     setPrivateRoutePrev(JSON.stringify(rest))
    //     return () => {
    //         console.log("PrivateRoute::useEffect::return")
    //     }
    // }, [])

    const renderFn = (param: any) => {
        console.info("PrivateRoute::5::globalInfoStore.getDspJwt()::" + globalInfoStore.getDspJwt())
        if (globalInfoStore.getDspJwt() != null && globalInfoStore.getDspJwt() != "") {
            const decodedJwt:any = jwt.decode(globalInfoStore.getDspJwt());
            const expires = decodedJwt.exp * 1000; //Date.now() 와 비교하기 1000ms 곱
            if (Date.now() > expires) {
                // return <Redirect to="/app/signin/index/login" />       
                console.log("PrivateRoute::1::" + JSON.stringify(param)) 
                setRedirectPage(param.location.pathname);
                setPrivateRoutePrev(JSON.stringify(param))
                return (<Redirect to={{ pathname: "/app/signin/index/login", state: { from: param.location } }}/>)
            }
            console.log("PrivateRoute::2::" + JSON.stringify(param)) 
            setRedirectPage(param.location.pathname);
            setPrivateRoutePrev(JSON.stringify(param))
            // return React.createElement(param.component, param);
            return <Component {...param}></Component>;
        }
        console.log("PrivateRoute::3::" + JSON.stringify(param)) 
        console.log("PrivateRoute::3::" + param.location.pathname)
        setRedirectPage(param.location.pathname);
        setPrivateRoutePrev(JSON.stringify(param))
        return (<Redirect to={{ pathname: "/app/signin/index/login", state: { from: param.location } }}/>)
    }
    
    return <Route {...rest} render={renderFn} />;
};
