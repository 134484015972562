import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { globalInfoStore } from "../../stores/GlobalInfoStore";

const MainCardSlider = () => {
    const { t, i18n } = useTranslation();
//  const { globalInfoStore } = useStore();
    // const customSlider = React.useRef<Slider>(null)
    let customSlider = useRef(Array(1));
    // let customSlider = useRef(null);
    const [currentSlide, setCurrentSlide] = useState<number>(1);

    // const divWrapRef = React.useRef<HTMLDivElement>(null) // note the passed in `null` arg
    // let targetElement:any = null;
    useEffect(() => {
      const fetchPass = async () => {};
      fetchPass();

      // targetElement = divWrapRef.current;
      // enableBodyScroll(targetElement);
      //  disableBodyScroll(targetElement);
    }, [globalInfoStore.currentLangType]);

    const next = () => {
      customSlider.current[0].slickNext();
      // console.log("next" + customSlider);
    }
    const previous = () => {
      customSlider.current[0].slickPrev();
      // console.log("prev:" + customSlider);
    }

    const [sliderSettings, setSliderSettings] = useState({
      centerMode: true,
      infinite: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 750,
          settings: {
            centerPadding: "10px",
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
        {
          breakpoint: 10000,
          settings: {
            centerPadding: "10px",
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        }
      ],
      afterChange: ((currentSlide:number) => {
        setCurrentSlide(currentSlide + 1);
      })
    });

    //TODO: 모바일만 동작 반응형에서 체크 필요.
    return (
      <div className="layout">
        <h2 className="main_txt fade">{t("main.discover_seoul_pass")}</h2>
        <p className="main_exp fade">
          {t("main.discover_seoul_pass_subtitle")}
        </p>

        {/* <p className="main_exp">
          서울에서 무엇을 해야 할지, 어디에 가야 할지 고민되세요?
          <br />
          공항에서 시대 관광까지 디스커버서울패스 한 장이면 충분합니다.
        </p> */}

        <div className="slider">
          <Slider {...sliderSettings} ref={(ref) => { customSlider.current[0] = ref}}>
            <div className="slide">
              <img src={require("../../resources/img/main_card02_n.png")} alt="48시간권 이미지"/>
              <p className="m_only"> {t("main.discover_seoul_pass")} <b> {t("common.48h")}</b></p>
            </div>
            <div className="slide">
            <img src={require("../../resources/img/main_card03_n.png")} alt="72시간권 이미지"/>
              <p className="m_only"> {t("main.discover_seoul_pass")} <b> {t("common.72h")}</b></p>
            </div>
            <div className="slide">
              <img src={require("../../resources/img/main_card04_n.png")} alt="120시간권 이미지"/>
              <p className="m_only"> {t("main.discover_seoul_pass")} <b> {t("common.120h")}</b></p>
            </div>

          </Slider>
        </div>

        <div className="pager">
          <button className="btn_prev" onClick={previous}>
            이전 이미지
          </button>
          <p className="cnt">
            <span className="current">{currentSlide}</span>
            <span className="total">3</span>
          </p>
          <button className="btn_next" onClick={next}>
            다음 이미지
          </button>
        </div>
      </div>
    );
}

export default MainCardSlider;
