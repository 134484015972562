// import { todo } from './Todo';
import { globalInfoStore } from './GlobalInfoStore';
// import { createUserStore as userStore} from '../stores2/UserStore';

// const useRootStore = () => ({ todo, globalInfoStore });
const useRootStore = () => ({ globalInfoStore });

// const useStore = () => {
//     //   return { counter, market };
//         return { globalInfoStore };
//     };
    

export default useRootStore;