import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Navigation from '../../navigation/Navigation';
import AxiosConfig from '../../../services/AxiosConfig';
import Axios, { CancelTokenSource } from 'axios';
import Select from 'react-select/src/Select';
import validator from 'validator' 
import { ISignUpData } from '../../signin/SignUpWithEmail';
import { setReactionScheduler } from 'mobx/dist/internal';
import { globalInfoStore } from "../../../stores/GlobalInfoStore";
import { getPlatforms } from "@ionic/react";
// import { sign } from 'crypto';

const classNames = require("classnames");

const ProfileContentModify = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory()
 
    // const [signUpData, setSignUpData] = useState<SignUpData>({
    //     email: "",
    //     name: "",
    //     gender: null,
    //     code: null,
    //     // password: "",
    
    //     birthYear: null,
    //     countryUid: null,
    //     telCountryUid: null,
    //     tel: null,
    //     isReceived: null,
    //     platformInfo: getPlatforms().join(",")
    // });
    
    const [code, setCode] = useState(1);
    const [email, setEmail] = useState("");
    const [fullname, setFullname] = useState("");
    // const [isMale, setIsMale] = useState(false);
    const [gender, setGender] = useState(null);//회원 성별 ( 1: 남자, 2: 여자 )
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [selectedBirthyear, setSelectedBirthyear] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedPhoneCountry, setSelectedPhoneCountry] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isSubscribeNews, setIsSubscribeNews] = useState(2);

    const [listOfBirthYear, setListOfBirthYear] = useState([]);
    const [listOfCountry, setListOfCountry] = useState([]);
    const [orderedListOfCountry, setOrderedListOfCountry] = useState([]);
    let lang = "en";

    const fetchListOfBirthYear = () => {
        const year = (new Date()).getFullYear();
        setListOfBirthYear(Array.from(new Array(120),( val, index) => year - index));
    }

    const fetchListOfCountry = async () => {
        const res = await AxiosConfig.get("/api2/countryInfo");
        setListOfCountry(res.data);
        // setOrderedListOfCountry(res.data.content.filter((it:any) => it.fixed == 'Y')); // 기본정렬은 telCode 로 되어 잇음.
    };

    const fetchMe = async () => {

        const res = await AxiosConfig.get("/api2/memberInfo");
        // setSignUpData(res.data);
        setCode(res.data.code);
        setEmail(res.data.email)
        setFullname(res.data.name)
        setGender(res.data.gender)
        setSelectedBirthyear(res.data.birthYear)
        setSelectedCountry(res.data.countryUid)
        setSelectedPhoneCountry(res.data.telCountryUid)
        setPhoneNumber(res.data.tel)
        setIsSubscribeNews(res.data.isReceived)
    }

        
    useEffect(() => {
            fetchListOfBirthYear();
            fetchListOfCountry();
            fetchMe();
    }, [globalInfoStore.currentLangType])

    const renderListOfCountry = (listOfCountry || []).map((item) => {
        return (
            <option key={item.uid} value={item.uid}>
              {item[lang]}({item.telCode}) 
            </option>
          )
    });

    // const handleOnBlurEmail = () => {
    //     let emailError = "";

    //     if (!validator.isEmail(signUpData.email)) {
    //       emailError = "email Error!";
    //     }
    //     if (emailError) {
    //         // alert("이메일 주소 확인.")
    //     //   this.setState({ emailError });
    //       return false;
    //     }
    //     return true;
    // }

    /**
     * 
     * @param gender 회원 성별 ( 1: 남자, 2: 여자 )
     */
    // const setGender = (gender:number) => {
    //     setGender(gender);
    // }

    const handleIsSubscribeNews = () => {
        console.log("handleIsSubscribeNews" + !isSubscribeNews);
        setIsSubscribeNews(isSubscribeNews==1?2:1);
    }

    const handleBirthyear = () => {

    }

    const handleCountry = () => {

    }

    const postUpdateProfile = async (signUpData:ISignUpData) => {
        const res = await AxiosConfig.put("/api2/memberInfo/updateMemberInfo",  
            signUpData
        ) 
        console.log(res.data); 
        // code:'200'
        // desc:'정상
        if (res.status == 200 && res.data.code == 200) {
            alert("Your membership information has been modified");
            history.replace("/app/mypage/profile/index");
        }

        
    }

    /**
     * FIXME: 이름 길이 체크 추가.
     */
    const submitSave = () => {
       // 이메일(아이디) *
        // 이메일을 입력하세요.
        if (!validator.isEmail(email)) { 
            alert("Please Check Email Address.")
            return;
        }
        
        //이름 확인 FIXME: 길이는 
        if (!( fullname.length > 2 && fullname.length < 51 )) {
            alert("Please Check Name.(Min 3, Max 50");
            return;
        }

        // 출생년도 *
        // if (selectedBirthyear == t("sign_up_with_email.select_birth") || selectedBirthyear == "" ) {
        //     alert("Please Select Birthyear.");
        //     return;
        // }

        // 국적 *
        // if (selectedCountry == t("sign_up_with_email.select_country") || selectedCountry == "" ) {
        //     alert("Please Select Country.");
        //     return;
        // }
        
        if (selectedPhoneCountry == t("sign_up_with_email.select_phone_no") || selectedPhoneCountry == "" ) {
            alert("Please Select Phone Country.");
            return;
        }
        // 국가 전화번호 확인
        if (!validator.isMobilePhone(phoneNumber)) {
            alert("Please Check Phone Number");
            return;
        }

        // //이름 확인
        // if (!( password.length > 6 && password.length < 25 )) {
        //     alert("password 1");
        //     return;
        // }

        // if (!( passwordConfirm.length > 6 && passwordConfirm.length < 25 )) {
        //     alert("passwordConfirm 1");
        //     return;
        // }

        //비밀번호 두개가 같은지 체크

        let signUpData2:ISignUpData = {
            code: code,
            countryUid: parseInt(selectedCountry),
            telCountryUid: parseInt(selectedPhoneCountry),
            gender: gender,
            email: email,
            name: fullname,
            // password: "",
            tel: phoneNumber,
            // mobileUuid: uu,
            // snsId: "",
            birthYear: selectedBirthyear,
            isReceived: isSubscribeNews,
            platformInfo: getPlatforms().join(",")
        };
        postUpdateProfile(signUpData2)

    }

    return (

				<div className="layout">
	
					<div className="wrap_info">
                        <h6 className="sec_tit side" dangerouslySetInnerHTML={{__html:t("profile_content_modify.title")}}>

                        </h6>

						<em className="top_exp">* {t("profile_content_modify.required")}</em>

						<dl>
							<dt>{t("profile_content_modify.email")} <em>*</em></dt>
							<dd>
                                <input className="readonly" type="text" autoCapitalize="false" 
                                value={email} 
                                onChange={e => setEmail(e.target.value)}
                                title="이름" readOnly={true}/>
							</dd>
						</dl>
						<dl>
							<dt>{t("profile_content_modify.name")} <em>*</em></dt>
							<dd>
								<input type="text" value={fullname} onChange={e => setFullname(e.target.value)} title="이름" />
							</dd>
						</dl>
						{/* <dl>
							<dt>{t("profile_content_modify.year_of_birth")} </dt>
							<dd>
								<select onChange={e => setSelectedBirthyear(e.target.value)} value={selectedBirthyear}>
									<option>{t("profile_content_modify.select_birth")}</option>
									{listOfBirthYear.map((year, index) => {
										return <option key={`year${index}`} value={year}>{year}</option>
									})}
								</select>
							</dd>
						</dl> */}
						<dl>
							<dt>{t("profile_content_modify.country")} </dt>
							<dd>
								<select onChange={e => setSelectedCountry(e.target.value)}  value={selectedCountry}>
									<option>{t("profile_content_modify.select_country")}</option>
									{renderListOfCountry}
								</select>
								{/* <Select options={options} /> */}
							</dd>
						</dl>
						<dl>
							<dt>{t("profile_content_modify.gender")} </dt>
							<dd>
                                <button className={classNames({active: gender==null})} onClick={()=>setGender(null)}>{t("profile_content_modify.gender_unknown")}</button>
								<button className={classNames({active: gender==2})} onClick={()=>setGender(2)}>{t("profile_content_modify.female")}</button>
								<button className={classNames({active: gender==1})} onClick={()=>setGender(1)}>{t("profile_content_modify.male")}</button>
							</dd>
						</dl>
						<dl>
							<dt>{t("profile_content_modify.phone_no")} <em>*</em></dt>
							<dd>
								<select className="phone" onChange={e => setSelectedPhoneCountry(e.target.value)} value={selectedPhoneCountry}>
									<option>{t("profile_content_modify.select_phone_no")}</option>
									{renderListOfCountry}
								</select>
								<input type="text" title="전화번호" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} autoComplete="false" />
							</dd>
						</dl>

						<input type="checkbox" id="ck" title="체크박스" checked={isSubscribeNews==1?true:false} onChange={handleIsSubscribeNews}/>
						<label htmlFor="ck">{t("profile_content_modify.subscribe_desc")}</label>

                        {/* <button className="btn_save" onClick={submitSave}>저장하기</button> */}
                        <button className="btn_save" onClick={submitSave}>{t("profile_content_modify.save")}</button>
					</div>

				</div>
		// 	</article>

		// </section>
    );
};

export default ProfileContentModify;