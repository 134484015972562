import React from "react";
import { useTranslation } from "react-i18next";
import { Route } from 'react-router';
import Navigation from '../navigation/Navigation';
import SpotTitle from './SpotTitle';
import ArexTitle from './ArexTitle';
import OthersTitle from './OthersTitle';
import TransportationTitle from './TransportationTitle';
import Spot from './Spot';
import ArexQR from './ArexQr';
import TransportationIndex from './TransportationIndex';
import OthersIndex from './OthersIndex';
import SpotView from './SpotView';


const SpotIndex = () => {
	const { t, i18n } = useTranslation();
	
    return (
        <section id="container" className="sub">
			<h6 className="hidden">본문</h6>


			<article className="bg sub03">
				<Route exact path="/app/spot/index/list" component={SpotTitle} />
				<Route path="/app/spot/transportation/index" component={TransportationTitle} />
				<Route path="/app/spot/arex" component={ArexTitle} />
				<Route path="/app/spot/others/index" component={OthersTitle} />
			</article>

			<Navigation></Navigation>

			<article id="content">
				<Route exact path="/app/spot/index/list" component={Spot} />
				<Route path="/app/spot/index/view/:uid" component={SpotView} />
				<Route path="/app/spot/transportation/index" component={TransportationIndex} />
				<Route path="/app/spot/arex" component={ArexQR} />
				<Route path="/app/spot/others/index" component={OthersIndex} />
			</article>

		</section>
    );
};

export default SpotIndex;

