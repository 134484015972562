import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import AxiosConfig from "../../../services/AxiosConfig";
import { codeInfoAtom, ICodeInfo } from "../../../services/CodeInfoService";
import MyEsimItem from "./MyEsimItem";

export enum EPasstypeUid {
    CARD_24 = 1,
    MOBILE_24 = 2,
    CARD_48 = 3,
    MOBILE_48 = 4,
    CARD_72= 5,
    MOBILE_72 = 6
}

export interface IMyEsim {
    uid: number;                                                     //     * QR의 고유번호
    status: number;                                                     //     * QR 상태(1: 판매 가능, 2: 사용 가능, 5: 환불, 6: 폐기)
    productUid: number;                                                     //     * 상품의 고유번호
    amount: number;                                                     //     * QR에서 사용 할 수 있는 패스의 수
    orderUid: number;                                                     //     * 주문 고유번호
    qr_code: string;                                                     //     * QR 번호
    qr_mgmt_code: string;                                                     //     * QR 관리번호
    memo: string;                                                     //     * 해당 QR의 메모
    groupCode: string;                                                     //     * QR 그룹코드
    createDate: string;                                                     //     * QR 생성일자
    updateDate: string;                                                     //     * QR 수정일자
    expireDate: string;                                                     //     * QR 유효기간
    managingTitle: string;                                                     //     * 관리용 상품명
    koTitle: string;                                                     //     * 한국어 상품명
    enTitle: string;                                                     //     * 영어 상품명
    jaTitle: string;                                                     //     * 일본어 상품명
    zhCnTitle: string;                                                     //	 * 중국어(간체) 상품명
    zhTwTitle: string;                                                     //	 * 중국어(번체) 상품명
    purchaseDate: string;                                                     //	 * 구매일자
    passtypeCode: number;                                                     //     * 실물:1 / 모바일:2
    passtypeUid:  EPasstypeUid;                                                //
    passtypeTime: number;
    filename: string;
    filepath: string;
    fullpath: string;
    extension: string;

    estatus: number;
    prchs_dtm: string;
    rental_fee_prod_nm: string;
    cust_nm: string;
    email_addr: string;
    iccid_num: string;
    roming_phon_num: string;
}

const MyEsim = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [myEsimItems, setMyEsimItems] = useState([]);
    const [filteredMyEsimItems, setFilteredMyEsimItems] = useState<Array<IMyEsim>>([]);
    const [filteredCodeInfo, setFilteredCodeInfo] = useState<ICodeInfo[]>(null);
	const [codeInfoes, setCodeInfoes] = useRecoilState<ICodeInfo[]>(codeInfoAtom);

    const fetchCodeInfoes = async () => {
		if (codeInfoes.length < 1) {
			let url = "/api2/codeInfo";
			const res = await AxiosConfig.get(url);
			if (res.status === 200) {
				setCodeInfoes(res.data)
			}
			filteringCodeInfo(res.data)
		} else {
			filteringCodeInfo(codeInfoes)
		}
	}

	const filteringCodeInfo = (codeInfoList:ICodeInfo[]) => {
		const filteredCodeInfoByQrStatus = codeInfoList.filter((item:ICodeInfo)=> {
			return item.groupCd === "qr_status"
		})
        
		setFilteredCodeInfo(filteredCodeInfoByQrStatus);
	}

    const fetchMyEsimItems = async () => {
        const url = `/api2/myEsim`;
        const res = await AxiosConfig.get(url);
        setMyEsimItems(res.data);
        setFilteredMyEsimItems(res.data.content);
    }



    useEffect(()=>{
        fetchCodeInfoes()
        fetchMyEsimItems();
    },[]);

    const handleClickMyPass = () => {
        alert("handleClickMyPass")
    }

    const renderMyEsims = (filteredMyEsimItems || []).map((item) => {
        return (<MyEsimItem 
                key={item.uid} 
                myEsimItem={item}
                filteredCodeInfoes={filteredCodeInfo}
                click={handleClickMyPass}
          ></MyEsimItem>)
    })

    if (filteredMyEsimItems.length < 1) {
        return (
            <div className="layout">
					
                <div className="wrap_ask">
                    <p className="exp" dangerouslySetInnerHTML={{__html:t("how_eSIM.no_registered_esim")}}></p>
                </div>
            </div>
        )
    }

    
    return (
        <div className="layout">
					
            <div className="wrap_purchase">

                {renderMyEsims}

            </div>

        </div>
    );
};

export default MyEsim;