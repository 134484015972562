import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import { useHistory } from "react-router";
import useDspLocalStorage from "../../services/UseDspLocalStorage";
import AxiosConfig from '../../services/AxiosConfig';
import { IProductInfo } from "../../services/ProductInfoService";
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import PassListItem from "./PassListItem";
import PassSidebar from './PassSidebar';
import { useLocalStorage } from "react-use";

const classNames = require("classnames");

export interface IPage {
    [key: string]: any;
}

// export interface IRecentProduct {
//     productUid: string;
//     imageUrl: string;
// }

const Pass = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [recentPass, setRecentPass, removeRecentPass] = useLocalStorage('recentPass', []);

    const [passInfoes, setPassInfoes] = useState<IPage>();
    const [filteredPassInfoes, setFilteredPassInfoes] = useState<IProductInfo[]>([]);
    const [subFilterName, setSubFilterName] = useState("all"); //card, mobile
    const [page, setPage] = useState(1); //page
	const [size, setSize] = useState(12);  //
    const [sorts, setSorts] = useState([]); //sort['updateDate,desc','uid,asc']
    const [inputSearchText, setInputSearchText] = useState("");
    const [searchKeyword, setSearchKeyword] = useState("");

    const fetchPassInfoes = async (page:number, size:number, sorts:[]) => {

        let url = `/api2/productInfoWithDiscount?page=${page}&size=${size}`;

        if (sorts.length > 0){
            url += (sorts || []).map((item)=>`&sort=${item}`);
        }

        if (searchKeyword != ""){
            url += `&searchKeyword=${searchKeyword}`
        }

        if (subFilterName == "card"){
			url += `&code=1`
		} else if (subFilterName == "mobile") {
			url += `&code=2`
		}

        const res = await AxiosConfig.get(url);

        console.log(res.data);
        setPassInfoes(res.data);
        setFilteredPassInfoes(res.data.content)
    };

    useEffect(() => {
        fetchPassInfoes(page, size, []);
        window.scrollTo(0, 0);
    }, [globalInfoStore.currentLangType, page, subFilterName, searchKeyword]);

    const [isShowRecentPass, setIsShowRecentPass] = useState(false);
    useEffect(() => {
        console.log("recentPass.length: " + recentPass.length);
        setIsShowRecentPass(recentPass.length > 0)
    }, [recentPass.length]);

    const handleSubFilter = (subFilter:string) => {
		setSubFilterName(subFilter);
        setPage(1)
	}

    // const handleLikeClick = (e:any) => {
    //     alert("handleLikeClick")
    // }

    const renderProductList = (filteredPassInfoes || []).map((item) => {
        return (
            <PassListItem key={item.uid} productItem={item}></PassListItem>
        )
    });

    const handlePageClick = (pageNumber: any) => {
		setPage(pageNumber)
	};

    const onSearchTextChange = (e: any) => {
        console.log(e.type + ":", e.target.value);
        setInputSearchText(e.target.value);
    }

    const handleSearch = (e: any) => {
        /** TODO: 검색어 필터가 걸리고 다시 풀려고 할 경우에 대한 테스트 코드 필요...*/
        //INPUT 은 비엇고 searchKeyword는 있을 경우 filter를 제거하고 검색시도한다
        if (inputSearchText == null)  return;
        if (inputSearchText == "" && searchKeyword == "") { alert("검색어를 입력하세요."); return; }

        setSearchKeyword(inputSearchText)
        // fetchSpots(1, 18, []);
    }

    const onSearchKeyDown = (e: any) => {
		// setSearchKeyword(inputSearchText)
        if (e.keyCode === 13) {
			/** TODO: 검색어 필터가 걸리고 다시 풀려고 할 경우에 대한 테스트 코드 필요...*/
			//INPUT 은 비엇고 searchKeyword는 있을 경우 filter를 제거하고 검색시도한다
			if (inputSearchText == null)  return;
			if (inputSearchText == "" && searchKeyword == "") { alert("검색어를 입력하세요."); return; }

            setSearchKeyword(inputSearchText)
            // fetchSpots(page, size, []);// hooks로 변경.
        }
    }

    // const PassCount = () => {
    //     if (passInfoes == null)
    //         return (<span className="cnt">총 0 개</span>)
    //     return (<span className="cnt">총 {passInfoes.totalElements}개</span>)
    // }

    const PassPager = () => {

        if (passInfoes == undefined || passInfoes.totalPages < 2)
            return (<></>)

        return (
            <Pagination
                innerClass={'board_pager'}
                // activeClass={'active'}
                // activeLinkClass={'active'}
                activePage={page}
                itemsCountPerPage={size}
                totalItemsCount={passInfoes.totalElements}
                pageRangeDisplayed={5}
                onChange={(pageNumber)=>handlePageClick(pageNumber)}
                itemClassFirst={'btn_pprv'}
                itemClassPrev={'btn_prev'}
                itemClassNext={'btn_next'}
                itemClassLast={'btn_nnxt'}
            />
        )
    }


    return (
        <div className="layout">

            {/* <div className="wrap_sch">
                <fieldset>
                    <legend>패스 검색</legend>
                    <input type="text" title="검색" className="input_sch" placeholder="검색어를 입력하세요." />
                    <button className='btn_sch'>검색</button>
                </fieldset>
                <div className="left">
                    <span>총 120개</span>
                    <button className="active">전체</button>
                    <button>실물패스</button>
                    <button>모바일패스</button>
                </div>
            </div> */}

            <div className="wrap_sch">
                <fieldset>
                    <legend>패스 검색</legend>
                    <input type="text" title="검색" className="input_sch" placeholder={t("pass.search_placeholder")}
                    onChange={onSearchTextChange}
                    onKeyDown={(e) => onSearchKeyDown(e)} />
                    <button className="btn_sch" onClick={handleSearch}>검색</button>
                </fieldset>
                <div className="left">
                    {/* <span>{t("pass.total_prefix")} {(passInfoes == null)?0:passInfoes.totalElements}{t("pass.total_postfix")}</span> */}
                    <button className={classNames({active:(subFilterName == "all")})} onClick={e=>handleSubFilter("all")}>{t("pass.btn_all")}</button>
                    <button className={classNames({active:(subFilterName == "card_pass")})}  onClick={e=>handleSubFilter("card")}>{t("pass.btn_card_pass")}</button>
                    <button className={classNames({active:(subFilterName == "mobile_pass")})}  onClick={e=>handleSubFilter("mobile")}>{t("pass.btn_mobile_pass")}</button>
                </div>
            </div>

            <div className="wrap_passList" >
                {renderProductList}
            </div>

            <PassPager></PassPager>

            {(isShowRecentPass)?<PassSidebar show={setIsShowRecentPass}></PassSidebar>:""}

            

        </div>
    );
};

export default Pass;