import React, { Component, useEffect, useRef, forwardRef, LegacyRef, useCallback, useState, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router";
import Footer from "./main/Footer";
import Header from "./main/Header";

import {BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { PrivateRoute } from '../routes/PrivateRoute';
import CsIndex from './cs/CsIndex';
import CsIndexNV from './cs/CsIndexNV';
import EndRedirect from "./auth/EndRedirect";
import Main from "./main/Main";
import SpotIndex from "./spot/SpotIndex";
import SpotIndexNV from "./spot/SpotIndexNV";
import SignInIndex from "./signin/SignInIndex";
import MypageIndex from "./mypage/MypageIndex";
import SupportPage from "./mypage/support/SupportPage";
import Pass from "./pass/Pass";
import GuideIndexNV from "./guide/GuideIndexNV";
import useStore from "../stores/UseRootStore";
import { observe } from "mobx";
// import Search from "./search/Search";
import SearchIndexNV from "./search/SearchIndexNV";
import Terms from "./policy/PolicyTerms";
import Privacy from "./policy/PolicyPrivacy";
import PolicyTerms from "./policy/PolicyTerms";
import PolicyPrivacy from "./policy/PolicyPrivacy";
import PolicyLocation from "./policy/PolicyLocation";
import PolicyIndex from "./policy/PolicyIndex";
import PolicyIndexNV from "./policy/PolicyIndexNV";
import PassIndex from "./pass/PassIndex";
import ReactEnv from "../ReactEnv";

import { LANG_TYPES, ILanguage, ISiteLanguageInfo } from "../stores/GlobalInfoStore";

const classNames = require("classnames");


interface LayoutProps extends RouteComponentProps {
  // RefObject
}
const LayoutNV : React.FC<LayoutProps> = (props: LayoutProps) => {
    const { t, i18n } = useTranslation();

    const { globalInfoStore } = useStore();
    const [lastScrollTop, setLastScrollTop] = useState(0);

    const [appHeader, setAppHeader] = useState(false);
    const [btnTop, setBtnTop] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const appWrap = useRef<HTMLDivElement>(null);
    const contentRef = useRef(null);

    const AppHeader = observe(globalInfoStore, "appHeader", (change) => {
      setAppHeader(Boolean(change.newValue));
    });

    const ButtonTop = observe(globalInfoStore, "btn_top", (change) => {
      setBtnTop(Boolean(change.newValue));
    });

    const Sidebar = observe(globalInfoStore, "sidebar", (change) => {
      setBtnTop(Boolean(change.newValue));
    });
  
  
    useEffect(() => {  
      const query = new URLSearchParams(props.location.search);
      const lang = query.get('lang');
      var langNumber = 1; //ENG(Default)
      if(lang) {
        if(Number(lang) > 0)
          langNumber = Number(lang)-1;
      }
      globalInfoStore.handleSetLang(LANG_TYPES[langNumber])
    }, []); 
  
    const filter = "win16|win32|win64|mac|macintel";
  
    const updateHeader = () => {
      const nowScroll = window.scrollY;
  
        if ( navigator.platform ) { 
              if ( filter.indexOf( navigator.platform.toLowerCase() ) < 0 ) { 
                    
                  if(nowScroll >= 0 && nowScroll < 200){
  
                      globalInfoStore.appHeader = false;
                      globalInfoStore.sidebar = false;
                      globalInfoStore.btn_top = false;
  
                  }else if(nowScroll >= 200){
  
                    globalInfoStore.appHeader = true;
                    globalInfoStore.sidebar = true;
  
                  };
                
                  if(nowScroll >= 3600){
                    globalInfoStore.btn_top = true;
                  }else{
                    globalInfoStore.btn_top = false;
                  }
  
  
              } else {
  
                  if(nowScroll >= 0 && nowScroll < 1000){
  
                      if(nowScroll >= 300) {
                        globalInfoStore.appHeader = true;
                        globalInfoStore.sidebar = true;
                      }else{
                        globalInfoStore.appHeader = false;
                        globalInfoStore.sidebar = false;
                        globalInfoStore.btn_top = false;
                      }
  
                  }else if(nowScroll >= 1000){
  
                    globalInfoStore.appHeader = true;
                    globalInfoStore.sidebar = true;
  
                  };
                
                  if(nowScroll >= 1200){
                    globalInfoStore.btn_top = true;
                  }else{
                    globalInfoStore.btn_top = false;
                  }
  
                  
              } 
          }
    }
  
    window.addEventListener("scroll", updateHeader)
    return (
      <>
  
        <div id="wrap" ref={appWrap}>
            
            <Route path="/appnv/search"  component={SearchIndexNV} />

            <Route path="/appnv/policy" component={PolicyIndexNV} />
            <Route path="/appnv/guide/index" component={GuideIndexNV} />
            <Route path="/appnv/cs/index" component={CsIndexNV} />
            <Route path="/appnv/spot" component={SpotIndexNV} />

        </div>
      
      </>
    );
  
}

export default LayoutNV;
