import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AxiosConfig from '../../services/AxiosConfig'
import Axios, { CancelTokenSource } from 'axios';
import Select from 'react-select/src/Select';
import validator from 'validator' 
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import { useHistory } from "react-router";

const classNames = require("classnames");

const FindId = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const [email, setEmail] = useState("");
    const [fullname, setFullname] = useState("");
    // const [isMale, setIsMale] = useState(false);
    const [gender, setGender] = useState(2);//회원 성별 ( 1: 남자, 2: 여자 )
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [selectedBirthyear, setSelectedBirthyear] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedPhoneCountry, setSelectedPhoneCountry] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isSubscribeNews, setIsSubscribeNews] = useState(false);


    const [listOfBirthYear, setListOfBirthYear] = useState([]);
    const [listOfCountry, setListOfCountry] = useState([]);
    const [orderedListOfCountry, setOrderedListOfCountry] = useState([]);
    let lang = "en";


    const fetchListOfCountry = async () => {
        const res = await AxiosConfig.get("/api2/countryInfo");
        setListOfCountry(res.data);
        // setOrderedListOfCountry(res.data.content.filter((it:any) => it.fixed == 'Y')); // 기본정렬은 telCode 로 되어 잇음.
    };

    const postFindId = async () => {
        const url = `/api2/memberInfo/findEmailByNameAndTel`;
        const data = JSON.stringify({
                "name":fullname,
                "tel":phoneNumber,
                "telCountryUid":selectedPhoneCountry
            });

        const res = await AxiosConfig.post(url,
            data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            });
        if (res.status == 200) {
            // setFindIdResult(res.data);
            // res.data.email
            history.push({
                pathname: '/app/signin/index/findid/complete',
                state: { email: res.data.email }
            });
        } else {
            alert("Not Found Account.")
        }
    }

    useEffect(() => {
        fetchListOfCountry();
    }, [globalInfoStore.currentLangType])

    const renderListOfCountry = (listOfCountry || []).map((item) => {
        const currentLang = "ko";
        let langTitle = "koTitle";
        let langDescription = "koDescription";
        return (
            <option key={item.uid} value={item.uid}>
              {item[lang]}({item.telCode}) 
            </option>
          )
    });

    return (
        <div className="layout">
            
            <div className="wrap_info find">
                <h6 className="sec_tit side">{t("find_id.title")}</h6>
                <p className="sec_exp side" dangerouslySetInnerHTML={{__html:t("find_id.sub_title")}}></p>

                <em className="top_exp">* {t("find_id.required")}</em>

                <dl>
                    <dt>{t("find_id.name")} <em>*</em></dt>
                    <dd>
                        {/* FIXME: <!-- 입력 안 됐을 경우 'focus' 클래스 추가 --> */}
                        {/* <input type="text" title="이름" className="focus" /> */}
                        <input type="text" value={fullname} onChange={e => setFullname(e.target.value)} title="이름" />
                    </dd>
                    <dd className="txt_alert">{t("find_id.alert_input_name")}</dd>
                </dl>
                <dl>
                    <dt>{t("find_id.phone_no")} <em>*</em></dt>
                    <dd>
                        <select className="phone"  onChange={e => setSelectedPhoneCountry(e.target.value)} value={selectedPhoneCountry}>
                            <option>{t("find_id.select_country")}</option>
                            {renderListOfCountry}
                        </select>
                        <input type="text" title="전화번호" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} autoComplete="false" />
                    </dd>
                    {/* <dd className="txt_alert">국가번호를 선택해주세요</dd>
                    <dd className="txt_alert">전화번호를 입력해주세요</dd> */}
                </dl>
                <button className="btn_find" onClick={postFindId}>{t("find_id.btn_find_id")}</button>
            </div>

        </div>
    );
};

export default FindId;