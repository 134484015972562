import React from "react";
import { useTranslation } from "react-i18next";

const CsTitleNotice = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <div className="layout">
            {/* <h2 className="page_tit">공지사항</h2> */}
            <h2 className="page_tit">{t("navigation.app_depth2title_cs_index_notice")}</h2>
            {/* <p>디스커버서울패스의 새로운 소식과 이벤트를 확인하세요.</p> */}
            <p>{t("notice.sub_title")}</p>
        </div>
    );
};

export default CsTitleNotice;