import React from "react";
import { useTranslation } from "react-i18next";

// const SpotViewNotice = (props:any) => {
const SpotViewNotice = (props:{notice:any}) => {
    const { t, i18n } = useTranslation();
    
    if (props == null || props.notice == null || props.notice.trim() == "") {
        return null;
    }

    return (
        // <p className="top_notice">6월 26일부터 한국방역 지침에 따라, 롯데월드 방문 시 필수적으로 명부(이름, 전화번호 등) 작성 후 입장해야 합니다. 롯데월드 방문자는 여권 또는 외국인 등록증(주한외국인)을 지참해주세요. ※ 여권 미 소지 시 입장불가</p>
        <p className="top_notice">{props.notice}</p>
    );
};

export default SpotViewNotice;