import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import { Link, useHistory } from "react-router-dom";
import AxiosConfig from '../../../services/AxiosConfig';
import { globalInfoStore } from "../../../stores/GlobalInfoStore";
import { codeInfoAtom, codeInfoByGroupCd, ICodeInfo } from "../../../services/CodeInfoService";
import Moment from "react-moment";
import { useRecoilState, useRecoilValue } from "recoil";
import { getValueByKey } from "../../../services/Util";

const classNames = require("classnames");

interface InquiryInfo  {
    [key: string]: any;
}

export interface IQuestionType {
	groupCd: string,
	codeId: number,
	codeCd: string,
	codeName: string
}

const InquiryList = () => {
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const filteredCodeInfo = useRecoilValue<ICodeInfo[]>(codeInfoByGroupCd({groupCd:"qna-category"}));

    const [inquiry, setInquiry] = useState<InquiryInfo>();
    const [filteredInquiryList, setFilteredInquiryList] = useState([]);
    // const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1); //page
	const [size, setSize] = useState(10);  //
	// const [sorts, setSorts] = useState([]); //sort['updateDate,desc','uid,asc']
    // initialValue.push(...allowedState);

    const fetchInquiry = async (page:number, size:number, sorts:[]) => {
        let url = `/api2/csQnaInfo?page=${page}&size=${size}`;

        if (sorts.length > 0){
            url += (sorts || []).map((item)=>`&sort=${item}`);
        }

        const res = await AxiosConfig.get(url);

        setInquiry(res.data);
        setFilteredInquiryList(res.data.content)
    };
    
    useEffect(() => {
        fetchInquiry(page, size, []);
        window.scrollTo(0, 0);
    }, [globalInfoStore.currentLangType, page]);

    const getCodeInfo = (codeId:number) => {
        const codeInfo:ICodeInfo = filteredCodeInfo.find((item:ICodeInfo) => {
            return item.codeId === codeId;
        });
        if (codeInfo == null) {
            console.error("category type is null");
            return 1;
        }
        return getValueByKey(codeInfo, globalInfoStore.currentLangType.codename);
    }

    const renderInquiryItem = filteredInquiryList.map((item) => {
        return (
            <div className="ask">
                <ul>
                    <li>
                        <p className="th" dangerouslySetInnerHTML={{__html:t("inquiry.create_date")}}>
                        </p>
                        <p className="td"> <Moment format="YYYY-MM-DD H:mm:ss">{item.createDate}</Moment>
                            <span className="state">{item.completed==1?t("inquiry.inquiry_accepted"):t("inquiry.answered")}</span> 
                            <span className={classNames({state:true, finish:item.completed==2})}>{item.completed==1?t("inquiry.inquiry_accepted"):t("inquiry.answered")}</span> 
                        </p>
                    </li>
                    <li>
                        <p className="th">{t("inquiry.inquiry_type")}</p>
                        {/* <p className="td">일반사항</p> */}
                        <p className="td">
                            {
                                getCodeInfo(item.category)
                            }
                        </p>
                    </li>
                    <li>
                        <p className="th">{t("inquiry.your_email")}</p>
                        {/* <p className="td">hongildong@naver.com</p> */}
                        <p className="td">{item.email}</p>
                    </li>
                    <li>
                        <p className="th">{t("inquiry.details")}</p>
                        <p className="td m_100">
                            {item.question}
                        </p>
                    </li>
                </ul>
            </div>
        )
    })
    const handlePageClick = (pageNumber: any) => {
		setPage(pageNumber)
	};

    const DspPager = () => {

        if (inquiry == undefined || inquiry.totalPages < 2)
            return (<></>)

        return (
            <Pagination
                innerClass={'board_pager'}
                // activeClass={'active'}
                // activeLinkClass={'active'}
                activePage={page}
                itemsCountPerPage={size}
                totalItemsCount={inquiry.totalElements}
                pageRangeDisplayed={5}
                onChange={(pageNumber)=>handlePageClick(pageNumber)}
                itemClassFirst={'btn_pprv'}
                itemClassPrev={'btn_prev'}
                itemClassNext={'btn_next'}
                itemClassLast={'btn_nnxt'}
            />
        )
    }

    const InquiryHistoryNone = () => {
        if (filteredInquiryList.length == 0)
            return (
                <>
                    <p className="exp">{t("inquiry.no_inquiries_found")}</p>
                    <Link to="/app/cs/index/onetoone" target="_self" className="btn_goToInquiry">{t("inquiry.make_inquiry")}</Link> 
                </>
            );
        return (null);
    };
    
    // if (filteredInquiryList.length > 0) { // 문의가 있으면
    //   return <InquiryHistory></InquiryHistory>;
    // }
    // return <InquiryHistoryNone></InquiryHistoryNone>;


    return (
        <div className="layout">
                        
            <div className="wrap_ask">
                <InquiryHistoryNone></InquiryHistoryNone>
                {renderInquiryItem}
            </div>
            
            <DspPager></DspPager>
        </div>
    )
};

export default InquiryList;